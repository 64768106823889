import { useEffect } from 'react';

/**
 * Hook that allows setting an event listener that will be called every time the
 * user clicks outside of the element (passed through ref).
 *
 * Based on: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export const useOnClickElsewhereListener = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside, undefined);
    };
  }, [callback, ref]);
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default useOnClickElsewhereListener;
