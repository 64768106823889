import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';
import { GeneralTerms } from '../shared/TermsAndConditions/TermsAndConditions';
import tnc from '../../../../config/no/terms';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        Ved å be om tilbud godtar du{' '}
        <GeneralTerms terms={tnc}>våre vilkår</GeneralTerms>.
      </div>
    );
  }
}

export function registerNOTermsQuestion() {
  const questionType = 'Axo Terms NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
