import { FC } from 'react';
import { useInputState } from '../../../hooks/useInputState';
import { IWrappedSoftValidatedInput } from '../../../models';
import Radio from '../../atoms/Radio';
import InputWrapper from '../InputWrapper';
import styles from './RadioGroup.module.scss';

// Type from SurveyJS - itemValue
type ItemValue = string | number | boolean;
interface IRadioGroupOption {
  value: ItemValue;
  text: string;
}

type IOption = string | IRadioGroupOption;

export interface IRadioGroup
  extends IWrappedSoftValidatedInput<HTMLInputElement, string> {
  options: IOption[];
  radioStyle?: 'radio' | 'button' | 'both' | 'both-larger';
  color?: 'success' | 'primary';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const RadioGroup: FC<IRadioGroup> = ({
  options,
  radioStyle,
  color,
  ...props
}) => {
  const inputState = useInputState({ ...props, visuallyDetachedState: true });

  const { name, onChange, onClick } = inputState.htmlProps;

  return (
    <InputWrapper inputState={inputState} className={'RadioGroup'}>
      <div
        className={styles.group}
        onClick={onClick}
        role="radiogroup"
        aria-labelledby={inputState.axoProps.labelId}
      >
        {options.map((option) => {
          const text = typeof option === 'object' ? option.text : option;
          const value = typeof option === 'object' ? option.value : option;
          return (
            <Radio
              key={text}
              id={text}
              name={name}
              value={value.toString()}
              checked={value === inputState.htmlProps.value}
              radioStyle={radioStyle}
              color={color}
              onChange={(e) => {
                inputState.setValue(e.target.value);
                onChange?.(e);
              }}
            >
              {text}
            </Radio>
          );
        })}
      </div>
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default RadioGroup;
