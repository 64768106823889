/* eslint-disable max-classes-per-file */
import { ErrorModel } from '@axo/deprecated/util/ui-components';
import { IThousandsSeparator } from '@axo/shared/util/string';
import React, { CSSProperties } from 'react';
import {
  ElementFactory,
  LocalizableString,
  FunctionFactory,
  Question,
  QuestionMultipleTextModel,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import LoanOverview, { ILoanOverview } from './LoanOverview';
import {
  calculateMonthlyPayment,
  calculateMonthlyPaymentNO,
} from '../../../utils/CalculateMonthlyPayment';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionMultipleTextModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('appliedAmountLabel', this, false);
    this.createLocalizableString('loanDurationLabel', this, false);
    this.createLocalizableString('editModeButtonText', this, false);
    this.createLocalizableString('readonlyModeButtonText', this, false);
    this.createLocalizableString('monthlyPayLabel', this, false);
    this.createLocalizableString('loanDurationSuffix', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get syncAppliedAmount() {
    return this.getPropertyValue('syncAppliedAmount', '');
  }

  set syncAppliedAmount(newValue: string) {
    this.setPropertyValue('syncAppliedAmount', newValue);
  }

  get appliedAmountLabel() {
    return this.getLocalizableStringText('appliedAmountLabel', '');
  }

  set appliedAmountLabel(newValue: string) {
    this.setLocalizableStringText('appliedAmountLabel', newValue);
  }

  get locAppliedAmountLabel(): LocalizableString {
    return this.getLocalizableString('appliedAmountLabel');
  }

  get syncLoanDurationYears() {
    return this.getPropertyValue('syncLoanDurationYears', '');
  }

  set syncLoanDurationYears(newValue: string) {
    this.setPropertyValue('syncLoanDurationYears', newValue);
  }

  get loanDurationLabel() {
    return this.getLocalizableStringText('loanDurationLabel', '');
  }

  set loanDurationLabel(newValue: string) {
    this.setLocalizableStringText('loanDurationLabel', newValue);
  }

  get locLoanDurationLabel(): LocalizableString {
    return this.getLocalizableString('loanDurationLabel');
  }

  get loanDurationSuffix() {
    return this.getLocalizableStringText('loanDurationSuffix', '');
  }

  set loanDurationSuffix(newValue: string) {
    this.setLocalizableStringText('loanDurationSuffix', newValue);
  }

  get locLoanDurationSuffix(): LocalizableString {
    return this.getLocalizableString('loanDurationSuffix');
  }

  get interestRateYearly() {
    return this.getPropertyValue('interestRateYearly', 0.0);
  }

  set interestRateYearly(newValue: number) {
    this.setPropertyValue('interestRateYearly', newValue);
  }

  get editModeButtonText() {
    return this.getLocalizableStringText('editModeButtonText', '');
  }

  set editModeButtonText(newValue: string) {
    this.setLocalizableStringText('editModeButtonText', newValue);
  }

  get locEditModeButtonText(): LocalizableString {
    return this.getLocalizableString('editModeButtonText');
  }

  get readonlyModeButtonText() {
    return this.getLocalizableStringText('readonlyModeButtonText', '');
  }

  set readonlyModeButtonText(newValue: string) {
    this.setLocalizableStringText('readonlyModeButtonText', newValue);
  }

  get locReadonlyModeButtonText(): LocalizableString {
    return this.getLocalizableString('readonlyModeButtonText');
  }

  get currencyCode() {
    return this.getPropertyValue('currencyCode', '');
  }

  set currencyCode(newValue: ILoanOverview['currencyCode']) {
    this.setPropertyValue('currencyCode', newValue);
  }

  get monthlyPayLabel() {
    return this.getLocalizableStringText('monthlyPayLabel', '');
  }

  set monthlyPayLabel(newValue: string) {
    this.setLocalizableStringText('monthlyPayLabel', newValue);
  }

  get locMonthlyPayLabel(): LocalizableString {
    return this.getLocalizableString('monthlyPayLabel');
  }

  get thousandsSeparator() {
    return this.getPropertyValue('thousandsSeparator', ' ');
  }

  set thousandsSeparator(newValue: IThousandsSeparator) {
    this.setPropertyValue('thousandsSeparator', newValue);
  }

  get valuePrefix(): string {
    return this.getPropertyValue('valuePrefix', '');
  }

  set valuePrefix(_valuePrefix: string) {
    this.setPropertyValue('valuePrefix', _valuePrefix);
  }

  get valueExpression(): string {
    return this.getPropertyValue('valueExpression', null);
  }

  set valueExpression(valueExpression: string) {
    this.setPropertyValue('valueExpression', valueExpression);
  }

  get expressionDependencies(): string[] {
    return this.getPropertyValue('expressionDependencies', null);
  }

  set expressionDependencies(dependencies: string[]) {
    this.setPropertyValue('expressionDependencies', dependencies);
  }

  get loanAmountMin(): number {
    return this.getPropertyValue('loanAmountMin', null);
  }

  set loanAmountMin(value: number) {
    this.setPropertyValue('loanAmountMin', value);
  }

  get loanAmountMax(): number {
    return this.getPropertyValue('loanAmountMax', null);
  }

  set loanAmountMax(value: number) {
    this.setPropertyValue('loanAmountMax', value);
  }

  get loanAmountStep(): number {
    return this.getPropertyValue('loanAmountStep', null);
  }

  set loanAmountStep(value: number) {
    this.setPropertyValue('loanAmountStep', value);
  }

  get loanDurationMax(): number {
    return this.getPropertyValue('loanDurationMax', null);
  }

  set loanDurationMax(value: number) {
    this.setPropertyValue('loanDurationMax', value);
  }

  get renderAmountAsInput(): boolean {
    return this.getPropertyValue('renderAmountAsInput', false);
  }

  set renderAmountAsInput(value: boolean) {
    this.setPropertyValue('renderAmountAsInput', value);
  }
}

type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = {
      errors: [],
      estimatedMonthlyPay: this.question.survey.runExpression(
        this.question.valueExpression
      ),
    };
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.assertQuestionPropNotNull('syncAppliedAmount');
    this.assertQuestionPropNotNull('syncLoanDurationYears');
    this.assertQuestionPropNotNull('interestRateYearly');
    this.question.value = this.question.survey.runExpression(
      this.question.valueExpression
    );
    this.question.expressionDependencies.forEach((dep) => {
      const question = this.question.survey.getQuestionByName(dep);

      question.registerFunctionOnPropertyValueChanged(
        'value',
        () =>
          this.setState((state: any) => ({
            ...state,
            estimatedMonthlyPay: this.question.survey.runExpression(
              this.question.valueExpression
            ),
          })),

        `${question.name}-loan-overview`
      );
    });
  }
  private assertQuestionPropNotNull(key: keyof QuestionModel) {
    if (!this.question[key]) {
      console.warn(
        `Required property ${key} is missing on the question model. Without this the LoanOverview will not work properly.`
      );
    }
  }

  private getFieldProps(name: string) {
    const item = this.question.survey.getQuestionByName(name) as any;

    const errors = ((item as Question)?.errors || []).map((e: any) => ({
      message: e.text,
    }));

    return {
      name: item?.name,
      value: item?.value,
      errors,
      min: item?.min,
      max: item?.max,
    };
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  handleChangeComplete = (e: any) => {
    const errors = this.props.question.errors.map(
      (e: any) => e.text
    ) as ErrorModel[];
    this.setState({ errors });

    this.question.survey.getQuestionByName(e.target.name).value = Number(
      e.target.value
    );
  };

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderAmount(): JSX.Element {
    const appliedAmount = this.getFieldProps(this.question.syncAppliedAmount);

    if (this.question.renderAmountAsInput) {
      return (
        <LoanOverview.Input
          config={{
            min: this.question.loanAmountMin,
            max: this.question.loanAmountMax,
          }}
          separator={this.question.thousandsSeparator}
          microcopy={{
            suffix: this.question.currencyCode,
          }}
          value={appliedAmount.value}
          name={appliedAmount.name}
          label={this.question.appliedAmountLabel}
          onChange={this.handleChangeComplete}
        />
      );
    } else {
      return (
        <LoanOverview.Select
          config={{
            min: this.question.loanAmountMin,
            step: this.question.loanAmountStep,
            max: this.question.loanAmountMax,
          }}
          separator={this.question.thousandsSeparator}
          microcopy={{
            suffix: this.question.currencyCode,
          }}
          value={appliedAmount.value}
          name={appliedAmount.name}
          label={this.question.appliedAmountLabel}
          onChange={this.handleChangeComplete}
        />
      );
    }
  }
  protected renderTenure(): JSX.Element {
    const loanDurationYears = this.getFieldProps(
      this.question.syncLoanDurationYears
    );
    return (
      <LoanOverview.Select
        config={{ min: 1, step: 1, max: this.question.loanDurationMax }}
        separator={this.question.thousandsSeparator}
        microcopy={{
          suffix: this.question.loanDurationSuffix,
        }}
        value={loanDurationYears.value}
        name={loanDurationYears.name}
        label={this.question.loanDurationLabel}
        onChange={this.handleChangeComplete}
      />
    );
  }

  protected renderElement(): JSX.Element {
    const estimatedMonthlyPay = this.state.estimatedMonthlyPay;

    return (
      <div style={this.style}>
        <LoanOverview
          estimatedMonthlyPay={estimatedMonthlyPay}
          currencyCode={this.question.currencyCode}
          separator={this.question.thousandsSeparator}
          microcopy={{
            button: {
              done: this.question.readonlyModeButtonText,
              change: this.question.editModeButtonText,
            },
            monthlyPay: {
              label: this.question.monthlyPayLabel,
            },
          }}
        >
          {this.renderAmount()}
          {this.renderTenure()}
        </LoanOverview>
      </div>
    );
  }
}

export function registerLoanOverviewQuestion() {
  const questionType = 'Axo Loan Overview';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'syncAppliedAmount',
        default: '',
        category: 'general',
      },
      {
        name: 'syncLoanDurationYears',
        default: '',
        category: 'general',
      },
      {
        name: 'loanDurationSuffix',
        default: '',
        category: 'general',
        serializationProperty: 'locLoanDurationSuffix',
      },
      {
        name: 'interestRateYearly',
        default: 0.0,
        category: 'general',
      },
      {
        name: 'monthlyPayLabel',
        default: '',
        category: 'general',
        serializationProperty: 'locMonthlyPayLabel',
      },
      {
        name: 'currencyCode',
        default: '',
        category: 'general',
      },
      {
        name: 'thousandsSeparator',
        default: ' ',
        category: 'general',
        choices: ['', ' ', ',', '.'],
      },
      {
        name: 'appliedAmountLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locAppliedAmountLabel',
      },
      {
        name: 'loanDurationLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locLoanDurationLabel',
      },
      {
        name: 'editModeButtonText',
        category: 'general',
        type: 'string',
        serializationProperty: 'locEditModeButtonText',
      },
      {
        name: 'readonlyModeButtonText',
        category: 'general',
        type: 'string',
        serializationProperty: 'locReadonlyModeButtonText',
      },
      {
        name: 'loanAmountMin',
        category: 'general',
        type: 'number',
      },
      {
        name: 'loanAmountMax',
        category: 'general',
        type: 'number',
      },
      {
        name: 'loanAmountStep',
        category: 'general',
        type: 'number',
      },
      {
        name: 'loanDurationMax',
        category: 'general',
        type: 'number',
      },
      {
        name: 'monthlyPayLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMonthlyPayLabel',
      },
      {
        name: 'valuePrefix',
        category: 'general',
        type: 'string',
      },
      {
        name: 'valueExpression',
        type: 'string',
        category: 'general',
      },
      {
        name: 'expressionDependencies',
        type: 'string',
        category: 'general',
      },
      {
        name: 'renderAmountAsInput',
        default: false,
        category: 'general',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'multipletext'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register(
    'calculateMonthlyPayment',
    calculateMonthlyPayment
  );

  FunctionFactory.Instance.register(
    'calculateMonthlyPaymentNO',
    calculateMonthlyPaymentNO
  );
}
