import client from '..';
import { dispatch, getFormState } from '../../../store';
import { IAuthorizationToken } from '../../../types/data/IAuthorizationToken';
import {
  IPartialPerson,
  partialPersonSchema,
  protoPersonSchema,
} from '../../../types/data/IPerson';

export const updatePerson = async (
  changes: IPartialPerson,
  options: { Authorization: IAuthorizationToken; LoanApplicationID: string }
): Promise<void> => {
  const partialPerson = partialPersonSchema.parse(changes);
  const protoPersonParseResult = protoPersonSchema.safeParse(partialPerson);
  const currentPersonId = getFormState().fieldNameToBackendIDMap['Person'];

  if (protoPersonParseResult.success) {
    const person = await client.createPerson(protoPersonParseResult.data, {
      Authorization: options.Authorization,
    });
    await client.updateLoanApplication(
      options.LoanApplicationID,
      {
        PersonID: person.ID,
      },
      { Authorization: options.Authorization }
    );
    dispatch({
      type: 'field-name-to-backend-id-map.add',
      fieldName: 'Person',
      backendID: person.ID,
    });
  } else {
    if (!currentPersonId) {
      throw new Error('Missing person id: can not update person');
    }
    await client.updatePerson(currentPersonId, partialPerson, {
      Authorization: options.Authorization,
    });
  }
};
