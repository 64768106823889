import { createContext } from 'react';

export interface ISurveyState {
  hasPendingRequest: boolean;
  continueButtonContext: {
    increaseContinueClickCounter: () => void;
    clickedTimes: number;
  };
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const SurveyStateContext = createContext<ISurveyState>(
  {} as ISurveyState
);
