import { Subject } from '@axo/shared/util/reactivity';
import { Dependencies } from '../types/Dependencies';
import { IStep } from '../types/Step';
import { completeStepFactory } from './completeStep';
import { State } from './state';
import { initFactory } from './init';

export const completeApplicationFactory =
  ({ loan_application_api, state }: Dependencies & { state: Subject<State> }) =>
  async ({
    step,
    data,
    loanApplicationId,
    token,
    baseUrl,
  }: {
    step: IStep;
    data: object;
    loanApplicationId: string;
    token: string;
    baseUrl: string;
  }) => {
    if (!state.value.initialized) {
      await initFactory({ loan_application_api, state })({
        loanApplicationId,
        token,
        baseUrl,
      });
    }
    const completeStepPromise = completeStepFactory({
      loan_application_api,
      state,
    })({
      step,
      data,
      loanApplicationId,
      token,
      baseUrl,
    });
    const completeApplicationPromise =
      loan_application_api.patchApplicationComplete(
        baseUrl,
        token,
        loanApplicationId,
        data
      );

    return Promise.all([completeStepPromise, completeApplicationPromise]);
  };
