import { captureException } from '@sentry/browser';

type ICheckConsent = {
  token: string;
  axoApiUrl: string;
  applicationId: string;
};

type IConsentResponse = {
  timestamp: string;
  uuid: string;
  errorCode: string;
  errorMessage: string;
  hasConsent: boolean;
};

export const checkConsent = async ({
  token,
  axoApiUrl,
  applicationId,
}: ICheckConsent): Promise<IConsentResponse> => {
  try {
    const response = await fetch(
      `${axoApiUrl}/debt-register/${applicationId}/consent`,
      {
        method: 'PATCH',
        headers: {
          'Content-type': 'text/plain',
          Authorization: token,
        },
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    captureException(error);
    console.error(error);
    throw error;
  }
};
