import { SurveyModel, Question, PageModel } from 'survey-core';
import { syncField } from './surveyValueChanged/syncFields';

/**
 * if any of the questions has defaultvalues
 * and are visible they should be patched.
 */
export const syncFieldsWithDefaultValues = (survey: SurveyModel) => {
  ((survey.currentPage as PageModel).questions as Question[]).forEach((q) => {
    if (q.isVisible && q.defaultValue) {
      syncField({ value: q.value ?? q.defaultValue, name: q.name });
    }
  });
};
