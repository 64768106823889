import { TRefinanceableDebt } from '../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext.types';

type TGetIsOverLimit = {
  refinanceableDebts: TRefinanceableDebt[];
  maxLoanAmount?: number;
  cashAmount: number;
};

export const getIsOverLimit = ({
  refinanceableDebts,
  maxLoanAmount,
  cashAmount,
}: TGetIsOverLimit) => {
  if (!maxLoanAmount) return false;

  const totalDebt = refinanceableDebts.reduce(
    (acc, { value }) => acc + value,
    0
  );

  return totalDebt + cashAmount > maxLoanAmount;
};
