import { createElement } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { Skeleton } from './Skeleton';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  get height() {
    return this.getPropertyValue('height');
  }

  set height(value) {
    this.setPropertyValue('height', value);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element | null {
    return <Skeleton height={this.question.height} />;
  }
}

export function registerSkeletonQuestion() {
  const questionType = 'Axo Skeleton';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'height',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
