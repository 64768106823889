import { ILocalizationConfig } from '../type';

export const fi: ILocalizationConfig = {
  genericErrorAlert: {
    title: 'Oho, jotain meni pieleen!',
    paragraphs: [
      'Päivitä sivu ja yritä uudelleen.',
      'Ongelman jatkuessa ota yhteyttä asiakaspalveluumme <a href="tel:+0942452326">09 4245 2326.</a>',
    ],
    retry: 'Jatka',
  },
};
