import { P } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';

import styles from './ContinueButtonWrapper.module.scss';

export type IContinueButtonWrapper = {
  children: React.ReactNode;
  headline: string;
  boldText: string;
};

const ContinueButtonWrapper = ({
  children,
  headline,
  boldText,
}: IContinueButtonWrapper) => {
  return (
    <div className={styles.wrapper}>
      <P className={classNames(styles.font, styles.fontSmall)}>{headline}</P>
      <P
        className={classNames(styles.font, styles.fontBold, styles.extraMargin)}
      >
        {boldText}
      </P>
      {children}
    </div>
  );
};

export default ContinueButtonWrapper;
