import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import Button from '../../atoms/Button';
import MessageIcon from '../../atoms/MessageIcon';
import Modal from '../../atoms/Modal';
import { H3, P } from '../../atoms/StyledElements';
import styles from './ErrorAlert.module.scss';

export interface IErrorAlert {
  title?: string;
  paragraphs?: string[];
  buttonText?: string;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ErrorAlert: FC<IErrorAlert> = ({
  title,
  paragraphs = [],
  buttonText,
  onButtonClick,
}: IErrorAlert) => {
  return (
    <Modal topBorderColor="--danger-700">
      <div className={styles.icon}>
        <MessageIcon variety="error" />
      </div>
      <H3 className={styles.header}>{title}</H3>
      {paragraphs.map((p) => (
        <P
          key={p}
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(p) }}
        />
      ))}
      <Button variant="primary" onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Modal>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default ErrorAlert;
