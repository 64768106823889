import { configureUIComponents } from '@axo/deprecated/util/ui-components/configure';

/**
 * This can be pulled out into a separate library if we need to, but ideally we
 * should avoid having libraries that need to be configured before use.
 *
 * The reason for using it here is that we wanted to avoid too much refactoring
 * related to environment variables when pulling the axo form v1 into a library.
 */
const createConfig = <T = Record<string, string | undefined>>(
  appName: string
) => {
  let config: T | undefined = undefined;

  return {
    configure(environmentVariables: T) {
      config = environmentVariables;
    },
    getConfig(): T {
      if (!config)
        throw new Error(
          `Missing configuration. Did you remember to configure ${appName}?`
        );
      return config;
    },
  };
};

export interface IEnv {
  readonly ENV: string;
  readonly API_URL: string;
  readonly ASSENTLY_URL: string;
  readonly LAUNCH_DARKLY_CLIENT?: string;
}

const { configure, getConfig } = createConfig<IEnv>('Axo Form v1');

export { getConfig as getLibConfig };

export const configureAxoFormV1 = (env: IEnv) => {
  configure(env);
  configureUIComponents(env);
};
