import { ICustomer, IPartialCustomer } from '../../../types/data/ICustomer';
import { IUpdateFieldsOptions } from '../Client';
import { updateCustomer } from '../update-customer';

export const updateMarketingConsent = async (
  changes: IPartialCustomer,
  acceptMarketing: boolean,
  options: IUpdateFieldsOptions
) => {
  if (!acceptMarketing) return;

  if (acceptMarketing) {
    changes = {
      MarketingConsent: changes as ICustomer['MarketingConsent'],
    };
    await updateCustomer(
      {
        ...changes,
      },
      {
        JWT: options.Authorization,
      }
    );
  }
};
