import {
  AxoUrlQueryParams,
  getUrlQueryParam,
} from '@axo/deprecated/util/ui-components';
import {
  createChannelSnapshot,
  setStartedAtUrlIfNotSet,
} from '@axo/form/data-access/tracking';
import { cookieWithConverter } from '@axo/shared/util/cookie';

import { FormFlagSet } from '@axo/form/configs';
import { getActiveExperiments } from '@axo/shared/services/feature-flags';
import { Model } from 'survey-core';
import { handleInitialSetup } from '../../backend-sync';
import { changeHandler } from '../../backend-sync/handle-changes/index';
import { Variant } from '../../config/getConfig';
import { getFormState } from '../../store/store';
import { ISurveyOnCurrentPageChangingOptions } from '../../types/survey/ISurveyOnCurrentPageChanging';
import { runAsStatefulAsync } from '../../utils/statefulAsync';
import { getFieldValuesOnPage } from '../utils/getFieldValuesOnPage';
import { resolveWhenNoUnsyncedChanges } from '../utils/resolveWhenNoUnsyncedChanges';
import { getDebtBalanceTrackingData, getTrackingData } from './getTrackingData';

type TSurveyPageChangingParams = {
  survey: Model;
  options: ISurveyOnCurrentPageChangingOptions;
  variant: Variant;
  shouldTrackDebtBalance?: boolean;
  onComplete?: (
    step: number,
    data: Record<string, unknown>,
    debtBalanceData?: Record<string, number>
  ) => Promise<void>;
};

export const surveyPageChanging = async ({
  survey,
  options,
  variant,
  onComplete,
  shouldTrackDebtBalance = false,
}: TSurveyPageChangingParams) => {
  // skip step 1 completed on pre as the main variant will handle it
  if (variant !== 'pre' && options.isNextPage && options.allowChanging) {
    const trackingData = getTrackingData(survey);
    const debtBalanceData = shouldTrackDebtBalance
      ? getDebtBalanceTrackingData(survey)
      : undefined;

    await onComplete?.(
      options.oldCurrentPage.num,
      trackingData,
      debtBalanceData
    );
  }

  // Before the survey has been initialized, allow the current page to be
  // changed freely without sending calls to the backend.
  if (getFormState().setupState !== 'ready') return;

  setStartedAtUrlIfNotSet();

  // scroll to top every time we land on a new step - ONE-5279
  if (window) {
    const form = document.getElementById('axo-form-root');
    form?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  if (options.isNextPage) {
    await handleNextPage(survey, options);
  }
};

let skipChecks = false; // Without this, calling survey.nextPage will re-trigger the same checks and cause an infinite loop
const handleNextPage = async (
  survey: Model,
  options: ISurveyOnCurrentPageChangingOptions
) => {
  if (skipChecks) return (skipChecks = false);

  // SurveyJS does not allow async event handlers. To get around this we stop
  // the page from changing and manually call survey.nextPage when our async
  // code has completed.
  options.allowChanging = false;

  runAsStatefulAsync(async () => {
    const formState = getFormState();

    if (!formState.customer || !formState.loanApplication) {
      if (!survey) throw new Error('Missing Survey object');
      const firstPageValues = getFieldValuesOnPage(survey, 0);

      const cookieValue = cookieWithConverter.get('axotc') as unknown;

      const initialChannelSnapshot = createChannelSnapshot({
        searchParams: new URL(window.document.location.href).searchParams,
        completedSteps: [{ name: survey.getPage(0).name }],
        cookieData: cookieValue || {},
        domain: window.location.hostname,
        experiments: getActiveExperiments(FormFlagSet),
      });

      await handleInitialSetup(firstPageValues, initialChannelSnapshot);
    } else {
      await changeHandler.onNextPage();
    }

    await resolveWhenNoUnsyncedChanges();

    const { errors, customer, loanApplication } = getFormState();

    if (errors.length === 0 && customer && loanApplication) {
      skipChecks = true;

      const formStepFromQuery = parseInt(
        getUrlQueryParam(AxoUrlQueryParams.AXO_FORM_STEP) || ''
      );
      if (survey.currentPageNo + 1 !== formStepFromQuery) {
        survey.nextPage();
      }
    }
  });
};
