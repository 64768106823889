import { CSSProperties } from 'react';
import { Question } from 'survey-core';
import { SurveyQuestionElementBase } from 'survey-react-ui';

const getBasicQuestionProps = (
  sq: SurveyQuestionElementBase,
  question: Question
) => {
  const errors = question.errors.map((e: any): { message: string } => ({
    message: e.text,
  }));

  const style =
    question.getPropertyValue('readOnly') || question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    question.value = event.target.value;
  };

  return {
    label: sq.props.question.fullTitle,
    value: question.value ?? question.defaultValue,
    name: question.name,
    errors: errors,
    style: style,
    onChange,
    placeholder: sq.props.question.placeholder,
    warning: sq.props.question.warning,
    softValidationError: question.survey.runExpression(
      sq.props.question.softValidationError
    ),
    disabled: sq.props.question.disabled,
    tooltip: sq.props.question.tooltip,
  };
};

export default getBasicQuestionProps;
