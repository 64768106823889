import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
  SurveyModel,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { POPInsurance } from './POPInsurance';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('checkboxText', this, false);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  public get checkboxText(): string {
    return this.getLocalizableStringText('checkboxText');
  }

  public set checkboxText(val: string) {
    this.setLocalizableStringText('checkboxText', val);
  }

  get locCheckboxText(): LocalizableString {
    return this.getLocalizableString('checkboxText');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): React.ReactElement | null {
    const page = (this.question.survey as SurveyModel).currentPageNo;

    // Show on Step 5
    if (page !== 4) return null;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.question.value = event.target.checked;
    };

    return (
      <POPInsurance
        isChecked={this.question.value}
        onChange={onChange}
        title={this.question.title}
        description={this.question.description}
        checkboxText={this.question.checkboxText}
      />
    );
  }
}

export function registerPOPInsuranceQuestion() {
  const questionType = 'POP Insurance';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'checkboxText',
        type: 'string',
        category: 'general',
        serializationProperty: 'locCheckboxText',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
