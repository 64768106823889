/* eslint-disable max-classes-per-file */
import { DropdownNO } from '@axo/deprecated/util/ui-components';
import { Formatters } from '@axo/shared/util/string';
import React from 'react';
import {
  ElementFactory,
  QuestionDropdownModel,
  Serializer,
  LocalizableString,
} from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionDropdown } from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

export class QuestionModel extends QuestionDropdownModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('placeholder', this, false);
    this.createLocalizableString('tooltip', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get placeholder(): string {
    return this.getLocalizableStringText('placeholder');
  }

  public set placeholder(val: string) {
    this.setLocalizableStringText('placeholder', val);
  }

  get locPlaceholder(): LocalizableString {
    return this.getLocalizableString('placeholder');
  }

  public get scrollIntoViewOnRender(): boolean {
    return this.getPropertyValue('scrollIntoViewOnRender');
  }

  public set scrollIntoViewOnRender(value: boolean) {
    this.setPropertyValue('scrollIntoViewOnRender', value);
  }
}

export class SurveyQuestion extends SurveyQuestionDropdown {
  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);
    const min = 1;
    const max = 20;
    const increment = 1;
    const defaultValue = 5;

    // set initial value to defaultvalue
    if (!props.value) {
      this.question.value = defaultValue;
    }

    const generateChoices = () => {
      const choices = [];
      for (let value = min; value <= max; value += increment) {
        choices.push({
          text: `${Formatters.formatNumber(value, ' ')} år`,
          value: value,
        });
      }

      return choices;
    };

    return (
      <div style={style}>
        <DropdownNO
          {...props}
          options={generateChoices()}
          onChange={(event) =>
            (this.question.value = Number(event.target.value))
          }
        />
      </div>
    );
  }

  componentDidMount() {
    if (this.question.scrollIntoViewOnRender) {
      setTimeout(() => {
        /**
         * the full question has the react prop but I could not find the correct type.
         * Another weird thing with this is that the react prop is not there when mounted - thus the setTimeout
         * Sorry to all future developers seing this.
         */
        this.question?.react?.rootRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, 50);
    }
  }
}

export function registerLoanDurationDropdownNOQuestion() {
  const questionType = 'Axo Loan Duration Dropdown NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'scrollIntoViewOnRender',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'dropdown'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (questionType: string) => new QuestionModel(questionType, questionType)
  );
}
