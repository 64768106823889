export function calculateMonthlySavedAmount(params: string[]) {
  if (!params[0] || !params[1]) return 0;
  const loanAmount = Number(params[0]);
  const years = Number(params[1]);
  let interest = 0.06;
  const monthlyInterest = interest / 12;
  // Fees.
  const startUpFee = 950;
  const monthlyFee = 30;

  // Calculates monthly cost with loan.
  const monthlyCost =
    ((loanAmount + startUpFee) * monthlyInterest) /
      (1 - Math.pow(1 + monthlyInterest, -12 * years)) +
    monthlyFee;

  const creditCardInterest = Number(params[2]) || 0.22;
  const monthlyCreditCardInterest = creditCardInterest / 12;
  const minPay = 250;
  const cardPay = 0.03;
  const cardFee = 30;
  interest = Math.round(loanAmount * monthlyCreditCardInterest);
  let saldo = Math.round(loanAmount + interest);
  let payment = Math.round(
    Math.min(Math.max(minPay, saldo * cardPay), saldo) + cardFee
  );
  let minSaldo = Math.round(saldo - payment + cardFee);

  let i = 1;
  let paymentAverage = payment;

  while (saldo > 0) {
    interest = Math.round(minSaldo * monthlyCreditCardInterest);
    saldo = Math.round(minSaldo + interest);
    payment = Math.round(
      Math.min(Math.max(minPay, saldo * cardPay), saldo) + cardFee
    );
    minSaldo = Math.round(saldo - payment + cardFee);
    // add += interest;
    if (i < 12) {
      paymentAverage += payment;
    }
    i++;
  }

  paymentAverage /= 12;

  // const totalCostLoan = (((loanAmount) * monthlyInterest) / (1 - Math.pow(1 + monthlyInterest, -12 * years))) * months - loanAmount;
  const monthlySaving = paymentAverage - monthlyCost;

  if (monthlySaving > 0) {
    return parseInt(monthlySaving.toFixed(0));
  } else {
    return 0;
  }
}
