import { FC, ReactNode } from 'react';
import { IInputProps } from '../../../models';
import classNames from '../../../utils/class-names';
import styles from './Radio.module.scss';

export interface IRadio extends IInputProps<HTMLInputElement, string> {
  radioStyle?: 'radio' | 'button' | 'both' | 'both-larger';
  color?: 'success' | 'primary';
  checked?: boolean;
  id: string;
  classes?: ClassNamesProp;
  children?: ReactNode;
}

type ClassNamesProp = {
  root?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Radio: FC<IRadio> = ({
  radioStyle,
  color = 'success',
  value,
  checked,
  id,
  children,
  classes,
  ...props
}) => {
  if (!radioStyle) {
    radioStyle = 'radio';
  }

  return (
    <div
      className={classNames(
        'Radio',
        styles['radio-wrapper'],
        classes?.root,
        children ? styles[radioStyle] : undefined,
        styles[color]
      )}
    >
      <input
        className={classNames(styles.input)}
        type="radio"
        id={`radio-${id}`}
        value={value}
        checked={checked}
        {...props}
      />
      {children && <label htmlFor={`radio-${id}`}>{children}</label>}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Radio;
