import { SurveyModel } from 'survey-core';
import { getFormState } from '../../store';

export function getTrackingData(survey: SurveyModel) {
  const { loanApplication, customer } = getFormState();
  if (!loanApplication || !customer) {
    return {
      appliedAmount: survey.getQuestionByName('LoanApplication/AppliedAmount')
        .value,
      loanDuration: survey.getQuestionByName('LoanApplication/LoanDuration')
        .value,
      didApplyForRefinancing:
        survey.getQuestionByName('LoanApplication/Purpose')?.value ===
        'Refinancing loan',
    };
  }

  return {
    customerID: customer.ID,
    loanApplicationID: loanApplication.ID,
    formCompletedTimestamp: new Date().toISOString(),
    appliedAmount: survey.getQuestionByName('LoanApplication/AppliedAmount')
      .value,
    loanDuration: survey.getQuestionByName('LoanApplication/LoanDuration')
      .value,
    didApplyForRefinancing:
      survey.getQuestionByName('LoanApplication/Purpose')?.value ===
      'Refinancing loan',
  };
}

export function getDebtBalanceTrackingData(survey: SurveyModel) {
  return {
    creditCard: survey.getQuestionByName('Debt/CreditCard/OutstandingAmount')
      .value,
    consumerLoan: survey.getQuestionByName('Debt/Personal/OutstandingAmount')
      .value,
  };
}
