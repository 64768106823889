import {
  FC,
  useState,
  useEffect,
  MouseEventHandler,
  MouseEvent,
  FormEvent,
} from 'react';
import PhoneInput from '../../molecules/PhoneInput';
import RadioGroup from '../../molecules/RadioGroup';
import styles from './ApplicationForm.module.scss';
import * as GLOBALS from '../../../variables/norway';
import EmailInput from '../../molecules/EmailInput';
import { ErrorModel } from '../../../models';
import { IFormDataNO } from '../../../models/form.model';
import Button from '../../atoms/Button';
import { A, H3, P, Span } from '../../atoms/StyledElements';
import { PopupConsent } from '../../molecules/PopUpConsent';

export interface IApplicationForm {
  header: string;
  loanAmount?: number;
  tenure?: number;
  refinancing?: boolean;
  scrollDown?: MouseEventHandler<HTMLButtonElement>;
  onModifyFormData?: (
    property: keyof IFormDataNO,
    value: string | number | boolean
  ) => void;
  endpoint?: string;
}

const calculateIRR = ({
  monthlyTerms,
  value,
  loanAmount,
  irr,
  iterations,
}: {
  monthlyTerms: number;
  value: number;
  loanAmount: number;
  irr: number;
  iterations: number;
}) => {
  const sum = monthlyTerms * value + GLOBALS.STARTUP_FEE;

  for (let i = 0; i < iterations; i++) {
    const rInv = 1.0 / (1.0 + irr / 12.0);
    const npv =
      GLOBALS.STARTUP_FEE +
      (value * (Math.pow(rInv, monthlyTerms + 1) - rInv)) / (rInv - 1.0);

    irr = (irr * Math.log(loanAmount / sum)) / Math.log(npv / sum);
  }

  return irr;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const calculateMonthlyPayment = (loanAmount: number, tenure: number) => {
  const monthlyTerms = tenure * 12;
  const termInterest = GLOBALS.RATES.MID / 100 / 12;
  const tenor = Math.round(monthlyTerms);
  const value =
    (loanAmount * termInterest) / (1 - 1 / Math.pow(1 + termInterest, tenor)) +
    Math.round(GLOBALS.MONTHLY_FEE);

  const irr = calculateIRR({
    monthlyTerms,
    value,
    loanAmount,
    irr: (GLOBALS.RATES.MID / 100) * 2,
    iterations: 4,
  });

  const eff = Math.pow(irr / 12 + 1, 12) - 1;
  const total = monthlyTerms * value + GLOBALS.STARTUP_FEE;
  const cost = total - loanAmount;

  return {
    totalCost: total,
    monthlyPayment: value,
    loanCost: cost,
    effectiveRate: eff,
    loanAmount: loanAmount,
    tenure: tenure,
  };
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const formatAmount = (amount: number, replaceValue: string) => {
  return Math.round(amount)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, replaceValue);
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ApplicationForm: FC<IApplicationForm> = ({
  header,
  loanAmount,
  tenure,
  refinancing = false,
  scrollDown,
  onModifyFormData,
  endpoint = 'https://www2.axofinans.no/s%C3%B8k/forbruksl%C3%A5n',
}) => {
  const maxLoanSize = 600000;
  const maxTenure = 20;
  const refinancingQuestion = 'Hvor mye vil du refinansiere?';
  const nonRefinancingQuestion = 'Hvor mye ønsker du å låne?';
  const emailErrorMessage =
    'Fyll inn din e-postadresse, for eksempel kundeservice@axofinans.no. Kan ikke inneholde æøå.';
  const phoneErrorMessage = 'Bruk ditt mobilnummer, 8 siffer';
  const [prevLoanAmount, setPrevLoanAmount] = useState(
    loanAmount ? Math.ceil(loanAmount / 5000) * 5000 : 100000
  );
  const [prevLoanDuration, setPrevLoanDuration] = useState(
    tenure ?? refinancing ? 10 : 5
  );

  if (loanAmount) {
    loanAmount = Math.ceil(loanAmount / 5000) * 5000;
  }

  if (loanAmount && loanAmount > maxLoanSize) {
    loanAmount = maxLoanSize;
  }

  if (tenure && tenure > maxTenure) {
    tenure = maxTenure;
  }

  const [formData, setFormData] = useState<IFormDataNO>({
    loanAmount: loanAmount ?? 100000,
    tenure: tenure ?? refinancing ? 10 : 5,
    refinancing: refinancing,
    email: '',
    telephone: '',
    monthlyPayment: calculateMonthlyPayment(
      loanAmount ?? 100000,
      tenure ?? refinancing ? 10 : 5
    ).monthlyPayment,
    effectiveRent: calculateMonthlyPayment(
      loanAmount ?? 100000,
      tenure ?? refinancing ? 10 : 5
    ).effectiveRate,
    loanCost: calculateMonthlyPayment(
      loanAmount ?? 100000,
      tenure ?? refinancing ? 10 : 5
    ).loanCost,
    totalCost: calculateMonthlyPayment(
      loanAmount ?? 100000,
      tenure ?? refinancing ? 10 : 5
    ).totalCost,
    marketing_accepted: 0,
  });

  const [visibleExtraFields, setVisibleExtraFields] = useState(false);
  const [isTenureChanged, setTenureChanged] = useState(false);
  const [isPhoneValid, setPhoneValid] = useState<boolean>();
  const [isEmailValid, setEmailValid] = useState<boolean>();
  const [showingEmailSuggestions, setShowingEmailSuggestions] = useState(false);
  const [showConsentPopup, setConsentPopupVisibility] = useState<
    'terms' | 'partners' | 'hidden'
  >('hidden');

  const emailProviders = [
    '@gmail.com',
    '@hotmail.com',
    '@live.no',
    '@online.no',
    '@yahoo.com',
    '@yahoo.no',
    '@outlook.com',
  ];
  const [emailError, setEmailError] = useState<[ErrorModel] | []>([]);
  const [phoneError, setPhoneError] = useState<[ErrorModel] | []>([]);

  useEffect(() => {
    if (loanAmount && prevLoanAmount !== loanAmount) {
      modifyFormData('loanAmount', loanAmount);
    }

    if (tenure && prevLoanDuration !== tenure) {
      modifyFormData('tenure', tenure);
      setTenureChanged(false);
    }

    setPrevLoanAmount(loanAmount ?? 100000);
    setPrevLoanDuration(tenure ?? refinancing ? 10 : 5);
  }, [loanAmount, tenure]);

  const returnCheckIcon = () => {
    return (
      <svg width="19" height="15" viewBox="0 0 19 15" fill="none">
        <path
          d="M17.11 1L6.907 13.0825L1 8.13966"
          stroke="#4B9B59"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const modifyFormData = (
    property: keyof IFormDataNO,
    value: string | number | boolean
  ) => {
    const newFormData: IFormDataNO = { ...formData };
    // @ts-ignore
    newFormData[property] = value;

    if (onModifyFormData) {
      onModifyFormData(property, value);
    }

    if (property === 'loanAmount') {
      const results = calculateMonthlyPayment(
        value as number,
        formData['tenure']
      );
      newFormData['monthlyPayment'] = results['monthlyPayment'];
      newFormData['effectiveRent'] = parseFloat(
        results['effectiveRate'].toFixed(4)
      );
      newFormData['loanCost'] = Math.ceil(results['loanCost']);
      newFormData['totalCost'] = Math.ceil(results['totalCost']);
    } else if (property === 'tenure') {
      setTenureChanged(true);
      const results = calculateMonthlyPayment(
        formData['loanAmount'],
        value as number
      );
      newFormData['monthlyPayment'] = results['monthlyPayment'];
      newFormData['effectiveRent'] = parseFloat(
        results['effectiveRate'].toFixed(4)
      );
      newFormData['loanCost'] = Math.ceil(results['loanCost']);
      newFormData['totalCost'] = Math.ceil(results['totalCost']);
    } else if (property === 'refinancing') {
      let tempTenure = formData['tenure'];
      if (!isTenureChanged) {
        tempTenure = value ? 10 : 5;
        newFormData['tenure'] = tempTenure;

        if (onModifyFormData) {
          onModifyFormData('tenure', tempTenure);
        }
      }
      const results = calculateMonthlyPayment(
        formData['loanAmount'],
        tempTenure as number
      );
      newFormData['monthlyPayment'] = results['monthlyPayment'];
      newFormData['effectiveRent'] = parseFloat(
        results['effectiveRate'].toFixed(4)
      );
      newFormData['loanCost'] = Math.ceil(results['loanCost']);
      newFormData['totalCost'] = Math.ceil(results['totalCost']);
    }

    setFormData(newFormData);
  };

  const submitForm = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    if (!visibleExtraFields) {
      setVisibleExtraFields(true);
      event.preventDefault();
      return;
    }

    checkValidity('email', formData['email']);
    checkValidity('telephone', formData['telephone']);

    if (isEmailValid && isPhoneValid) {
      const form = window.document.querySelector('form');

      const createAndAppendElement = (name: string, data: string) => {
        const element = window.document.createElement('input');
        element.name = name;
        element.type = 'text';
        element.value = data;

        form?.appendChild(element);
      };

      const element = form?.querySelector?.(
        'input[name*=mobile_number]'
      ) as HTMLInputElement;

      if (element)
        element.value = formData['telephone'].toString().replace(' ', '');

      createAndAppendElement('loan_amount', formData['loanAmount'].toString());
      createAndAppendElement(
        'consolidate_debt',
        formData['refinancing'] ? '1' : '0'
      );
      createAndAppendElement(
        'accepted-marketing',
        formData['marketing_accepted'] ? '1' : '0'
      );
      createAndAppendElement('terms', 'baf6f61cc41ab238b77066092e95e983');
      createAndAppendElement('accepted-terms', 'on');

      form?.submit();
    }
  };

  const checkValidity = (property: keyof IFormDataNO, value: string) => {
    switch (property) {
      case 'email':
        if (new RegExp(GLOBALS.EMAIL_REGEX).test(value)) {
          modifyFormData('email', value);
          setEmailError([]);
          setEmailValid(true);
        } else {
          modifyFormData('email', value);
          setEmailError([{ message: emailErrorMessage }]);
          setEmailValid(false);
        }
        break;
      case 'telephone':
        if (new RegExp(GLOBALS.TEL_NUMBER_REGEX).test(value as string)) {
          setPhoneValid(true);
          modifyFormData('telephone', value);
          setPhoneError([]);
        } else {
          setPhoneValid(false);
          modifyFormData('telephone', value);
          setPhoneError([{ message: phoneErrorMessage }]);
        }
        break;
    }
  };

  return (
    <form
      className={styles.ApplicationForm}
      action={endpoint}
      method="POST"
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        submitForm(event);
      }}
    >
      <H3>{header}</H3>

      <Span className={styles.loanAmountQuestion}>
        {formData['refinancing'] ? refinancingQuestion : nonRefinancingQuestion}
      </Span>

      <div className={styles.dropdownHolder}>
        <select
          aria-label="Loan Amount"
          title="Select Loan Amount"
          onChange={(e) => {
            modifyFormData(
              'loanAmount',
              Number.parseInt(e.target.options[e.target.selectedIndex].value)
            );
          }}
        >
          {[...Array(119)].map((entry, index) => {
            return (
              <option
                value={(index + 2) * 5000}
                selected={(index + 2) * 5000 === formData['loanAmount']}
                key={index}
              >
                {formatAmount((index + 2) * 5000, ' ') + ' kr'}
              </option>
            );
          })}
        </select>
      </div>

      <div className={styles.monthlyPaymentHolder}>
        <div className={styles.text}>
          <P>Estimert månedsbeløp</P>
        </div>
        <div className={styles.amount}>
          <P> {formatAmount(Math.ceil(formData['monthlyPayment']), '.')},-</P>
        </div>
      </div>

      <div className={styles.lineSeparator}>
        <hr />
      </div>

      <div className={styles.loanTypeQuestion}>
        Vil du refinansiere dyr smågjeld og spare penger?
      </div>

      {/* The name is empty as we don't want it to be sent with the POST. We have another field corresponding to this */}
      <div className={styles.radioGroupHolder}>
        <RadioGroup
          options={['Ja', 'Nei']}
          name=""
          value={formData['refinancing'] ? 'Ja' : 'Nei'}
          radioStyle="both-larger"
          color="primary"
          onClick={(e) => {
            modifyFormData(
              'refinancing',
              (e.target as HTMLInputElement).value === 'Ja'
            );
          }}
        />
      </div>

      {visibleExtraFields && (
        <div className={styles.extraFields}>
          <Span className={styles.paymentTimeHeader}>
            Ønsket nedbetalingstid
          </Span>

          <div className={styles.paymentTimeHolder}>
            <select
              name="tenure"
              className={styles.paymentTime}
              onChange={(e) => {
                modifyFormData(
                  'tenure',
                  Number.parseInt(
                    e.target.options[e.target.selectedIndex].value
                  )
                );
              }}
            >
              {[...Array(20)].map((entry, index) => {
                return (
                  <option
                    value={index + 1}
                    selected={index + 1 === formData['tenure']}
                    key={index + 1}
                  >
                    {index + 1} år
                  </option>
                );
              })}
            </select>
          </div>

          <div className={styles.inputWithIconHolder}>
            <Span
              className={[
                styles.inputIcon,
                typeof isEmailValid !== 'undefined'
                  ? styles[isEmailValid ? 'valid' : 'invalid']
                  : '',
              ].join(' ')}
              id={styles.emailIcon}
            >
              @
            </Span>

            <EmailInput
              name="email"
              value=""
              placeholder="Din e-post"
              showSuggestions={true}
              onShowingSuggestions={(showingSuggestions) => {
                setShowingEmailSuggestions(showingSuggestions);
              }}
              onBlur={(event) =>
                !showingEmailSuggestions &&
                checkValidity('email', event.target.value)
              }
              emailDomains={emailProviders}
              onSuggestionClick={(suggestion) => {
                checkValidity('email', suggestion);
              }}
              errors={emailError}
              autoComplete="off"
              defaultValue=""
            />
          </div>

          <div className={styles.inputWithIconHolder}>
            <Span
              className={[
                styles.inputIcon,
                typeof isPhoneValid !== 'undefined'
                  ? styles[isPhoneValid ? 'valid' : 'invalid']
                  : '',
              ].join(' ')}
              id={styles.telephoneIcon}
            >
              <svg viewBox="0 0 18 18" fill="none">
                <path
                  fill="black"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.3 13.1v2.5a1.7 1.7 0 0 1-1.8 1.7A16.5 16.5 0 0 1 3.3 9.7 16.5 16.5 0 0 1 .8 2.5 1.7 1.7 0 0 1 2.4.7H5c.9 0 1.6.6 1.7 1.4.1.8.3 1.6.6 2.3.2.7 0 1.3-.4 1.8l-1 1c1.1 2.1 2.9 3.9 5 5l1-1c.5-.5 1.1-.6 1.8-.4.7.3 1.5.5 2.3.6.8.1 1.5.9 1.4 1.7Z"
                  clipRule="evenodd"
                />
              </svg>
            </Span>

            <PhoneInput
              i18n="NO"
              name="mobile_number"
              value=""
              placeholder="Ditt mobilnummer"
              onChange={(event) => {
                checkValidity('telephone', event.target.value as string);
              }}
              onBlur={(event) => {
                checkValidity('telephone', event.target.value as string);
              }}
              errors={phoneError}
              hasPrefix={false}
              defaultValue=""
            />
          </div>

          <Span className={styles.terms}>
            Ved å be om tilbud godtar du{' '}
            <A href="/om/brukervilkaar">våre vilkår</A>.
          </Span>

          <div className={styles.marketingTerms}>
            <input
              type="checkbox"
              defaultChecked={false}
              checked={formData['marketing_accepted'] === 1}
              onClick={() =>
                setFormData({
                  ...formData,
                  ...{
                    marketing_accepted:
                      formData['marketing_accepted'] === 0 ? 1 : 0,
                  },
                })
              }
            />

            <label
              htmlFor="marketingconcent"
              onClick={() => {
                setFormData({
                  ...formData,
                  ...{
                    marketing_accepted:
                      formData['marketing_accepted'] === 0 ? 1 : 0,
                  },
                });
              }}
            >
              Ja takk, jeg vil gjerne motta markedsføring vedrørende finansielle
              produkter og andre tjenesteytelser vist{' '}
              <A
                role="button"
                className={styles.termsText}
                onClick={(e) => {
                  e.stopPropagation();
                  setConsentPopupVisibility('terms');
                }}
              >
                her
              </A>{' '}
              fra Axo Finans AS og våre&nbsp;
              <A
                role="button"
                className={styles.termsText}
                onClick={(e) => {
                  e.stopPropagation();
                  setConsentPopupVisibility('partners');
                }}
              >
                samarbeidspartnere
              </A>{' '}
              via e-post og SMS. Samtykket kan når som helst tilbakekalles ved
              henvendelse til Axo Finans AS.
            </label>
          </div>
        </div>
      )}

      <div className={styles.buttonHolder}>
        <Button
          variant="primary"
          width="full-width"
          onClick={(e) => {
            submitForm(e);
            scrollDown && scrollDown(e);
          }}
          classes={{ root: styles.submitButton }}
        >
          {!visibleExtraFields ? 'Neste' : 'Gå Videre'}
        </Button>

        {visibleExtraFields && (
          <Span className={styles.bankInfo}>
            Søknaden vurderes av 23 banker
          </Span>
        )}
      </div>

      <div className={styles.footerList}>
        <div>
          {returnCheckIcon()}
          <P className={styles.bold}>
            Når du søker får du en kostnadsfri kredittscore på vår
            søsternettside Defero.
          </P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>
            Alle bankene har individuell rente og din rente presenteres først
            sammen med lånetilbudet.
          </P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>
            Nedbetalingstiden er 1 til 20 år, maks 5 år dersom du ikke
            refinansierer.
          </P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>Nominell rente varierer fra minimum 6,9% til maksimum 23,4%.</P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>Effektiv rente varierer fra minimum 8,19% til maksimum 26,23%.</P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>
            Eksempel: Nominell rente{' '}
            {GLOBALS.RATES.MID.toString().replace('.', ',')}%, effektiv rente{' '}
            {(formData['effectiveRent'] * 100)
              .toFixed(2)
              .toString()
              .replace('.', ',')}
            %, lånebeløp kr {formatAmount(formData['loanAmount'], ' ')} med
            nedbetaling over {formData['tenure']} år, kostnad kr{' '}
            {formatAmount(formData['loanCost'], ' ')} totalt kr{' '}
            {formatAmount(formData['totalCost'], ' ')}.
          </P>
        </div>
        <div>
          {returnCheckIcon()}
          <P>Etableringsgebyr varierer fra kr 0,- til 1990,-.</P>
        </div>
        <input type="hidden" name="inc_source" />
      </div>

      {showConsentPopup === 'terms' && (
        <PopupConsent
          showIcon={true}
          title="Andre tjenesteytelser fra Axo Finans"
          partnersList={[
            'Forsikring',
            'Internett',
            'Telefoni',
            'Strøm',
            'Bedriftslån',
            'Kredittsjekk',
          ]}
          buttonText="Ok, jeg forstår"
          onClick={() => {
            setConsentPopupVisibility('hidden');
          }}
          type="services"
          onBackgroundClick={() => {
            setConsentPopupVisibility('hidden');
          }}
        />
      )}

      {showConsentPopup === 'partners' && (
        <PopupConsent
          showIcon={true}
          title="Våre samarbeidspartnere"
          partnersList={['Defero']}
          buttonText="Ok, jeg forstår"
          onClick={() => {
            setConsentPopupVisibility('hidden');
          }}
          type="partners"
          onBackgroundClick={() => {
            setConsentPopupVisibility('hidden');
          }}
        />
      )}
    </form>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default ApplicationForm;
