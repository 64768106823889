import { useEffect } from 'react';
import {
  AxoUrlQueryParams,
  getUrlQueryParam,
} from '@axo/deprecated/util/ui-components';
import { SurveyModel } from 'survey-core';
import { getFormState } from '../store';

export const useNavigateByQueryParams = (survey: SurveyModel | undefined) => {
  const { customer, loanApplication } = getFormState();
  const currentStepFromUrl = parseInt(
    getUrlQueryParam(AxoUrlQueryParams.AXO_FORM_STEP) || ''
  );
  useEffect(() => {
    if (survey) {
      if (currentStepFromUrl && customer && loanApplication) {
        survey.currentPageNo = currentStepFromUrl - 1;
      }
    }
  }, [
    survey,
    typeof window === 'undefined' ? undefined : window.location.href,
  ]);
};
