import { SurveyModel } from 'survey-core';
import { Variant } from '../../config/getConfig';
import { ISurveyOnAfterRenderPage } from '../../types/survey/ISurveyOnAfterRenderPage';
import { getTrackingData } from './getTrackingData';
import { onPageLoad } from './onPageLoad';

let previousPageNumber: number;

export const surveyAfterRenderPage = async (
  survey: SurveyModel,
  options: ISurveyOnAfterRenderPage,
  variant: Variant,
  onComplete?: (step: number, data: Record<string, unknown>) => Promise<void>
) => {
  const { page, htmlElement } = options;

  if (previousPageNumber !== page.num) {
    onPageLoad(survey, { page, htmlElement });
    previousPageNumber = page.num;

    // onStepViewed
    if (
      // skip step 2 on pre as the main variant will handle it
      (variant === 'pre' && page.num > 1) ||
      // skip step 1 on main as the pre variant will handle it
      (variant === 'main' && page.num === 1)
    )
      return;

    await onComplete?.(page.num, getTrackingData(survey));
  }
};
