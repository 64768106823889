export enum EmploymentStatus {
  PermanentPublic = 'PermanentPublic',
  Permanent = 'Permanent',
  Temporary = 'Temporary',
  Entrepreneur = 'Entrepreneur',
  Pensioner = 'Pensioner',
  Student = 'Student',
  MaternityLeave = 'Maternity leave',
  Unemployed = 'Unemployed',
  Disabled = 'Disabled',
  Rehabilitation = 'Rehabilitation',
  EarlyRetirement = 'Early retirement',
}
