import { type IAuthProviderConfig } from '../../../../IAuthProviderConfig.types';
import { TProviders } from '../../../../IAuthProviders.types';
import { IAssentlyResponse } from '../../client/assentlyClient';
import getAxoToken from './getAxoToken';

type THandleAssentlyEvent = {
  response: IAssentlyResponse;
  url: string;
  onSuccess: IAuthProviderConfig['onSuccess'];
  onError: IAuthProviderConfig['onError'];
  onCanceled: IAuthProviderConfig['onCanceled'];
};

export const handleAssentlyEvent = async ({
  response,
  url,
  onSuccess,
  onError,
  onCanceled,
}: THandleAssentlyEvent) => {
  const { type } = response;

  if (type === 'authenticated') {
    const token = response.token;

    if (!token) {
      onError({
        errorMessage: 'Failed to get token',
        provider: TProviders.BankID,
      });
      return;
    }

    try {
      // Assently Response Token is external so it needs to be converted to Axo Token
      const axoToken = await getAxoToken(url, token);

      if (!axoToken) throw new Error('Failed to convert Assently to AxoToken');

      onSuccess({
        token: axoToken.JWT,
        personID: axoToken.PersonID,
        transactionId: response.transactionId,
        data: response,
        provider: TProviders.BankID,
      });
    } catch (error) {
      if (error instanceof Error)
        onError({
          errorMessage: error.message,
          provider: TProviders.BankID,
        });
    }

    return;
  }

  if (type === 'cancelled') {
    onCanceled?.({
      errorMessage: response.errorMessage,
      data: response,
      provider: TProviders.BankID,
    });
    return;
  }

  if (type === 'error' || type === 'failed') {
    onError({
      errorMessage: response.errorMessage,
      data: response,
      provider: TProviders.BankID,
    });
    return;
  }
};
