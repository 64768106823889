import { ReactNode } from 'react';
import clsx from 'clsx';
import { feedbackStateIconsMap } from '../../models/feedback-state-icons.const';
import { feedbackState } from '../../models/feedback-state.const';
import { Stack } from '../layout/item';
import styles from './message.module.scss';
import { Icon, IconName } from '../Icon';

// TODO align with general states (warn / error)
export const variants = [...feedbackState, 'bankID'] as const;

const variantIconsMap: Record<(typeof variants)[number], IconName> = {
  ...feedbackStateIconsMap,
  bankID: 'chat-circle-text-solid',
};

export type MessageProps = {
  variant?: (typeof variants)[number];
  hasIcon?: boolean;
  size?: 's' | 'm';
  className?: string;
  children?: ReactNode;
  iconName?: IconName;
};

const MessageRoot = ({
  variant = 'neutral',
  hasIcon = false,
  size = 'm',
  className,
  children,
  iconName,
}: MessageProps) => (
  <div
    className={clsx(styles.message, className)}
    data-variant={variant}
    data-size={size}
  >
    {hasIcon && (
      <div className={styles.icon}>
        <Icon
          name={iconName ? iconName : variantIconsMap[variant]}
          size={'xs'}
        />
      </div>
    )}

    {children}
  </div>
);

type MessageAction = {
  children: ReactNode;
  className?: string;
};

const MessageAction = ({ children, className }: MessageAction) => (
  <Stack className={clsx(styles.action, className)}>{children}</Stack>
);

export const Message = Object.assign(MessageRoot, { Action: MessageAction });
