import { FC } from 'react';
import { IInputState } from '../../../hooks/useInputState';
import { Icons } from '../../atoms/Icons';
import InputInfoBox, { IInputInfoBox } from './InputInfoBox';

interface ISpecializedInputInfoBox extends Partial<IInputInfoBox> {
  inputState: IInputState;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputSoftValidationBox: FC<ISpecializedInputInfoBox> = ({
  inputState,
  ...overrides
}) => (
  <InputInfoBox
    message={
      inputState.axoProps.primaryState === 'soft-invalid'
        ? inputState.axoProps.softValidationError
        : undefined
    }
    prompt={inputState.axoProps.softValidationPrompt}
    icon={<Icons.CirclePromptBlack size="sm" />}
    backgroundColor="--primary-200"
    onClickYes={(e) => {
      e.preventDefault(); // I'm not 100% sure why, but without this, the field seems to get focus when ´yes´ is clicked
      inputState.dismissSoftValidation();
    }}
    onClickNo={() => {
      const el = document.getElementById(inputState.htmlProps.id);
      el?.focus();
    }}
    {...overrides}
  />
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputErrorBox: FC<ISpecializedInputInfoBox> = ({
  inputState,
  ...overrides
}) => (
  <InputInfoBox
    message={
      inputState.axoProps.primaryState === 'invalid'
        ? inputState.axoProps.errors.map((e) => e.message).join(', ')
        : undefined
    }
    icon={<Icons.CircleWarningWhite size="sm" />}
    backgroundColor="--secondary-700"
    color="--white-100"
    {...overrides}
  />
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputTooltipBox: FC<ISpecializedInputInfoBox> = ({
  inputState,
  ...overrides
}) => (
  <InputInfoBox
    message={
      inputState.axoProps.primaryState === 'undetermined-with-tooltip'
        ? inputState.axoProps.tooltip
        : undefined
    }
    icon={<Icons.CircleInfoBlack size="sm" />}
    backgroundColor="--primary-200"
    {...overrides}
  />
);
