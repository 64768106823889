import { loan_application } from '@axo/shared/data-access/types';
import { differenceInYears } from 'date-fns';
import { possibleAgeOfPersonWithIdNumber } from 'norwegian-national-id-validator';
import { SurveyModel } from 'survey-core';

type ShouldDisplayStorebrandArgs = {
  survey: SurveyModel;
};

export const shouldDisplayStorebrand = ({
  survey,
}: ShouldDisplayStorebrandArgs) => {
  const page = survey.currentPageNo;

  // Show on Step 5
  if (page !== 4)
    return {
      display: false,
      reason: 'Step is different than 5',
    };

  const applicantSSN = survey.getQuestionByName(
    'Person/SocialSecurityNumber'
  ).value;
  const applicantAge =
    possibleAgeOfPersonWithIdNumber(applicantSSN.toString()) ?? 0;
  if (applicantAge < 25)
    return {
      display: false,
      reason: 'Age is below 25',
    };

  const employmentStatus = survey.getQuestionByName(
    'LoanApplication/EmploymentStatus'
  ).value;
  if (
    [
      loan_application.EmploymentStatus.Temporary,
      loan_application.EmploymentStatus.Rehabilitation,
    ].includes(employmentStatus)
  )
    return {
      display: false,
      reason: 'Employment Status is Temporary | Rehabilitation',
    };

  const citizenship = survey.getQuestionByName(
    'LoanApplication/Citizenship'
  ).value;
  if (citizenship !== 'NO') {
    const livedInNorwaySinceDate = survey.getQuestionByName(
      'LoanApplication/LivedInMarketCountrySince'
    ).value;

    const livedInNorwaySince = differenceInYears(
      new Date(),
      new Date(livedInNorwaySinceDate)
    );

    if (livedInNorwaySince < 3)
      return {
        display: false,
        reason: 'User is living in NO for less than 3 years',
      };
  }

  const grossIncome = survey.getQuestionByName(
    'LoanApplication/GrossIncomeYearly'
  ).value;
  if (Number(grossIncome) < 250_000)
    return {
      display: false,
      reason: 'Gross income is below 250k',
    };

  return {
    display: true,
    reason: 'All conditions met',
  };
};
