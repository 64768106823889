import { Formatter } from './Formatter';

export type IThousandsSeparator = '' | ' ' | '.' | ',';
export type CurrencyCode = 'DKK' | 'NOK' | 'SEK' | 'EUR';

export function currencySymbol(code: CurrencyCode) {
  switch (code) {
    case 'NOK':
      return 'kr';
    case 'SEK':
    case 'DKK':
      return 'kr';
    case 'EUR':
      return '€';
  }
}

const getNumberFormatter = (
  value: string,
  thousandsSeparator: IThousandsSeparator
) =>
  Formatter.builder()
    .withPattern('***' + thousandsSeparator)
    .removeCharactersMatching('([^0-9]|^0+(?!$))')
    .repeatPatternToLength(value.length)
    .reversePattern()
    .shouldAllowOverflow(true)
    .build();

const formatNumber = (
  value: number,
  thousandsSeparator: IThousandsSeparator
) => {
  const valueStr = Math.round(value).toString();
  return getNumberFormatter(valueStr, thousandsSeparator).format(valueStr);
};

const unformatted = () =>
  new Formatter('', { allowAppendingAtEndOfPattern: true });

const FormatterUtils = { getNumberFormatter, formatNumber, unformatted };
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default FormatterUtils;
