import { FC } from 'react';
import Dropdown, { IDropdown } from '../Dropdown';
import styles from './DropdownNO.module.scss';

export interface IDropdownNO extends IDropdown {
  iconBackgroundColor?: boolean;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const DropdownNO: FC<IDropdownNO> = ({
  iconBackgroundColor = false,
  ...props
}: IDropdownNO) => (
  <Dropdown
    disableValidations={true}
    caretProps={{
      color: iconBackgroundColor ? 'white' : 'default',
      iconProps: { size: 'sm' },
    }}
    inputStateBoxProps={{
      classes: {
        root: styles.overrideStateBoxRoot,
        iconWrapper: iconBackgroundColor
          ? styles.overrideStateBoxIconWrapper
          : '',
      },
    }}
    className={`DropdownNO${iconBackgroundColor ? ' IconPrimaryState' : ''}`}
    {...props}
  />
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default DropdownNO;
