/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  ElementFactory,
  PageModel,
  Question,
  Serializer,
  SurveyModel,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import {
  IStepperStepState,
  IStepperStep,
  Stepper,
} from '@axo/deprecated/util/ui-components';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { FormFlagSet } from '@axo/form/configs';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get testing() {
    return this.survey;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  getSteps() {
    const pages = (this.question.survey as unknown as SurveyModel)
      .pages as PageModel[];
    const steps = pages.map((page) => {
      let state: IStepperStepState = 'untouched';
      if (page.isActive) state = 'active';
      else if (page.isStarted) state = 'touched';
      else if (page.passed) state = 'completed';

      return {
        title: page.title,
        state: state,
        name: page.name,
      } as IStepperStep;
    });
    return steps;
  }

  protected renderElement(): JSX.Element {
    const survey = this.question.survey as unknown as SurveyModel;

    const steps = this.getSteps();

    return (
      <div style={{ marginBottom: 'var(--margin-small-4)' }}>
        <Stepper steps={steps} locale={survey.locale} />
      </div>
    );
  }
}

export function registerStepperQuestion() {
  const questionType = 'Axo Stepper';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
