import { ReactElement } from 'react';
import styles from './Skeleton.module.scss';

export type ISkeleton = {
  height: number;
};

export function Skeleton({ height }: ISkeleton): ReactElement | null {
  return <div style={{ height }} className={styles.skeleton} />;
}
