import { createElement, FC, ReactElement, ReactNode } from 'react';
import styles from './LinkButton.module.scss';
import classNames from '../../../utils/class-names';
import { IPreprSystemFields } from '../../../models/prepr-system-fields.model';
import { IButtonVariant } from '../Button';

/*
  We add the linkElement prop in order for us to use this component
  with the NEXT link as a wrapper for prefetching. We could look into
   this solution https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
*/

type ClassNamesProps = {
  button?: string;
};

export interface ILinkButton
  extends IPreprSystemFields,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  redirectTo: string;
  linkElement?: ReactElement;
  fullWidth?: boolean;
  classes?: ClassNamesProps;
  variant?: IButtonVariant;
  fontVariant?: 'primary' | 'secondary';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

/**
 * @deprecated Avoid using ui-components. Try to split o\ut functionality into
 * smaller libraries instead.
 */
export const LinkButton: FC<ILinkButton> = ({
  redirectTo,
  children,
  linkElement = <a />,
  fullWidth = false,
  target = '_self',
  variant = 'secondary',
  fontVariant = 'primary',
  classes,
  startIcon,
  endIcon,
}) => {
  return createElement(
    linkElement['type'],
    {
      href: redirectTo,
      className: classNames(
        fullWidth ? styles['full-width'] : '',
        styles.link,
        classes?.button,
        styles[variant],
        styles[`font-${fontVariant}`]
      ),
      target,
    },
    <>
      {startIcon}
      {children}
      {endIcon}
    </>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default LinkButton;
