import { z } from 'zod';
import { EventName } from './types';

export const newListener = <Schema extends z.ZodSchema>(
  name: EventName,
  schema: Schema
) => {
  return (listener: (e: CustomEvent<z.infer<Schema>>) => void) => {
    const wrappedListener = (e: Event) => {
      if (!(e instanceof CustomEvent)) {
        throw new Error('event must be of type CustomEvent');
      }
      schema.parse(e.detail);
      listener(e as CustomEvent<z.infer<typeof schema>>);
    };
    document.addEventListener(name, wrappedListener);

    return () => document.removeEventListener(name, wrappedListener);
  };
};
