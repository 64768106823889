import { FC, MouseEventHandler } from 'react';
import styles from './TextAndHeading.module.scss';
import { IPreprSystemFields } from '../../../models/prepr-system-fields.model';
import { StandaloneButton, Text } from '../..';

export interface ITextAndHeading extends IPreprSystemFields {
  heading: string;
  text: string;
  cta_button_text?: string;
  cta_button_link?: string;
  cta_button_onClick?: MouseEventHandler<HTMLButtonElement>;
  open_link_in_new_tab?: boolean;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TextAndHeading: FC<ITextAndHeading> = ({
  heading,
  text,
  cta_button_link,
  cta_button_text,
  cta_button_onClick,
  open_link_in_new_tab = false,
  ...props
}) => (
  <section className={styles.text}>
    <Text
      _id={props._id + '_heading'}
      format="H3"
      body={`<h3>${heading}</h3>`}
    />

    <Text
      _id={props._id + '_text'}
      classes={{ root: styles.rightColumn }}
      format={null}
      body={text}
    />

    {cta_button_text && (
      <StandaloneButton
        text={cta_button_text}
        open_new_window={open_link_in_new_tab}
        url={cta_button_link}
        _id={props._id}
        button_onClick={cta_button_onClick}
      />
    )}
  </section>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default TextAndHeading;
