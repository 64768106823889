import { Button, IButton, Icons } from '@axo/deprecated/util/ui-components';
import { useContext } from 'react';

import { SurveyStateContext } from '../../../../contexts/SurveyStateContext';
import { IContinueButtonWrapper } from './ContinueButtonWrapper';
import { SavingsAmountContinueButton } from './SavingsAmountContinueButton';

import styles from './NavigationButtons.module.scss';

export interface INavigationButton {
  textContinue: string;
  textBack: string;
  textSkip: string;
  continueButtonVisible: boolean;
  backButtonVisible: boolean;
  skipButtonVisible: boolean;
  continueButtonFontVariant?: IButton['fontVariant'];
  previousButtonFontVariant?: IButton['fontVariant'];
  onClickContinue: React.MouseEventHandler<HTMLButtonElement>;
  onClickBack: React.MouseEventHandler<HTMLButtonElement>;
  onClickSkip: React.MouseEventHandler<HTMLButtonElement>;
  continueButtonWrapperProps?: Omit<IContinueButtonWrapper, 'children'>;
  isCurrentPageValid?: boolean;
  width?: 'standard' | 'full-width';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const NavigationButtons = ({
  textContinue,
  textBack,
  textSkip,
  continueButtonVisible,
  backButtonVisible,
  skipButtonVisible,
  onClickContinue,
  onClickBack,
  onClickSkip,
  continueButtonFontVariant = 'primary',
  previousButtonFontVariant = 'secondary',
  continueButtonWrapperProps,
  isCurrentPageValid,
  width = 'standard',
}: INavigationButton) => {
  const {
    hasPendingRequest,
    continueButtonContext: { increaseContinueClickCounter = () => void 0 } = {},
  } = useContext(SurveyStateContext);

  const buttonBackProps: IButton = {
    type: 'button',
    children: textBack,
    variant: 'secondary',
    onClick: onClickBack,
    startIcon: <Icons.ArrowRight size="xs" rotation="180" />,
    fontVariant: previousButtonFontVariant,
    classes: { root: styles.button },
    width: width,
    className: 'NavigationButtons__button__secondary',
  };

  const buttonContinueProps: IButton = {
    type: 'submit',
    children: textContinue,
    onClick: (e) => {
      increaseContinueClickCounter?.();
      onClickContinue(e);
    },
    variant: 'primary',
    loading: hasPendingRequest,
    endIcon: <Icons.ArrowRight size="xs" />,
    fontVariant: continueButtonFontVariant,
    classes: { root: styles.button },
    width: width,
    className: 'NavigationButtons__button__primary',
  };

  const buttonSkipProps: IButton = {
    type: 'button',
    children: textSkip,
    onClick: onClickSkip,
    variant: 'ghost',
    fontVariant: continueButtonFontVariant,
    classes: { root: styles.skip },
    width: width,
    className: 'NavigationButtons__button__ghost',
  };

  return (
    <>
      <div className={styles.container}>
        {skipButtonVisible && <Button {...buttonSkipProps} />}
        {backButtonVisible && <Button {...buttonBackProps} />}
        {continueButtonVisible && continueButtonWrapperProps ? (
          <SavingsAmountContinueButton
            buttonContinueProps={buttonContinueProps}
            continueButtonWrapperProps={continueButtonWrapperProps}
          />
        ) : (
          <ContinueButton
            isCurrentPageValid={isCurrentPageValid}
            {...buttonContinueProps}
          />
        )}
      </div>
    </>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default NavigationButtons;

function ContinueButton(
  props: IButton & {
    isCurrentPageValid?: boolean;
  }
) {
  const { isCurrentPageValid, ...rest } = props;

  return <Button {...rest} />;
}
