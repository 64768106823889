import { IPreprSystemFields } from '../../../models/prepr-system-fields.model';
import React, { FC, ReactNode } from 'react';
import { LinkButton, Button, IButtonVariant } from '../..';

export interface IStandaloneButton extends IPreprSystemFields {
  url?: string;
  text: string;
  open_new_window: boolean;
  button_onClick?: React.MouseEventHandler<HTMLButtonElement>;
  scroll_to_the_top?: boolean;
  classes?: ClassNamesProps;
  variant?: IButtonVariant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

type ClassNamesProps = {
  root?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const StandaloneButton: FC<IStandaloneButton> = ({
  _id,
  url,
  text,
  open_new_window,
  button_onClick,
  classes,
  variant = 'primary',
  startIcon,
  endIcon,
}) => {
  if (!text) {
    return;
  }

  if (url) {
    return (
      <LinkButton
        redirectTo={url}
        _id={_id + '_linkButton'}
        target={open_new_window ? '_blank' : '_self'}
        variant={variant}
        classes={{ button: classes?.root }}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {text}
      </LinkButton>
    );
  } else {
    return (
      <Button
        variant={variant}
        onClick={button_onClick}
        classes={{ root: classes?.root }}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {text}
      </Button>
    );
  }
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default StandaloneButton;
