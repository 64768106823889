import { loan_application } from '@axo/shared/data-access/types';
import { send } from '../../lib/send';
import { IAPI } from '../../lib/models/api';

interface IRevisionResponse {
  Items: loan_application.LoanApplication[];
}

export function getRevisionByStatus({
  url: { service: baseURL },
  token,
}: IAPI) {
  return ({
    applicationID,
    status,
  }: {
    applicationID: string;
    status: loan_application.ApplicationStatus;
  }): Promise<IRevisionResponse> =>
    send({
      baseURL,
      token,
      path: `/loan-application/${applicationID}/revisions?status=${status}`,
      method: 'GET',
    });
}
