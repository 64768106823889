/* eslint-disable @nx/enforce-module-boundaries */
import { useMemo } from 'react';
import { useInputState } from '@axo/deprecated/util/ui-components/hooks/useInputState';
import { IWrappedSoftValidatedInput } from '@axo/deprecated/util/ui-components/models';
import { Formatter } from '@axo/deprecated/util/ui-components/utils/Formatter';
import FormattedInput from '@axo/deprecated/util/ui-components/components/atoms/FormattedInput';
import InputStateBox from '@axo/deprecated/util/ui-components/components/molecules/InputStateBox';
import InputWrapper from '@axo/deprecated/util/ui-components/components/molecules/InputWrapper';

export interface IOrganisationNumberInput
  extends IWrappedSoftValidatedInput<HTMLInputElement, string> {
  maxLength: number;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const OrganisationNumberInput = ({
  maxLength,
  ...props
}: IOrganisationNumberInput) => {
  const inputState = useInputState<HTMLInputElement>(props);

  const formatter = useMemo(
    () =>
      Formatter.builder()
        .withPattern('*** ')
        .repeatPatternToLength(maxLength)
        .removeCharactersMatching('[^0-9,+]')
        .build(),
    [maxLength]
  );

  return (
    <InputWrapper inputState={inputState}>
      <InputStateBox inputState={inputState}>
        <FormattedInput
          inputState={inputState}
          getFormatter={() => formatter}
        />
      </InputStateBox>
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default OrganisationNumberInput;
