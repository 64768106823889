import styles from './CardDetails.module.scss';
import SavingsIcon from './assets/SavingsIcon';

type TCardDetailsProps = {
  title: string;
  description?: string;
};

/**
 *
 * @deprecated use ui-feature HightLightInfo instead
 */
const CardDetails = ({ title, description }: TCardDetailsProps) => {
  return (
    <div data-testid="card-details" className={styles.card}>
      <div className={styles.container}>
        <SavingsIcon className={styles.image} />
        <div className={styles.title} data-testid="card-details-title">
          {title}
        </div>
        {description && (
          <div data-testid="description" className={styles.description}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDetails;
