import { Button } from '@axo/ui-core/components/Button';
import { Modal } from '@axo/ui-core/components/Modal';

type ErrorModalUIProps = {
  title: string;
  message: string;
  actionLabel: string;
  onAction: () => void;
  isOpen?: boolean;
};

export const ErrorModalUI = ({
  title,
  message,
  actionLabel,
  onAction,
  isOpen = false,
}: ErrorModalUIProps) => {
  return (
    <Modal open={isOpen}>
      <Modal.Header feedbackState={'error'}>
        <Modal.Title>{title}</Modal.Title>
        <Modal.Subtitle>{message}</Modal.Subtitle>
      </Modal.Header>
      <Modal.Actions>
        <Button onClick={onAction}>{actionLabel}</Button>
      </Modal.Actions>
    </Modal>
  );
};
