import { FinnishSSN } from 'finnish-ssn';

export function getAgeFromFinnishSSN(params: string[]) {
  if (!params[0]) return;
  if (!FinnishSSN.validate(params[0])) return;
  const parsedSSN = FinnishSSN.parse(params[0]);
  return parsedSSN.ageInYears;
}

export function getGenderFromFinnishSSN(params: string[]) {
  if (!params[0]) return;
  if (!FinnishSSN.validate(params[0])) return;
  const parsedSSN = FinnishSSN.parse(params[0]);
  return parsedSSN.sex;
}
