import { Subject } from '@axo/shared/util/reactivity';
import { setTag } from '@sentry/react';
import { withMonitoring } from '../monitoring/withMonitoring';
import { Dependencies } from '../types/Dependencies';
import { completeApplicationFactory } from './completeApplication';
import { completeStepFactory } from './completeStep';
import { initFactory } from './init';
import { State } from './state';

export const trackingFactory = ({ loan_application_api }: Dependencies) => {
  const state = new Subject<State>({
    completedSteps: [],
    initialized: false,
  });

  return {
    state: state.readonly,
    setTag,
    withMonitoring,
    completeStep: completeStepFactory({ loan_application_api, state }),
    completeApplication: completeApplicationFactory({
      loan_application_api,
      state,
    }),
    /**
     * Used to populate completedSteps based on the backend channel snapshots.
     * Calling this is optional. If it is not called, initialization will happen
     * lazily, right before it is needed.
     */
    initialize: initFactory({ loan_application_api, state }),
  };
};
