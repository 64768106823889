/* eslint-disable max-classes-per-file */
import {
  CountryCode,
  DateDropdown,
  IDateDropdown,
} from '@axo/deprecated/util/ui-components';
import { getLocaleAndCountry } from '@axo/shared/util/string';
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionDropdown } from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('minYearSuffix', this, false);
    this.createLocalizableString('maxYearSuffix', this, false);
    this.createLocalizableString('yearPlaceholder', this, false);
    this.createLocalizableString('monthPlaceholder', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get country() {
    const localeAndCountry = getLocaleAndCountry(this.getLocale());
    return this.getPropertyValue('country', localeAndCountry);
  }

  set country(newCountry: CountryCode) {
    const localeAndCountry = getLocaleAndCountry(newCountry);
    this.setPropertyValue('country', localeAndCountry);
  }

  get minYear() {
    return this.getPropertyValue('minYear');
  }

  set minYear(newMinYear) {
    this.setPropertyValue('minYear', newMinYear);
  }

  get maxYear(): IDateDropdown['maxYear'] {
    return this.getPropertyValue('maxYear');
  }

  set maxYear(newMaxYear: IDateDropdown['maxYear']) {
    this.setPropertyValue('maxYear', newMaxYear);
  }

  get futureMaxYear(): boolean {
    return this.getPropertyValue('futureMaxYear');
  }

  set futureMaxYear(newValue: boolean) {
    this.setPropertyValue('futureMaxYear', newValue);
  }

  get disableDatesInPast(): boolean {
    return this.getPropertyValue('disableDatesInPast');
  }

  set disableDatesInPast(val: boolean) {
    this.setPropertyValue('disableDatesInPast', val);
  }

  get disableDatesInFuture(): boolean {
    return this.getPropertyValue('disableDatesInFuture');
  }

  set disableDatesInFuture(val: boolean) {
    this.setPropertyValue('disableDatesInFuture', val);
  }

  public get minYearSuffix(): string {
    return this.getLocalizableStringText('minYearSuffix');
  }

  public set minYearSuffix(val: string) {
    this.setLocalizableStringText('minYearSuffix', val);
  }

  get locMinYearSuffix(): LocalizableString {
    return this.getLocalizableString('minYearSuffix');
  }

  public get maxYearSuffix(): string {
    return this.getLocalizableStringText('maxYearSuffix');
  }

  public set maxYearSuffix(val: string) {
    this.setLocalizableStringText('maxYearSuffix', val);
  }

  get locMaxYearSuffix(): LocalizableString {
    return this.getLocalizableString('maxYearSuffix');
  }

  public get yearPlaceholder(): string {
    return this.getLocalizableStringText('yearPlaceholder');
  }

  public set yearPlaceholder(val: string) {
    this.setLocalizableStringText('yearPlaceholder', val);
  }

  get locYearPlaceholder(): LocalizableString {
    return this.getLocalizableString('yearPlaceholder');
  }

  public get monthPlaceholder(): string {
    return this.getLocalizableStringText('monthPlaceholder');
  }

  public set monthPlaceholder(val: string) {
    this.setLocalizableStringText('monthPlaceholder', val);
  }

  get locMonthPlaceholder(): LocalizableString {
    return this.getLocalizableString('monthPlaceholder');
  }

  get latestYearAtTop() {
    return this.getPropertyValue('latestYearAtTop', false);
  }

  set latestYearAtTop(newLatestYearAtTop) {
    this.setPropertyValue('latestYearAtTop', newLatestYearAtTop);
  }

  get showOnlyYear(): boolean {
    return this.getPropertyValue('showOnlyYear', false);
  }

  set showOnlyYear(newShowOnlyYear) {
    this.setPropertyValue('showOnlyYear', newShowOnlyYear);
  }
}

export class SurveyQuestion extends SurveyQuestionDropdown {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    const minYear = this.question.disableDatesInPast
      ? new Date().getFullYear()
      : new Date().getFullYear() - 10;

    const maxYear = this.question.futureMaxYear
      ? new Date().getFullYear() + 10
      : new Date().getFullYear();

    return (
      <div style={style}>
        <DateDropdown
          {...props}
          disableDatesInPast={this.question.disableDatesInPast}
          disableDatesInFuture={this.question.disableDatesInFuture}
          minYear={this.props.question.minYear || minYear}
          maxYear={this.props.question.maxYear || maxYear}
          minYearSuffix={this.props.question.minYearSuffix}
          maxYearSuffix={this.props.question.maxYearSuffix}
          i18n={this.props.question.country}
          latestYearAtTop={this.props.question.latestYearAtTop}
          yearPlaceholder={this.props.question.yearPlaceholder}
          monthPlaceholder={this.props.question.monthPlaceholder}
          showOnlyYear={this.props.question.showOnlyYear}
        />
      </div>
    );
  }
}

export function registerDateDropdownQuestion() {
  const questionType = 'Axo Date Dropdown';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'country',
        category: 'general',
        choices: ['DK', 'FI', 'NO', 'SE'],
      },
      {
        name: 'minYear',
        category: 'general',
        type: 'number',
      },
      {
        name: 'maxYear',
        category: 'general',
        type: 'number',
        nextToProperty: '*minYear',
      },
      {
        name: 'noPastYears',
        category: 'general',
        type: 'boolean',
        default: false,
      },
      {
        name: 'futureMaxYear',
        category: 'general',
        type: 'boolean',
        default: false,
      },
      {
        name: 'minYearSuffix',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMinYearSuffix',
      },
      {
        name: 'maxYearSuffix',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMaxYearSuffix',
        nextToProperty: '*minYearSuffix',
      },
      {
        name: 'yearPlaceholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locYearPlaceholder',
      },
      {
        name: 'monthPlaceholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMonthPlaceholder',
        nextToProperty: '*yearPlaceholder',
      },
      {
        name: 'latestYearAtTop',
        default: false,
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'showOnlyYear',
        default: false,
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'disableDatesInPast',
        default: false,
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'disableDatesInFuture',
        default: false,
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
