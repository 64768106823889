import { z } from 'zod';
import { newListener } from '../core/newListener';

const NAME = 'axo.abtest.ready';

const schemas = {
  v1: z.object({
    formValues: z
      .object({
        loanAmount: z.number().optional(),
        // TODO: AB test to be able to hide/show the slider instead of dropdown in NO for appliedAmount. Should be removed after the test (edit: 22.09.2023)
        useSliderAppliedAmount: z.boolean().optional(),
      })
      .optional()
      .default({}),
  }),
};

export const listenAbtestReady = newListener(`${NAME}/v1`, schemas.v1);
