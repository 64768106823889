import { IDebtsResponse } from '@axo/form/feature/debt-registry';
import React, { ComponentProps } from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { getStoredRefinanceableDebts } from '../../../../../components/ExtendedLoanOverview/context/ExtendedLoanOverviewContext/store/getStoredValues';
import { ExtendedLoanOverview } from '../../../../../components/ExtendedLoanOverview/ExtendedLoanOverview';
import { type TCurrency } from '../../../../../components/ExtendedLoanOverview/ExtendedLoanOverview.types';
import LDWrapper from '../../../../../components/LDWrapper/LDWrapper';
import { getFormState } from '../../../../../store/store';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import getDebtRegistryLabels from '../../no/utils/getDebtRegistryLabels';
import { getAllocationPriority } from './utils/getAllocationPriority';
import { getRefinanceableDebts } from './utils/getRefinanceableDebts';

export type TRefinanceableDebt = {
  label: string;
  valuePath: string;
  key: string;
  step: number;
};

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);

    this.createLocalizableString('title', this, false);
    this.createLocalizableString('refinanceAmountLabel', this, false);
    this.createLocalizableString('creditCardDebtLabel', this, false);
    this.createLocalizableString('unsecuredDebtLabel', this, false);
    this.createLocalizableString('cashAmountLabel', this, false);
    this.createLocalizableString('monthlyPaymentLabel', this, false);
    this.createLocalizableString('loanDurationLabel', this, false);
    this.createLocalizableString('totalLoanAmountLabel', this, false);
    this.createLocalizableString('warningMessage', this, false);
    this.createLocalizableString('yearsLabel', this, false);
    this.createLocalizableString('monthLabel', this, false);
    this.createLocalizableString('maxLoanAmountMessage', this, false);
    this.createLocalizableString('adjustLabel', this, false);
    this.createLocalizableString('monthlyPaymentDisclaimer', this, false);
  }

  getType(): string {
    return this.questionType ?? '';
  }

  public get title(): string {
    return this.getLocalizableStringText('title');
  }

  public set title(val: string) {
    this.setLocalizableStringText('title', val);
  }

  get locTitle(): LocalizableString {
    return this.getLocalizableString('title');
  }

  public get refinanceAmountLabel(): string {
    return this.getLocalizableStringText('refinanceAmountLabel');
  }

  public set refinanceAmountLabel(val: string) {
    this.setLocalizableStringText('refinanceAmountLabel', val);
  }

  get locRefinanceAmountLabel(): LocalizableString {
    return this.getLocalizableString('refinanceAmountLabel');
  }

  public get monthlyPaymentDisclaimer(): string {
    return this.getLocalizableStringText('monthlyPaymentDisclaimer');
  }

  public set monthlyPaymentDisclaimer(val: string) {
    this.setLocalizableStringText('monthlyPaymentDisclaimer', val);
  }

  get locMonthlyPaymentDisclaimer(): LocalizableString {
    return this.getLocalizableString('monthlyPaymentDisclaimer');
  }

  public get creditCardDebtLabel(): string {
    return this.getLocalizableStringText('creditCardDebtLabel');
  }

  public set creditCardDebtLabel(val: string) {
    this.setLocalizableStringText('creditCardDebtLabel', val);
  }

  get locCreditCardDebtLabel(): LocalizableString {
    return this.getLocalizableString('creditCardDebtLabel');
  }

  public get unsecuredDebtLabel(): string {
    return this.getLocalizableStringText('unsecuredDebtLabel');
  }

  public set unsecuredDebtLabel(val: string) {
    this.setLocalizableStringText('unsecuredDebtLabel', val);
  }

  get locUnsecuredDebtLabel(): LocalizableString {
    return this.getLocalizableString('unsecuredDebtLabel');
  }

  public get cashAmountLabel(): string {
    return this.getLocalizableStringText('cashAmountLabel');
  }

  public set cashAmountLabel(val: string) {
    this.setLocalizableStringText('cashAmountLabel', val);
  }

  get locCashAmountLabel(): LocalizableString {
    return this.getLocalizableString('cashAmountLabel');
  }

  public get monthlyPaymentLabel(): string {
    return this.getLocalizableStringText('monthlyPaymentLabel');
  }

  public set monthlyPaymentLabel(val: string) {
    this.setLocalizableStringText('monthlyPaymentLabel', val);
  }

  get locMonthlyPaymentLabel(): LocalizableString {
    return this.getLocalizableString('monthlyPaymentLabel');
  }

  public get loanDurationLabel(): string {
    return this.getLocalizableStringText('loanDurationLabel');
  }

  public set loanDurationLabel(val: string) {
    this.setLocalizableStringText('loanDurationLabel', val);
  }

  get locLoanDurationLabel(): LocalizableString {
    return this.getLocalizableString('loanDurationLabel');
  }

  public get totalLoanAmountLabel(): string {
    return this.getLocalizableStringText('totalLoanAmountLabel');
  }

  public set totalLoanAmountLabel(val: string) {
    this.setLocalizableStringText('totalLoanAmountLabel', val);
  }

  get locTotalLoanAmountLabel(): LocalizableString {
    return this.getLocalizableString('totalLoanAmountLabel');
  }

  public get warningMessage(): string {
    return this.getLocalizableStringText('warningMessage');
  }

  public set warningMessage(val: string) {
    this.setLocalizableStringText('warningMessage', val);
  }

  get locWarningMessage(): LocalizableString {
    return this.getLocalizableString('warningMessage');
  }

  public get yearsLabel(): string {
    return this.getLocalizableStringText('yearsLabel');
  }

  public set yearsLabel(val: string) {
    this.setLocalizableStringText('yearsLabel', val);
  }

  get locYearsLabel(): LocalizableString {
    return this.getLocalizableString('yearsLabel');
  }

  public get monthLabel(): string {
    return this.getLocalizableStringText('monthLabel');
  }

  public set monthLabel(val: string) {
    this.setLocalizableStringText('monthLabel', val);
  }

  get locMonthLabel(): LocalizableString {
    return this.getLocalizableString('monthLabel');
  }

  public get maxLoanAmountMessage(): string {
    return this.getLocalizableStringText('maxLoanAmountMessage');
  }

  public set maxLoanAmountMessage(val: string) {
    this.setLocalizableStringText('maxLoanAmountMessage', val);
  }

  get locMaxLoanAmountMessage(): LocalizableString {
    return this.getLocalizableString('maxLoanAmountMessage');
  }

  public get maxLoanAmount(): number {
    return this.getPropertyValue('maxLoanAmount');
  }

  public set maxLoanAmount(val: number) {
    this.setPropertyValue('maxLoanAmount', val);
  }

  public get currency(): string {
    return this.getPropertyValue('currency', 0);
  }

  public set currency(val: string) {
    this.setPropertyValue('currency', val);
  }

  public get adjustLabel(): string {
    return this.getLocalizableStringText('adjustLabel');
  }

  public set adjustLabel(val: string) {
    this.setLocalizableStringText('adjustLabel', val);
  }

  get locAdjustLabel(): LocalizableString {
    return this.getLocalizableString('adjustLabel');
  }

  public get isDebtRegistry(): boolean {
    return this.getPropertyValue('isDebtRegistry');
  }

  public set isDebtRegistry(val: boolean) {
    this.setPropertyValue('isDebtRegistry', val);
  }

  get refinanceableDebts() {
    return this.getPropertyValue('refinanceableDebts');
  }

  public set refinanceableDebts(val: any) {
    this.setPropertyValue('refinanceableDebts', val);
  }

  public get isLoanDurationVisible(): boolean {
    return this.getPropertyValue('isLoanDurationVisible');
  }

  public set isLoanDurationVisible(val: boolean) {
    this.setPropertyValue('isLoanDurationVisible', val);
  }

  get isEmptyDebtsVisible(): boolean {
    return this.getPropertyValue('isEmptyDebtsVisible');
  }

  public set isEmptyDebtsVisible(val: boolean) {
    this.setPropertyValue('isEmptyDebtsVisible', val);
  }

  get isDebtInfoButtonVisible(): boolean {
    return this.getPropertyValue('isDebtInfoButtonVisible');
  }

  public set isDebtInfoButtonVisible(val: boolean) {
    this.setPropertyValue('isDebtInfoButtonVisible', val);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    const { loanApplication } = getFormState();
    const loanApplicationID = loanApplication?.ID;

    const labels: ComponentProps<typeof ExtendedLoanOverview>['labels'] = {
      refinanceAmountLabel: this.question.refinanceAmountLabel,
      cashAmountLabel: this.question.cashAmountLabel,
      monthlyPaymentLabel: this.question.monthlyPaymentLabel,
      loanDurationLabel: this.question.loanDurationLabel,
      totalLoanAmountLabel: this.question.totalLoanAmountLabel,
      warningMessage: this.question.warningMessage,
      yearsLabel: this.question.yearsLabel,
      title: this.question.title,
      monthLabel: this.question.monthLabel,
      maxLoanAmount: this.question.maxLoanAmountMessage,
      monthlyPaymentDisclaimer: this.question.monthlyPaymentDisclaimer,
    };

    const appliedAmount = this.question.survey.getQuestionByName(
      'LoanApplication/AppliedAmount'
    );
    // Fixing the Duration as 15 years as a subtask of https://axofinance.atlassian.net/browse/ONE-11736
    const loanDuration = { value: 15 };
    // const loanDuration = this.question.survey.getQuestionByName(
    //   'LoanApplication/LoanDuration'
    // );

    const isDebtRegistry = !!this.question.isDebtRegistry;

    const debtRegistryResponse = isDebtRegistry
      ? (this.question.survey.getQuestionByName('Ignore/DebtRegistry')?.value
          ?.data as IDebtsResponse)
      : undefined;

    const isUseSessionStorageRefinanceableDebts =
      !!this.question.survey.getQuestionByName('Ignore/DebtRegistry')?.value
        ?.useSessionStorage;
    const storedRefinanceableDebts = isUseSessionStorageRefinanceableDebts
      ? getStoredRefinanceableDebts({})
      : undefined;

    const debtRegistryDebts = isDebtRegistry
      ? debtRegistryResponse?.debts
      : undefined;

    const refinanceableDebts: ComponentProps<
      typeof ExtendedLoanOverview
    >['refinanceableDebts'] = storedRefinanceableDebts
      ? storedRefinanceableDebts
      : getRefinanceableDebts({
          refinanceableDebts: this.question.refinanceableDebts,
          isDebtRegistry,
          survey: this.question.survey,
          debtRegistryDebts,
          isEmptyDebtsVisible: this.question.isEmptyDebtsVisible,
        });

    const creditCardDebt = this.question.survey.getQuestionByName(
      'Debt/CreditCard/OutstandingAmount'
    );
    const unsecuredDebt = this.question.survey.getQuestionByName(
      'Debt/Personal/OutstandingAmount'
    );
    const refinanceAmount = this.question.survey.getQuestionByName(
      'Debt/Personal/RefinanceAmount'
    );
    const totalRefinancingAmount = this.question.survey.getQuestionByName(
      'LoanApplication/TotalRefinancingAmount'
    );

    const isRefinancing =
      this.question.survey.getQuestionByName('LoanApplication/InitialPurpose')
        ?.value === 'Refinancing loan';

    const onRefinanceAmountChange = (newValue: number) => {
      if (!refinanceAmount) return;
      refinanceAmount.value = newValue;
      totalRefinancingAmount.value = newValue;
    };

    const onTotalAmountChange = (newValue: number) => {
      if (appliedAmount.value === newValue) return;
      appliedAmount.value = newValue;
    };

    const onLoanDurationChange = (newValue: number) => {
      loanDuration.value = newValue;
    };

    const allocationPriority = getAllocationPriority({
      isDebtRegistry,
      creditCardDebt: creditCardDebt?.value,
      unsecuredDebt: unsecuredDebt?.value,
    });

    const debtInfoProps:
      | ComponentProps<typeof ExtendedLoanOverview>['debtInfoProps']
      | undefined =
      isDebtRegistry && debtRegistryResponse
        ? {
            debts: debtRegistryResponse.debts,
            totalDebt: debtRegistryResponse.debtTotal,
            loansModalDebtEntriesTexts:
              getDebtRegistryLabels('')['loansModalProps'][
                'loansModalDebtEntriesTexts'
              ],
            loansModalTexts:
              getDebtRegistryLabels('')['loansModalProps']['loansModalTexts'],
          }
        : undefined;

    const onFeatureFlagChange = (isEnabled: boolean) => {
      this.question.value = { isEnabled };
    };

    return (
      <LDWrapper
        featureFlagKey="enableExtendedLoanOverview"
        onFeatureFlag={onFeatureFlagChange}
      >
        <div style={style}>
          <ExtendedLoanOverview
            labels={labels}
            loanDuration={loanDuration.value}
            maxLoanAmount={this.question.maxLoanAmount}
            allocationPriority={allocationPriority}
            currency={this.question.currency as TCurrency}
            appliedAmount={appliedAmount.value}
            isUpSell={!isRefinancing}
            onRefinanceAmountChange={onRefinanceAmountChange}
            onTotalAmountChange={onTotalAmountChange}
            onLoanDurationChange={onLoanDurationChange}
            refinanceableDebts={refinanceableDebts}
            debtInfoProps={debtInfoProps}
            isLoanDurationVisible={this.question.isLoanDurationVisible}
            loanApplicationID={loanApplicationID}
            isDebtInfoButtonVisible={this.question.isDebtInfoButtonVisible}
          />
        </div>
      </LDWrapper>
    );
  }
}

export function registerExtendedLoanOverviewWidgetQuestion() {
  const questionType = 'Axo Extended Loan Overview';
  Serializer.addClass(
    questionType,
    [
      {
        name: 'title',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTitle',
      },
      {
        name: 'refinanceAmountLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locRefinanceAmountLabel',
      },
      {
        name: 'creditCardDebtLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locCreditCardDebtLabel',
      },
      {
        name: 'unsecuredDebtLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locUnsecuredDebtLabel',
      },
      {
        name: 'cashAmountLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locCashAmountLabel',
      },
      {
        name: 'monthlyPaymentLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMonthlyPaymentLabel',
      },
      {
        name: 'loanDurationLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locLoanDurationLabel',
      },
      {
        name: 'totalLoanAmountLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTotalLoanAmountLabel',
      },
      {
        name: 'warningMessage',
        category: 'general',
        type: 'string',
        serializationProperty: 'locWarningMessage',
      },
      {
        name: 'yearsLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locYearsLabel',
      },
      {
        name: 'monthLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMonthLabel',
      },
      {
        name: 'maxLoanAmountMessage',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMaxLoanAmountMessage',
      },
      {
        name: 'maxLoanAmount',
        category: 'general',
        type: 'number',
      },
      {
        name: 'currency',
        category: 'general',
        type: 'string',
      },
      {
        name: 'adjustLabel',
        category: 'general',
        type: 'string',
        serializationProperty: 'locAdjustLabel',
      },
      {
        name: 'isDebtRegistry',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'refinanceableDebts',
        category: 'general',
      },
      {
        name: 'isLoanDurationVisible',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'isEmptyDebtsVisible',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'monthlyPaymentDisclaimer',
        category: 'general',
        type: 'string',
        serializationProperty: 'locMonthlyPaymentDisclaimer',
      },
      {
        name: 'isDebtInfoButtonVisible',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
