import { Slider, Stat } from '@axo/deprecated/util/ui-components';
import { FormFlagSet } from '@axo/form/configs';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import {
  ILoanDurationSelector,
  LoanDurationSelector,
} from '@axo/ui-form/components/LoanDurationSelector';
import styles from './LoanDurationAlternator.module.scss';

export const LoanDurationAlternator = ({ ...props }: ILoanDurationSelector) => {
  let { 'enable-new-loan-duration-selector': newLoanDurationSelector } =
    useFlags(FormFlagSet);
  useFlagImpression(FormFlagSet, {
    'enable-new-loan-duration-selector': newLoanDurationSelector,
  });
  newLoanDurationSelector ??= false;

  return (
    <>
      {newLoanDurationSelector && <LoanDurationSelector {...props} />}
      {!newLoanDurationSelector && (
        <div className={styles.oldDurationSelector}>
          <Slider
            min={props.minDuration}
            minSuffix="vuosi"
            max={props.maxDuration}
            maxSuffix="vuotta"
            step={1}
            value={props.currentLoanDuration}
            label="Laina-aika"
            valueSuffix={props.currentLoanDuration === 1 ? 'vuosi' : 'vuotta'}
            onChange={props.onDurationChange}
          />
          <Stat
            label="<b>Arvioitu kuukausierä</b>"
            valuePrefix="€"
            thousandsSeparator=" "
            name="Ignore/MonthlyCost"
            value={props.loanAmountPerMonth}
          />
        </div>
      )}
    </>
  );
};
