export function validateFinnishPhoneNumber(params: string[]) {
  const pattern = /^(04\d|050|0457)\d{4,8}$/;
  return pattern.test(params[0]);
}

export function validateNorwegianPhoneNumber(params: string[]) {
  const pattern = /^((0047)?|(\+47)?)[4|9]\d{7}$/;

  return pattern.test(params[0]);
}
