import { FC } from 'react';
import styles from './Arrow.module.scss';

export interface IArrow {
  color: 'light' | 'dark';
  direction: 'left' | 'right' | 'up' | 'down';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Arrow: FC<IArrow> = ({ color, direction, ...props }) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 14 16"
      className={`${styles.arrow} ${color ? styles['style-' + color] : ''} ${
        color ? styles['direction-' + direction] : ''
      }`}
      {...props}
      style={{
        position: 'relative',
        top: '2px',
        width: '20px',
        height: '20px',
      }}
    >
      <title>{`Arrow facing ${direction}`}</title>
      <path d="M7 0a1 1 0 0 1 1 1v11.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 1.414-1.414L6 12.586V1a1 1 0 0 1 1-1Z" />
    </svg>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Arrow;
