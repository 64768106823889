import { FC, useEffect, useRef, useState } from 'react';
import styles from './EmailSuggestionsList.module.scss';
import useKeyPress from '../../../../hooks/useKeyPress';
import { Span, Ul } from '../../../atoms/StyledElements';

export interface ISuggestionForm {
  suggestionsList: string[];
  value: string;
  onSuggestionClick: (suggestion: string) => void;
  onShowingSuggestions?: (showingSuggestions: boolean) => void;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const EmailSuggestionsList: FC<ISuggestionForm> = ({
  suggestionsList,
  value,
  onSuggestionClick,
  onShowingSuggestions = (showingSuggestions) => showingSuggestions,
}) => {
  const [username, domain] = value.split('@');
  const suggestionListRef = useRef<HTMLInputElement | null>(null);
  const arrowDownPressed = useKeyPress('ArrowDown');
  const arrowUpPressed = useKeyPress('ArrowUp');
  const enterPressed = useKeyPress('Enter');
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);

  const filteredSuggestionsList = domain
    ? suggestionsList.filter((suggestionDomain) =>
        suggestionDomain.startsWith(domain, 1)
      )
    : suggestionsList;

  useEffect(() => {
    if (filteredSuggestionsList.length !== suggestionsList.length) {
      setActiveSuggestionIndex(null);
    }
  }, [filteredSuggestionsList.length, suggestionsList.length]);

  useEffect(() => {
    const handleClick = (event: any) => {
      event.preventDefault();
      // If click on email input element on parent
      if (suggestionListRef.current && event?.target?.id === 'email') {
        onShowingSuggestions?.(true);
      }

      if (
        suggestionListRef.current &&
        !suggestionListRef.current.contains(event.target)
      ) {
        onShowingSuggestions?.(false);
      }
    };

    if (
      filteredSuggestionsList.length === 0 ||
      filteredSuggestionsList.includes('@' + value.split('@')[1])
    ) {
      onShowingSuggestions?.(false);
    }

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [suggestionListRef, filteredSuggestionsList]);

  useEffect(() => {
    if (arrowDownPressed) {
      setActiveSuggestionIndex((prevActiveIndex) => {
        if (
          prevActiveIndex === null ||
          prevActiveIndex === filteredSuggestionsList.length - 1
        ) {
          return 0;
        }
        return prevActiveIndex + 1;
      });
    }

    if (arrowUpPressed) {
      setActiveSuggestionIndex((prevActiveIndex) => {
        if (prevActiveIndex === null || prevActiveIndex === 0) {
          return filteredSuggestionsList.length - 1;
        }
        return prevActiveIndex - 1;
      });
    }

    if (enterPressed && activeSuggestionIndex !== null) {
      onSuggestionClick(
        username + filteredSuggestionsList[activeSuggestionIndex]
      );
    }
  }, [arrowDownPressed, arrowUpPressed, enterPressed]);

  return (
    <div id={styles.suggestionsList} ref={suggestionListRef}>
      <Ul>
        {filteredSuggestionsList.map((suggestion, index) => {
          return (
            <li
              key={index}
              className={activeSuggestionIndex === index ? styles.hover : ''}
              onClick={() => onSuggestionClick(username + suggestion)}
            >
              {username}
              <Span className={styles.boldSuggestion}>{suggestion}</Span>
            </li>
          );
        })}
      </Ul>
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default EmailSuggestionsList;
