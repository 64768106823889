import { loan_application } from '@axo/shared/data-access/types';

export async function patchStepComplete(
  baseUrl: string,
  token: string,
  loanApplicationId: string,
  data: loan_application.StepComplete
): Promise<loan_application.StepComplete> {
  const url = new URL(
    `/loan-application/${loanApplicationId}/step-complete`,
    baseUrl
  );

  const response = await fetch(url, {
    method: 'PATCH',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const responseJson = await response.json();

  if (response.ok) return responseJson;

  throw new Error(
    `Error patching ${response.url}: ${response.status} ${
      response.statusText
    } ${responseJson.error || responseJson.message}`
  );
}
