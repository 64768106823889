import { calculateMonthlyPayment, formatAmount } from '../components';
import * as GLOBALS from '../variables/norway';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const calculateTotalPaymentNO = (args: any[]) => {
  const [loanAmount, tenure] = args;
  const rate = `${GLOBALS.RATES.MID.toString().replace('.', ',')}%`;
  const monthlyPayment = calculateMonthlyPayment(loanAmount, tenure);
  const effectiveRate = (monthlyPayment.effectiveRate * 100)
    .toFixed(2)
    .toString()
    .replace('.', ',');
  const loanAmountFormatted = formatAmount(monthlyPayment.loanAmount, ' ');
  const loanCostFormatted = formatAmount(monthlyPayment.loanCost, ' ');
  const totalCostFormatted = formatAmount(monthlyPayment.totalCost, ' ');
  return `Eksempel: Nominell rente ${rate}, effektiv rente ${effectiveRate}%, lånebeløp kr ${loanAmountFormatted} med nedbetaling over ${monthlyPayment.tenure} år, kostnad kr ${loanCostFormatted} totalt kr ${totalCostFormatted}.`;
};
