import { useState } from 'react';
import { ErrorModel } from '../../../models';
import { getLibConfig } from '../../../configure';

interface BackendEmailValidationResult {
  Input: { Email: string; Local: string; Host: string };
  IsValid: boolean;
  IsRisky: boolean;
  Suggestion: { Host: string };
}

export interface IBackendValidationArgs {
  hardError?: ErrorModel;
  softError?: ErrorModel;
  onHardError?: (err: ErrorModel) => void;
  enableSuggestions?: boolean;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const useBackendEmailValidation = ({
  hardError,
  softError,
  onHardError,
  enableSuggestions,
}: IBackendValidationArgs = {}) => {
  const [validationState, setValidationState] = useState<
    BackendEmailValidationResult | undefined
  >();
  const [errorState, setErrorState] = useState<ErrorModel | undefined>();
  const [softErrorState, setSoftErrorState] = useState<
    ErrorModel | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const disabled = !hardError && !softError && !enableSuggestions;

  return {
    validate: async (email: string) => {
      if (disabled) return;

      setIsLoading(true);
      const response = await fetch(
        `${getLibConfig().API_URL}/email-validation/validate?email=${email}`
      );
      const data = (await response.json()) as BackendEmailValidationResult;
      setIsLoading(false);

      if (email !== data.Input.Email) return;

      if (!data.IsValid && hardError) {
        setErrorState(hardError);
        setSoftErrorState(undefined);
        onHardError?.(hardError);
      } else if (data.IsRisky && softError) {
        setErrorState(undefined);
        setSoftErrorState(softError);
      } else {
        setErrorState(undefined);
        setSoftErrorState(undefined);
      }

      setValidationState(data);
    },
    error: errorState,
    softError: softErrorState?.message,
    validation: validationState,
    isLoading,
  };
};
