import { NumberInput } from '@axo/deprecated/util/ui-components';
import { IThousandsSeparator } from '@axo/shared/util/string';
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.createLocalizableString('tooltip', this, false);
    this.createLocalizableString('placeholder', this, false);
    this.createLocalizableString('softValidationError', this, false);
    this.createLocalizableString('softValidationPromptYes', this, false);
    this.createLocalizableString('softValidationPromptNo', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get prefix() {
    return this.getPropertyValue('prefix');
  }

  set prefix(newPrefix) {
    this.setPropertyValue('prefix', newPrefix);
  }

  get suffix() {
    return this.getPropertyValue('suffix');
  }

  set suffix(newSuffix) {
    this.setPropertyValue('suffix', newSuffix);
  }

  get max() {
    return this.getPropertyValue('max');
  }

  set max(newMax: number) {
    this.setPropertyValue('max', newMax);
  }

  get min() {
    return this.getPropertyValue('min');
  }

  set min(newMin: number) {
    this.setPropertyValue('min', newMin);
  }

  get showState() {
    return this.getPropertyValue('showState', true);
  }

  set showState(newShowState) {
    this.setPropertyValue('showState', newShowState);
  }

  get thousandsSeparator() {
    return this.getPropertyValue('thousandsSeparator', ' ');
  }

  set thousandsSeparator(newThousandsSeparator: IThousandsSeparator) {
    this.setPropertyValue('thousandsSeparator', newThousandsSeparator);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get placeholder(): string {
    return this.getLocalizableStringText('placeholder');
  }

  public set placeholder(val: string) {
    this.setLocalizableStringText('placeholder', val);
  }

  get locPlaceholder(): LocalizableString {
    return this.getLocalizableString('placeholder');
  }

  public get softValidationError(): string {
    return this.getLocalizableStringText('softValidationError');
  }

  public set softValidationError(val: string) {
    this.setLocalizableStringText('softValidationError', val);
  }

  get locSoftValidationError(): LocalizableString {
    return this.getLocalizableString('softValidationError');
  }

  public get softValidationPromptYes(): string {
    return this.getLocalizableStringText('softValidationPromptYes');
  }

  public set softValidationPromptYes(val: string) {
    this.setLocalizableStringText('softValidationPromptYes', val);
  }

  get locSoftValidationPromptYes(): LocalizableString {
    return this.getLocalizableString('softValidationPromptYes');
  }
  get locSoftValidationPromptNo(): LocalizableString {
    return this.getLocalizableString('softValidationPromptNo');
  }

  public get softValidationPromptNo(): string {
    return this.getLocalizableStringText('softValidationPromptNo');
  }

  public set softValidationPromptNo(val: string) {
    this.setLocalizableStringText('softValidationPromptNo', val);
  }

  public set disableValidations(val: boolean) {
    this.setPropertyValue('disableValidations', val);
  }

  public get disableValidations(): boolean {
    return this.getPropertyValue('disableValidations');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected mapValue(value: string) {
    return Number(value);
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, onChange, ...props } = getBasicQuestionProps(
      this,
      this.questionBase
    );

    return (
      <div style={style}>
        <NumberInput
          {...props}
          disableValidations={this.question.disableValidations}
          onChange={(event) => {
            if (this.question.value === event.target.value) return;
            this.questionBase.value =
              event.target.value === '' ? null : Number(event.target.value);
          }}
          thousandsSeparator={this.question.thousandsSeparator}
          prefix={this.question.prefix}
          suffix={this.question.suffix}
          softValidationPrompt={{
            yesText: this.question.softValidationPromptYes,
            noText: this.question.softValidationPromptNo,
          }}
          max={this.question.max}
          min={this.question.min}
        />
      </div>
    );
  }
}

export function registerNumberInputQuestion() {
  const questionType = 'Axo Number Input';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'value',
        category: 'general',
        type: 'number',
      },
      {
        name: 'min',
        category: 'general',
        type: 'number',
      },
      {
        name: 'max',
        category: 'general',
        type: 'number',
        nextToProperty: '*min',
      },
      {
        name: 'thousandsSeparator',
        default: '',
        category: 'general',
        choices: ['', ' ', ',', '.'],
      },
      {
        name: 'prefix',
        category: 'general',
        type: 'text',
      },
      {
        name: 'suffix',
        category: 'general',
        type: 'text',
      },
      {
        name: 'showState',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'softValidationError',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationError',
      },
      {
        name: 'softValidationPromptYes',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptYes',
      },
      {
        name: 'softValidationPromptNo',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptNo',
      },
      {
        name: 'disableValidations',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
