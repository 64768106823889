import { TStoredValues } from './storedValues.types';

export const setStoredCashAmount = (cashAmount: number) => {
  localStorage.setItem(TStoredValues.cashAmount, cashAmount.toString());
};

export const setStoredRefinanceableDebts = (refinanceableDebts: string) => {
  localStorage.setItem(TStoredValues.refinanceableDebts, refinanceableDebts);
};

export const setStoredAppliedAmount = (appliedAmount: number) => {
  localStorage.setItem(TStoredValues.appliedAmount, appliedAmount.toString());
};
