import { IChanges } from '../../../types/data/IChanges';

/**
 * This functions breaks the changes object into a record that
 * has an object path that idetifies which endpoint to send to.
 * The object path stores the key-value-pair.
 */
export const reduceChangesToObjects = (changes: IChanges) => {
  const result: Record<string, Record<string, unknown>> = {};

  for (const key in changes) {
    const value = changes[key as keyof IChanges];
    const segments = key.split('/');
    const attributeName = segments[segments.length - 1];
    const normalizedAttributeName = attributeName.split('+')[0];
    const objectPath = (/.*(?=\/)/.exec(key) || [key])[0];

    if (!result[objectPath]) result[objectPath] = {};

    if (
      result[objectPath][normalizedAttributeName] === undefined ||
      !attributeName.includes('+')
    ) {
      result[objectPath][normalizedAttributeName] = value;
    }
  }

  return result;
};
