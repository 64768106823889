import React, { FC } from 'react';
import styles from './ButtonWithAnchorStyles.module.scss';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ButtonWithAnchorStyles: FC<JSX.IntrinsicElements['button']> = ({
  className,
  ...props
}) => {
  const cn = `${styles.self} ${className}`;
  return <button type="button" className={cn} {...props}></button>;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default ButtonWithAnchorStyles;
