/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  QuestionTextModel,
  LocalizableString,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { emailPattern } from './validators';
import { EmailInput } from '@axo/deprecated/util/ui-components';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionTextModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('placeholder', this, false);
    this.createLocalizableString('tooltip', this, false);
    this.createLocalizableString(
      'backendValidationHardErrorMessage',
      this,
      false
    );
    this.createLocalizableString(
      'backendValidationSoftErrorMessage',
      this,
      false
    );
    this.createLocalizableString(
      'backendValidationSuggestionPrefix',
      this,
      false
    );
    this.createLocalizableString('softValidationPromptYes', this, false);
    this.createLocalizableString('softValidationPromptNo', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get emailDomains(): string[] {
    return this.getPropertyValue('emailDomains');
  }

  set emailDomains(emailDomains: string[]) {
    this.setPropertyValue('emailDomains', emailDomains);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get backendValidationEnableSuggestions(): boolean {
    return this.getPropertyValue('backendValidationEnableSuggestions');
  }

  public set backendValidationEnableSuggestions(val: boolean) {
    this.setPropertyValue('backendValidationEnableSuggestions', val);
  }

  public get backendValidationSuggestionPrefix(): string {
    return this.getLocalizableStringText('backendValidationSuggestionPrefix');
  }

  public set backendValidationSuggestionPrefix(val: string) {
    this.setLocalizableStringText('backendValidationSuggestionPrefix', val);
  }

  get locBackendValidationSuggestionPrefix(): LocalizableString {
    return this.getLocalizableString('backendValidationSuggestionPrefix');
  }

  public get backendValidationHardErrorMessage(): string {
    return this.getLocalizableStringText('backendValidationHardErrorMessage');
  }

  public set backendValidationHardErrorMessage(val: string) {
    this.setLocalizableStringText('backendValidationHardErrorMessage', val);
  }

  get locBackendValidationHardErrorMessage(): LocalizableString {
    return this.getLocalizableString('backendValidationHardErrorMessage');
  }

  public get backendValidationSoftErrorMessage(): string {
    return this.getLocalizableStringText('backendValidationSoftErrorMessage');
  }

  public set backendValidationSoftErrorMessage(val: string) {
    this.setLocalizableStringText('backendValidationSoftErrorMessage', val);
  }

  get locBackendValidationSoftErrorMessage(): LocalizableString {
    return this.getLocalizableString('backendValidationSoftErrorMessage');
  }

  public get softValidationPromptYes(): string {
    return this.getLocalizableStringText('softValidationPromptYes');
  }

  public set softValidationPromptYes(val: string) {
    this.setLocalizableStringText('softValidationPromptYes', val);
  }

  get locSoftValidationPromptYes(): LocalizableString {
    return this.getLocalizableString('softValidationPromptYes');
  }

  get locSoftValidationPromptNo(): LocalizableString {
    return this.getLocalizableString('softValidationPromptNo');
  }

  public get softValidationPromptNo(): string {
    return this.getLocalizableStringText('softValidationPromptNo');
  }

  public set softValidationPromptNo(val: string) {
    this.setLocalizableStringText('softValidationPromptNo', val);
  }
}

type QuestionProps = {
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);
    const domains = this.question.emailDomains || [];
    const shouldShowSuggestions = domains.length !== 0;

    return (
      <div style={style}>
        <EmailInput
          {...props}
          emailDomains={domains}
          showSuggestions={shouldShowSuggestions}
          onSuggestionClick={(suggestion) => {
            this.questionBase.value = suggestion;
          }}
          softValidationPrompt={{
            yesText: this.question.softValidationPromptYes,
            noText: this.question.softValidationPromptNo,
          }}
          backendValidation={{
            hardError: this.question.backendValidationHardErrorMessage
              ? {
                  message: this.question.backendValidationHardErrorMessage,
                }
              : undefined,
            softError: this.question.backendValidationSoftErrorMessage
              ? {
                  message: this.question.backendValidationSoftErrorMessage,
                }
              : undefined,
            enableSuggestions: this.question.backendValidationEnableSuggestions,
            suggestionPrefix: this.question.backendValidationSuggestionPrefix,
          }}
        />
      </div>
    );
  }
}

export function registerEmailQuestion() {
  const questionType = 'Axo Email Input';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'emailDomains',
        category: 'general',
        domains: [],
      },
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'backendValidationEnableSuggestions',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'backendValidationSuggestionPrefix',
        category: 'general',
        type: 'string',
        serializationProperty: 'locBackendValidationSuggestionPrefix',
      },
      {
        name: 'backendValidationHardErrorMessage',
        category: 'general',
        type: 'string',
        serializationProperty: 'locBackendValidationHardErrorMessage',
      },
      {
        name: 'backendValidationSoftErrorMessage',
        category: 'general',
        type: 'string',
        serializationProperty: 'locBackendValidationSoftErrorMessage',
      },
      {
        name: 'softValidationPromptYes',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptYes',
      },
      {
        name: 'softValidationPromptNo',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptNo',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'text'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register('emailPattern', emailPattern);
}
