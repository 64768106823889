/* eslint-disable max-classes-per-file */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { P } from '@axo/deprecated/util/ui-components';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get text(): string {
    return this.getPropertyValue('text', null);
  }

  set text(text: string) {
    this.setPropertyValue('text', text);
  }
}
type QuestionProps = {
  question?: QuestionModel;
};
export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    return (
      <P
        style={style}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(this.question.text),
        }}
      ></P>
    );
  }
}

export function registerTextQuestion() {
  const questionType = 'Axo Text';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'text',
        type: 'string',
        category: 'general',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
