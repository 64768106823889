import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  getStoredCashAmount,
  getStoredRefinanceableDebts,
} from '../store/getStoredValues';

type TGetInitialValues = {
  isUpSell: boolean;
  appliedAmount: number;
  refinanceableDebts: TRefinanceableDebt[];
};

export const getInitialValues = ({
  isUpSell,
  appliedAmount,
  refinanceableDebts,
}: TGetInitialValues) => {
  const storedRefinanceableDebts =
    getStoredRefinanceableDebts() as TRefinanceableDebt[];

  const _refinanceableDebts = storedRefinanceableDebts ?? refinanceableDebts;

  const storedCashAmountValue = getStoredCashAmount();
  const totalDebt = _refinanceableDebts.reduce(
    (acc, { value }) => acc + value,
    0
  );

  const cashAmount =
    storedCashAmountValue ??
    (isUpSell ? appliedAmount : appliedAmount - totalDebt);

  return {
    refinanceableDebts: _refinanceableDebts,
    cashAmount,
  };
};
