import {
  InputHTMLAttributes,
  useState,
  useEffect,
  FocusEventHandler,
  useRef,
} from 'react';
import { ISoftValidationPrompt } from '../components/molecules/SoftValidationError';
import { ErrorModel, IInputPrimaryState } from '../models';
import useGeneratedId from './useGeneratedId';
import { useHasFocus } from './useHasFocus';
import { useErrorsWithoutAddingNewWhenFocused } from './useErrorsWithoutAddingNewWhenFocused';

export type InputStateElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;
interface Props<E extends InputStateElement>
  extends Omit<InputHTMLAttributes<E>, 'value'> {
  softValidationError?: string;
  errors?: ErrorModel[];
  label?: string;
  tooltip?: string;
  warning?: string;
  softValidationPrompt?: ISoftValidationPrompt;
  value?: string;
  visuallyDetachedState?: boolean;
  disableValidations?: boolean;
}

export interface IInputState<E extends InputStateElement = InputStateElement> {
  axoProps: {
    primaryState: IInputPrimaryState;
    hasFocus: boolean;
    softValidationError: string | undefined;
    hasSoftValidationError: boolean;
    errors: ErrorModel[];
    label: string | undefined;
    labelId: string;
    tooltip: string | undefined;
    warning: string | undefined;
    softValidationPrompt: ISoftValidationPrompt | undefined;
    visuallyDetachedState: boolean;
  };
  htmlProps: Omit<
    InputHTMLAttributes<E>,
    'value' | 'id' | 'onBlur' | 'onFocus'
  > & {
    value: string;
    id: string;
    onBlur: FocusEventHandler<any>;
    onFocus: FocusEventHandler<any>;
    ref: React.MutableRefObject<E | null>;
    nls_fa_el_name: string;
  };
  setValue: (value: string) => void;
  dismissSoftValidation: () => void;
}

const inputStateStorage = {
  set: (name: string, key: string, value: string) => {
    if (typeof window === 'undefined') return;

    const prev = JSON.parse(
      window.sessionStorage.getItem('axo.input-state') ?? '{}'
    ) as Record<string, Record<string, string>>;

    if (!prev[name]) prev[name] = {};
    prev[name][key] = value;

    window.sessionStorage.setItem('axo.input-state', JSON.stringify(prev));
  },
  get: (name: string, key: string): string | undefined =>
    typeof window !== 'undefined'
      ? JSON.parse(window.sessionStorage.getItem('axo.input-state') ?? '{}')?.[
          name
        ]?.[key]
      : undefined,
  softValidationDismissed: {
    set: (name: string, value: boolean) =>
      inputStateStorage.set(name, 'softValidationDismissed', String(value)),
    get: (name: string) =>
      inputStateStorage.get(name, 'softValidationDismissed') === 'true',
  },
};

/**
 * This hook takes in a lot of properties that are used for the InputWrapper and
 * InputStateBox and performs some transformations on some of the properties. It
 * allows us to have a single place to manipulate the state for these separate
 * components. This is needed because some places the two components use the
 * same data and need the same transformations (for example both use the errors array).
 * It also sets consistent default values for some of the properties.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const useInputState = <E extends InputStateElement>({
  id,
  softValidationError,
  errors,
  label,
  tooltip,
  warning,
  softValidationPrompt,
  value,
  visuallyDetachedState = false,
  name,
  disableValidations,
  ...props
}: Props<E>): IInputState<E> => {
  const fallbackId = useGeneratedId();
  const labelId = useGeneratedId();
  const ref = useRef<E>(null);

  const [softValidationDismissed, setSoftValidationDismissed] = useState(
    name ? inputStateStorage.softValidationDismissed.get(name) : false
  );

  useEffect(() => {
    if (!name) return;
    inputStateStorage.softValidationDismissed.set(
      name,
      softValidationDismissed
    );
  }, [name, softValidationDismissed]);

  const [statefulValue, setStatefulValue] = useState(value);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setStatefulValue(value);
  }, [value]);

  const { hasFocus, onFocus, onBlur } = useHasFocus(props);
  const disabled = props.disabled || false;

  const filteredErrors = useErrorsWithoutAddingNewWhenFocused(
    errors || [],
    hasFocus
  );

  useEffect(() => {
    if (statefulValue !== props.defaultValue) {
      setIsDirty(true);
    }
  }, [props.defaultValue, statefulValue]);

  const primaryState: IInputPrimaryState = (() => {
    if (disabled) return 'disabled';

    if (disableValidations) return 'undetermined';

    if (filteredErrors && filteredErrors.length > 0) return 'invalid';

    if (hasFocus && tooltip) return 'undetermined-with-tooltip';
    if (hasFocus) return 'undetermined';

    if (softValidationError && !softValidationDismissed) return 'soft-invalid';

    if (isDirty && (!filteredErrors || filteredErrors.length === 0))
      return 'valid';

    return 'undetermined';
  })();

  return {
    axoProps: {
      hasFocus,
      softValidationError: softValidationError,
      hasSoftValidationError: !!softValidationError,
      errors: filteredErrors,
      label,
      labelId,
      tooltip,
      warning,
      softValidationPrompt,
      primaryState: primaryState,
      visuallyDetachedState,
    },
    htmlProps: {
      ...props,
      id: id ?? name ?? fallbackId,
      name: name,
      value: statefulValue ?? '',
      onFocus: (e) => {
        onFocus?.(e);
      },
      onBlur: (e) => {
        onBlur?.(e);
      },
      onChange: (e) => {
        setStatefulValue(e.target.value);
        setSoftValidationDismissed(false);
        props.onChange?.(e);
        setIsDirty(true);
      },
      ref,
      nls_fa_el_name: name || '',
    },
    setValue: setStatefulValue,
    dismissSoftValidation: () => setSoftValidationDismissed(true),
  };
};
