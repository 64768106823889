import { getIsOverLimit } from '../../../utils/getIsOverLimit';
import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';

type TGetValuesForRefinanceValueChange = {
  newValue: number;
  debtType: string;
  currentCashAmount: number;
  maxLoanAmount?: number;
  isUpSell: boolean;
  refinanceableDebts: TRefinanceableDebt[];
  maxRefinanceableDebtsValues: number[];
};

export const getValuesForRefinanceValueChange = ({
  newValue,
  debtType,
  refinanceableDebts,
  currentCashAmount,
  maxLoanAmount,
  isUpSell,
  maxRefinanceableDebtsValues,
}: TGetValuesForRefinanceValueChange) => {
  const currentRefinanceableDebtsValue = refinanceableDebts.reduce(
    (acc, { value }) => acc + value,
    0
  );

  const currentLoanAmount = currentRefinanceableDebtsValue + currentCashAmount;

  const isDebtChecked = refinanceableDebts.some(
    (debt) => debt.key === debtType && debt.isChecked
  );

  const newRefinanceableDebts = refinanceableDebts.map((debt, idx) => {
    if (debtType !== debt.key) return debt;

    return {
      ...debt,
      value: Math.min(newValue, maxRefinanceableDebtsValues[idx]),
    };
  });

  const isOverLimit = getIsOverLimit({
    refinanceableDebts: newRefinanceableDebts,
    maxLoanAmount,
    cashAmount: currentCashAmount,
  });

  const newRefinanceableDebtsValue = newRefinanceableDebts.reduce(
    (acc, { value, isChecked }) => (isChecked ? acc + value : acc),
    0
  );

  const newCashAmount = isUpSell
    ? currentCashAmount
    : Math.max(0, currentLoanAmount - newRefinanceableDebtsValue);

  if (maxLoanAmount && isOverLimit && isDebtChecked) {
    if (newRefinanceableDebtsValue >= maxLoanAmount) {
      const sumOfOtherDebts = refinanceableDebts.reduce(
        (acc, { value, isChecked, key }) =>
          isChecked && key !== debtType ? acc + value : acc,
        0
      );

      const maxValueForChangedDebt = Math.max(
        0,
        maxLoanAmount - sumOfOtherDebts
      );

      const newRefinanceableDebtsWithMaxValue = newRefinanceableDebts.map(
        (debt) => {
          if (debt.key !== debtType) return debt;

          return {
            ...debt,
            value: maxValueForChangedDebt,
          };
        }
      );

      return {
        refinanceableDebts: newRefinanceableDebtsWithMaxValue,
        cashAmount: 0,
      };
    }

    const newCashAmount = Math.max(
      0,
      maxLoanAmount - newRefinanceableDebtsValue
    );

    return {
      refinanceableDebts: newRefinanceableDebts,
      cashAmount: newCashAmount || 0,
    };
  }

  return {
    refinanceableDebts: newRefinanceableDebts,
    cashAmount: newCashAmount,
  };
};
