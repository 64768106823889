import { IIcon } from '../Icons';
import classNames from '../../../utils/class-names';
import styles from './TrustPilotStars.module.scss';
import { Icons } from '../Icons/Icons';

export type ITrustPilotStars = {
  rating: number;
  size?: IIcon['size'];
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TrustPilotStars = ({ rating, size }: ITrustPilotStars) => {
  const [integer, decimal] = rating.toString().split('.');
  const starsCount = parseInt(integer);
  const partialStarDecimal = parseInt(decimal);

  const Star = () => (
    <div className={styles.starWrapper}>
      <Icons.TrustPilotStar size={size} />
    </div>
  );

  const PartialStar = () => (
    <div className={styles.starWrapperPartial}>
      <Icons.TrustPilotStar
        size={size}
        classes={{
          root: classNames(
            styles.star,
            styles[`partialStar-${partialStarDecimal}`]
          ),
        }}
      />
    </div>
  );

  return (
    <div className={styles.starContainer}>
      {Array(starsCount)
        .fill('')
        .map((_, key) => (
          <Star key={'TrustPilot_star' + key} />
        ))}
      {partialStarDecimal > 0 && <PartialStar />}
    </div>
  );
};
