import { IThousandsSeparator, Formatters } from '@axo/shared/util/string';
import clsx from 'clsx';
import styles from './Stat.module.scss';
import { Span } from '@axo/deprecated/util/ui-components';

export type IStat = {
  label: string;
  value: number;
  name: string;
  valuePrefix?: string;
  thousandsSeparator?: IThousandsSeparator;
};

export const Stat = ({
  value,
  label,
  valuePrefix,
  thousandsSeparator = ' ',
}: IStat) => (
  <div className={clsx('Stat', styles.container)}>
    <div className={styles.content}>
      <Span
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: label }}
      ></Span>
      <Span className={styles.stat}>{`${Formatters.formatNumber(
        Math.round(value),
        thousandsSeparator
      )}${valuePrefix ?? ''}`}</Span>
    </div>
  </div>
);

export default Stat;
