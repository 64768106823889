export const getAssentlyToken = async (axoApiUrl: string): Promise<string> => {
  const url = `${axoApiUrl}/debt-register/assently-token`;

  const res = await fetch(url);
  if (res.ok) {
    return await res.json();
  }
  const errorMessage = `Failed to fetch auth token: ${res.status} ${res.statusText}`;
  throw new Error(errorMessage);
};
