import Cookie from 'js-cookie';
import { parseCookieData } from '../ParseCookie/ParseCookieData';

export const cookieWithConverter = Cookie.withConverter({
  read: (value: string, name: string) => {
    const res = Cookie.converter.read(value, name);
    return parseCookieData(JSON.parse(res));
  },

  write: (value: { [key: string]: string } | string, name: string): string => {
    if (typeof value === 'object' && value !== null) {
      value = Object.entries(value)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');
    }
    return Cookie.converter.write(JSON.stringify(value), name);
  },
});
