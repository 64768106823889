/**
 * A function that allows running arbitrary code after a delay. It returns a
 * Promise which resolves to the value returned from the callback.
 */
const doAfterDelay = <T>(callback: () => T, delayMs: number): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        resolve(await callback());
      } catch (e) {
        reject(e);
      }
    }, delayMs);
  });
};

export default doAfterDelay;
