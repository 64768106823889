import * as GLOBAL from './global';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const EMAIL_REGEX = GLOBAL.EMAIL_REGEX;
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TEL_NUMBER_REGEX = '^(0047|\\+47|47)?(4|9)\\d{7}';
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const INTEREST_RATE = 0.06;
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const STARTUP_FEE = 950;
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const MONTHLY_FEE = 30;
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const RATES = {
  LOW: 4.9,
  MID: 11.9,
  HIGH: 19.9,
};
