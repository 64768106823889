import { classNames } from '@axo/shared/util/dom';
import { ReactElement } from 'react';
import { Icons } from '../../atoms/Icons';
import { P, Span } from '../../atoms/StyledElements';
import MessageBox from '../MessageBox';
import styles from './SavingsExample.module.scss';

type ISavingsCard = {
  key: string;
  title: string;
  value: string;
};

interface ISavingsExample {
  title: string;
  disclaimer?: string;
  savingCards: ISavingsCard[];
  grayscale?: boolean;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export function SavingsExample({
  title,
  savingCards,
  disclaimer,
  grayscale = false,
}: ISavingsExample): ReactElement | null {
  const isSingleCard = savingCards.length === 1;

  return (
    <div className={styles.savingsExampleContainer} data-grayscale={grayscale}>
      <div className={styles.titleWrapper}>
        <Icons.PiggyBank size="xs" color={{ primary: '--success-900' }} />
        <P>{title}</P>
      </div>
      <div className={styles.cardContainer}>
        {savingCards.map(({ key, title, value }) => (
          <MessageBox
            key={key}
            classes={{
              root: classNames(styles.saveAmount, styles.messageBoxOverrides),
            }}
            iconVisible={false}
            variety="success"
          >
            <div
              className={classNames(
                styles.cardContent,
                isSingleCard && styles.row
              )}
            >
              <P>{value}</P>
              <P>{title}</P>
            </div>
          </MessageBox>
        ))}
      </div>
      {disclaimer && <Span className={styles.disclaimer}>{disclaimer}</Span>}
    </div>
  );
}

export default SavingsExample;
