import React, { FC } from 'react';
import styles from './Switch.module.scss';
import classNames from '../../../utils/class-names';

export interface ISwitch
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'onChange'
  > {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  variant?: 'compact' | 'default' | 'square';
  // default is light
  color?: 'light' | 'dark';
  classes?: ClassNamesProp;
}

type ClassNamesProp = {
  root?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Switch: FC<ISwitch> = ({
  onChange,
  children,
  checked = false,
  variant = 'default',
  color = 'light',
  classes,
  ...props
}) => (
  <button
    {...props}
    type="button"
    className={classNames(styles.container, classes?.root)}
    aria-checked={`${checked}`}
    onClick={() => onChange?.(!checked)}
  >
    <span
      className={classNames(
        styles.switch,
        checked && styles.checked,
        styles[variant],
        styles[color]
      )}
    >
      <span aria-hidden="true" className={styles.thumb} />
    </span>
    {children}
  </button>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Switch;
