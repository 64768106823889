type ISubmitConsent = {
  token: string;
  axoApiUrl: string;
  applicationId: string;
};

export const submitConsent = async ({
  token,
  applicationId,
  axoApiUrl,
}: ISubmitConsent) => {
  const response = await fetch(
    `${axoApiUrl}/debt-register/${applicationId}/consent`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'text/plain',
        Authorization: token,
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = await response.json();

  if (data) return data;
};
