import { z } from 'zod';

export const utmParametersSchema = z.object({
  Source: z.string().nullable().optional(),
  Medium: z.string().nullable().optional(),
  Term: z.string().nullable().optional(),
  Content: z.string().nullable().optional(),
  Name: z.string().nullable().optional(),
});

export type IUtmParameters = z.infer<typeof utmParametersSchema>;
