/* eslint-disable max-classes-per-file */
import { Dropdown } from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  QuestionDropdownModel,
  Serializer,
} from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionDropdown } from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import { IGetCountryOptions, getCountryOptions } from './getCountryOptions';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionDropdownModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('placeholder', this, false);
    this.createLocalizableString('tooltip', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get placeholder(): string {
    return this.getLocalizableStringText('placeholder');
  }

  public set placeholder(val: string) {
    this.setLocalizableStringText('placeholder', val);
  }

  get locPlaceholder(): LocalizableString {
    return this.getLocalizableString('placeholder');
  }

  public get locale(): string {
    return this.getPropertyValue('locale');
  }

  public set locale(val: string) {
    this.setPropertyValue('locale', val);
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
}

export class SurveyQuestion extends SurveyQuestionDropdown {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <Dropdown
          {...props}
          options={getCountryOptions(
            this.question.locale as IGetCountryOptions
          )}
        />
      </div>
    );
  }
}

export function registerCountryDropdownQuestion() {
  const questionType = 'Axo Country Dropdown';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'locale',
        category: 'general',
        type: 'string',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'dropdown'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
