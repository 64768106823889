import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
  ReactNode,
  Ref,
} from 'react';
import styles from './stack.module.scss';

export const directions = ['h', 'v'] as const;
export const gaps = [
  '3xs',
  '2xs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  '2xl',
  '3xl',
] as const;

export const alignments = [
  'start',
  'end',
  'center',
  'stretch',
  'baseline',
] as const;

type StackProps<T extends ElementType = 'div'> = {
  children: ReactNode;
  direction?: (typeof directions)[number];
  gap?: (typeof gaps)[number];
  align?: (typeof alignments)[number];
  wrap?: boolean;
  className?: string;
  style?: CSSProperties;
  as?: T;
  _ref?: Ref<Element>;
} & ComponentPropsWithoutRef<T>;

export const Stack = <T extends ElementType = 'div'>({
  children,
  direction = 'v',
  gap = 'm',
  align,
  wrap,
  className,
  as,
  _ref,
  ...props
}: StackProps<T>) => {
  const Component = as || ('div' as ElementType);

  return (
    <Component
      className={clsx(styles.stack, className)}
      data-direction={direction}
      data-gap={gap}
      data-align={align}
      data-wrap={wrap}
      ref={_ref}
      {...props}
    >
      {children}
    </Component>
  );
};
