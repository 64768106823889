import { ReactNode } from 'react';

import styles from './AuthenticationCard.module.scss';

type TAuthenticationCardProps = {
  title: string;
  description: string;
  children: ReactNode;
};

export const AuthenticationCard = ({
  title,
  description,
  children,
}: TAuthenticationCardProps) => {
  return (
    <div className={styles.authenticationCard}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <div className={styles.authentication}>{children}</div>
    </div>
  );
};
