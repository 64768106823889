import { KeyBenefits, Switch } from '@axo/deprecated/util/ui-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useAuthenticationContext } from '@axo/form/feature/axo-form-v2/lib/context/Authentication/AuthenticationContext';
import {
  AuthenticationProviders,
  AuthenticationProvidersContextProvider,
  type IAuthProviderResponse,
  type TAuthenticationProvidersProps,
} from '@axo/shared/auth/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { Spinner } from '@axo/ui-core/components/Spinner';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { MessageBoxDebtInfo } from '../MessageBoxDebtInfo';
import { AuthenticationCard } from './components/AuthenticationCard/AuthenticationCard';
import {
  type DebtRegistryModalsTypes,
  modals,
} from './components/DebtRegistryModals';
import { DebtRegistryModalsUI } from './components/DebtRegistryModals/DebtRegistryModalsUI';
import { ProvidersSeparator } from './components/ProvidersSeparator/ProvidersSeparator';
import events from './constants/events';
import labels from './constants/labels';
import styles from './DebtRegistry.module.scss';
import type { IDebtsResponse } from './hooks/useAssentlyClient';
import { useConsent } from './hooks/useConsent';
import { getDebts } from './utils/getDebts';

const switchStyles = { root: styles.switch };
const keyBenefitsStyles = { root: styles.keyBenefits };

export const DEBT_REGISTRY_SESSION_KEYS = {
  debtManualInput: 'axo.showDebtRegisterManualInput',
};

const DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE =
  'DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE';
export const debtRegistrySharingTimestampStorage = {
  get: () => {
    const str = sessionStorage.getItem(DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE);
    return str ? new Date(str) : undefined;
  },
  set: (token: Date) =>
    sessionStorage.setItem(
      DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE,
      token.toString()
    ),
  delete: () =>
    sessionStorage.removeItem(DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE),
};

export type TDebtRegistryProps = {
  applicationID: string;
  customerJWT: string;
  onToggleManualMode?: (manual: boolean) => void;
  onFetchDebt?: (data: IDebtsResponse) => void;
  allowManualInput?: boolean;
  forwardRef?: RefObject<HTMLButtonElement>;
  isDebtInfoViewFeatureEnabled?: boolean;
  isSellingPointsVisible?: boolean;
  hasCard?: boolean;
  axoApiUrl: string;
  providers: TAuthenticationProvidersProps['providers'];
};

export const DebtRegistry = ({
  applicationID,
  customerJWT,
  providers,
  allowManualInput = true,
  isSellingPointsVisible = true,
  axoApiUrl,
  onToggleManualMode,
  onFetchDebt,
  isDebtInfoViewFeatureEnabled = true,
  hasCard = true,
}: TDebtRegistryProps) => {
  const { token, setAuthentication } = useAuthenticationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [showManualInput, setShowManualInput] = useState(false);
  const [activeModal, setActiveModal] = useState<
    DebtRegistryModalsTypes | undefined
  >(undefined);

  const [debtsData, setDebtsData] = useState<IDebtsResponse | undefined>(
    undefined
  );

  const { track } = useAnalytics();

  const { checkUserConsent, submitUserConsent, hasConsented } = useConsent({
    applicationID,
    axoApiUrl,
  });

  const handleToggleManualInput = useCallback(
    (showManualInput: boolean) => {
      track({
        event: events.debtRegisterFillManualToggled,
        params: { value: showManualInput },
      });

      setShowManualInput(showManualInput);
      onToggleManualMode?.(showManualInput);

      window.sessionStorage.setItem(
        DEBT_REGISTRY_SESSION_KEYS.debtManualInput,
        JSON.stringify(showManualInput)
      );
    },
    [onToggleManualMode, track]
  );

  useEffect(() => {
    const showManualInput = window.sessionStorage.getItem(
      DEBT_REGISTRY_SESSION_KEYS.debtManualInput
    );

    if (showManualInput) {
      handleToggleManualInput(JSON.parse(showManualInput));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowConsentModal = useCallback(
    async (showModal: boolean) => {
      if (!showModal) return;

      setActiveModal(modals.consent);

      track({
        event: events.debtRegisterConsentInitiated,
        params: { value: showModal },
      });
    },

    [track]
  );

  const handleOnConsent = useCallback(
    async (accepted: boolean) => {
      if (accepted) {
        if (!token) throw new Error('Token missing for submit Consent');

        setIsLoading(true);

        const debtsResponse = await submitUserConsent(token);

        if (!debtsResponse?.debts) {
          setActiveModal('error');
          setIsLoading(false);
          return;
        }

        onFetchDebt?.(debtsResponse);
        setDebtsData(debtsResponse);
        setActiveModal(undefined);
        setIsLoading(false);
      }
    },
    [token, submitUserConsent, onFetchDebt]
  );

  const handleShowDetailsModal = () => {
    setActiveModal(modals.information);

    track({
      event: events.debtRegisterDetailsViewed,
    });
  };

  const handleModalClose = () => {
    setActiveModal(undefined);
  };

  const onProviderLoginSuccess = useCallback(
    async ({ token, provider: _provider }: IAuthProviderResponse) => {
      if (!token) throw new Error('Missing Axo JWT Token');
      setIsLoading(true);
      setAuthentication(token, _provider);

      const hasConsented = await checkUserConsent(token);

      if (!hasConsented) {
        setIsLoading(false);
        handleShowConsentModal(true);
        return;
      }

      const debtsResponse = await getDebts({
        authToken: token,
        applicationId: applicationID,
        customerJWT,
        axoApiUrl,
      });

      if (debtsResponse?.debts) {
        onFetchDebt?.(debtsResponse);
        setDebtsData(debtsResponse);
      } else {
        handleShowConsentModal(true);
      }
      setIsLoading(false);
    },
    [
      applicationID,
      axoApiUrl,
      checkUserConsent,
      customerJWT,
      onFetchDebt,
      handleShowConsentModal,
      setAuthentication,
    ]
  );

  const onProviderLoginError = () => {
    setActiveModal(modals.error);
  };

  return (
    <>
      <div className={styles.debtRegistry} data-has-card={hasCard}>
        {isSellingPointsVisible && !hasConsented && (
          <KeyBenefits
            ingress={labels.sellingPointsTitle}
            benefits={labels.sellingPoints}
            classes={keyBenefitsStyles}
          />
        )}
        {!hasConsented && (
          <AuthenticationCard
            title={labels.loginText}
            description={labels.loginDescription}
          >
            <AuthenticationProvidersContextProvider
              onProviderLoginError={onProviderLoginError}
              onProviderLoginSuccess={onProviderLoginSuccess}
            >
              <AuthenticationProviders
                providers={providers}
                axoApiUrl={axoApiUrl}
                separator={
                  <ProvidersSeparator>
                    {labels.loginSeparatorText}
                  </ProvidersSeparator>
                }
              />
            </AuthenticationProvidersContextProvider>
          </AuthenticationCard>
        )}
        {allowManualInput && (
          <Switch
            checked={showManualInput}
            onChange={handleToggleManualInput}
            classes={switchStyles}
          >
            {labels.manualInputText}
          </Switch>
        )}
        {isDebtInfoViewFeatureEnabled && hasConsented && (
          <MessageBoxDebtInfo
            text={labels.debtInformationText}
            onClick={handleShowDetailsModal}
            totalDebtText={
              labels.loansModalLabels.loansModalDebtEntriesTexts.totalDebt
            }
            showDetailsText={labels.debtInformationShowDetailsText}
            totalDebt={debtsData?.debtTotal ?? 0}
          />
        )}
        <DebtRegistryModalsUI
          activeModal={activeModal}
          handleModalClose={handleModalClose}
          handleOnConsent={handleOnConsent}
          debtsData={debtsData}
        />
      </div>

      {isLoading && (
        <div className={styles.spinner}>
          <Spinner size="xs" />
        </div>
      )}
    </>
  );
};
