import { IAssentlyClient, initAssently } from '../client/assentlyClient';

/**'
 * Adds the Assently script to the DOM dynamically.
 *
 * @param serviceSourceUrl - The URL of the Assently script.
 * @param onLoad - The callback function to be executed when the script is loaded.
 * @returns The HTMLScriptElement representing the added script.
 */
export const addAssentlyScriptToDom = (
  serviceSourceUrl: string,
  assentlyClientConfig: IAssentlyClient
): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = serviceSourceUrl;
  script.async = true;
  script.onload = () => initAssently(assentlyClientConfig);

  return document.body.appendChild(script);
};
