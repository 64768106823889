import { clsx } from 'clsx';
import { FC, ReactNode, useEffect, useState } from 'react';
import { IInputProps } from '@axo/deprecated/util/ui-components';
import styles from './CheckboxButton.module.scss';
export interface ICheckboxButton
  extends IInputProps<HTMLInputElement, boolean | undefined> {
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
  fontVariant?: 'primary' | 'secondary';
}

const CheckboxButton: FC<ICheckboxButton> = ({
  value = false,
  children,
  name,
  onChange,
  elementId,
  icon,
  fontVariant = 'primary',
  defaultValue,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue || value);
  useEffect(() => setIsChecked(value), [value]);

  // @ts-expect-error FIXME avoid passing irrelevant props to DOM, react complains about it
  const { errors, warning, softValidationError, tooltip, ...inputProps } =
    props;

  return (
    <div className={clsx('CheckboxButton', styles.button)}>
      <label htmlFor={elementId}>
        <input
          id={elementId}
          name={name}
          checked={isChecked}
          type="checkbox"
          className={styles.checkbox}
          onChange={(e) => {
            setIsChecked(value);
            onChange?.(e);
          }}
          {...inputProps}
        />
        <span className={styles[`font-${fontVariant}`]}>{children}</span>
        {icon && <span className={styles.iconWrapper}>{icon}</span>}
      </label>
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default CheckboxButton;
