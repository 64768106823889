import doAfterDelay from './doAfterDelay';

/**
 * Function allowing to retry arbitrary code a set amount of times with a given
 * delay between each retry. It returns a Promise which resolves either to the
 * value returned from the callback or rejects with the error thrown from the
 * callback.
 */
const retryFailedAfterDelay = async <T>(
  callback: () => T,
  delayMs = 1000,
  retryCount = 3
): Promise<T> => {
  try {
    return await callback();
  } catch (e) {
    if (retryCount > 0) {
      return await doAfterDelay(
        () => retryFailedAfterDelay(callback, delayMs, --retryCount),
        delayMs
      );
    } else {
      throw e;
    }
  }
};

export default retryFailedAfterDelay;
