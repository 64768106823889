import {
  formatFactory,
  useOnClickElsewhereListener,
} from '@axo/deprecated/util/ui-components';
import { CurrencyCode, IThousandsSeparator } from '@axo/shared/util/string';
import React, {
  Children,
  cloneElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './LoanOverview.module.scss';
import { Button, IInput, ISelect, Input, Select } from './components';

type ChildType = React.ReactElement<ISelect | IInput>;
export interface ILoanOverview {
  children: [ChildType, ChildType];
  currencyCode: CurrencyCode;
  estimatedMonthlyPay: number;
  separator: IThousandsSeparator;
  microcopy: {
    button: {
      change: string;
      done: string;
    };
    monthlyPay: {
      label: string;
    };
  };
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const LoanOverview = ({
  children,
  currencyCode,
  estimatedMonthlyPay,
  separator,
  microcopy,
}: ILoanOverview) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const closeEditMode = () => setIsEditing(false);
  const toggle = () => setIsEditing((prev) => !prev);
  useOnClickElsewhereListener(ref, closeEditMode);

  const formatter = useMemo(
    () => formatFactory({ thousandsSeparator: separator }),
    [separator]
  );

  const formattedMonthlyPay = useMemo(
    () => formatter(estimatedMonthlyPay, currencyCode),
    [formatter, estimatedMonthlyPay, currencyCode]
  );

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.row}>
        {Children.map(children, (c) =>
          cloneElement(c, { disabled: !isEditing })
        )}

        <Button mode={isEditing ? 'edit' : 'change'} onClick={toggle}>
          {isEditing ? microcopy.button.done : microcopy.button.change}
        </Button>
      </div>

      {isEditing && (
        <div className={styles.row}>
          <p>{microcopy.monthlyPay.label}</p>
          <strong>{formattedMonthlyPay}</strong>
        </div>
      )}
    </div>
  );
};

// LoanOverview.Amount = Amount;
LoanOverview.Select = Select;
LoanOverview.Input = Input;

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default LoanOverview;
