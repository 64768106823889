import {
  AxoFormV1,
  MARKETS,
  VARIANTS,
  loadFormVariant,
  loadMarket,
} from '@axo/form/feature/axo-form-v1';
import { useSetUtmParametersFromAffiliateTrackingLinkOnMount } from '@axo/form/util/white-labels';
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { sentryConfig } from './sentry-config';

Sentry.init(sentryConfig);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  useSetUtmParametersFromAffiliateTrackingLinkOnMount({
    integrationUrl: import.meta.env.VITE_INTEGRATION_URL,
  });

  const market = MARKETS.find((m) => m === import.meta.env.VITE_MARKET_COUNTRY);
  const variant = VARIANTS.find((v) => v === import.meta.env.VITE_FORM_VARIANT);

  return (
    <SentryRoutes>
      <Route
        path="*"
        element={
          <AxoFormV1
            market={loadMarket({ market })}
            variant={loadFormVariant({ variant })}
          />
        }
      />
    </SentryRoutes>
  );
};

export default App;
