import { TProviders } from '@axo/shared/auth/providers';

type TState = {
  isAuthenticated: boolean;
  token?: string;
  provider?: TProviders;
  loading?: boolean;
  debtProviderData?: Record<string, unknown>;
};

type TAction =
  | {
      type: 'setProvider';
      payload: TProviders;
    }
  | {
      type: 'setIsAuthenticated';
      payload: boolean;
    }
  | {
      type: 'setToken';
      payload: string;
    }
  | {
      type: 'setLoading';
      payload: boolean;
    }
  | {
      type: 'setAuthentication';
      payload: {
        token: string;
        provider: TProviders;
        isAuthenticated: boolean;
      };
    }
  | {
      type: 'setDebtProviderData';
      payload: {
        data: Record<string, unknown>;
      };
    };

export const reducer = (state: TState, action: TAction): TState => {
  switch (action.type) {
    case 'setProvider':
      return {
        ...state,
        provider: action.payload,
      };
    case 'setIsAuthenticated':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'setToken':
      return {
        ...state,
        token: action.payload,
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      };
    case 'setAuthentication':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
        provider: action.payload.provider,
      };
    case 'setDebtProviderData':
      return {
        ...state,
        debtProviderData: action.payload.data,
      };
    default:
      return state;
  }
};
