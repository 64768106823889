import { createContext, useContext, useMemo } from 'react';
import {
  TAuthenticationProvidersContext,
  TAuthenticationProvidersContextProviderProps,
} from './AuthenticationContext.types';

const AuthenticationProvidersContext =
  createContext<TAuthenticationProvidersContext | null>(null);

export const AuthenticationProvidersContextProvider = ({
  children,
  onProviderLoginError,
  onProviderLoginSuccess,
}: TAuthenticationProvidersContextProviderProps) => {
  const value = useMemo(
    () => ({
      onError: onProviderLoginError,
      onSuccess: onProviderLoginSuccess,
    }),
    [onProviderLoginError, onProviderLoginSuccess]
  );

  return (
    <AuthenticationProvidersContext.Provider value={value}>
      {children}
    </AuthenticationProvidersContext.Provider>
  );
};

export const useAuthenticationProvidersContext = () => {
  const context = useContext(AuthenticationProvidersContext);

  if (!context) {
    throw new Error(
      'useAuthenticationProvidersContext must be used within a AuthenticationProvidersContextProvider'
    );
  }

  return context;
};
