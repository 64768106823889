interface GAEvent {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  page?:
    | {
        title: string;
        url: `/${string}`;
      }
    | string;
}

/**
 * @deprecated use analytics package instead
 * @param {GAEvent} event
 */
export const sendGAEvent = (event: GAEvent) => {
  if (typeof window === 'undefined') return;
  const w = window as typeof window & { dataLayer: (GAEvent | any)[] };

  // check if the GA script is loaded and consent is set
  if (
    !w.dataLayer?.some((item) => item[0] === 'consent' && item[1] === 'default')
  ) {
    setTimeout(() => sendGAEvent(event), 1000);
    return;
  }

  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push(event);
};

/**
 * @deprecated use analytics package instead
 * @param {{name: string, number: number}} step
 */
export const sendGAPageLoadEvent = (step: { name: string; number: number }) =>
  sendGAEvent({
    event: 'GAEvent',
    eventCategory: 'Form',
    eventAction: `Step ${step.number}`,
    eventLabel: '',
    page: {
      title: step.name,
      url: `/${step.name
        .replaceAll(' ', '-')
        .replaceAll("'", '')
        .replaceAll(':', '')
        .toLowerCase()}`,
    },
  });
