import { useAnalytics } from '@axo/shared/services/analytics';
import { captureException } from '@sentry/browser';
import { useEffect, useState } from 'react';

interface IConsentResponse {
  timestamp: string;
  uuid: string;
  errorCode: string;
  errorMessage: string;
  hasConsent: boolean;
}

export interface IAssentlyResponse {
  provider?: string;
  success: boolean;
  token?: string;
  transactionId?: string;
  type: 'authenticated' | 'failed' | 'cancelled' | 'error';
  errorMessage?: string;
}

export interface IAssentlyClient {
  setShowConsentModal: (show: boolean) => void;
  endpoint: string;
  customerJWT: string;
  assentlySrc: string;
  applicationId: string;
  onError: (errormessage: string) => void;
  onSuccess: (data: IDebtsResponse) => void;
  onSubmitConsent: () => void;
}

export interface IDebtsResponse {
  debtTotal: number;
  debts: IDebt[];
  savings: number;
}

export enum AuthenticationTypes {
  Authenticated = 'authenticated',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Error = 'error',
}

export interface IDebt {
  id?: string;
  ssn?: string;
  batchId?: number;
  type: string;
  receivedTime: string;
  processedTime: string;
  financialInstitutionId?: string;
  financialInstitutionName: string;
  creditLimit: number;
  interestBearingBalance: number;
  nonInterestBearingBalance: number;
  nominalInterestRate: number;
  installmentChargePeriod: string;
  installmentCharges: number;
  balance: number;
  originalBalance: number;
  terms?: string;
  coBorrower: string;
  createdAt?: string;
  owner: string;
  refinanceable: boolean;
}

const ASSENTLY_TOKEN = 'ASSENTLY_TOKEN';
const assentlyTokenStorage = {
  get: () => sessionStorage.getItem(ASSENTLY_TOKEN),
  set: (token: string) => sessionStorage.setItem(ASSENTLY_TOKEN, token),
  delete: () => sessionStorage.removeItem(ASSENTLY_TOKEN),
};

export const useAssentlyClient = ({
  setShowConsentModal,
  endpoint,
  applicationId,
  customerJWT,
  onError,
  onSuccess,
  assentlySrc,
  onSubmitConsent,
}: IAssentlyClient) => {
  const [isLoading, setIsLoading] = useState(true);
  const [assentlyToken, setAssentlyToken] = useState('');
  const [totalDebt, setTotalDebt] = useState(0);
  const [debts, setDebts] = useState<IDebt[]>([]);

  const { track } = useAnalytics();

  const handleError = (e: string) => {
    // Remove token on error, always
    assentlyTokenStorage.delete();
    // Set loading to false on error always
    setIsLoading(false);
    onError(e);
  };

  let coreIdClient: any;

  useEffect(() => {
    const authenticate = () => {
      // First check storage if we have token.
      const cachedToken = assentlyTokenStorage.get();
      if (cachedToken) {
        // We have cached token, check consent on it
        // if the token is invalid, an error will be thrown from one of the api routes which will remove it.
        setAssentlyToken(cachedToken);
        checkConsent(cachedToken);
      } else {
        fetch(`${endpoint}/debt-register/assently-token`, {
          headers: {
            Authorization: customerJWT,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              handleError(response.statusText);
            }
          })
          .then((token) => {
            // https://docs.assently.com/coreid/#coreid-client-api-and-configuration
            coreIdClient = (window as any).coreid_client;
            coreIdClient.init({
              config: {
                allowedEids: ['no-bankid-mobile', 'no-bankid'],
                mode: 'auth',
                language: 'no',
                location: 'no',
              },
              token: token,
              callback: function (data: IAssentlyResponse) {
                handleAssentlyResult(data);
              },
            });
            setIsLoading(false);
          });
      }
    };

    const script = document.createElement('script');

    script.src = assentlySrc;
    script.async = true;
    script.onload = authenticate;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // @see https://docs.assently.com/coreid/#coreid-client-api-and-configuration
  const handleAssentlyResult = (data: IAssentlyResponse) => {
    switch (data.type) {
      case AuthenticationTypes.Authenticated:
        if (data.success && data.token) {
          setIsLoading(false);
          coreIdClient.close();
          setAssentlyToken(data.token);
          checkConsent(data.token);
        } else {
          const msg = 'no token returned from assently';
          setIsLoading(false);
          handleError(msg);
        }
        break;

      case AuthenticationTypes.Failed:
        track({
          event: 'Debt Register Consent Aborted',
          params: {
            type: 'failed',
            message: data.errorMessage,
          },
        });
        handleError(data.errorMessage || 'Authentication failed');
        break;

      case AuthenticationTypes.Cancelled:
        track({
          event: 'Debt Register Consent Aborted',
          params: {
            type: 'cancelled',
            message: data.errorMessage,
          },
        });
        setIsLoading(false);
        break;

      case AuthenticationTypes.Error:
        track({
          event: 'Debt Register Consent Aborted',
          params: {
            type: 'error',
            message: data.errorMessage,
          },
        });
        handleError(data.errorMessage || 'an error occurred');
        setIsLoading(false);
        break;
    }
  };

  const showAssentlyModal = () => {
    setIsLoading(true);
    (window as any).coreid_client.start();
  };

  const checkConsent = (assentlyToken: string) => {
    fetch(`${endpoint}/debt-register/${applicationId}/consent`, {
      method: 'PATCH',
      body: assentlyToken,
      headers: {
        'Content-type': 'text/plain',
        Authorization: customerJWT,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          handleError(response.statusText);
        }
      })
      .then((data: IConsentResponse) => {
        setShowConsentModal(!data.hasConsent);

        if (data.hasConsent) {
          onSubmitConsent();
          getDebts(assentlyToken);
          // Store token in session storage to persist on page reloads
          assentlyTokenStorage.set(assentlyToken);
        }
      })
      .catch((err) => {
        captureException(err);
        handleError(err);
      });
  };

  const submitConsent = async () => {
    const response = await fetch(
      `${endpoint}/debt-register/${applicationId}/consent`,
      {
        method: 'POST',
        body: assentlyToken,
        headers: {
          'Content-type': 'text/plain',
          Authorization: customerJWT,
          jwt: customerJWT,
        },
      }
    );

    if (!response.ok) {
      handleError(response.statusText);
      throw response.statusText;
    }

    const data = await response.json();

    if (data) return data;
  };

  const getDebts = async (assentlyTokenArgument?: string) => {
    const response = await fetch(
      `${endpoint}/debt-register/${applicationId}/data`,
      {
        method: 'PATCH',
        body: assentlyTokenArgument ?? assentlyToken,
        headers: {
          Authorization: customerJWT,
          jwt: customerJWT,
        },
      }
    );

    if (!response.ok) {
      handleError(response.statusText);
      throw response.statusText;
    }

    const data: IDebtsResponse = await response.json();

    if (data) {
      const convertedData = convertIntToDecimals(data);
      onSuccess(convertedData);
      setTotalDebt(convertedData['debtTotal']);
      setDebts(convertedData['debts']);
      return convertedData;
    }
  };

  return {
    isLoading,
    showAssentlyModal,
    submitConsent,
    getDebts,
    totalDebt,
    debts,
  };
};

// The data that comes from debtRegistry is stored as integers to avoid decimals. Convert to decimals before use.
export const convertIntToDecimals = (debt: IDebtsResponse) => {
  debt.debtTotal = Math.round(debt.debtTotal / 100);

  debt.debts.forEach((debtObject) => {
    debtObject.interestBearingBalance = Math.round(
      debtObject.interestBearingBalance / 100
    );
    debtObject.creditLimit = Math.round(debtObject.creditLimit / 100);
    debtObject.nonInterestBearingBalance = Math.round(
      debtObject.nonInterestBearingBalance / 100
    );
    debtObject.originalBalance = Math.round(debtObject.originalBalance / 100);
    debtObject.balance = Math.round(debtObject.balance / 100);
    debtObject.nominalInterestRate /= 100;
  });

  return debt;
};
