/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Change = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Change</title>
    <path
      d="M12.3693 1.06873C11.7445 0.443888 10.7314 0.443888 10.1066 1.06873L4.03793 7.13736V9.4001H6.30066L12.3693 3.33147C12.9941 2.70663 12.9941 1.69357 12.3693 1.06873Z"
      fill="#5A6570"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0379276 3.8001C0.0379276 2.91644 0.754272 2.2001 1.63793 2.2001H4.83793C5.27975 2.2001 5.63793 2.55827 5.63793 3.0001C5.63793 3.44193 5.27975 3.8001 4.83793 3.8001H1.63793V11.8001H9.63793V8.6001C9.63793 8.15827 9.9961 7.8001 10.4379 7.8001C10.8798 7.8001 11.2379 8.15827 11.2379 8.6001V11.8001C11.2379 12.6838 10.5216 13.4001 9.63793 13.4001H1.63793C0.754272 13.4001 0.0379276 12.6838 0.0379276 11.8001V3.8001Z"
      fill="#5A6570"
    />
  </svg>
);
