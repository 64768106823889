/* eslint-disable max-classes-per-file */
import {
  KeyBenefits,
  IKeyBenefits,
  calculateTotalPaymentNO,
} from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';

const __CALCULATED_EXAMPLE__ = '__CALCULATED_EXAMPLE__';

type Maybe<T> = T | null | undefined;

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get syncAppliedAmount() {
    return this.getPropertyValue('syncAppliedAmount', '');
  }

  set syncAppliedAmount(newValue: string) {
    this.setPropertyValue('syncAppliedAmount', newValue);
  }

  get syncLoanDurationYears() {
    return this.getPropertyValue('syncLoanDurationYears', '');
  }

  set syncLoanDurationYears(newValue: string) {
    this.setPropertyValue('syncLoanDurationYears', newValue);
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  public get content(): string[] {
    return this.getPropertyValue('content');
  }
  public set content(val: string[]) {
    this.setPropertyValue('content', val);
  }

  get valueExpression(): Maybe<string> {
    return this.getPropertyValue('valueExpression', null);
  }

  set valueExpression(valueExpression: Maybe<string>) {
    this.setPropertyValue('valueExpression', valueExpression);
  }

  get expressionDependencies(): Maybe<string[]> {
    return this.getPropertyValue('expressionDependencies', null);
  }

  set expressionDependencies(dependencies: Maybe<string[]>) {
    this.setPropertyValue('expressionDependencies', dependencies);
  }
  get ingress(): string {
    return this.getPropertyValue('ingress');
  }

  set ingress(ingress: string) {
    this.setPropertyValue('ingress', ingress);
  }
  get checkmarkColor(): IKeyBenefits['checkmarkColor'] {
    return this.getPropertyValue('checkmarkColor');
  }

  set checkmarkColor(checkmarkColor: IKeyBenefits['checkmarkColor']) {
    this.setPropertyValue('checkmarkColor', checkmarkColor);
  }
}

type QuestionProps = {
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.assertQuestionPropNotNull('syncAppliedAmount');
    this.assertQuestionPropNotNull('syncLoanDurationYears');
    this.question.value = this.question.valueExpression
      ? this.question.survey.runExpression(this.question.valueExpression)
      : '';
    this.question?.expressionDependencies?.forEach((dep) => {
      const question = this.question.survey.getQuestionByName(dep);
      question.registerFunctionOnPropertyValueChanged(
        'value',
        () =>
          // set timeout to "ensure" values being updated internally in surveyJs before running expression.
          setTimeout(() => {
            this.question.value = this.question.valueExpression
              ? this.question.survey.runExpression(
                  this.question.valueExpression
                )
              : '';
          }, 50),
        `${question.name}-keyBenefits`
      );
    });
  }

  private assertQuestionPropNotNull(key: keyof QuestionModel) {
    if (!this.question[key]) {
      console.warn(
        `Required property ${key} is missing on the question model. Without this the KeyBenefits will not work properly.`
      );
    }
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const benefits = this.question.content.map((v) => {
      if (v === __CALCULATED_EXAMPLE__) {
        return this.question.value;
      }
      return v;
    });
    return (
      <KeyBenefits
        ingress={this.question.ingress}
        benefits={benefits}
        checkmarkColor={this.question.checkmarkColor}
      />
    );
  }
}

export function registerKeyBenefitsQuestion() {
  const questionType = 'Axo Key Benefits';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'content',
        category: 'general',
        type: 'string',
      },
      {
        name: 'syncAppliedAmount',
        default: '',
        category: 'general',
      },
      {
        name: 'syncLoanDurationYears',
        default: '',
        category: 'general',
      },
      {
        name: 'valueExpression',
        type: 'string',
        category: 'general',
      },
      {
        name: 'expressionDependencies',
        type: 'string',
        category: 'general',
      },
      {
        name: 'ingress',
        type: 'string',
        category: 'general',
      },
      {
        name: 'checkmarkColor',
        type: 'string',
        category: 'general',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'text'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register(
    'calculateTotalPaymentNO',
    calculateTotalPaymentNO
  );
}
