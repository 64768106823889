import { IAuthorizationToken } from '../../types/data/IAuthorizationToken';
import { IChanges } from '../../types/data/IChanges';
import { ICustomer } from '../../types/data/ICustomer';
import { ILoanApplication } from '../../types/data/ILoanApplication';
import client from '../client';

export const sendChanges = async ({
  changes,
  dependencies,
}: {
  changes: IChanges;
  dependencies: {
    customer: ICustomer;
    loanApplication: ILoanApplication;
    JWT: IAuthorizationToken;
  };
}) => {
  const { customer, loanApplication, JWT } = dependencies;

  await client.updateFields(changes, {
    Authorization: JWT,
    CustomerID: customer.ID,
    LoanApplicationID: loanApplication.ID,
  });
};
