export const i18n = {
  year: {
    fi: 'vuotta',
    no: 'år',
    dk: 'år',
    se: 'år',
  },
  monthlyInstallment: {
    fi: 'Arvioitu kuukausierä',
    dk: 'Forventet månedlig ydelse*',
    no: 'Estimert månedlig avdrag',
    se: 'Beräknad månatlig avbetalning',
  },
  currency: {
    fi: '€',
    no: ',-',
    se: ',-',
    dk: 'kr',
  },
  loanDuration: {
    fi: 'Laina-aika',
    dk: 'Løbetid',
    se: 'Återbetalningstid',
    no: 'Tid for hevn',
  },
  recommended: {
    fi: 'suositus',
    no: 'anbefales',
    dk: 'anbefalede',
    se: 'rekommenderad',
  },
};
