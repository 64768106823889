/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, H1, H2, Modal } from '@axo/deprecated/util/ui-components';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { CMSContentTermsAndConditions } from './CMSContentTermsAndConditions';
import { ITerms } from './Terms';
import styles from './TermsAndConditions.module.scss';

interface ITermsAndConditions {
  children?: ReactNode;
  terms: ITerms;
}

export const GeneralTerms = ({ children, terms }: ITermsAndConditions) => {
  const [modal, setModal] = useState<ReactNode>();

  return (
    <>
      <span
        className={styles.clickableTerm}
        onClick={(e) => {
          e.preventDefault();
          setModal(
            <Modal
              onBackgroundClick={() => setModal(null)}
              onCloseButton={() => setModal(null)}
              topBorderColor="--primary-500"
              data-theme={'legacy'}
              classes={{
                root: styles.termsModalBackground,
                box: clsx(styles.termsModal, 'TermsAndConditions_termsModal'),
              }}
            >
              <H1 className={styles.title}>
                {terms.general_terms_modal_title}
              </H1>
              <H2 className={styles.version}>{terms.general_terms_version}</H2>

              <section className={styles.paragraphsHolder}>
                <CMSContentTermsAndConditions
                  content={terms.general_terms_intro_paragraph}
                />
              </section>

              <div className={styles.contentHolder}>
                <CMSContentTermsAndConditions content={terms.general_terms} />
              </div>

              <Button
                classes={{ root: styles.button }}
                variant="primary"
                onClick={() => setModal(null)}
              >
                {terms.general_terms_modal_button_text}
              </Button>
            </Modal>
          );
        }}
      >
        {children}
      </span>
      {typeof document !== 'undefined' &&
        //// @ts-expect-error - TS2345: Type 'bigint' is not assignable to type 'ReactNode'.
        // @ts-ignore
        createPortal(modal, document.body)}
    </>
  );
};

export const DataRetentionTerms = ({
  children,
  terms,
}: ITermsAndConditions) => {
  const [modal, setModal] = useState<ReactNode>();

  return (
    <>
      <span
        className={styles.clickableTerm}
        onClick={(e) => {
          e.preventDefault();
          setModal(
            <Modal
              onBackgroundClick={() => setModal(null)}
              onCloseButton={() => setModal(null)}
              topBorderColor="--primary-500"
              data-theme={'legacy'}
              classes={{
                root: styles.termsModalBackground,
                box: styles.termsModal,
              }}
            >
              <H1 className={styles.title}>
                {terms.data_retention_modal_title}
              </H1>
              <H2 className={styles.version}>{terms.data_retention_version}</H2>

              <section className={styles.paragraphsHolder}>
                <CMSContentTermsAndConditions
                  content={terms.data_retention_intro_paragraph}
                />
              </section>

              <div className={styles.contentHolder}>
                <CMSContentTermsAndConditions
                  content={terms.data_retention_terms}
                />
              </div>

              <Button
                classes={{ root: styles.button }}
                variant="primary"
                onClick={() => setModal(null)}
              >
                {terms.data_retention_modal_button_text}
              </Button>
            </Modal>
          );
        }}
      >
        {children}
      </span>
      {typeof document !== 'undefined' &&
        //// @ts-expect-error - TS2345: Type 'bigint' is not assignable to type 'ReactNode'.
        // @ts-ignore
        createPortal(modal, document.body)}
    </>
  );
};
