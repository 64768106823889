import clsx from 'clsx';
import { ReactNode } from 'react';
import Label from '../../atoms/Label/Label';
import MessageBox from '../MessageBox';
import { IInputState } from '../../../hooks/useInputState';
import styles from './InputWrapper.module.scss';
import {
  InputErrorBox,
  InputSoftValidationBox,
  InputTooltipBox,
} from '../InputInfoBox';
import classNames from '../../../utils/class-names';

enum ClassNames {
  label = 'label',
  root = 'root',
  gap = 'gap',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IInputWrapper {
  inputState: IInputState;
  children?: ReactNode;
  classes?: ClassNamesProp;
  className?: string;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputWrapper = ({
  inputState,
  children,
  classes,
  className,
}: IInputWrapper) => {
  const { label, visuallyDetachedState, errors, warning, softValidationError } =
    inputState.axoProps;
  const { id } = inputState.htmlProps;

  return (
    <div className={clsx(classes?.root, className)}>
      <Label
        htmlFor={id}
        labelText={label}
        id={inputState.axoProps.labelId}
        className={classes?.label}
      >
        {children}
      </Label>
      {visuallyDetachedState && inputState.axoProps.tooltip && (
        <>
          <div className={styles.gap}></div>
          <InputTooltipBox inputState={inputState} />
        </>
      )}
      {visuallyDetachedState && softValidationError && (
        <>
          <div className={styles.gap}></div>
          <InputSoftValidationBox inputState={inputState} />
        </>
      )}
      {visuallyDetachedState && errors.length > 0 && (
        <>
          <div className={classNames(styles.gap, classes?.gap)}></div>
          <InputErrorBox inputState={inputState} />
        </>
      )}
      {warning && <MessageBox variety="warning" text={warning} />}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default InputWrapper;
