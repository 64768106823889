import { Button } from '@axo/ui-core/components/Button';
import { CriiptoProvidersProps } from './criiptoProviderMapping';
import styles from './providers.module.scss';

export const BankID = ({ label, href, onClick }: CriiptoProvidersProps) => {
  return (
    <Button
      className={styles.bankId}
      href={href}
      target="_self"
      rel="noreferrer"
      onClick={onClick}
      data-testid="criipto-login"
    >
      <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="#212529"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3195 10.4205H13.028C12.8955 10.4205 12.7874 10.5254 12.7874 10.6549V11.7773C12.7874 11.9064 12.8955 12.0114 13.028 12.0114H17.3195C17.452 12.0114 17.5601 11.9064 17.5601 11.7773V10.6549C17.5601 10.5254 17.452 10.4205 17.3195 10.4205ZM4.59216 10.4205H0.300619C0.168156 10.4205 0.0600586 10.5254 0.0600586 10.6549V11.7773C0.0600586 11.9064 0.168156 12.0114 0.300619 12.0114H4.59216C4.72463 12.0114 4.83273 11.9064 4.83273 11.7773V10.6549C4.83273 10.5254 4.72463 10.4205 4.59216 10.4205ZM10.9559 7.23864H6.6644C6.53193 7.23864 6.42384 7.34357 6.42384 7.47313V8.59549C6.42384 8.72461 6.53193 8.82955 6.6644 8.82955H10.9559C11.0884 8.82955 11.1965 8.72461 11.1965 8.59549V7.47313C11.1965 7.34357 11.0884 7.23864 10.9559 7.23864ZM4.59216 7.23864H0.300619C0.168156 7.23864 0.0600586 7.34357 0.0600586 7.47313V8.59549C0.0600586 8.72461 0.168156 8.82955 0.300619 8.82955H4.59216C4.72463 8.82955 4.83273 8.72461 4.83273 8.59549V7.47313C4.83273 7.34357 4.72463 7.23864 4.59216 7.23864ZM17.3195 4.0555H13.0275C12.895 4.0555 12.7874 4.16044 12.7874 4.28999V5.41235C12.7874 5.54147 12.895 5.64641 13.028 5.64641H17.3195C17.452 5.64641 17.5601 5.54147 17.5601 5.41235V4.28999C17.5601 4.16044 17.452 4.0555 17.3195 4.0555ZM10.9559 4.05682H6.6644C6.53193 4.05682 6.42384 4.16176 6.42384 4.29131V5.41367C6.42384 5.54279 6.53193 5.64773 6.6644 5.64773H10.9559C11.0884 5.64773 11.1965 5.54279 11.1965 5.41367V4.29131C11.1965 4.16176 11.0884 4.05682 10.9559 4.05682ZM17.3195 0.875H13.028C12.8955 0.875 12.7874 0.979938 12.7874 1.10949V2.23185C12.7874 2.36097 12.8955 2.46591 13.028 2.46591H17.3195C17.452 2.46591 17.5601 2.36097 17.5601 2.23185V1.10949C17.5601 0.979938 17.452 0.875 17.3195 0.875ZM4.59216 0.875H0.300619C0.168156 0.875 0.0600586 0.979938 0.0600586 1.10949V2.23185C0.0600586 2.36097 0.168156 2.46591 0.300619 2.46591H4.59216C4.72463 2.46591 4.83273 2.36097 4.83273 2.23185V1.10949C4.83273 0.979938 4.72463 0.875 4.59216 0.875Z"
        />
      </svg>

      <span className={styles.label}>{label}</span>
    </Button>
  );
};
