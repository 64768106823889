/* eslint-disable max-classes-per-file */
import {
  ButtonWithAnchorStyles,
  Checkbox,
  PopupConsent,
} from '@axo/deprecated/util/ui-components';
import {
  getMarketingConsentPartners,
  getMarketingConsentServices,
} from '@axo/form/configs';
import { reloadData } from '@axo/form/data-access/backend-sync';
import React, { CSSProperties, MouseEventHandler } from 'react';
import {
  ElementFactory,
  FunctionFactory,
  Question,
  Serializer,
  SurveyModel,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = {
      errors: [],
      isPartnersModalOpen: false,
      isServicesModalOpen: false,
      shouldRetrieveConsent: true,
    };
  }

  componentDidMount(): void {
    if (window.sessionStorage === undefined) return;
    const shouldRetrieveConsent =
      reloadData.get().shouldRetrieveMarketingConsent;

    // do not update state if flag is not set, should default to true
    if (shouldRetrieveConsent === null) return;

    this.setState({
      // get value as boolean
      shouldRetrieveConsent: shouldRetrieveConsent === 'true',
    });
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderElement(): JSX.Element {
    const { style, label, ...props } = getBasicQuestionProps(
      this,
      this.questionBase
    );

    const PartnersModal = () => (
      <PopupConsent
        title="Våre samarbeidspartnere"
        type="partners"
        buttonText="Ok, jeg forstår"
        onClick={togglePartnersModal}
        onBackgroundClick={togglePartnersModal}
        partnersList={getMarketingConsentPartners('no')}
      />
    );

    const ServicesModal = () => (
      <PopupConsent
        title="Andre tjenesteytelser fra Axo Finans"
        type="services"
        buttonText="Ok, jeg forstår"
        onClick={toggleServicesModal}
        onBackgroundClick={toggleServicesModal}
        partnersList={getMarketingConsentServices('no')}
      />
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.question.value = event.target.checked;
    };

    const togglePartnersModal = () => {
      this.setState({ isPartnersModalOpen: !this.state.isPartnersModalOpen });
    };

    const toggleServicesModal = () => {
      this.setState({ isServicesModalOpen: !this.state.isServicesModalOpen });
    };

    const getShouldShowConsent = () => {
      // default to true when sessionstorage is not available
      if (typeof window === 'undefined') return true;

      const pageNo = (this.question.survey as SurveyModel).currentPageNo;
      return (
        (pageNo === 0 && this.state.shouldRetrieveConsent) ||
        (pageNo === 3 && this.state.shouldRetrieveConsent)
      );
    };

    return (
      <>
        {getShouldShowConsent() && (
          <div style={style}>
            <Checkbox {...props} onChange={onChange}>
              <p>
                Ja takk, jeg vil gjerne motta markedsføring vedrørende
                finansielle produkter og andre tjenesteytelser vist{' '}
                <ButtonWithAnchorStyles
                  onClick={withPreventDefault(toggleServicesModal)}
                >
                  her
                </ButtonWithAnchorStyles>{' '}
                fra Axo Finans AS og våre{' '}
                <ButtonWithAnchorStyles
                  onClick={withPreventDefault(togglePartnersModal)}
                >
                  samarbeidspartnere
                </ButtonWithAnchorStyles>{' '}
                via e-post og SMS. Samtykket kan når som helst tilbakekalles ved
                henvendelse til Axo Finans AS.
              </p>
            </Checkbox>

            {this.state.isPartnersModalOpen && <PartnersModal />}
            {this.state.isServicesModalOpen && <ServicesModal />}
          </div>
        )}
      </>
    );
  }
}

const withPreventDefault = (fn: () => void) => {
  const wrapped: MouseEventHandler = (e) => {
    e.preventDefault();
    fn();
  };
  return wrapped;
};

export function registerMarketingConsentNorwayQuestion() {
  const questionType = 'Axo Marketing Consent Norway';
  const getMarketingConsentPartnersNO = () => getMarketingConsentPartners('no');
  const getMarketingConsentServicesNO = () => getMarketingConsentServices('no');

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'checkbox'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register(
    'getMarketingConsentPartnersNO',
    getMarketingConsentPartnersNO
  );
  FunctionFactory.Instance.register(
    'getMarketingConsentServicesNO',
    getMarketingConsentServicesNO
  );
}
