import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function getAllDebts({ url: { service: baseUrl }, token }: IAPI) {
  return async function getAllDebts(
    loanApplicationId: string
  ): Promise<loan_application.Debt[]> {
    const url = new URL(`/loan-application/${loanApplicationId}/debt`, baseUrl);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function postDebt({ url: { service: baseUrl }, token }: IAPI) {
  return async function postDebt(
    loanApplicationId: string,
    debt: loan_application.CreateDebt
  ): Promise<loan_application.Debt> {
    const url = new URL(`/loan-application/${loanApplicationId}/debt`, baseUrl);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(debt),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error posting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function patchDebt({ url: { service: baseUrl }, token }: IAPI) {
  return async function patchDebt(
    loanApplicationId: string,
    debtId: string,
    debt: Partial<loan_application.Debt>
  ): Promise<loan_application.Debt> {
    const url = new URL(`/loan-application/${loanApplicationId}/debt`, baseUrl);

    const response = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ...debt, ID: debtId }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error patching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function deleteDebt({ url: { service: baseUrl }, token }: IAPI) {
  return async function patchDebt(
    loanApplicationID: string,
    debtID: string
  ): Promise<void> {
    const url = new URL(`/loan-application/${loanApplicationID}/debt`, baseUrl);
    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ID: debtID }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error deleting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}
