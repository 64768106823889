/* eslint-disable max-classes-per-file */
import { SSNInput } from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import { getAgeFromFinnishSSN, getGenderFromFinnishSSN } from './parsers';
import { validateFinnishSSN, validateNorwegianNationalId } from './validators';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get country() {
    return this.getPropertyValue('country', this.getLocale().toUpperCase());
  }

  set country(newCountry: 'DK' | 'FI' | 'NO' | 'SE') {
    this.setPropertyValue('country', newCountry);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <SSNInput
          {...props}
          onChange={(e) => {
            this.question.value = e.target.value.toUpperCase();
          }}
          i18n={this.question.country}
        />
      </div>
    );
  }
}

export function registerSSNInputQuestion() {
  const questionType = 'Axo SSN Input';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'country',
        default: 'FI',
        category: 'general',
        choices: ['DK', 'FI', 'NO', 'SE'],
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register('validateFinnishSSN', validateFinnishSSN);
  FunctionFactory.Instance.register(
    'validateNorwegianNationalId',
    validateNorwegianNationalId
  );

  FunctionFactory.Instance.register(
    'getAgeFromFinnishSSN',
    getAgeFromFinnishSSN
  );
  FunctionFactory.Instance.register(
    'getGenderFromFinnishSSN',
    getGenderFromFinnishSSN
  );
}
