import { TRefinanceableDebt } from '../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext.types';

type TGetRefinanceAmount = {
  refinanceableDebts: TRefinanceableDebt[];
};

export const getRefinanceAmount = ({
  refinanceableDebts,
}: TGetRefinanceAmount) => {
  const refinanceAmount = refinanceableDebts.reduce(
    (acc, { value, isChecked }) => {
      if (isChecked) {
        return acc + value;
      }

      return acc;
    },
    0
  );

  return refinanceAmount;
};
