import { Button } from '@axo/ui-core/components/Button';
import { CriiptoProvidersProps } from './criiptoProviderMapping';
import styles from './providers.module.scss';

export const MitID = ({ label, href, onClick }: CriiptoProvidersProps) => {
  return (
    <Button
      className={styles.mitId}
      href={href}
      target="_self"
      rel="noreferrer"
      onClick={onClick}
      data-testid="criipto-login"
    >
      <svg
        width="62"
        height="16"
        viewBox="0 0 62 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8417 0C20.8291 0 21.6191 0.805031 21.6191 1.81132C21.6191 2.81761 20.8291 3.62264 19.8417 3.62264C18.8542 3.62264 18.0642 2.81761 18.0642 1.81132C18.0642 0.805031 18.8542 0 19.8417 0ZM40.7759 0C42.8496 0 44.5283 1.71069 44.5283 3.8239C44.5283 5.93711 42.8496 7.6478 40.7759 7.6478C38.7022 7.6478 37.0235 5.93711 37.0235 3.8239C37.0235 1.71069 38.7022 0 40.7759 0ZM21.3229 4.93082V15.8994H18.3605V4.93082H21.3229ZM3.746 0.805031L8.28833 7.94969L12.7319 0.805031H15.5956V15.8994H12.5344V6.13836L8.28833 12.5786H8.18959L3.94349 6.13836V15.8994H0.882355V0.805031H3.746ZM40.7759 9.0566C44.3308 9.0566 47.1944 11.0692 47.5894 15.8994H34.0611C34.4561 11.0692 37.3198 9.0566 40.7759 9.0566ZM52.1317 0.201258C58.4515 0.201258 61.1176 3.72327 61.1176 8.05031C61.1176 12.3774 58.4515 15.8994 52.1317 15.8994H49.5643V0.201258H52.1317ZM27.6426 1.91195V4.93082H30.0125V7.34591H27.6426V12.1761C27.6426 13.0818 28.1364 13.3836 28.9263 13.3836C29.4201 13.3836 29.9138 13.283 30.21 12.9811V15.6981C29.815 15.8994 29.0251 16 28.2351 16C26.0627 16 24.6802 14.8931 24.6802 12.478V7.34591H23.0015V4.93082H24.6802V1.91195H27.6426Z"
          fill="white"
        />
      </svg>

      <span className={styles.label}>{label}</span>
    </Button>
  );
};
