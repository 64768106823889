import { dispatch, getFormState } from '../../store';
import { IPersistanceValues } from '../../types/IPersistanceValues';
import { IAuthorizationToken } from '../../types/data/IAuthorizationToken';
import { IChanges } from '../../types/data/IChanges';
import { IChild } from '../../types/data/IChild';
import {
  ICustomer,
  ICustomerWithoutJWT,
  IPartialCustomer,
  IProtoCustomer,
} from '../../types/data/ICustomer';
import { IDebt, IPartialDebt, IProtoDebt } from '../../types/data/IDebt';
import {
  ILoanApplication,
  IPartialLoanApplication,
  IProtoLoanApplication,
} from '../../types/data/ILoanApplication';
import { IPerson, IProtoPerson } from '../../types/data/IPerson';
import { handleChangesPerTypeFactory } from './change-router';
import { updateCustomer } from './update-customer';
import { updateMarketingConsent } from './update-marketingConsent/updateMarketingConsent';
import { updatePerson } from './update-person/updatePerson';

export interface IClientAuthorizationHeaderOption {
  Authorization: IAuthorizationToken;
}
export interface IOptions {
  acceptedTerms?: boolean;
}
export interface IClientCustomerIdOption {
  CustomerID: string;
}
export interface IClientApplicationIdOption {
  LoanApplicationID: string;
}

export type IUpdateFieldsOptions = IClientAuthorizationHeaderOption &
  IClientCustomerIdOption &
  IClientApplicationIdOption;

export abstract class Client {
  constructor(protected fetch: typeof window.fetch) {}

  abstract stepCompleted(
    stepName: string,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption
  ): Promise<void>;

  abstract applicationCompleted(
    finalState: unknown,
    {
      LoanApplicationID,
      Authorization,
    }: IClientApplicationIdOption & IClientAuthorizationHeaderOption
  ): Promise<void>;

  // Changes to Person (SSN) should always create a new Person
  // and update LoanApplication with PersonID.
  public async updateFields(
    data: IChanges,
    options: IUpdateFieldsOptions
  ): Promise<void> {
    const changeHandler = handleChangesPerTypeFactory({
      Customer: async (key, changes) => {
        const newChanges = { ...changes };
        const keyIncludesMarketingConsent = key.includes('MarketingConsent');

        const acceptMarketing = !!newChanges.AcceptMarketing;
        // Only used for conditional logic, and is not accepted by the backend. Remove before sending.
        delete newChanges.AcceptMarketing;

        if (keyIncludesMarketingConsent)
          await updateMarketingConsent(
            newChanges as IPartialCustomer,
            acceptMarketing,
            options
          );
        else {
          await updateCustomer(
            {
              ...newChanges,
              MobilePhoneNumber: changes.MobilePhoneNumber?.toString(),
            },
            {
              JWT: options.Authorization,
            }
          );
        }
      },

      CoCustomer: async (_, changes) => {
        if (!changes.Email) {
          console.warn('missing email for CoCustomer');
          return;
        }
        if (!changes.MobilePhoneNumber) {
          console.warn('missing MobilePhoneNumber for CoCustomer');
          return;
        }

        if (!changes.MobilePhoneCountryCode) {
          console.warn('missing mobilePhoneCountryCode for CoCustomer');
          return;
        }

        const { ID: coCustomerID, JWT: coJWT } = await this.createCustomer({
          Email: changes.Email,
        });

        await this.updateCustomer(
          coCustomerID,
          {
            ...changes,
          },
          {
            Authorization: coJWT,
          }
        );

        return this.updateLoanApplication(
          options.LoanApplicationID,
          {
            CoApplicantCustomerID: coCustomerID,
          },
          {
            Authorization: options.Authorization,
          }
        );
      },

      Debt: async (key, changes) => {
        const id = getFormState().fieldNameToBackendIDMap[key];
        if (!id) {
          const debtID = await this.createDebtObject(changes as IProtoDebt, {
            Authorization: options.Authorization,
            LoanApplicationID: options.LoanApplicationID,
          });

          dispatch({
            type: 'field-name-to-backend-id-map.add',
            backendID: debtID,
            fieldName: key,
          });

          return;
        }

        await this.updateDebtObject(id, changes, {
          Authorization: options.Authorization,
          LoanApplicationID: options.LoanApplicationID,
        });
      },

      CoDebt: async (key, changes) => {
        const id = getFormState().fieldNameToBackendIDMap[key];
        if (!id) {
          const coDebtID = await this.createDebtObject(changes as IProtoDebt, {
            Authorization: options.Authorization,
            LoanApplicationID: options.LoanApplicationID,
          });

          dispatch({
            type: 'field-name-to-backend-id-map.add',
            backendID: coDebtID,
            fieldName: key,
          });

          return;
        }

        await this.updateDebtObject(id, changes, {
          Authorization: options.Authorization,
          LoanApplicationID: options.LoanApplicationID,
        });
      },

      CoPerson: async (_, changes) => {
        if (changes.SocialSecurityNumber && changes.MarketCountry) {
          const person = await this.createPerson(changes as IProtoPerson, {
            Authorization: options.Authorization,
          });
          return this.updateLoanApplication(
            options.LoanApplicationID,
            {
              CoApplicantPersonID: person.ID,
            },
            { Authorization: options.Authorization }
          );
        } else {
          // If we dont get ssn from changes it could be that they are hidden and are nulled in the surveyCompleting step, so we need to remove CoApplicantPersonId from the application.
          return this.updateLoanApplication(
            options.LoanApplicationID,
            {
              CoApplicantPersonID: null,
            },
            { Authorization: options.Authorization }
          );
        }
      },

      Person: async (_, changes) => updatePerson(changes, options),
      LoanApplication: (_, changes) =>
        this.updateLoanApplication(options.LoanApplicationID, changes, {
          Authorization: options.Authorization,
        }),

      AmountOfChildren: (_, changes) =>
        this.updateAmountOfChildren(parseInt(changes.AmountOfChildren || ''), {
          Authorization: options.Authorization,
          LoanApplicationID: options.LoanApplicationID,
        }),
    });

    return changeHandler(data);
  }

  abstract createCustomer(options: IProtoCustomer): Promise<ICustomer>;

  protected abstract updateCustomer(
    id: string,
    data: IPartialCustomer,
    options: IClientAuthorizationHeaderOption
  ): Promise<ICustomerWithoutJWT>;

  abstract getCustomer(
    id: string,
    headers: IClientAuthorizationHeaderOption
  ): Promise<ICustomerWithoutJWT>;

  abstract getVerifiedJWT(
    customerId: string,
    pin: string
  ): Promise<IAuthorizationToken>;

  abstract createPerson(
    data: Pick<IPerson, 'MarketCountry' | 'SocialSecurityNumber'>,
    headers: IClientAuthorizationHeaderOption
  ): Promise<IPerson>;

  abstract updatePerson(
    id: string,
    data: Pick<IPerson, 'MarketCountry' | 'SocialSecurityNumber'>,
    headers: IClientAuthorizationHeaderOption
  ): Promise<IPerson>;

  abstract getPerson(
    personID: string,
    headers: IClientAuthorizationHeaderOption
  ): Promise<IPerson>;

  abstract createLoanApplication(
    data: IProtoLoanApplication,
    headers: IClientAuthorizationHeaderOption
  ): Promise<ILoanApplication>;

  abstract updateLoanApplication(
    id: string,
    data: IPartialLoanApplication,
    options: IClientAuthorizationHeaderOption
  ): Promise<void>;

  abstract getLoanApplication(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption
  ): Promise<ILoanApplication>;

  abstract getPersistanceValues(
    magicTokenUUID: string
  ): Promise<IPersistanceValues>;

  abstract revokeMarketingConsent(
    customerId: string,
    headers: IClientAuthorizationHeaderOption
  ): Promise<ICustomerWithoutJWT>;

  protected abstract updateDebtObject(
    id: string,
    data: IPartialDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption
  ): Promise<void>;

  protected abstract createDebtObject(
    data: IProtoDebt,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption
  ): Promise<string>;

  protected abstract deleteDebtObject(
    id: string,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption
  ): Promise<string>;

  abstract getAllDebtObjects(
    loanApplicationId: string,
    headers: IClientAuthorizationHeaderOption
  ): Promise<IDebt[]>;

  protected abstract updateAmountOfChildren(
    amount: number,
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption
  ): Promise<void>;

  abstract getChildren(
    options: IClientAuthorizationHeaderOption & IClientApplicationIdOption
  ): Promise<IChild[]>;
}

export class ClientError extends Error {}
