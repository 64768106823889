import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Modal } from '@axo/ui-core/components/Modal';
import { ReactElement } from 'react';
import { InformationContainer } from '../../../../InformationContainer';
import styles from './ConsentModal.module.scss';

export interface IConsentModal {
  consentHeading?: string;
  consentText: string;
  consentButtonText: string;
  cancelButtonText: string;
  onConfirm: (consent: boolean) => void;
  onCancel: () => void;
  isOpen?: boolean;
}

export function ConsentModalUI({
  consentText,
  consentHeading,
  consentButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  isOpen = true,
}: IConsentModal): ReactElement {
  return (
    <Modal open={isOpen}>
      {consentHeading && (
        <Modal.Header feedbackState={'info'}>
          <Modal.Title>{consentHeading}</Modal.Title>
        </Modal.Header>
      )}
      <Stack className={styles.informationContainer}>
        <InformationContainer classes={{ root: styles.informationContainer }}>
          <div
            className={styles.consentText}
            dangerouslySetInnerHTML={{ __html: consentText }}
          />
        </InformationContainer>
      </Stack>

      <Modal.Actions>
        <Button variant="tertiary" onClick={() => onCancel()}>
          {cancelButtonText}
        </Button>
        <Button
          onClick={() => onConfirm(true)}
          suffix={<Icon name={'arrow-right'} />}
        >
          {consentButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
