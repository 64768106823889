/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TrustPilotLogoSVG = () => (
  <svg viewBox="0 0 1133 279">
    <g fill="none">
      <path
        fill="#FFF"
        d="M298 99h114v21h-45v120h-24V120h-45V99zm110 39h21v20l4-9a36 36 0 0116-12l10-2h6l3 1v21a72 72 0 00-10-1c-4 0-8 1-11 3l-9 6-6 11-2 15v49h-23V138h1zm164 102h-23v-14a34 34 0 01-29 17c-14 0-24-3-30-10s-9-17-9-30v-65h23v63c0 9 2 15 5 19 4 3 9 5 15 5 5 0 8 0 12-2 3-1 5-3 7-6 2-2 4-5 5-9l1-11v-59h23v102zm38-33c1 7 3 12 8 14 4 3 9 4 15 4h7l8-2 6-4 2-7c0-3-1-5-3-7l-8-5-10-3-12-2-13-4-10-5c-3-2-6-5-8-9l-2-13c0-5 1-10 4-14l10-9 14-5 15-1 15 2 13 5a32 32 0 0114 25h-23c-1-6-4-10-8-11-3-2-8-3-13-3a42 42 0 00-12 2c-2 0-3 2-5 3l-2 5c0 3 1 5 3 7l8 4c3 2 6 3 10 3l13 3 12 3c4 2 7 3 10 6l8 8c2 4 3 8 3 13 0 6-2 11-5 15-2 5-6 8-10 11l-15 6a74 74 0 01-34-1c-6-1-11-4-15-6a33 33 0 01-13-27h23v-1zm75-69h17v-31h22v31h21v17h-21v54l1 7 1 4 3 3 6 1h5l5-1v17l-7 1a58 58 0 01-22-1c-4-1-7-3-9-5l-4-8-1-12v-60h-17v-17zm76 0h21v14h1c3-6 7-11 13-13a48 48 0 0140 0c5 3 10 7 14 12s7 11 9 17a73 73 0 01-7 59 40 40 0 01-34 16 55 55 0 01-19-4 34 34 0 01-15-12h-1v51h-22V138zm79 51l-2-13-5-12-9-8c-4-2-8-3-13-3-9 0-16 3-21 10-5 6-7 15-7 26 0 5 0 10 2 15l5 11c3 3 6 6 9 7l12 3a25 25 0 0022-11c3-4 4-7 5-12l2-13zm40-90h22v21h-22V99zm0 39h22v102h-22V138zm42-39h23v141h-23V99zm92 144c-8 0-15-1-22-4-6-3-11-6-16-11-4-5-8-11-10-17a66 66 0 010-44c2-6 6-12 10-17 5-5 10-8 16-11 7-3 14-4 22-4 9 0 16 1 22 4 7 3 12 7 17 11l10 17c2 7 3 14 3 22s-1 15-3 22l-10 17a56 56 0 01-38 15zm0-18a26 26 0 0023-11l5-12a53 53 0 000-26l-5-12-10-8c-3-2-8-3-13-3a26 26 0 00-22 11l-5 12a54 54 0 000 26l5 12 9 8c4 2 8 3 13 3zm59-87h17v-31h22v31h21v17h-21v54l1 7 1 4 3 3 6 1h5l5-1v17l-7 1a58 58 0 01-22-1c-4-1-6-3-8-5-3-2-4-5-5-8l-1-12v-60h-17v-17z"
      ></path>
      <path
        fill="#00B67A"
        d="M271 99H168L136 0l-32 99H0l84 61-32 98 84-61 84 61-32-98z"
      ></path>
      <path fill="#005128" d="M195 182l-7-22-52 37z"></path>
    </g>
  </svg>
);
