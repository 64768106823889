/* eslint-disable max-classes-per-file */
import { TextInput } from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  LocalizableString,
  QuestionTextModel,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import { validateNorwegianBankAccountNumber } from './validators/norwegianBankAccountNumber';
import { validateFinnishIBAN } from './validators/validateFinnishIBAN';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionTextModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('tooltip', this, false);
    this.createLocalizableString('placeholder', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get format(): string {
    return this.getPropertyValue('format');
  }

  public set format(val: string) {
    this.setPropertyValue('format', val);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public set upperCaseInput(val: boolean) {
    this.setPropertyValue('upperCaseInput', val);
  }

  public get upperCaseInput(): boolean {
    return this.getPropertyValue('upperCaseInput');
  }

  public set htmlInputType(val: boolean) {
    this.setPropertyValue('htmlInputType', val);
  }

  public get htmlInputType(): boolean {
    return this.getPropertyValue('htmlInputType');
  }
  public set htmlInputMode(val: 'text' | 'numeric') {
    this.setPropertyValue('htmlInputMode', val);
  }
  public get htmlInputMode(): 'text' | 'numeric' {
    return this.getPropertyValue('htmlInputMode');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <TextInput
          {...props}
          inputMode={(this.questionBase as QuestionModel).htmlInputMode}
          format={(this.questionBase as QuestionModel)?.format}
          upperCaseInput={this.props.question.upperCaseInput}
          type={this.props.question.htmlInputType}
        />
      </div>
    );
  }
}

export function registerTextInputQuestion() {
  const questionType = 'Axo Text Input';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'format',
        category: 'general',
        type: 'string',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'upperCaseInput',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'htmlInputType',
        category: 'general',
        type: 'string',
      },
      {
        name: 'htmlInputMode',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'text'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
  FunctionFactory.Instance.register('validateFinnishIBAN', validateFinnishIBAN);
  FunctionFactory.Instance.register(
    'validateNorwegianBankAccountNumber',
    validateNorwegianBankAccountNumber
  );
}
