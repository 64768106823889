/* eslint-disable max-classes-per-file */
import { Icons } from '@axo/deprecated/util/ui-components';
import React, { CSSProperties } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import CheckboxButton from './CheckboxButton';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get checkedValue(): unknown {
    return this.getPropertyValue('checkedValue', true);
  }
  public set checkedValue(val: unknown) {
    this.setPropertyValue('checkedValue', val);
  }

  public get uncheckedValue(): unknown {
    return this.getPropertyValue('uncheckedValue', false);
  }
  public set uncheckedValue(val: unknown) {
    this.setPropertyValue('uncheckedValue', val);
  }

  public get onClickScrollToQuestionWithName(): string {
    return this.getPropertyValue('onClickScrollToQuestionWithName', false);
  }

  public set onClickScrollToQuestionWithName(val: string) {
    this.setPropertyValue('onClickScrollToQuestionWithName', val);
  }

  public get onClickScrollToElementWithId(): string {
    return this.getPropertyValue('onClickScrollToElementWithId', false);
  }

  public set onClickScrollToElementWithId(val: string) {
    this.setPropertyValue('onClickScrollToElementWithId', val);
  }
}

type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderElement(): JSX.Element {
    const { style, label, ...props } = getBasicQuestionProps(
      this,
      this.questionBase
    );

    const handleScrollOnClick = () => {
      if (this.question.onClickScrollToQuestionWithName) {
        const question = this.question.survey.getQuestionByName(
          this.question.onClickScrollToQuestionWithName
        ) as QuestionModel;

        setTimeout(
          () =>
            question?.react?.rootRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start',
            }),
          50
        );
      } else if (this.question.onClickScrollToElementWithId) {
        const el = document.getElementById(
          this.question.onClickScrollToElementWithId
        );
        if (!el)
          // throw new Error(
          console.error(
            `could not find element with Id: ${this.question.onClickScrollToElementWithId}`
          );

        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        this.question.value = this.question.checkedValue;
      } else {
        this.question.value = this.question.uncheckedValue;
      }
      handleScrollOnClick();
    };
    const locale = this.question.survey.getLocale();

    return (
      <div style={style}>
        <CheckboxButton
          {...props}
          onChange={onChange}
          icon={locale === 'no' && <Icons.ArrowRight size="xs" />}
          fontVariant={locale === 'no' ? 'secondary' : 'primary'}
        >
          {label}
        </CheckboxButton>
      </div>
    );
  }
}

export function registerCheckboxButtonQuestion() {
  const questionType = 'Axo Checkbox Button';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'valueLabel',
        category: 'general',
        type: 'text',
        isLocalizable: true,
      },
      {
        name: 'onClickScrollToQuestionWithName',
        category: 'general',
        type: 'text',
      },
      {
        name: 'onClickScrollToElementWithId',
        category: 'general',
        type: 'text',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'checkbox'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
