import { z } from 'zod';
import { emitEvent } from '../core/emitEvent';
import { newListener } from '../core/newListener';

const NAME = 'axo.form.ready';

const schemas = {
  v1: z.undefined().nullable(),
};

export const emitFormReady = () => {
  emitEvent(`${NAME}/v1`, schemas.v1, undefined);
};

export const listenFormReady = newListener(`${NAME}/v1`, schemas.v1);
