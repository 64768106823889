import { storeCrossDomainAuth } from '@axo/shared/auth/crossdomain/store/store-cross-domain-auth';
import { Product } from '@axo/shared/data-access/types';
import { isServer } from '@axo/shared/util/dom';

export const APPLICATION_ID_KEY = 'axo.application-id';
export const CUSTOMER_JWT_KEY = 'axo.token';
export const SHOULD_RETRIEVE_MARKETING_CONSENT =
  'axo.should-retrieve-marketing-consent';

export const reloadData = {
  reset: () => {
    if (isServer()) return;
    sessionStorage.removeItem(APPLICATION_ID_KEY);
    sessionStorage.removeItem(CUSTOMER_JWT_KEY);
    sessionStorage.removeItem(SHOULD_RETRIEVE_MARKETING_CONSENT);

    for (const product of Object.keys(Product)) {
      sessionStorage.removeItem(`${APPLICATION_ID_KEY}.${product}`);
      sessionStorage.removeItem(`${CUSTOMER_JWT_KEY}.${product}`);
      sessionStorage.removeItem(
        `${SHOULD_RETRIEVE_MARKETING_CONSENT}.${product}`
      );
    }
  },
  set({
    product,
    applicationId,
    customerJWT,
    shouldRetrieveMarketingConsent,
  }: {
    product?: Product;
    applicationId?: string;
    customerJWT?: string;
    shouldRetrieveMarketingConsent?: string;
  }) {
    if (isServer()) return;

    if (applicationId) {
      product === Product.CreditCard
        ? sessionStorage.setItem(
            `${APPLICATION_ID_KEY}.${product}`,
            applicationId
          )
        : sessionStorage.setItem(APPLICATION_ID_KEY, applicationId);
    }

    if (customerJWT) {
      sessionStorage.setItem(CUSTOMER_JWT_KEY, customerJWT);
    }

    if (shouldRetrieveMarketingConsent) {
      sessionStorage.setItem(
        SHOULD_RETRIEVE_MARKETING_CONSENT,
        shouldRetrieveMarketingConsent
      );
    }

    storeCrossDomainAuth({
      applicationId,
      token: customerJWT,
    });
  },
  get(product?: Product) {
    if (isServer()) return {};

    return {
      applicationId:
        product === Product.CreditCard
          ? sessionStorage.getItem(`${APPLICATION_ID_KEY}.${product}`)
          : sessionStorage.getItem(APPLICATION_ID_KEY),
      customerJWT: sessionStorage.getItem(CUSTOMER_JWT_KEY),
      // not casted to boolean as we also need the null value to be returned
      shouldRetrieveMarketingConsent: sessionStorage.getItem(
        SHOULD_RETRIEVE_MARKETING_CONSENT
      ),
    };
  },
};
