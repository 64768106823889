import countries from 'i18n-iso-countries';
import fi from 'i18n-iso-countries/langs/fi.json';
import no from 'i18n-iso-countries/langs/no.json';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(fi);

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(no);

// from task LEGACY-5268
const excludeCountries = ['AX'];

type CountryOption = {
  text: string;
  value: string;
};

export type IGetCountryOptions = 'FI' | 'NO';

export const getCountryOptions = (
  locale: IGetCountryOptions
): CountryOption[] => {
  const countryCodes = countries.getAlpha2Codes();
  return Object.keys(countryCodes)
    .filter((code) => !excludeCountries.includes(code))
    .map((code) => ({
      text: countries.getName(code, locale.toLowerCase()) || '',
      value: code,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
};
