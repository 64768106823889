import { HttpError } from '@axo/form/feature/axo-form-v1';
import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const sentryConfig: BrowserOptions = {
  enabled: import.meta.env.VITE_ENV !== 'development',

  dsn: 'https://4abb57cae34f4de1a6eecd94aaf0ebba@o1128170.ingest.sentry.io/6478819',
  environment: import.meta.env.VITE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        // I could not figure out why the types don't match up here, but it
        // seems like a minor thing. Feel free to remove "as any" in the future.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        matchRoutes as any
      ),
    }),
  ],
  tracesSampleRate: 0.8,
  sampleRate: 1,

  beforeSend(event, hint) {
    const error = hint.originalException;

    if (!event.tags) event.tags = {};
    if (error instanceof HttpError) {
      event.tags['axoform.request.url'] = error.url;
      event.tags['axoform.request.url.service'] = getUrlService(error.url);
      event.tags['axoform.request.status'] = error.statusCode;
    }

    const scriptInfo = getScriptInfo(event);
    event.tags['script.filename'] = scriptInfo.filename;
    event.tags['script.host'] = scriptInfo.host;

    return event;
  },
};

const urlServiceMatcher = /^https?:\/\/.*?\/(.*?)\/.*$/;
const getUrlService = (url: string): string => {
  return urlServiceMatcher.exec(url)?.[1] ?? '';
};

const getScriptInfo = (event: Sentry.Event) => {
  const stacktrace = event.exception?.values?.[0]?.stacktrace?.frames ?? [];
  const lastStackTrace = stacktrace[stacktrace.length - 1];
  let url: URL | undefined;

  try {
    url = new URL(lastStackTrace.filename?.replace(/^async /, '') ?? '');
  } catch (e) {
    console.error('While parsing stack trace for Sentry: ', e);
  }

  return {
    filename: lastStackTrace?.filename,
    host: url?.host,
  };
};
