import { Span } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import { Icon } from '@axo/ui-core/components/Icon';
import { ReactElement, useState } from 'react';
import { IDebt } from '../../../../hooks/useAssentlyClient';
import { getDebtDetails } from '../utils';
import styles from './LoanUI.module.scss';

export type DebtEntriesTexts = {
  totalDebt: string;
  originalBalance: string;
  remainingBalance: string;
  interestBearingDebt: string;
  nonInterestBearingDebt: string;
  nominalInterest: string;
  creditType: string;
  creditLimit: string;
  installmentCharges: string;
  installmentChargePeriod: string;
  processedTime: string;
  receivedTime: string;
  coBorrower: string;
};

export type Loan = {
  labels: DebtEntriesTexts;
  debt: IDebt;
};

export function LoanUI(props: Loan): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { headerDetails, expandedDetails } = getDebtDetails(props);

  return (
    <section className={styles.loanHolder}>
      <button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className={styles.header}
      >
        <div className={styles.row}></div>
        <div className={styles.issuerHolder}>
          <Span>{props.debt.financialInstitutionName}</Span>
        </div>
      </button>

      <div className={classNames(styles.mainContent)}>
        <div className={styles.detailsContainer}>
          {Object.keys(headerDetails).map((key) => (
            <div className={styles.column} key={key}>
              <div className={styles.columnHeader}>{key}</div>
              <div className={styles.amount}>{headerDetails[key]}</div>
            </div>
          ))}
        </div>

        <div className={styles.buttonContainer}>
          <span className={styles.separator} />
          <button
            type="button"
            className={styles.detailsButton}
            onClick={() => setExpanded(!expanded)}
          >
            Se detaljer{' '}
            <Icon name={!expanded ? 'chevron-down' : 'chevron-up'} size={'s'} />
          </button>
        </div>
      </div>

      {expanded && (
        <div className={styles.mainContent}>
          <table className={styles.table}>
            <tbody>
              {Object.keys(expandedDetails).map((key) => (
                <tr key={key}>
                  <td className={styles.tableTitle}>{key}</td>
                  <td className={styles.tableValue}>{expandedDetails[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}
