/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const formatDate = (date: Date | undefined, format: string) =>
  format
    .replaceAll('[yyyy]', formatNumber(date?.getFullYear()))
    .replaceAll(
      '[MM]',
      formatNumber(date ? date.getMonth() + 1 : undefined, { minLength: 2 })
    )
    .replaceAll('[dd]', formatNumber(date?.getDate(), { minLength: 2 }))
    .replaceAll('[hh]', formatNumber(date?.getHours(), { minLength: 2 }))
    .replaceAll('[mm]', formatNumber(date?.getMinutes(), { minLength: 2 }));

interface IFormatNumberOptions {
  minLength: number;
}
const formatNumber = (
  value: number | undefined,
  { minLength }: IFormatNumberOptions = { minLength: 0 }
) => {
  if (value === undefined) return '';

  let out = value.toString();

  while (out.length < minLength) {
    out = '0' + out;
  }

  return out;
};
