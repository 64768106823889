import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import { IPreprSystemFields } from '../../../models/prepr-system-fields.model';
import styles from './Text.module.scss';
import classNames from '../../../utils/class-names';

type HeadingTypes = 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
export interface IText extends IPreprSystemFields {
  _id: string;
  body: string;
  format?: HeadingTypes | 'HTML' | 'Code' | null;
  classes?: ClassNamesProp;
}

type ClassNamesProp = {
  root?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Text: FC<IText> = ({ _id, body, classes }) => {
  return (
    <div
      key={_id}
      className={classNames(styles.text, classes?.root)}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }}
    ></div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Text;
