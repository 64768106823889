import { SavingsExample } from '@axo/deprecated/util/ui-components';
import { Formatters } from '@axo/shared/util/string';
import { createElement } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { calculateMonthlyPaymentNO } from '../../../utils/CalculateMonthlyPayment';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    const appliedAmount = this.question.survey.getQuestionByName(
      'LoanApplication/AppliedAmount'
    ).value;

    const loanDuration = this.question.survey.getQuestionByName(
      'LoanApplication/LoanDuration'
    ).value;

    const currentCost = calculateMonthlyPaymentNO([appliedAmount, 10, 24.0]);
    const newCost = calculateMonthlyPaymentNO([
      appliedAmount,
      loanDuration,
      11.9,
    ]);
    const monthlySavings = currentCost - newCost;
    const totalSaved = monthlySavings * 12 * loanDuration;

    const getFormattedSavedAmount = (value: number): string => {
      return `${Formatters.formatNumber(value, ' ')} kr`;
    };


    return (
      <div style={style}>
        {monthlySavings > 0 &&
          totalSaved > 0 &&
          <SavingsExample
          title="Ved å refinansiere sparer våre kunder i gjennomsnitt: "
          disclaimer="Besparelsen er basert på den gjennomsnittlige kredittkortrenten i Norge
            og gjennomsnittlig ny rente kunder blir tilbudt hos oss."
          savingCards={[
            {
              key: 'saveAmount',
              title: 'per måned',
              value: getFormattedSavedAmount(monthlySavings),
            },
            {
              key: 'totalSaved',
              title: 'totalt',
              value: getFormattedSavedAmount(totalSaved),
            },
          ]}
        />}
      </div>
    );
  }
}

export function registerSavingsExampleNOQuestion() {
  const questionType = 'Savings Example NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
