/* eslint-disable @nx/enforce-module-boundaries */
import { useAnalytics } from '@axo/shared/services/analytics';
import { Button } from '@axo/ui-core/components/Button';
import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { IAuthProviderResponse } from '../../../IAuthProviderResponse.types';
import { IAuthProviderUiProps } from '../../../IAuthProviderUiProps.types';
import { TProviders } from '../../../IAuthProviders.types';
import { useUniifyProvider } from '../services/uniify';
import styles from './ESkatLogin.module.scss';
import ESkatLogo from './eskat-logo.svg?react';

type IESkatLoginProps = IAuthProviderUiProps & {
  children: ReactNode;
  applicationID: string | null | undefined;
  token: string | null | undefined;
  hasIcon?: boolean;
};

export const ESkatLogin = ({
  children,
  applicationID,
  token,
  axoApiUrl,
  hasIcon,
  onError,
  onSuccess,
}: IESkatLoginProps): ReactElement => {
  const [loading, setLoading] = useState(false);

  const { track } = useAnalytics();

  const _onSuccess = useCallback(
    (data: IAuthProviderResponse) => {
      onSuccess(data);
      setLoading(false);
      track({
        event: 'Auth Provider Authenticated',
        params: { provider: TProviders.ESkat },
      });
    },
    [onSuccess, track]
  );

  const _onError = useCallback(
    (data: IAuthProviderResponse) => {
      onError(data);
      setLoading(false);
      track({
        event: 'Auth Provider Failed',
        params: { provider: TProviders.ESkat },
      });
    },
    [onError, track]
  );

  const { authenticate } = useUniifyProvider({
    applicationID,
    token,
    onSuccess: _onSuccess,
    onError: _onError,
    axoApiUrl,
  });

  const handleLogin = useCallback(() => {
    track({
      event: 'Auth Provider Initiated',
      params: { provider: TProviders.ESkat },
    });
    setLoading(true);
    authenticate();
  }, [authenticate, track]);

  return (
    <Button
      className={styles.eskatLogin}
      onClick={handleLogin}
      loading={loading}
      disabled={loading || !applicationID || !token}
      aria-disabled={loading || !applicationID || !token}
      prefix={hasIcon ? <ESkatLogo /> : undefined}
      data-testid="eskat-login-button"
    >
      {children}
    </Button>
  );
};
