import { ILocalizationConfig } from './type';
import { no } from './locales/no';
import { en } from './locales/en';
import { fi } from './locales/fi';

export default (locale: string): ILocalizationConfig => {
  switch (locale.toLowerCase()) {
    case 'no':
      return no;
    case 'fi':
      return fi;
    case 'en':
    default:
      return en;
  }
};
