import { FC } from 'react';
import { Modal, P, Ul } from '../../';
import styles from './PopUpConsent.module.scss';
import { TermsIcon } from './TermsIcon';
import { PartnersIcon } from './PartnersIcon';

export interface IPopupConsent {
  showIcon?: boolean;
  title: string;
  mainText?: string;
  partnersList?: string[];
  buttonText: string;
  onClick: () => void;
  onBackgroundClick?: () => void;
  type: 'partners' | 'services';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const PopupConsent: FC<IPopupConsent> = ({
  showIcon = true,
  title,
  mainText,
  partnersList,
  buttonText,
  type,
  onClick,
  onBackgroundClick,
}) => {
  const icon = (() => {
    if (!showIcon) return;
    if (type === 'services') return <TermsIcon />;
    return <PartnersIcon />;
  })();

  return (
    <Modal
      topBorderColor="--primary-500"
      onBackgroundClick={onBackgroundClick}
      icon={icon}
      title={title}
      buttons={[{ children: buttonText, variant: 'edit', onClick: onClick }]}
    >
      <div className={styles.content}>
        {mainText && <P className={styles.mainText}>{mainText}</P>}

        {partnersList && (
          <Ul className={styles.partnersList}>
            {partnersList.map((element, index) => (
              <li key={index}>{element}</li>
            ))}
          </Ul>
        )}
      </div>
    </Modal>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default PopupConsent;
