import { useAnalytics } from '@axo/shared/services/analytics';

export function useTracking() {
  const { track } = useAnalytics();

  async function onDebtBalanceCompleted({
    loanDebtBalance,
    creditCardDebtBalance,
  }: {
    loanDebtBalance: number;
    creditCardDebtBalance: number;
  }) {
    if (!loanDebtBalance && !creditCardDebtBalance) return;

    const totalDebtBalance = loanDebtBalance + creditCardDebtBalance;

    await track({
      event: 'Debt Manual Balance Completed',
      params: {
        loanDebtBalance,
        creditCardDebtBalance,
        totalDebtBalance,
      },
    });
  }

  async function onSurveyCompleted(data: Record<string, unknown>) {
    await track({
      event: 'Form Completed',
      params: data,
      options: {
        send_immediately: true,
      },
    });
  }

  async function onStepViewed(step: number, data: Record<string, unknown>) {
    await track({
      event: `Form Step ${step} Viewed`,
      params: data,
    });
  }

  async function onStepInitiated(step: number, data: Record<string, unknown>) {
    await track({
      event: `Form Step ${step} Initiated`,
      params: data,
    });
  }

  async function onStepComplete(step: number, data: Record<string, unknown>) {
    await track({
      event: `Form Step ${step} Completed`,
      params: data,
      options: {
        send_immediately: true,
      },
    });
  }

  return {
    onStepViewed,
    onStepInitiated,
    onStepComplete,
    onSurveyCompleted,
    onDebtBalanceCompleted,
  };
}
