import { tracking, ga } from '@axo/form/data-access/tracking';
import { SurveyModel, PageModel } from 'survey-core';

export const onPageLoad = (
  survey: SurveyModel,
  { page }: { page: PageModel; htmlElement: HTMLElement }
) => {
  ga.sendGAPageLoadEvent({
    name: page.name,
    number: page.num,
  });

  tracking.setTag('axoform.step.name', page.name);
  tracking.setTag('axoform.step', page.num);
};
