import { getFormState } from '../../store';

export const resolveWhenNoUnsyncedChanges = () =>
  new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      const { hasUnsyncedChanges, loadingState } = getFormState();
      const shouldResolve = !(hasUnsyncedChanges && loadingState === 'pending');

      if (shouldResolve) {
        resolve();
        clearInterval(interval);
      }
    }, 100);
  });
