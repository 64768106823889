/**
 * Checks if two arrays are equal.
 * @param arr1 The first array.
 * @param arr2 Array to compare to.
 * @returns True if the arrays are equal, false otherwise.
 */
export const isArraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr2.every((element) => arr1.includes(element));
};
