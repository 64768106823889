/* eslint-disable max-classes-per-file */
import React from 'react';
import { ReactQuestionFactory, SurveyQuestionDropdown } from 'survey-react-ui';

import {
  ElementFactory,
  QuestionDropdownModel,
  Serializer,
  LocalizableString,
} from 'survey-core';
import { DropdownNO } from '@axo/deprecated/util/ui-components';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';
import { Formatters } from '@axo/shared/util/string';

export class QuestionModel extends QuestionDropdownModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('placeholder', this, false);
    this.createLocalizableString('tooltip', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get placeholder(): string {
    return this.getLocalizableStringText('placeholder');
  }

  public set placeholder(val: string) {
    this.setLocalizableStringText('placeholder', val);
  }

  get locPlaceholder(): LocalizableString {
    return this.getLocalizableString('placeholder');
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
}

export class SurveyQuestion extends SurveyQuestionDropdown {
  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);
    const maxLoanAmount = 800000;
    const startLoanAmount = 10000;
    const increment = 5000;
    const defaultValue = 100000;

    // set initial value to defaultvalue
    if (!props.value) {
      this.question.value = defaultValue;
    }

    const generateChoices = () => {
      const choices = [];
      for (
        let value = startLoanAmount;
        value <= maxLoanAmount;
        value += increment
      ) {
        choices.push({
          text: `${Formatters.formatNumber(value, ' ')} kr`,
          value: value,
        });
      }

      // Add option with value from question if it doesn't exist.
      // This is useful if data is set from an external source (ACE) that
      // doesn't now about the specific values in the dropdown list.
      if (!choices.find((choice) => choice.value === this.question.value)) {
        choices.push({
          text: `${Formatters.formatNumber(this.question.value, ' ')} kr`,
          value: this.question.value,
        });
      }

      return choices;
    };

    return (
      <div style={style}>
        <DropdownNO
          {...props}
          iconBackgroundColor={true}
          options={generateChoices()}
          onChange={(event) => {
            this.question.value = Number(event.target.value);
          }}
        />
      </div>
    );
  }
}

export function registerLoanAmountDropdownNOQuestion() {
  const questionType = 'Axo Loan Amount Dropdown NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'dropdown'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (questionType: string) => new QuestionModel(questionType, questionType)
  );
}
