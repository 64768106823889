import {
  Button,
  Icons,
  Modal,
  P,
  Span,
} from '@axo/deprecated/util/ui-components';
import { IDebt } from '../../../hooks/useAssentlyClient';
import { DebtEntriesTexts, Loan } from './Loan';
import styles from './LoansModal.module.scss';
import { formatCurrency } from './utils';

export interface ILoansModal {
  loansModalTexts: {
    title: string;
    text: string;
    confirmText: string;
  };

  loansModalDebtEntriesTexts: DebtEntriesTexts;
  totalDebt: number;
  debts: IDebt[];
  onButtonClick: () => void;
  onCloseButtonClick: () => void;
}

export function LoansModal({
  loansModalTexts: modalTexts,
  loansModalDebtEntriesTexts: debtEntriesTexts,
  totalDebt,
  debts,
  onButtonClick,
  onCloseButtonClick,
}: ILoansModal) {
  return (
    <Modal
      onCloseButton={onCloseButtonClick}
      icon={<Icons.AxoFinans size="lg" />}
      topBorderColor="--primary-500"
      classes={{ root: styles.informationDebtModal, box: styles.box }}
    >
      <Span className={styles.header}>{modalTexts.title}</Span>
      <P className={styles.text}>{modalTexts.text}</P>

      <div className={styles.currentDebt}>
        <Span>{debtEntriesTexts.totalDebt}</Span>
        <Span className={styles.debtAmount}>{formatCurrency(totalDebt)}</Span>
      </div>

      <div className={styles.loansWrapper}>
        {debts.map((debt, i) => (
          <Loan key={'loans_' + i} labels={debtEntriesTexts} debt={debt} />
        ))}
      </div>
      <Button variant="primary" onClick={onButtonClick}>
        {modalTexts.confirmText}
      </Button>
    </Modal>
  );
}
