import { useEffect } from 'react';
import { useState } from 'react';
import {
  dispatch,
  initialState,
  subscribe,
  unsubscribe,
  IFormState,
} from '../store';
import { Observer } from '../utils/Subject';

const useFormState = () => {
  const [state, setState] = useState<IFormState>(initialState);

  useEffect(() => {
    const observer: Observer<IFormState> = (newState, previousState) => {
      if (JSON.stringify(newState) !== JSON.stringify(previousState)) {
        setState(newState);
      }
    };
    subscribe(observer);

    return () => unsubscribe(observer);
  }, []);

  return [state, dispatch] as const;
};

export default useFormState;
