import { IMarketingConsentMarkets } from './IMarketingConsentMarkets';

export const getMarketingConsentText = (country: IMarketingConsentMarkets) => {
  switch (country) {
    case 'no':
      return 'Ja takk, jeg vil gjerne motta markedsføring vedrørende finansielle produkter og andre tjenesteytelser vist her fra Axo Finans AS og våre samarbeidspartnere via e-post og SMS. Samtykket kan når som helst tilbakekalles ved henvendelse til Axo Finans AS.';
    case 'se':
      return 'Jag vill bli medlem i Axo Finans AB Kundklubb för att ta del av exklusiva erbjudanden, förmåner och nyhetsbrev via SMS och e-post.';
    case 'fi':
      return 'Kyllä, haluan saada Axo Finance Oy:lta ja kumppaneiltamme rahoitustuotteita ja muita palveluita koskevia tarjouksia ja tietoja kampanjoista sähköpostitse, tekstiviestillä ja puhelimitse. Voin koska tahansa peruuttaa suostumukseni ottamalla yhteyttä Axo Finance Oy:hyn.';
    case 'dk':
      return 'Ja tak, jeg vil gerne modtage markedsføring vedrørende finansielle produkter og andre tjenesteydelser fra LendMe ApS og vores samarbejdspartnere via e-mail, sms og telefonkald. Samtykket kan til enhver tid tilbagekaldes ved henvendelse til LendMe ApS.';
    default:
      return '';
  }
};
