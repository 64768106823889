import { addBreadcrumb as sentryAddBreadcrumb } from '@sentry/react';
import { scrubObjectProperties } from './scrubObjectProperties';

interface IBreadcrumb {
  message: string;
  data: object;
}
export const addStepCompleteBreadcrumb = (breadcrumb: IBreadcrumb) =>
  sentryAddBreadcrumb({
    category: 'step-complete',
    message: breadcrumb.message,
    timestamp: Date.now(),
    data: scrubObjectProperties(breadcrumb.data),
  });
