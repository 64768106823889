/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import CardDetails from '../../../../components/CardDetails/CardDetails';
import LDWrapper from '../../../../components/LDWrapper/LDWrapper';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('description', this, false);
    this.createLocalizableString('title', this, false);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get imageSrc(): string {
    return this.getPropertyValue('imageSrc');
  }

  public set imageSrc(val: string) {
    this.setPropertyValue('imageSrc', val);
  }

  public get imageAlt(): string {
    return this.getPropertyValue('imageAlt');
  }

  public set imageAlt(val: string) {
    this.setPropertyValue('imageAlt', val);
  }

  public get title(): string {
    return this.getLocalizableStringText('title');
  }

  public set title(val: string) {
    this.setLocalizableStringText('title', val);
  }

  get locTitle(): LocalizableString {
    return this.getLocalizableString('title');
  }

  public get _description(): string {
    return this.getPropertyValue('description');
  }

  public set _description(val: string) {
    this.setPropertyValue('description', val);
  }

  get locDescription(): LocalizableString {
    return this.getLocalizableString('description');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <LDWrapper featureFlagKey="enableStep5CardOnTopOfForm">
        <div style={style}>
          <CardDetails
            title={this.question.title}
            description={this.question._description}
            {...props}
          />
        </div>
      </LDWrapper>
    );
  }
}

export function registerCardDetailsQuestion() {
  const questionType = 'Axo Card Details';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      { name: 'imageSrc', category: 'general', type: 'string' },
      { name: 'imageAlt', category: 'general', type: 'string' },
      {
        name: 'title',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTitle',
      },
      {
        name: 'description',
        category: 'general',
        type: 'string',
        serializationProperty: 'locDescription',
      },
    ],
    () => new QuestionModel(questionType, '')
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
