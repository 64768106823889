/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TermsIcon = () => {
  return (
    <svg viewBox="0 0 363 363">
      <circle cx="181.4" cy="181.6" r="180.8" fill="#FFE187" />
      <g clipPath="url(#a)">
        <path
          fill="#ba8b00"
          d="M136.2 208.7h63.3v18h-63.3v-18Zm0-36.1h90.4v18h-90.4v-18Zm0-36.2h90.4v18h-90.4v-18Zm108.5-36.2H207a27.2 27.2 0 0 0-25.5-18 27.2 27.2 0 0 0-25.5 18h-37.7a18.1 18.1 0 0 0-18 18V245a18.5 18.5 0 0 0 5.2 12.8c2.5 2.4 5.6 4.3 9.1 5 1.2.2 2.4.3 3.7.3h126.5c10 0 18-8.2 18-18.1V118.3c0-10-8-18-18-18ZM181.4 98c3.7 0 6.8 3 6.8 6.8 0 3.7-3 6.7-6.8 6.7-3.7 0-6.7-3-6.7-6.7 0-3.8 3-6.8 6.7-6.8Zm63.3 146.9H118.2V118.3h126.5V245Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            d="M0 0h217v217H0z"
            transform="translate(73 73.1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
