import { loan_application } from '@axo/shared/data-access/types';
import { send } from '../../lib/send';
import { IAPI } from '../../lib/models/api';

export function getAllChildren({ url: { service: baseURL }, token }: IAPI) {
  return (loanApplicationId: string): Promise<loan_application.Child[]> =>
    send({
      baseURL,
      token,
      path: `/loan-application/${loanApplicationId}/child`,
      method: 'GET',
    });
}

export function postChild({ url: { service: baseURL }, token }: IAPI) {
  return (
    loanApplicationId: string,
    child: loan_application.CreateChild
  ): Promise<loan_application.Child> =>
    send({
      baseURL,
      token,
      path: `/loan-application/${loanApplicationId}/child`,
      method: 'POST',
      body: child,
    });
}

export function patchChild({ url: { service: baseURL }, token }: IAPI) {
  return (
    loanApplicationId: string,
    childId: string,
    child: loan_application.UpdateChild
  ): Promise<loan_application.Child> =>
    send({
      baseURL,
      token,
      path: `/loan-application/${loanApplicationId}/child`,
      method: 'PATCH',
      body: { ...child, ID: childId },
    });
}

export function deleteChild({ url: { service: baseURL }, token }: IAPI) {
  return (loanApplicationId: string, childId: string): Promise<string> =>
    send({
      baseURL,
      token,
      path: `/loan-application/${loanApplicationId}/child`,
      method: 'DELETE',
      body: { ID: childId },
    });
}
