import { useMemo } from 'react';
import { useInputState } from '../../../hooks/useInputState';
import { CountryCode, IWrappedSoftValidatedInput } from '../../../models';
import { Formatter, IFormatterOptions } from '../../../utils/Formatter';
import { useTransformInputValue } from '../../../hooks/useTransformValue';
import InputWrapper from '../InputWrapper';
import InputStateBox from '../InputStateBox';
import FormattedInput from '../../atoms/FormattedInput';

export interface ISSNInput
  extends IWrappedSoftValidatedInput<HTMLInputElement, string> {
  i18n: CountryCode;
}

export interface ISSNConfig {
  pattern: string;
  formatOptions: IFormatterOptions;
  censored?: boolean;
}

const censoredCharacter = '*';
/**
 * TODO: We must check that no event emits the censored value.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const SSNInput = ({ i18n, ...props }: ISSNInput) => {
  const inputState = useInputState(props);

  const config = useMemo(() => getConfigFromCountryCode(i18n), [i18n]);

  const transformedInputState = useTransformInputValue((v, s) => {
    if (!config.censored) return v;
    return s.axoProps.hasFocus ? v : censoredCharacter.repeat(v?.length || 0);
  }, inputState);

  const formatter = useMemo(() => {
    return new Formatter(config.pattern, config.formatOptions);
  }, [config]);

  return (
    <InputWrapper inputState={transformedInputState}>
      <InputStateBox inputState={transformedInputState}>
        <FormattedInput
          inputState={transformedInputState}
          getFormatter={() => formatter}
        />
      </InputStateBox>
    </InputWrapper>
  );
};

const getConfigFromCountryCode = (countryCode: CountryCode): ISSNConfig => {
  switch (countryCode) {
    case 'NO':
      return {
        censored: false,
        pattern: '*'.repeat(11),
        formatOptions: { remove: `[^0-9${censoredCharacter}]` },
      };
    case 'DK':
      return {
        censored: true,
        pattern: '*'.repeat(13),
        formatOptions: { remove: `[^0-9${censoredCharacter}]` },
      };
    case 'SE':
      return {
        censored: true,
        pattern: '*'.repeat(10),
        formatOptions: { remove: `[^0-9${censoredCharacter}]` },
      };
    case 'FI':
      return {
        censored: true,
        pattern: '*'.repeat(11),
        formatOptions: { remove: '' },
      };
  }
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default SSNInput;
