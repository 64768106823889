export enum AxoUrlQueryParams {
  AXO_FORM_STEP = 'step',
  AXO_BYPASS_NATIONAL_ID = 'bypassNationalId',
  // for retrieving the loanApplicationID of a returning customer
  AXO_MAGIC_TOKEN = 'token',
  PROVIDER = 'provider',
}

/**
 *  updates query parameter and pushes the new url to the history object
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const updateUrlQueryParam = (
  value: number,
  paramToChange?: AxoUrlQueryParams
): void => {
  if (typeof window !== 'undefined') {
    const param = paramToChange
      ? paramToChange
      : AxoUrlQueryParams.AXO_FORM_STEP;
    const url = new URL(window.location.href);
    url.searchParams.set(param, value.toString());
    window.history.pushState({}, '', url.href);
  }
};

/**
 *  retrieve an Axo query param from the url
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const getUrlQueryParam = (
  paramToGet: AxoUrlQueryParams
): string | undefined => {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const param = url.searchParams.get(paramToGet);
    return param || undefined;
  }
  return undefined;
};
