import { ToChangesWithPrefix } from '../utils/ToChangesWithPrefix';
import { IPartialCustomer } from './ICustomer';
import { IPartialDebt } from './IDebt';
import { IPartialLoanApplication } from './ILoanApplication';
import { IPartialPerson } from './IPerson';
import { isObject } from './validation';

export type IDebtChanges = ToChangesWithPrefix<IPartialDebt, 'Debt'>;
export type ICoDebtChanges = ToChangesWithPrefix<IPartialDebt, 'CoDebt'>;
export type ILoanApplicationChanges = ToChangesWithPrefix<
  IPartialLoanApplication,
  'LoanApplication'
>;
export type ICustomerChanges = ToChangesWithPrefix<
  IPartialCustomer,
  'Customer'
>;

export type IPersonChanges = ToChangesWithPrefix<IPartialPerson, 'Person'>;
export type ICoPersonChanges = ToChangesWithPrefix<IPartialPerson, 'CoPerson'>;
export type IAmountOfChildren = { AmountOfChildren?: number };

export type IChanges = IDebtChanges &
  ICoDebtChanges &
  ILoanApplicationChanges &
  ICustomerChanges &
  IPersonChanges &
  IAmountOfChildren;

export const VALID_KEYS = Object.freeze([
  'LoanApplication',
  'Debt',
  'CoDebt',
  'Customer',
  'CoCustomer',
  'CoPerson',
  'AmountOfChildren',
  'Ignore',
  'Person',
]);

export const isChanges = (input: unknown): input is IChanges => {
  if (!isObject(input)) return false;

  const allKeysAreValid = Object.keys(input).reduce((sum, next) => {
    const prefix = next.split('/')[0];
    return sum && VALID_KEYS.includes(prefix);
  }, true);

  if (!allKeysAreValid) return false;

  return true;
};
