import { FormFlagSet } from '@axo/form/configs';
import { getActiveExperiments } from '@axo/shared/services/feature-flags/getActiveExperiments';
import { cookieWithConverter } from '@axo/shared/util/cookie';
import { Subject } from '@axo/shared/util/reactivity';
import { addStepCompleteBreadcrumb } from '../monitoring/addStepCompleteBreadcrumb';
import { Dependencies } from '../types/Dependencies';
import { IStep } from '../types/Step';
import { ICookieData, createChannelSnapshot } from './createChannelSnapshot';
import { initFactory } from './init';
import { State } from './state';

export const completeStepFactory =
  ({ loan_application_api, state }: Dependencies & { state: Subject<State> }) =>
  async ({
    step,
    data,
    loanApplicationId,
    token,
    baseUrl,
  }: {
    step: IStep;
    data: object;
    loanApplicationId: string;
    token: string;
    baseUrl: string;
  }): Promise<unknown> => {
    if (!state.value.initialized) {
      await initFactory({ loan_application_api, state })({
        loanApplicationId,
        token,
        baseUrl,
      });
    }
    if (state.value.completedSteps.some((v) => v.name === step.name))
      return Promise.resolve();

    state.update({
      ...state.value,
      completedSteps: [...state.value.completedSteps, step],
    });

    const cookieValue = cookieWithConverter.get('axotc') as ICookieData;

    const channelSnapshot = createChannelSnapshot({
      searchParams: new URL(window.document.location.href).searchParams,
      completedSteps: state.value.completedSteps,
      cookieData: cookieValue || {},
      domain: window.location.hostname,
      experiments: getActiveExperiments(FormFlagSet),
    });

    addStepCompleteBreadcrumb({
      message: `${step.name} completed`,
      data: data,
    });

    const promises = [
      loan_application_api.postChannelSnapshot(
        baseUrl,
        token,
        loanApplicationId,
        channelSnapshot
      ),
      loan_application_api.patchStepComplete(
        baseUrl,
        token,
        loanApplicationId,
        {
          Step: step.name,
        }
      ),
    ];

    return Promise.all(promises);
  };
