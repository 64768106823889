import { IThousandsSeparator } from '@axo/shared/util/string';
/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import Stat from './Stat';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions
class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('caption', this, true);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  public get caption(): string {
    return this.getLocalizableStringText('caption');
  }
  public set caption(val: string) {
    this.setLocalizableStringText('caption', val);
  }
  get locCaption(): LocalizableString {
    return this.getLocalizableString('caption');
  }

  get valuePrefix(): string {
    return this.getPropertyValue('valuePrefix', '');
  }

  set valuePrefix(_valuePrefix: string) {
    this.setPropertyValue('valuePrefix', _valuePrefix);
  }

  get valueExpression(): string {
    return this.getPropertyValue('valueExpression', null);
  }

  set valueExpression(valueExpression: string) {
    this.setPropertyValue('valueExpression', valueExpression);
  }

  get expressionDependencies(): string[] {
    return this.getPropertyValue('expressionDependencies', null);
  }

  set expressionDependencies(dependencies: string[]) {
    this.setPropertyValue('expressionDependencies', dependencies);
  }

  get thousandsSeparator(): IThousandsSeparator {
    return this.getPropertyValue('thousandsSeparator', null);
  }

  set thousandsSeparator(dependencies: IThousandsSeparator) {
    this.setPropertyValue('thousandsSeparator', dependencies);
  }
}
type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};
export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
    this.question.value = this.question.survey.runExpression(
      this.question.valueExpression
    );

    this.question.expressionDependencies.forEach((dep) => {
      const question = this.question.survey.getQuestionByName(dep);

      question?.registerFunctionOnPropertyValueChanged(
        'value',
        () =>
          // set timeout to "ensure" values being updated internally in surveyJs before running expression.
          setTimeout(() => {
            this.question.value = this.question.survey.runExpression(
              this.question.valueExpression
            );
          }, 50),
        `${question.name}-stat`
      );
    });
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <Stat
          {...props}
          label={this.question.caption ?? ''}
          valuePrefix={this.question.valuePrefix}
          thousandsSeparator={this.question.thousandsSeparator}
        />
      </div>
    );
  }
}

export function registerStatQuestion() {
  const questionType = 'Axo Stat';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'caption',
        category: 'general',
        type: 'string',
        serializationProperty: 'locCaption',
      },
      {
        name: 'valuePrefix',
        category: 'general',
        type: 'string',
      },
      {
        name: 'valueExpression',
        type: 'string',
        category: 'general',
      },
      {
        name: 'expressionDependencies',
        type: 'string',
        category: 'general',
      },
      {
        name: 'thousandsSeparator',
        type: 'string',
        category: 'general',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register(
    'calculateMonthlyPayment',
    calculateMonthlyPayment
  );

  FunctionFactory.Instance.register(
    'calculateMonthlyPaymentNO',
    calculateMonthlyPaymentNO
  );
}

const calculateMonthlyPayment = (args: any[]) => {
  const startAmount = args[0];
  const tenure = args[1] * 12;
  const interestRate = args[2] / 12;

  const monthlyPayment =
    startAmount /
    ((Math.pow(1 + interestRate, tenure) - 1) /
      (interestRate * Math.pow(1 + interestRate, tenure)));
  return monthlyPayment;
};

const MONTHLY_FEE_NO = 30;
const RATES_NO = {
  LOW: 4.9,
  MID: 11.9,
  HIGH: 19.9,
};

const calculateMonthlyPaymentNO = (args: any[]) => {
  const loanAmount = args[0];
  const tenure = args[1];

  const monthlyTerms = tenure * 12;
  const termInterest = RATES_NO.MID / 100 / 12;
  const tenor = Math.round(monthlyTerms);
  const value =
    (loanAmount * termInterest) / (1 - 1 / Math.pow(1 + termInterest, tenor)) +
    Math.round(MONTHLY_FEE_NO);
  return Math.ceil(value);
};
