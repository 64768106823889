import { SaveAmount } from '@axo/deprecated/util/ui-components';
import { Formatters } from '@axo/shared/util/string';
import { createElement } from 'react';
import { ElementFactory, QuestionHtmlModel, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { calculateMonthlyPaymentNO } from '../../utils/CalculateMonthlyPayment';

class QuestionModel extends QuestionHtmlModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get countryCode() {
    return this.getPropertyValue('countryCode');
  }

  set countryCode(countryCode: 'no' | 'fi') {
    this.setPropertyValue('countryCode', countryCode);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected renderElement(): JSX.Element | null {
    const creditCardAppliedAmount = this.questionBase.survey.getQuestionByName(
      'Debt/CreditCard/OutstandingAmount'
    ).value;

    const personalAppliedAmount = this.questionBase.survey.getQuestionByName(
      'Debt/Personal/OutstandingAmount'
    ).value;

    if (!creditCardAppliedAmount && !personalAppliedAmount) return null;

    const totalAmount = creditCardAppliedAmount + personalAppliedAmount;
    const loanDuration = this.questionBase.survey.getQuestionByName(
      'LoanApplication/LoanDuration'
    ).value;

    // We are using the same calculation as NO here in FI
    const currentCost = calculateMonthlyPaymentNO([totalAmount, 10, 19.5]);
    const newCost = calculateMonthlyPaymentNO([totalAmount, loanDuration, 10]);

    const monthlySavings = currentCost - newCost;

    if (!monthlySavings || monthlySavings <= 0) return null;

    return (
      <SaveAmount
        title={`Säästä jopa ${Formatters.formatNumber(
          monthlySavings,
          ' '
        )} € kuukaudessa `}
        description={
          'Yhdistämällä vanhat kalliit lainasi yhdeksi edulliseksi säästät rahaa ja voit merkittävästi alentaa kuukausittaisia lainakulujasi.'
        }
      />
    );
  }
}

export function registerSaveAmountFIQuestion() {
  const questionType = 'Axo Save Amount FI';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'countryCode',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
