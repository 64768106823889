import { Button } from '@axo/deprecated/util/ui-components';
import { ReactNode } from 'react';

import { classNames } from '@axo/shared/util/dom';
import styles from './SteppedNumberInput.module.scss';
import ButtonBG from './icons/ButtonBG';
import Minus from './icons/Minus';
import Plus from './icons/Plus';

type TSteppedNumberInputProps = {
  value: number;
  onChange: (newValue: number) => void;
  children?: ReactNode;
  maxValue?: number;
  minValue?: number;
  isWarning?: boolean;
  valueFormatter?: (value: number) => ReactNode;
};

const SteppedNumberInput = ({
  value,
  onChange,
  maxValue,
  minValue = 0,
  isWarning = false,
  valueFormatter,
}: TSteppedNumberInputProps) => {
  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    onChange(value - 1);
  };

  const formattedValue = valueFormatter ? valueFormatter(value) : value;

  const isIncrementDisabled = maxValue !== undefined && value >= maxValue;
  const isDecrementDisabled = minValue !== undefined && value <= minValue;

  return (
    <div
      className={classNames(
        styles.container,
        isWarning && styles['container--warning']
      )}
    >
      <Button
        value={value}
        onClick={handleDecrement}
        className={classNames(
          styles.button,
          isDecrementDisabled && styles.disabled
        )}
        disabled={isDecrementDisabled}
      >
        <ButtonBG isDisabled={isDecrementDisabled} />
        <Minus className={styles.icon} isDisabled={isDecrementDisabled} />
      </Button>
      {formattedValue}
      <Button
        value={value}
        onClick={handleIncrement}
        className={classNames(
          styles.button,
          isIncrementDisabled && styles.disabled
        )}
        disabled={isIncrementDisabled}
      >
        <ButtonBG isDisabled={isIncrementDisabled} />
        <Plus className={styles.icon} isDisabled={isIncrementDisabled} />
      </Button>
    </div>
  );
};
export default SteppedNumberInput;
