import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get staticValue() {
    return this.getPropertyValue('staticValue');
  }

  set staticValue(newValue: string) {
    this.setPropertyValue('staticValue', newValue);
  }

  get triggerChangeEventOnChangeIn() {
    return this.getPropertyValue('sendChangeEventWith');
  }

  set triggerChangeEventOnChangeIn(newValue: string[]) {
    this.setPropertyValue('sendChangeEventWith', newValue);
  }

  get setIf() {
    return this.getPropertyValue('setIf');
  }

  set setIf(newValue: string) {
    this.setPropertyValue('setIf', newValue);
  }
  get runStaticValueAsExpression() {
    return this.getPropertyValue('runStaticValueAsExpression');
  }

  set runStaticValueAsExpression(newValue: string) {
    this.setPropertyValue('runStaticValueAsExpression', newValue);
  }
}

/**
 * This question wrapper allows us to send a change event with a pre-determined
 * value whenever a change happens in related fields. Utilizing how the change
 * handling code in the Axo Form works, this question wrapper can add extra
 * information to backend updates without the user having to enter that data.
 * The value for the data is set using the `staticValue` property and the
 * `triggerChangeEventOnChangeIn` property is used to define which fields to
 * listen to change events on.
 */
export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    const shouldSendChangesTogetherWithFields =
      this.question.triggerChangeEventOnChangeIn &&
      this.question.triggerChangeEventOnChangeIn.length > 0;

    if (shouldSendChangesTogetherWithFields) {
      this.question.triggerChangeEventOnChangeIn.forEach((key) => {
        const dependency = this.question.survey.getQuestionByName(key);
        if (!dependency) {
          console.warn('Could not find dependency for static value: ' + key);
          return;
        }
        dependency.registerFunctionOnPropertyValueChanged(
          'value',
          this.onDependencyChanged.bind(this),
          `${dependency.name}-${this.question.name}`
        );
      });
    } else {
      // If no dependencies are set just send the change event immediately.
      this.onDependencyChanged();
    }
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  private onDependencyChanged() {
    const shouldSetValue = this.question.setIf
      ? this.question.survey.runExpression(this.question.setIf)
      : true;
    this.question.value = undefined; // Forces the change handler to trigger by making sure the next line is a change
    if (shouldSetValue) {
      if (this.question.runStaticValueAsExpression) {
        this.question.value = this.question.survey.runExpression(
          this.question.staticValue
        );
      } else this.question.value = this.question.staticValue;
    }
  }

  protected renderElement(): JSX.Element {
    return <></>;
  }
}

export function registerStaticValueQuestion() {
  const questionType = 'Axo Static Value';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'staticValue',
        category: 'general',
        type: 'string',
      },
      {
        name: 'triggerChangeEventOnChangeIn',
        category: 'general',
        type: 'list',
      },
      {
        name: 'setIf',
        category: 'general',
        type: 'string',
      },
      {
        name: 'runStaticValueAsExpression',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
