import { loan_application } from '@axo/shared/data-access/types';
import { IStep } from '../types/Step';
import { getStartedAtUrl } from './startedAtUrl';

enum ClickIDParamName {
  GOOGLE = 'gclid',
  GOOGLE_ATT_APP = 'gbraid', // ATT: App Tracking Transparency - a new Apple policy
  GOOGLE_ATT_WEB = 'wbraid',
  FACEBOOK = 'fbclid',
  BING = 'msclkid',
}

const documentReferrerToCampaignSourceMap: { regex: RegExp; name: string }[] = [
  { regex: /google.[a-z]{2,3}(#|\?|\/|$)/, name: 'Google Search' },
  { regex: /bing.[a-z]{2,3}(#|\?|\/|$)/, name: 'Bing Search' },
];

export type ICookieData = {
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_campaign?: string;
};

type CreateChannelSnapshotArgs = {
  searchParams: URLSearchParams;
  completedSteps: IStep[];
  cookieData?: ICookieData;
  experiments?: string[];
  domain?: string;
};

export const createChannelSnapshot = ({
  searchParams,
  completedSteps,
  cookieData,
  domain,
  experiments,
}: CreateChannelSnapshotArgs): loan_application.ChannelSnapshot => {
  const startedAtUrl = getStartedAtUrl();

  const adsSnapshot = getAdsSnapshot(searchParams);

  const snapshot = {
    CampaignSource:
      searchParams.get('utm_source') ||
      cookieData?.['utm_source'] ||
      adsSnapshot.CampaignSource ||
      undefined,
    CampaignMedium:
      searchParams.get('utm_medium') || cookieData?.['utm_medium'] || undefined,
    CampaignTerm:
      searchParams.get('utm_term') || cookieData?.['utm_term'] || undefined,
    CampaignContent:
      searchParams.get('utm_content') ||
      cookieData?.['utm_content'] ||
      adsSnapshot.CampaignContent ||
      undefined,
    CampaignName:
      searchParams.get('utm_campaign') ||
      cookieData?.['utm_campaign'] ||
      undefined,
    UserAgent: navigator.userAgent,
    GoogleClickID: searchParams.get(ClickIDParamName.GOOGLE) || undefined,
    // ClientID:
    HasOffersTransactionID: searchParams.get('transaction_id') || undefined,
    DocumentReferrer: document.referrer || 'Direct',
    Keyword: searchParams.get('keyword') || undefined,
    FacebookClientID: searchParams.get(ClickIDParamName.FACEBOOK) || undefined,
    // FacebookPixelID:
    // WhitelabelPartnerClickID:
    Action: completedSteps[completedSteps.length - 1]?.name,
    StartedAtUrl: startedAtUrl,
    domain,
    Experiment: experiments,
  } satisfies loan_application.ChannelSnapshot;

  return snapshot;
};

const getGoogleClickId = (sp: URLSearchParams) =>
  sp.get(ClickIDParamName.GOOGLE) ??
  sp.get(ClickIDParamName.GOOGLE_ATT_APP) ??
  sp.get(ClickIDParamName.GOOGLE_ATT_WEB);

const getAdsSnapshot = (
  sp: URLSearchParams
): Pick<
  loan_application.ChannelSnapshot,
  'CampaignSource' | 'CampaignContent' | 'DocumentReferrer'
> => {
  const googleClickId = getGoogleClickId(sp);
  if (googleClickId)
    return {
      CampaignSource: 'Adwords',
      CampaignContent: googleClickId || undefined,
    };

  if (sp.get(ClickIDParamName.BING))
    return {
      CampaignSource: 'bing',
      CampaignContent: sp.get(ClickIDParamName.BING) || undefined,
    };

  for (const r of documentReferrerToCampaignSourceMap) {
    if (r.regex.test(document.referrer)) {
      return {
        CampaignSource: r.name,
      };
    }
  }

  return {};
};
