import { dispatch } from '../store/store';

// The promise state with the highest number takes priority when reducing multiple
// request states into a single promise state
export const LoadingStatePriorities = Object.freeze({
  idle: 1,
  failed: 2,
  complete: 3,
  pending: 4,
});

export type ILoadingState = keyof typeof LoadingStatePriorities;

let _counter = -1;
const _requestStates: ILoadingState[] = ['idle'];

/**
 * Wraps around an async function to sync its state with the form state. This means
 * that if a "stateful" async function has been called but has not resolved yet, the
 * form will be in a loading state.
 */
export const toStatefulAsync =
  <A extends unknown[], R>(callback: (...args: A) => Promise<R>) =>
  (...args: A): Promise<R> => {
    const requestIndex = getNextStateIndex();

    setPromiseState(requestIndex, 'pending');

    return callback(...args)
      .then((response) => {
        setPromiseState(requestIndex, 'complete');
        return response;
      })
      .catch((error) => {
        setPromiseState(requestIndex, 'failed');
        throw error;
      });
  };

const getNextStateIndex = () => ++_counter;

const setPromiseState = (index: number, state: ILoadingState) => {
  _requestStates[index] = state;
  handleStateChange(_requestStates);
};

const handleStateChange = (states: ILoadingState[]) => {
  const state = states.reduce((sum, next) => {
    if (LoadingStatePriorities[next] > LoadingStatePriorities[sum]) return next;
    else return sum;
  });
  switch (state) {
    case 'pending':
      return dispatch({
        type: 'promise.pending',
      });
    case 'complete':
      return dispatch({
        type: 'promise.complete',
      });
    case 'failed':
      return dispatch({
        type: 'promise.failed',
      });
  }
};

export const runAsStatefulAsync = (callback: () => Promise<void>) =>
  toStatefulAsync(callback)();
