import { SurveyModel } from 'survey-core';
import { ISurveyOnValueChangedOptions } from '../../../types/survey/ISurveyOnValueChangedOptions';
import { getTrackingData } from '../getTrackingData';
import { syncFieldsWithDefaultValues } from '../syncFieldsWithDefaultValues';
import { syncField } from './syncFields';
import { syncFieldsThatWereFixed } from './syncFieldsThatWereFixed';

export const surveyValueChanged = (
  survey: SurveyModel,
  options: ISurveyOnValueChangedOptions,
  onComplete?: (step: number, data: Record<string, unknown>) => Promise<void>
) => {
  if (!options?.question) {
    return console.warn('Missing question in change event handler', options);
  }

  syncFieldsWithDefaultValues(survey);
  syncFieldsThatWereFixed(survey);
  if (options.question.hasErrors()) return;

  if (options.name === 'Person/SocialSecurityNumber') {
    syncField({
      name: 'Person/MarketCountry',
      value: survey.getCalculatedValueByName('Person/MarketCountry').value,
    });
  }
  if (options.name === 'CoPerson/SocialSecurityNumber') {
    syncField({
      name: 'CoPerson/MarketCountry',
      value: survey.getCalculatedValueByName('Person/MarketCountry').value,
    });
  }

  syncField(options);

  // onStepInitiated - step 1 only (surveyjs does not have a reliable way to know when a step is initiated)
  if (options.name === 'Ignore/showPersonalDetails' && options.value === true) {
    onComplete?.(1, getTrackingData(survey));
  }
};
