import { createFakeSyntheticChangeEvent } from '../utils/createFakeSyntheticEvent';
import { useEffect, useState } from 'react';
import { IInputState, InputStateElement } from './useInputState';

type IValueTransformer = (v: string, s: IInputState) => string;

/**
 * This hook will return a new input state with a transformed value. The changes
 * will not persist, meaning that the value passed to transformer always is the
 * "untransformed" value. This works well when we want to apply a transformation
 * on the value in an input field, but for example only when the input looses focus.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const useTransformInputValue = (
  transformer: IValueTransformer,
  inputState: IInputState
) => {
  const [protoValue, setValue] = useState<string>(inputState.htmlProps.value);

  const value = transformer(protoValue, inputState);

  useEffect(() => {
    if (inputState.htmlProps.value && value !== inputState.htmlProps.value) {
      setValue(inputState.htmlProps.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState.htmlProps.value]);

  const inputStateCopy: IInputState<any> = {
    ...inputState,
    axoProps: { ...inputState.axoProps },
    htmlProps: { ...inputState.htmlProps },
  };

  inputStateCopy.htmlProps.onChange = (e) => {
    setValue(e.target.value);
    inputState.htmlProps.onChange?.(e);
  };

  inputStateCopy.htmlProps.value = value;

  return inputStateCopy;
};

/**
 * This hook will return a new input state with a transformed value. It does not
 * keep track of the value pre-transformation, so each transformation will be
 * applied to the already transformed value. This works well when we want to
 * actually change the value in the input field (not just temporarily).
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const usePersistentTransformInputValue = <E extends InputStateElement>(
  transformer: IValueTransformer,
  inputState: IInputState<E>
): IInputState<E> => {
  const [value, setValue] = useState(inputState.htmlProps.value);

  useEffect(() => {
    setValue(inputState.htmlProps.value);
  }, [inputState.htmlProps.value]);

  useEffect(() => {
    const transformedValue = transformer(value, inputState);

    if (transformedValue !== value) {
      setValue(transformedValue);

      const input = inputState.htmlProps.ref.current;

      if (!input) return;
      input.value = transformedValue;

      inputState.htmlProps.onChange?.(createFakeSyntheticChangeEvent(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState]);

  const inputStateCopy: IInputState = {
    ...inputState,
    htmlProps: { ...inputState.htmlProps },
    axoProps: { ...inputState.axoProps },
  };

  inputStateCopy.htmlProps.value = value;

  return inputStateCopy as IInputState<E>;
};
