import { Checkbox } from '@axo/shared/ui/input';

import { classNames } from '@axo/shared/util/dom';
import { getFormattedAmount } from '@axo/shared/util/formatters';
import { useCallback, useMemo } from 'react';
import { useExtendedLoanOverviewContext } from '../../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import Slider from '../Slider/Slider';
import styles from './Detail.module.scss';

export type TDetailProps = {
  label: string;
  value: number;
  isEditMode?: boolean;
  onCheckboxChange: (newValue: boolean) => void;
  onValueChange: (newValue: number) => void;
  maxValue: number;
  step?: number;
  isChecked: boolean;
};

const Detail = ({
  label,
  value = 0,
  isEditMode = false,
  onCheckboxChange,
  onValueChange,
  maxValue,
  step = 500,
  isChecked,
}: TDetailProps) => {
  const { currency } = useExtendedLoanOverviewContext();

  const valueLabel = `${getFormattedAmount(value)} ${currency}`;

  const _handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheckboxChange(e.target.checked);
    },
    [onCheckboxChange]
  );

  const isValueLabelGreen = useMemo(
    () => !!(value > 0 && isChecked && isEditMode),
    [value, isChecked, isEditMode]
  );

  return (
    <div className={classNames(isEditMode && styles.editModeContainer)}>
      <div className={styles.detail}>
        <span className={styles.text}>{label}</span>
        <div className={styles.right}>
          <span
            className={classNames(
              styles.text,
              isValueLabelGreen && styles.textHighlight
            )}
          >
            {valueLabel}
          </span>
          <Checkbox
            name=""
            onChange={_handleCheckboxChange}
            size="small"
            value={isChecked}
            isNoMargin
            classes={{ root: styles.checkbox }}
          />
        </div>
      </div>
      {isEditMode && isChecked && (
        <Slider
          step={step}
          value={value}
          onChange={onValueChange}
          min={0}
          max={maxValue}
          currency={currency}
          className={styles.slider}
          trackHeight={8}
        />
      )}
    </div>
  );
};

export default Detail;
