import { Icons } from '@axo/deprecated/util/ui-components';
import { FormFlagSet } from '@axo/form/configs';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { RadioGroup } from '@axo/shared/ui/input';
import { ReactElement } from 'react';

import styles from './Storebrand.module.scss';

export type IStorebrand = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const benefits = [
  'God og trygg rådgivning',
  'Se hvor mye du kan spare med riktig dekning',
];

export function Storebrand({ onChange }: IStorebrand): ReactElement | null {
  // TODO: Remove when FF is deleted
  let { 'storebrand-enabled': storebrandEnabled } = useFlags(FormFlagSet);
  useFlagImpression(FormFlagSet, { 'storebrand-enabled': storebrandEnabled });
  storebrandEnabled ??= false;

  if (!storebrandEnabled) return null;

  return (
    <div className={styles.root}>
      <div>
        <h2>Få opptil 15% rabatt hos Storebrand Forsikring!</h2>

        <div className={styles.icon}>
          <Icons.Storebrand size="full" />
        </div>
      </div>

      <p>
        Ikke la muligheten til å få et godt forsikringstilbud tilpasset dine
        behov gå fra deg. Ønsker du å se hvor mye du kan spare? Vår
        samarbeidspartner Storebrand tar direkte kontakt med deg og gir deg et
        godt forsikringstilbud med rett dekning og til en god pris.
      </p>

      <div>
        {benefits.map((text, i) => (
          <div key={i} className={styles.benefit}>
            <Icons.badgeCheck size="sm" />
            <span>{text}</span>
          </div>
        ))}
      </div>

      <RadioGroup
        name="Test/Storebrand"
        label="Jeg vil at Storebrand skal kontakte meg for et uforpliktende forsikringstilbud."
        radioStyle="both"
        setButtonsHalfWidth
        buttonHeight="regular"
        onChange={onChange}
        options={[
          { text: 'Ja', value: 'yes' },
          { text: 'Nei', value: 'no' },
        ]}
      />
    </div>
  );
}
