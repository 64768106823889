import { SurveyModel, Question, PageModel } from 'survey-core';
import { syncField } from './syncFields';

const hadErrorsMap: Record<string, boolean> = {};

/**
 * If a change in a field fixes validation for another field, we need to sync
 * the field that was fixed to the backend. This method tracks the errors on
 * each field to figure out if it needs to be synced or not.
 */
export const syncFieldsThatWereFixed = (survey: SurveyModel) => {
  ((survey.currentPage as PageModel).questions as Question[]).forEach((q) => {
    if (q.value) {
      const hadErrors = hadErrorsMap[q.name];
      const hasErrors = q.hasErrors();
      const currentChangeFixedError = hadErrors && !hasErrors;

      hadErrorsMap[q.name] = hasErrors;

      if (currentChangeFixedError) {
        syncField(q);
      }
    }
  });
};
