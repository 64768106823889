/**
 * Parses a cookie string into a key-value object. Works for cookies that has values separated by | -> name=value|name=value.
 * @param {string} cookie - The cookie string to parse.
 * @returns {Record<string, string>} - The parsed cookie object with key-value pairs.
 */
export const parseCookieData = (cookie: string): Record<string, string> => {
  const pairs = cookie.split('|');
  const result: Record<string, string> = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    result[key.trim()] = decodeURIComponent(value.trim());
  });
  return result;
};
