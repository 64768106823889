import { useAnalytics } from '@axo/shared/services/analytics';
import { useEffect } from 'react';
import { IFormState, subscribe, unsubscribe } from '../store';
import { Observer } from '../utils/Subject';

export const useCustomerAnalytics = () => {
  const { identify, reset } = useAnalytics();

  useEffect(() => {
    const observer: Observer<IFormState> = async (value, prevValue) => {
      if (value.customer?.ID && !prevValue?.customer?.ID) {
        await identify({
          uuid: value.customer.ID,
          country: value.customer.MarketCountry,
          email: value.customer.Email,
          phonenumber: `+${value.customer.MobilePhoneCountryCode}${value.customer.MobilePhoneNumber}`,
        });
      } else if (!value.customer?.ID && prevValue?.customer?.ID) {
        reset();
      }
    };

    subscribe(observer);

    return () => unsubscribe(observer);
  }, []);
};
