import { MarketCountryCode } from '@axo/shared/types';

export type IAssentlyResponse = {
  provider?: string;
  success: boolean;
  token?: string;
  transactionId?: string;
  type: 'authenticated' | 'failed' | 'cancelled' | 'error' | 'cookie-message';
  errorMessage?: string;
};

const configs = {
  [MarketCountryCode.Norway]: {
    allowedEids: ['no-bankid-mobile', 'no-bankid'],
    mode: 'auth',
    language: 'no',
    location: 'no',
  },
};

export type IAssentlyClient = {
  token: string;
  onCallback: (response: IAssentlyResponse) => void;
  country: MarketCountryCode.Norway;
};

/**

 * Initializes the Assently client with the provided token.
 *
 * @param token - The token used for authentication towards assently. Retrieved from axo backend
 * @param onResponse - The callback function that will be called when the user has authenticated or cancelled the process.
 * @throws Error if the window object is not defined.
 */
export const initAssently = ({
  token,
  onCallback,
  country,
}: IAssentlyClient) => {
  if (typeof window === 'undefined') {
    throw new Error('window is not defined');
  }

  // https://docs.assently.com/coreid/#coreid-client-api-and-configuration
  const coreIdClient = (window as any).coreid_client;
  coreIdClient.init({
    config: configs[country],
    token: token,
    callback: function (data: IAssentlyResponse) {
      // if the type is cookie-message, we ignore because it's just an action to change settings in the cookie
      if (data.type === 'cookie-message') return;
      onCallback(data);
      coreIdClient.close();
    },
  });
};

export const assentlyStart = () => {
  (window as any).coreid_client.start();
};
