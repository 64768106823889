import { updateUrlQueryParam } from '@axo/deprecated/util/ui-components';
import { Formatters } from '@axo/shared/util/string';
import { createElement } from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
  SurveyModel,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import NavigationButtons from './NavigationButtons';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('textContinue', this, false);
    this.createLocalizableString('textBack', this, false);
    this.createLocalizableString('textComplete', this, false);
    this.createLocalizableString('textSkip', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instantiated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get testing() {
    return this.survey;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  public get textContinue(): string {
    return this.getLocalizableStringText('textContinue');
  }

  public set textContinue(val: string) {
    this.setLocalizableStringText('textContinue', val);
  }

  get locTextContinue(): LocalizableString {
    return this.getLocalizableString('textContinue');
  }

  public get textComplete(): string {
    return this.getLocalizableStringText('textComplete');
  }

  public set textComplete(val: string) {
    this.setLocalizableStringText('textComplete', val);
  }

  get locTextComplete(): LocalizableString {
    return this.getLocalizableString('textComplete');
  }

  public get textSkip(): string {
    return this.getLocalizableStringText('textSkip');
  }

  public set textSkip(val: string) {
    this.setLocalizableStringText('textSkip', val);
  }

  get locTextSkip(): LocalizableString {
    return this.getLocalizableString('textSkip');
  }

  public get textBack(): string {
    return this.getLocalizableStringText('textBack');
  }

  public set textBack(val: string) {
    this.setLocalizableStringText('textBack', val);
  }

  get locTextBack(): LocalizableString {
    return this.getLocalizableString('textBack');
  }

  get continueButtonVisible() {
    return this.getPropertyValue('continueButtonVisible', true);
  }

  set continueButtonVisible(newContinuevisible: boolean) {
    this.setPropertyValue('continueButtonVisible', newContinuevisible);
  }

  get backButtonVisible() {
    return this.getPropertyValue('backButtonVisible');
  }

  set backButtonVisible(newBackvisible: boolean) {
    this.setPropertyValue('backButtonVisible', newBackvisible);
  }

  get skipButtonVisible() {
    return this.getPropertyValue('skipButtonVisible');
  }

  set skipButtonVisible(newSkipvisible: boolean) {
    this.setPropertyValue('skipButtonVisible', newSkipvisible);
  }

  get width() {
    return this.getPropertyValue('width');
  }

  set width(width: 'standard' | 'full-width') {
    this.setPropertyValue('width', width);
  }

  // Added to prevent step 1 embedded adding an extra entry to history when going from step1 to full-width
  get shouldAddQueryParamOnNavigate() {
    return this.getPropertyValue('shouldAddQueryParamOnNavigate');
  }

  set shouldAddQueryParamOnNavigate(value: boolean) {
    this.setPropertyValue('shouldAddQueryParamOnNavigate', value);
  }

  get totalAmountSavedHeading() {
    return this.getPropertyValue('totalAmountSavedHeading');
  }

  set totalAmountSavedHeading(value: string) {
    this.setPropertyValue('totalAmountSavedHeading', value);
  }

  set totalAmountSavedBoldText(value: string) {
    this.setPropertyValue('totalAmountSavedBoldText', value);
  }

  get totalAmountSavedBoldText() {
    return this.getPropertyValue('totalAmountSavedBoldText');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  handleClickContinue() {
    const { isLastPage } = this.question.survey as unknown as SurveyModel;
    const survey = this.question.survey as unknown as SurveyModel;
    const currentNextPageIndex = survey.currentPageNo + 1;
    const isNextPage = survey.nextPage();

    if (isLastPage) {
      survey.completeLastPage();
      return;
    }

    if (this.question.shouldAddQueryParamOnNavigate && isNextPage) {
      updateUrlQueryParam(currentNextPageIndex + 1);
    }
  }

  handleClickBack() {
    const survey = this.question.survey as unknown as SurveyModel;
    const currentPrevPageIndex = survey.currentPageNo - 1;
    const isPrevPage = survey.prevPage();

    if (this.question.shouldAddQueryParamOnNavigate && isPrevPage) {
      updateUrlQueryParam(currentPrevPageIndex + 1);
    }
  }

  handleClickSkip() {
    const survey = this.question.survey as unknown as SurveyModel;

    survey.doComplete();
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    const { isLastPage, isFirstPage } = this.question.survey as SurveyModel;
    const textContinue = isLastPage
      ? this.question.textComplete
      : this.question.textContinue;

    const locale = (this.question.survey as SurveyModel).getLocale();
    let continueButtonWrapperProps = {};

    const savedAmount = this.question.survey.getQuestionByName(
      'Ignore/SaveAmountNO'
    )?.value;

    // This is only available when using the debt registry in Norway, thus hardcoded to simplify the solution.
    if (savedAmount) {
      const roundedUpValue = Math.round(savedAmount / 100) * 100;

      continueButtonWrapperProps = {
        continueButtonWrapperProps: {
          boldText: `${Formatters.formatNumber(
            roundedUpValue,
            ' '
          )} kr i måneden`,
          headline: `Send søknaden din og spar`,
        },
      };
    }

    const page = this.question.page as unknown as SurveyModel;
    const isCurrentPageValid = page.validate(false);

    return (
      <div style={style}>
        <NavigationButtons
          isCurrentPageValid={isCurrentPageValid}
          continueButtonFontVariant={locale === 'no' ? 'primary' : 'secondary'} // Only NO should have primary font for now, could be changed later.. 18.01.23
          textContinue={textContinue}
          textBack={this.question.textBack}
          textSkip={this.question.textSkip}
          continueButtonVisible={this.question.continueButtonVisible}
          backButtonVisible={this.question.backButtonVisible && !isFirstPage}
          skipButtonVisible={this.question.skipButtonVisible}
          onClickContinue={this.handleClickContinue.bind(this)}
          onClickBack={this.handleClickBack.bind(this)}
          onClickSkip={this.handleClickSkip.bind(this)}
          width={this.question.width}
          {...continueButtonWrapperProps}
        />
      </div>
    );
  }
}

export function registerNavigationButtonsQuestion() {
  const questionType = 'Axo Navigation';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'textContinue',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTextContinue',
      },
      {
        name: 'textComplete',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTextComplete',
      },
      {
        name: 'textBack',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTextBack',
      },
      {
        name: 'textSkip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTextSkip',
      },
      {
        name: 'totalAmountSavedHeading',
        category: 'general',
        type: 'string',
      },
      {
        name: 'totalAmountSavedBoldText',
        category: 'general',
        type: 'string',
      },
      {
        name: 'continueButtonVisible',
        default: true,
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'backButtonVisible',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'skipButtonVisible',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'shouldAddQueryParamOnNavigate',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
