import styles from './SavedAmount.module.scss';

export type ISavedAmount = {
  html: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const SavedAmount = ({ html }: ISavedAmount) => (
  <div
    className={styles.container}
    dangerouslySetInnerHTML={{ __html: html }}
  ></div>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default SavedAmount;
