import { Model } from 'survey-core';
import { z } from 'zod';

export const configurablePropertiesSchema = z.object({
  refinancing: z.boolean().optional(),
  appliedAmount: z.number().optional(),
});
export type IConfigurableProperties = z.infer<
  typeof configurablePropertiesSchema
>;
type IValidKeys = keyof IConfigurableProperties;

export const setDefaultValuesFromDataAttributes = (
  data: IConfigurableProperties,
  surveyJsForm: Model
) => {
  const validatedData = configurablePropertiesSchema.strict().parse(data);

  Object.entries(validatedData).forEach(([key, value]) => {
    const action = keyActions[key as IValidKeys];
    if (action) {
      action(surveyJsForm, value);
    }
  });
};

const handleDefaultRefinancing = (surveyJsForm: Model, value?: boolean) => {
  if (value) {
    surveyJsForm.getQuestionByName('LoanApplication/Purpose').value =
      'Refinancing loan';
  }
};

const handleDefaultAppliedAmount = (surveyJsForm: Model, value?: number) => {
  if (value) {
    surveyJsForm.getQuestionByName('LoanApplication/AppliedAmount').value =
      value;
  }
};

const keyActions: Record<
  IValidKeys,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (surveyJsForm: Model, value: any) => void
> = {
  refinancing: handleDefaultRefinancing,
  appliedAmount: handleDefaultAppliedAmount,
};
