/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const PartnersIcon = () => {
  return (
    <svg viewBox="0 0 363 363" fill="none">
      <circle cx="180.8" cy="181.6" r="180.8" fill="#FFE187" />
      <path
        stroke="#BA8B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18.1"
        d="M226 254h45.2v-18.2a27.1 27.1 0 0 0-48.4-16.8m3.2 35h-90.4m90.4 0v-18.2a45 45 0 0 0-3.2-16.8m-87.2 35H90.4v-18.2a27.1 27.1 0 0 1 48.4-16.8m-3.2 35v-18.2a45 45 0 0 1 3.2-16.8m0 0a45.2 45.2 0 0 1 84 0m-14.9-82.6a27.1 27.1 0 1 1-54.2 0 27.1 27.1 0 0 1 54.2 0Zm54.3 27.1a18 18 0 1 1-36.2 0 18 18 0 0 1 36.2 0Zm-126.6 0a18 18 0 1 1-36.1 0 18 18 0 0 1 36.1 0Z"
      />
    </svg>
  );
};
