import { Checkbox } from '@axo/shared/ui/input';

import { getFormattedAmount } from '@axo/shared/util/formatters';
import { useCallback } from 'react';
import { useExtendedLoanOverviewContext } from '../../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import Slider from '../Slider/Slider';
import styles from './Detail.module.scss';

export type TDetailProps = {
  label: string;
  value: number;
  isEditMode?: boolean;
  onCheckboxChange: (newValue: boolean) => void;
  onValueChange: (newValue: number) => void;
  maxValue: number;
  step?: number;
  isChecked: boolean;
};

const Detail = ({
  label,
  value = 0,
  isEditMode = false,
  onCheckboxChange,
  onValueChange,
  maxValue,
  step = 500,
  isChecked,
}: TDetailProps) => {
  const { currency } = useExtendedLoanOverviewContext();

  const valueLabel = `${getFormattedAmount(value)} ${currency}`;

  const _handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheckboxChange(e.target.checked);
    },
    [onCheckboxChange]
  );

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Checkbox
          name=""
          onChange={_handleCheckboxChange}
          size="small"
          value={isChecked}
          isNoMargin
        />
        <div className={styles.labels}>
          <span>{label}</span>
          <span>{valueLabel}</span>
        </div>
      </div>
      {isEditMode && isChecked && (
        <Slider
          step={step}
          value={value}
          onChange={onValueChange}
          min={0}
          max={maxValue}
          currency={currency}
        />
      )}
    </div>
  );
};

export default Detail;
