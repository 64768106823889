import {
  getUrlQueryParam,
  AxoUrlQueryParams,
} from '@axo/deprecated/util/ui-components';
import { FinnishSSN } from 'finnish-ssn';
import { validateNorwegianIdNumber } from 'norwegian-national-id-validator';

export function validateFinnishSSN(params: (string | number)[]) {
  const nationalId = params[0];
  if (!nationalId) return;

  // Finnish national ids containing a "+" are for people born in 1800-1899. No
  // person in Finland is that old, so these SSNs should be considered invalid.
  // https://en.wikipedia.org/wiki/National_identification_number#Finland
  if (nationalId.toString().includes('+')) return false;

  return FinnishSSN.validate(nationalId.toString());
}

export function validateNorwegianNationalId(params: (string | number)[]) {
  const nationalId = params[0];
  if (!nationalId) return false;

  if (
    getUrlQueryParam(
      AxoUrlQueryParams.AXO_BYPASS_NATIONAL_ID
    )?.toLowerCase() === 'true'
  ) {
    return true;
  }

  // Survey JS sometimes decides that the string should be a number and silently
  // converts it to a number, which messes with the validation library. So we
  // need to make sure the national id is actually a string for sure, before
  // validating it.
  return validateNorwegianIdNumber(nationalId.toString());
}
