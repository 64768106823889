import { z } from 'zod';

export const personBackendGeneratedPropertiesSchema = z.object({
  ID: z.string(),
  Revision: z.number().optional(),
  CreatedAt: z.string().optional(),
  UpdatedAt: z.string().optional(),
});

export type IPersonBackendGeneratedProperties = z.infer<
  typeof personBackendGeneratedPropertiesSchema
>;

export const protoPersonSchema = z.object({
  SocialSecurityNumber: z.string(),
  MarketCountry: z.string().optional(),
  LivedAtAddressSince: z.string().optional(),
});

export type IProtoPerson = z.infer<typeof protoPersonSchema>;

export const personSchema = protoPersonSchema.and(
  personBackendGeneratedPropertiesSchema
);

export type IPerson = z.infer<typeof personSchema>;

export const partialPersonSchema = protoPersonSchema
  .partial()
  .and(personBackendGeneratedPropertiesSchema.partial());

export type IPartialPerson = z.infer<typeof partialPersonSchema>;
