/* eslint-disable @nx/enforce-module-boundaries */
import React, { useMemo } from 'react';
import { formatFactory } from '@axo/deprecated/util/ui-components/utils/formatFactory';
import { IThousandsSeparator } from '@axo/deprecated/util/ui-components/utils/Formatters';
import styles from './Select.module.scss';

export interface ISelect
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'defaultValue'> {
  separator: IThousandsSeparator;
  config: {
    min: number;
    max: number;
    step: number;
  };
  microcopy: {
    suffix: string;
  };
  label: string;
  name: string;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Select = ({
  value,
  label,
  separator,
  name,
  microcopy,
  config,
  ...rest
}: ISelect) => {
  const formatter = useMemo(
    () => formatFactory({ thousandsSeparator: separator }),
    [separator]
  );
  const options = useMemo(() => {
    const ret = [];
    for (let i = config.min; i <= config.max; i += config.step) {
      ret.push({
        label: formatter(i, microcopy.suffix),
        value: i,
      });
    }
    if (
      typeof value === 'number' &&
      !ret.find((listValue) => listValue.value === value)
    ) {
      ret.push({
        label: formatter(value, microcopy.suffix),
        value: value,
      });
    }

    return ret.sort((a, b) => a.value - b.value);
  }, [formatter, config, microcopy.suffix, value]);

  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      <select value={value} id={name} name={name} {...rest}>
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
