import { IMarketingConsentMarkets } from './IMarketingConsentMarkets';
import { marketingConsentPartnersDK } from './partners/MarketingConsentPartnersDK';
import { marketingConsentPartnersFI } from './partners/MarketingConsentPartnersFI';
import { marketingConsentPartnersNO } from './partners/MarketingConsentPartnersNO';
import { marketingConsentPartnersSE } from './partners/MarketingConsentPartnersSE';

export const getMarketingConsentPartners = (
  country: IMarketingConsentMarkets
) => {
  switch (country) {
    case 'no':
      return [...marketingConsentPartnersNO];
    case 'se':
      return [...marketingConsentPartnersSE];
    case 'fi':
      return [...marketingConsentPartnersFI];
    case 'dk':
      return [...marketingConsentPartnersDK];
    default:
      return [];
  }
};
