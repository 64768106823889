import { TStoredValues } from './storedValues.types';

export const getStoredCashAmount = () => {
  const savedCashAmountFromLocalStorage = localStorage.getItem(
    TStoredValues.cashAmount
  );

  const savedCashAmount = savedCashAmountFromLocalStorage
    ? parseInt(savedCashAmountFromLocalStorage, 10)
    : undefined;

  return savedCashAmount;
};

export const getStoredRefinanceableDebts = () => {
  const savedRefinanceableDebtsFromLocalStorage = localStorage.getItem(
    TStoredValues.refinanceableDebts
  );

  const savedRefinanceableDebts = savedRefinanceableDebtsFromLocalStorage
    ? JSON.parse(savedRefinanceableDebtsFromLocalStorage)
    : undefined;

  return savedRefinanceableDebts?.length ? savedRefinanceableDebts : undefined;
};

export const getStoredAppliedAmount = () => {
  const savedAppliedAmountFromLocalStorage = localStorage.getItem(
    TStoredValues.appliedAmount
  );

  const savedAppliedAmount = savedAppliedAmountFromLocalStorage
    ? parseInt(savedAppliedAmountFromLocalStorage, 10)
    : undefined;

  return savedAppliedAmount;
};

export const getStoredValues = () => ({
  savedCashAmount: getStoredCashAmount(),
  getStoredRefinanceableDebts: getStoredRefinanceableDebts(),
  appliedAmount: getStoredAppliedAmount(),
});
