import {
  Market,
  Variant,
  isValidFormVariant,
  isValidMarketCountry,
} from './getConfig';

export const loadMarket = ({
  market,
  config,
}: {
  market?: Market;
  config?: string;
} = {}) => {
  const rootEl =
    typeof document !== 'undefined'
      ? document.querySelector('#axo-root')
      : undefined;
  /**
   * @deprecated use data-market instead
   */
  const dataConfig = rootEl?.getAttribute('data-config') ?? config;
  /**
   * @deprecated use data-market instead
   */
  const dataDefaultLocale = rootEl?.getAttribute('data-default-locale');

  const dataMarketCountry = rootEl?.getAttribute('data-market');

  const legacyDataConfig = getConfigDataFromLegacyDataConfig(dataConfig ?? '');

  const marketCountry = getFirstDefined(
    market,
    dataMarketCountry,
    dataDefaultLocale,
    legacyDataConfig?.marketCountry
  );

  if (!isValidMarketCountry(marketCountry))
    throw new Error(`Missing or invalid data-market property on root div.`);

  return marketCountry;
};

export const loadFormVariant = ({
  variant,
  config,
}: {
  variant?: Variant;
  config?: string;
} = {}) => {
  const rootEl =
    typeof document !== 'undefined'
      ? document.querySelector('#axo-root')
      : undefined;
  /**
   * @deprecated use data-market instead
   */
  const dataConfig = rootEl?.getAttribute('data-config') ?? config;

  const dataFormVariant = rootEl?.getAttribute('data-form-variant');

  const legacyDataConfig = getConfigDataFromLegacyDataConfig(dataConfig ?? '');

  const formVariant = getFirstDefined(
    variant,
    dataFormVariant,
    legacyDataConfig?.formVariant,
    'main'
  );

  if (!isValidFormVariant(formVariant))
    throw new Error(
      `Missing or invalid data-form-variant property on root div.`
    );

  return formVariant;
};

const getFirstDefined = <T>(...args: (T | undefined)[]): T | undefined => {
  for (const arg of args) {
    if (arg !== undefined && arg !== null) {
      return arg;
    }
  }
};

const getConfigDataFromLegacyDataConfig = (
  dataConfig: string
): { marketCountry: string; formVariant: 'pre' | 'main' } | undefined => {
  if (dataConfig.includes('/config/no/stepOne.json')) {
    return {
      marketCountry: 'no',
      formVariant: 'pre',
    };
  }

  if (dataConfig.includes('/config/no/fullwidth.json')) {
    return {
      marketCountry: 'no',
      formVariant: 'main',
    };
  }

  if (dataConfig.includes('/config/embedded.json')) {
    return {
      marketCountry: 'fi',
      formVariant: 'pre',
    };
  }

  if (dataConfig.includes('/config/fullwidth.json')) {
    return {
      marketCountry: 'fi',
      formVariant: 'main',
    };
  }
};
