import { useAnalytics } from '@axo/shared/services/analytics';
import { useCallback, useMemo } from 'react';

const useTrackAuthentication = () => {
  const { track } = useAnalytics();

  const onSuccess = useCallback(
    (provider: string) => {
      track({
        event: 'Auth Provider Authenticated',
        params: { provider },
      });
    },
    [track]
  );

  const onError = useCallback(
    (provider: string) => {
      track({
        event: 'Auth Provider Failed',
        params: { provider },
      });
    },
    [track]
  );

  const onCanceled = useCallback(
    (provider: string) => {
      track({
        event: 'Auth Provider Cancelled',
        params: { provider },
      });
    },
    [track]
  );

  const onStarted = useCallback(
    (provider: string) => {
      track({
        event: 'Auth Provider Initiated',
        params: { provider },
      });
    },
    [track]
  );

  return useMemo(
    () => ({
      onSuccess,
      onError,
      onCanceled,
      onStarted,
    }),
    [onSuccess, onError, onCanceled, onStarted]
  );
};

export default useTrackAuthentication;
