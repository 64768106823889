import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function getApplicationStatuses({ url: { service }, token }: IAPI) {
  return async function (): Promise<loan_application.LoanApplicationMap> {
    const url = new URL('/loan-application/statuses/all', service);

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token ?? '',
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}
