import { MarketCountryCode } from '@axo/shared/types';
import { ReactElement } from 'react';
import { TProviders } from '../../../../IAuthProviders.types';
import { BankID } from './BankID';
import { MitID } from './MitID';

export type CriiptoProvidersProps = {
  label?: string;
  href: string;
  onClick: () => void;
};

type CriiptoProvidersMapping = {
  provider: TProviders;
  Component: (props: CriiptoProvidersProps) => ReactElement;
};

export const criiptoProviderMapping = new Map<
  MarketCountryCode,
  CriiptoProvidersMapping
>([
  [
    MarketCountryCode.Denmark,
    { provider: TProviders.Criipto, Component: MitID },
  ],
  [
    MarketCountryCode.Norway,
    { provider: TProviders.Criipto, Component: BankID },
  ],
  [
    MarketCountryCode.Sweden,
    { provider: TProviders.Criipto, Component: BankID },
  ],
]);
