import { TProviders } from '@axo/shared/auth/providers';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  TAuthenticationContext,
  TAuthenticationContextProviderProps,
} from './AuthenticationContext.types';
import { reducer } from './store/reducer';

const AuthenticationContext = createContext<TAuthenticationContext | undefined>(
  undefined
);

/**
 * This context should surround all form steps so that the whole application knows if the user is authenticated or not.
 * Also it would be ideal to deal with the providers here, since the user can be redirected to a page that doesn't render the DebtRegistry component.
 * For now it only works as a helper to deal with things that DebtRegistry shouldn't deal with.
 *
 */
export const AuthenticationContextProvider = ({
  children,
}: TAuthenticationContextProviderProps) => {
  const [
    { isAuthenticated, token, provider, loading, debtProviderData },
    dispatch,
  ] = useReducer(reducer, {
    isAuthenticated: false,
    token: undefined,
    provider: undefined,
    debtProviderData: undefined,
    loading: false,
  });

  const setAuthentication = useCallback(
    (token: string, provider: TProviders) => {
      dispatch({
        type: 'setAuthentication',
        payload: {
          token,
          provider,
          isAuthenticated: true,
        },
      });
    },
    [dispatch]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch({
        type: 'setLoading',
        payload: loading,
      });
    },
    [dispatch]
  );

  const setDebtProviderData = useCallback(
    (data: Record<string, unknown>) => {
      dispatch({
        type: 'setDebtProviderData',
        payload: {
          data,
        },
      });
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      isAuthenticated,
      token,
      provider,
      debtProviderData,
      loading,
      setLoading,
      setAuthentication,
      setDebtProviderData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, token, provider, loading, debtProviderData]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthenticationContext must be used within a AuthenticationContextProvider'
    );
  }

  return context;
};
