/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Done = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Done</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3657 0.609315C11.6781 0.921734 11.6781 1.42827 11.3657 1.74069L4.96569 8.14069C4.65327 8.4531 4.14673 8.4531 3.83431 8.14069L0.634314 4.94069C0.321895 4.62827 0.321895 4.12173 0.634314 3.80931C0.946734 3.4969 1.45327 3.4969 1.76569 3.80931L4.4 6.44363L10.2343 0.609315C10.5467 0.296895 11.0533 0.296895 11.3657 0.609315Z"
      fill="#00CAA6"
    />
  </svg>
);
