import {
  ErrorAlert,
  KeyBenefits,
  Switch,
} from '@axo/deprecated/util/ui-components';
import { useAnalytics } from '@axo/shared/services/analytics';
import { captureException } from '@sentry/browser';
import { ReactElement, RefObject, useEffect, useState } from 'react';
import { IDebtRegistryPreprProps } from '../../../data-access/DebtRegistry.transform';
import { MessageBoxBankID } from '../../MessageBoxBankID';
import { MessageBoxDebtInfo } from '../../MessageBoxDebtInfo';
import { ConsentModal } from '../components/DebtRegistryModals/ConsentModal';
import {
  ILoansModal,
  LoansModal,
} from '../components/DebtRegistryModals/LoansModal';
import { IDebtsResponse, useAssentlyClient } from '../hooks/useAssentlyClient';
import styles from './DebtRegistry.module.scss';

type Modals = 'information' | 'consent' | 'error';

export const DEBT_REGISTRY_SESSION_KEYS = {
  debtManualInput: 'axo.showDebtRegisterManualInput',
};

const DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE =
  'DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE';
export const debtRegistrySharingTimestampStorage = {
  get: () => {
    const str = sessionStorage.getItem(DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE);
    return str ? new Date(str) : undefined;
  },
  set: (token: Date) =>
    sessionStorage.setItem(
      DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE,
      token.toString()
    ),
  delete: () =>
    sessionStorage.removeItem(DEBT_REGISTRY_SHARING_TIMESTAMP_STORAGE),
};

export interface IDebtRegistry extends IDebtRegistryPreprProps {
  loansModalProps: Omit<
    ILoansModal,
    'debts' | 'totalDebt' | 'onButtonClick' | 'onCloseButtonClick' | 'currency'
  >;
  cancelButtonText: string;
  applicationID: string;
  customerJWT: string;
  manualInputText: string;
  endpoint: string;
  assentlySrc: string;
  debtInformationShowDetailsText: string;
  currency?: 'kr';
  onToggleManualMode?: (manual: boolean) => void;
  onFetchDebt?: (data: IDebtsResponse) => void;
  allowManualInput?: boolean;
  forwardRef?: RefObject<HTMLButtonElement>;
}

export function DebtRegistry({
  applicationID,
  customerJWT,
  loginText,
  loginButtonText,
  manualInputText,
  sellingPointsTitle,
  sellingPoints,
  consentHeading,
  consentText,
  cancelButtonText,
  consentButtonText,
  endpoint,
  assentlySrc,
  debtInformationText,
  debtInformationShowDetailsText,
  loansModalProps,
  onToggleManualMode,
  onFetchDebt,
  allowManualInput = true,
  forwardRef,
}: IDebtRegistry): ReactElement {
  const [showManualInput, setShowManualInput] = useState(false);
  const [hasSubmittedConsent, setHasSubmittedConsent] = useState(false);
  const [activeModal, setActiveModal] = useState<Modals | null>(null);

  useEffect(() => {
    const showManualInput = window.sessionStorage.getItem(
      DEBT_REGISTRY_SESSION_KEYS.debtManualInput
    );
    if (showManualInput) {
      handleToggleManualInput(JSON.parse(showManualInput));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { track } = useAnalytics();

  const handleLogin = () => {
    track({ event: 'Debt Register Login Initiated' });
    showAssentlyModal();
  };

  const handleShowConsentModal = (showModal: boolean) => {
    if (!showModal) return;

    setActiveModal('consent');

    track({
      event: 'Debt Register Consent Initiated',
      params: { value: showModal },
    });
  };

  const handleShowDetailsModal = () => {
    setActiveModal('information');

    track({
      event: 'Debt Register Details Viewed',
    });
  };

  const {
    isLoading,
    showAssentlyModal,
    submitConsent,
    getDebts,
    totalDebt,
    debts,
  } = useAssentlyClient({
    customerJWT,
    endpoint,
    applicationId: applicationID,
    setShowConsentModal: handleShowConsentModal,
    onError: () => setActiveModal('error'),
    onSuccess: (debtResponse) => {
      track({ event: 'Debt Register Login Completed' });
      onFetchDebt?.(debtResponse);
    },
    assentlySrc,
    onSubmitConsent: () => {
      track({ event: 'Debt Register Consent Completed' });
      setHasSubmittedConsent(true);
    },
  });

  const handleToggleManualInput = (showManualInput: boolean) => {
    track({
      event: 'Debt Register Fill Manual Toggled',
      params: { value: showManualInput },
    });
    setShowManualInput(showManualInput);
    onToggleManualMode?.(showManualInput);
    window.sessionStorage.setItem(
      DEBT_REGISTRY_SESSION_KEYS.debtManualInput,
      JSON.stringify(showManualInput)
    );
  };

  const handleConsent = (consent: boolean, assentlyToken?: string) => {
    if (consent) {
      submitConsent()
        .then(() => {
          setActiveModal(null);
          setHasSubmittedConsent(true);
          getDebts(assentlyToken);
        })
        .catch((e: any) => {
          captureException(e);
          setActiveModal('error');
        });
      return;
    }

    // show initial
    setActiveModal(null);
    getDebts(assentlyToken);
  };

  const modals: { [key in Modals]: () => ReactElement } = {
    consent: () => (
      <ConsentModal
        consentText={consentText}
        consentHeading={consentHeading}
        cancelButtonText={cancelButtonText}
        consentButtonText={consentButtonText}
        onConfirm={(consent: boolean) => handleConsent(consent)}
        onCancel={() => setActiveModal(null)}
      />
    ),
    information: () => (
      <LoansModal
        loansModalDebtEntriesTexts={loansModalProps.loansModalDebtEntriesTexts}
        loansModalTexts={loansModalProps.loansModalTexts}
        debts={debts}
        totalDebt={totalDebt}
        onButtonClick={() => setActiveModal(null)}
        onCloseButtonClick={() => setActiveModal(null)}
      />
    ),
    error: () => (
      <ErrorAlert
        title={'We have encountered an unknown error while authenticating'}
        paragraphs={['Please try again later.']}
        buttonText={'Try again'}
        onButtonClick={() => setActiveModal(null)}
      />
    ),
  };

  return (
    <div className={styles.DebtRegistry}>
      {!hasSubmittedConsent && (
        <>
          {sellingPoints && sellingPoints?.length > 0 && (
            <KeyBenefits
              ingress={sellingPointsTitle}
              benefits={sellingPoints}
              classes={{ root: styles.keyBenefits }}
            />
          )}

          <MessageBoxBankID
            forwardRef={forwardRef}
            onClick={handleLogin}
            manualInputText={manualInputText}
            loginText={loginText}
            loginButtonText={loginButtonText}
            loadingStateButton={isLoading}
          />

          {allowManualInput && (
            <Switch
              checked={showManualInput}
              onChange={handleToggleManualInput}
              classes={{ root: styles.switch }}
            >
              {manualInputText}
            </Switch>
          )}
        </>
      )}

      {hasSubmittedConsent && (
        <MessageBoxDebtInfo
          text={debtInformationText}
          onClick={handleShowDetailsModal}
          totalDebtText={loansModalProps.loansModalDebtEntriesTexts.totalDebt}
          showDetailsText={debtInformationShowDetailsText}
          totalDebt={totalDebt}
        />
      )}

      {activeModal && modals[activeModal]()}
    </div>
  );
}
