/* eslint-disable max-classes-per-file */
import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { validateFinnishPhoneNumber } from './validators';
import { validateNorwegianPhoneNumber } from './validators';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import { PhoneInput } from '@axo/deprecated/util/ui-components';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('tooltip', this, false);
    this.createLocalizableString('placeholder', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get country() {
    return this.getPropertyValue('country', this.getLocale().toUpperCase());
  }

  set country(newCountry: 'DK' | 'FI' | 'NO' | 'SE') {
    this.setPropertyValue('country', newCountry);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get placeholder(): string {
    return this.getLocalizableStringText('placeholder');
  }

  public set placeholder(val: string) {
    this.setLocalizableStringText('placeholder', val);
  }

  get locPlaceholder(): LocalizableString {
    return this.getLocalizableString('placeholder');
  }

  public get hasPrefix(): boolean {
    return this.getPropertyValue('hasPrefix');
  }

  public set hasPrefix(_hasPrefix: boolean) {
    this.setPropertyValue('hasPrefix', _hasPrefix);
  }

  public get scrollIntoViewOnRender(): boolean {
    return this.getPropertyValue('scrollIntoViewOnRender');
  }

  public set scrollIntoViewOnRender(value: boolean) {
    this.setPropertyValue('scrollIntoViewOnRender', value);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <PhoneInput
          {...props}
          i18n={this.question.country}
          hasPrefix={this.question.hasPrefix}
        />
      </div>
    );
  }

  componentDidMount() {
    if (this.question.scrollIntoViewOnRender) {
      setTimeout(() => {
        /**
         * the full question has the react prop but I could not find the correct type.
         * Another weird thing with this is that the react prop is not there when mounted - thus the setTimeout
         * Sorry to all future developers seing this.
         */
        this.question?.react?.rootRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, 50);
    }
  }
}

export function registerPhoneQuestion() {
  const questionType = 'Axo Phone Input';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'country',
        default: 'FI',
        category: 'general',
        choices: ['DK', 'FI', 'NO', 'SE'],
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'placeholder',
        category: 'general',
        type: 'string',
        serializationProperty: 'locPlaceholder',
      },
      {
        name: 'hasPrefix',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'scrollIntoViewOnRender',
        category: 'general',
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );

  FunctionFactory.Instance.register(
    'validateFinnishPhoneNumber',
    validateFinnishPhoneNumber
  );
  FunctionFactory.Instance.register(
    'validateNorwegianPhoneNumber',
    validateNorwegianPhoneNumber
  );
}
