import { Button } from '@axo/ui-core/components/Button';
import { Modal } from '@axo/ui-core/components/Modal';
import { Text } from '@axo/ui-core/components/typography';
import { IDebt } from '../../../hooks/useAssentlyClient';
import { DebtEntriesTexts, LoanUI } from './Loan/LoanUI';
import styles from './LoansModalUI.module.scss';
import { formatCurrency } from './utils';

export interface ILoansModal {
  loansModalTexts: {
    title: string;
    text: string;
    confirmText: string;
  };

  loansModalDebtEntriesTexts: DebtEntriesTexts;
  totalDebt: number;
  debts: IDebt[];
  onButtonClick: () => void;
  onCloseButtonClick: () => void;
  isOpen?: boolean;
}

export function LoansModalUI({
  loansModalTexts: modalTexts,
  loansModalDebtEntriesTexts: debtEntriesTexts,
  totalDebt,
  debts,
  onButtonClick,
  onCloseButtonClick,
  isOpen = true,
}: ILoansModal) {
  return (
    <Modal open={isOpen}>
      <Modal.Header feedbackState={'warn'}>
        <Modal.Title>{modalTexts.title}</Modal.Title>
        <Modal.Subtitle className={styles.subtitle}>
          {modalTexts.text}
        </Modal.Subtitle>
      </Modal.Header>

      <div className={styles.currentDebt}>
        <Text>{debtEntriesTexts.totalDebt}</Text>
        <Text className={styles.debtAmount}>{formatCurrency(totalDebt)}</Text>
      </div>

      <div className={styles.loansWrapper}>
        {debts.map((debt, i) => (
          <LoanUI key={'loans_' + i} labels={debtEntriesTexts} debt={debt} />
        ))}
      </div>

      <Modal.Actions>
        <Button variant="primary" onClick={onButtonClick}>
          {modalTexts.confirmText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
