import { capitalizeFirstLetter } from '../../../utils/string-utils';
import { IMonthsOptions, ISelectedDate, IYearOptions } from './DateDropdown';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const getModifiedEvent = (
  e: React.ChangeEvent<HTMLSelectElement>,
  year: number,
  month: number
) => ({
  ...e,
  target: {
    ...e.target,
    value: new Date(Date.UTC(year, month || 0, 1)).toJSON(), // set date to be january if not defined
  },
});

export function generateYearOptions(
  min: number,
  max: number | 'today',
  reverse: boolean,
  minYearSuffix: string,
  maxYearSuffix: string
) {
  const yearOptions: IYearOptions = [];
  const maxYear = max === 'today' ? new Date().getFullYear() : max;
  for (let i = min; i <= maxYear; i++) {
    let year = i.toString();

    if (i === min && minYearSuffix) {
      year = year + ' ' + minYearSuffix;
    } else if (i === max && maxYearSuffix) {
      year = year + ' ' + maxYearSuffix;
    }
    yearOptions.push({ text: year, value: i, disabled: false });
  }
  if (reverse) {
    return yearOptions.reverse();
  }
  return yearOptions;
}

export function generateMonthsOptions(i18n: string): IMonthsOptions {
  const monthsOptions = [] as IMonthsOptions;

  const baseDate = new Date(Date.UTC(1970, 1, 1));
  const numberOfMonths = 12;
  for (let index = 0; index < numberOfMonths; index++) {
    baseDate.setUTCMonth(index);

    const localMonthName = baseDate.toLocaleDateString(i18n, { month: 'long' });
    const capitalizedMonthName = capitalizeFirstLetter(localMonthName);

    monthsOptions.push({
      value: index,
      text: capitalizedMonthName,
      disabled: false,
    });
  }
  return monthsOptions;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const disableMonthsInFuture = (
  monthsOptions: IMonthsOptions,
  selectedDate: ISelectedDate,
  selectedYear: number
): { monthsOptions: IMonthsOptions; selectedDate: ISelectedDate } => {
  const currentYear = new Date().getFullYear();
  if (selectedYear === currentYear) {
    const currentMonth = new Date().getMonth();
    monthsOptions.forEach((monthOption) => {
      if (monthOption.value > currentMonth) {
        monthOption.disabled = true;
      } else {
        selectedDate.month =
          selectedDate.month && selectedDate.month > currentMonth
            ? currentMonth
            : selectedDate.month;
      }
    });
  } else {
    // reset disabled states if the value chosen is not within the current year
    monthsOptions.forEach((option) => {
      option.disabled = false;
    });
  }
  return { monthsOptions, selectedDate };
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const disableMonthsInPast = (
  monthsOptions: IMonthsOptions,
  selectedDate: ISelectedDate,
  selectedYear: number
): { monthsOptions: IMonthsOptions; selectedDate: ISelectedDate } => {
  const currentYear = new Date().getFullYear();
  if (selectedYear === currentYear) {
    const currentMonth = new Date().getMonth();
    monthsOptions.forEach((monthOption) => {
      if (monthOption.value < currentMonth) {
        monthOption.disabled = true;
      } else {
        selectedDate.month =
          selectedDate.month && selectedDate.month > currentMonth
            ? currentMonth
            : selectedDate.month;
      }
    });
  } else {
    // reset disabled states if the value chosen is not within the current year
    monthsOptions.forEach((option) => {
      option.disabled = false;
    });
  }
  return { monthsOptions, selectedDate };
};
