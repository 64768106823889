export function getLocaleAndCountry(alpha2Code: string) {
  switch (alpha2Code.toUpperCase()) {
    case 'NO':
      return 'nb-NO';
    case 'DK':
      return 'da-DK';
    case 'SE':
      return 'sv-SE';
    case 'FI':
      return 'fi-FI';
    default:
      return '';
  }
}
