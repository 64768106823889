import { ErrorWithRetry } from '../store/state';
import { dispatch } from '../store';

type IStep = () => Promise<void>;

/**
 * Takes a list of async callbacks and calls them one-by-one. If a callback
 * throws an error, a error.thrown action is dispatched to the form state store.
 * These errors have a retry method, which can be used to retry the failed step
 * and then run the remaining steps (this can be done anywhere).
 */
const runWithManualRetry = async (
  stepOrSteps: IStep | IStep[]
): Promise<void> => {
  const steps = Array.isArray(stepOrSteps) ? stepOrSteps : [stepOrSteps];

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];

    try {
      await step();
    } catch (error: unknown) {
      if (error instanceof Error) {
        const errorWithRetry = error as ErrorWithRetry;

        errorWithRetry.retry = () => {
          dispatch({ type: 'error.resolved', error: errorWithRetry });
          return runWithManualRetry(steps.slice(i));
        };
        dispatch({ type: 'error.thrown', error: errorWithRetry });

        break;
      } else {
        throw error;
      }
    }
  }
};

export default runWithManualRetry;
