import { useMemo } from 'react';
import { useInputState } from '../../../hooks/useInputState';
import { CountryCode, IWrappedSoftValidatedInput } from '../../../models';
import { Formatter } from '../../../utils/Formatter';
import FormattedInput from '../../atoms/FormattedInput';
import InputStateBox from '../InputStateBox';
import InputWrapper from '../InputWrapper';

export interface IPhoneInput
  extends IWrappedSoftValidatedInput<HTMLInputElement, string> {
  i18n: CountryCode;
  hasPrefix?: boolean;
  placeholder?: string;
}

export interface IPhoneInputI18nConfig {
  prefix: string;
  format: string;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const PhoneInput = ({
  i18n,
  hasPrefix = true,
  ...props
}: IPhoneInput) => {
  const i18nConfig = getI18nConfig(i18n);
  const formatter = useMemo(
    () =>
      Formatter.builder()
        .withPattern(i18nConfig.format)
        .removeCharactersMatching('[^0-9,+]')
        .build(),
    [i18nConfig.format]
  );

  const inputState = useInputState({
    ...props,
    pattern: '^[0-9-+\\s()]*$',
  });

  return (
    <InputWrapper inputState={inputState}>
      <InputStateBox inputState={inputState}>
        <FormattedInput
          inputState={inputState}
          prefix={hasPrefix === false ? undefined : i18nConfig.prefix}
          getFormatter={() => formatter}
          autoComplete="tel-national"
          type="tel"
        />
      </InputStateBox>
    </InputWrapper>
  );
};

function getI18nConfig(countryCode: CountryCode): IPhoneInputI18nConfig {
  switch (countryCode) {
    case 'DK':
      return {
        prefix: '+45',
        format: '** ** ** **',
      };
    case 'NO':
      return {
        prefix: '+47',
        format: '*** ** ***',
      };
    case 'SE':
      return {
        prefix: '+46',
        format: '*** *** ** **',
      };
    case 'FI':
      return {
        prefix: '+358',
        format: '*** *** **** ****',
      };
  }
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default PhoneInput;
