import type { AxoToken } from '../../../../AxoToken.types';

const getAxoToken = async (
  axoApiUrl: string,
  assentlyToken: string
): Promise<AxoToken | undefined> => {
  const url = `${axoApiUrl}/auth/provider/assently/convert`;

  const res = await fetch(url, {
    method: 'POST',
    body: assentlyToken,
  });

  if (res.ok) {
    return await res.json();
  }
  const errorMessage = `Failed to fetch axo token: ${res.status} ${res.statusText}`;
  throw new Error(errorMessage);
};

export default getAxoToken;
