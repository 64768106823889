type Observer<T> = (state: Readonly<T>) => unknown

export interface ReadonlySubject<T> {
  subscribe: (fn: Observer<T>) => void
  unsubscribe: (fn: Observer<T>) => void
  value: Readonly<T>
}

/**
 * Utility class that enables subscribing to a state. Calling
 * Subject.update will notify all subscribers about the change. In order to
 * control who updates the state, call Subject.readonly to get a read-only
 * instance of the Subject.
 */
export class Subject<T> {
  private observers: Observer<T>[] = []
  private state: T

  constructor(initialValue: T) {
    this.state = initialValue
  }

  public subscribe(fn: Observer<T>) {
    this.observers.push(fn)
  }

  public unsubscribe(fn: Observer<T>) {
    this.observers = this.observers.filter((observer) => observer !== fn)
  }

  public update(value: T) {
    this.state = value

    this.observers.forEach((observer) => observer(value))
  }

  get value(): Readonly<T> {
    return this.state
  }

  get readonly() {
    const getValue = () => this.value

    return {
      subscribe: this.subscribe.bind(this),
      unsubscribe: this.unsubscribe.bind(this),
      get value() {
        return getValue()
      },
    } satisfies ReadonlySubject<T>
  }
}
