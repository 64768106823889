import { ErrorAlert } from '@axo/deprecated/util/ui-components/components/molecules/ErrorAlert';
import labels from '../../constants/labels';
import { IDebtsResponse } from '../../hooks/useAssentlyClient';
import { ConsentModal } from './ConsentModal';
import { LoansModal } from './LoansModal';

export type DebtRegistryModalsTypes = 'information' | 'consent' | 'error';

export const modals: Record<DebtRegistryModalsTypes, DebtRegistryModalsTypes> =
  {
    information: 'information',
    consent: 'consent',
    error: 'error',
  };

type DebtRegistryModalsProps = {
  activeModal: DebtRegistryModalsTypes | undefined;
  handleOnConsent: (value: boolean) => void;
  handleModalClose: () => void;
  debtsData?: IDebtsResponse;
};
export const DebtRegistryModals = ({
  activeModal,
  handleOnConsent,
  handleModalClose,
  debtsData,
}: DebtRegistryModalsProps) => {
  switch (activeModal) {
    case modals.consent:
      return (
        <ConsentModal
          consentHeading={labels.consentHeading}
          consentText={labels.consentText}
          consentButtonText={labels.consentButtonText}
          cancelButtonText={labels.cancelButtonText}
          onConfirm={handleOnConsent}
          onCancel={handleModalClose}
        />
      );

    case modals.information:
      return (
        <LoansModal
          loansModalDebtEntriesTexts={
            labels.loansModalLabels.loansModalDebtEntriesTexts
          }
          loansModalTexts={labels.loansModalLabels.loansModalTexts}
          debts={debtsData?.debts || []}
          totalDebt={debtsData?.debtTotal ?? 0}
          onButtonClick={handleModalClose}
          onCloseButtonClick={handleModalClose}
        />
      );

    case modals.error:
      return (
        <ErrorAlert
          title={labels.errorModal.title}
          paragraphs={labels.errorModal.paragraphs}
          buttonText={labels.errorModal.buttonText}
          onButtonClick={handleModalClose}
        />
      );

    default:
      return null;
  }
};
