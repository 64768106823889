/* eslint-disable max-classes-per-file */
import { RadioGroup } from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  QuestionRadiogroupModel,
  Serializer,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionRadiogroup,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionRadiogroupModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('tooltip', this, false);
    this.createLocalizableString('softValidationError', this, false);
    this.createLocalizableString('softValidationPromptYes', this, false);
    this.createLocalizableString('softValidationPromptNo', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for values to be set by the user in Survey or Creator that are not in the model.
  get radioStyle() {
    return this.getPropertyValue('radioStyle', 'both');
  }

  set radioStyle(newRadioStyle: 'radio' | 'button' | 'both' | 'both-larger') {
    this.setPropertyValue('radioStyle', newRadioStyle);
  }

  get color() {
    return this.getPropertyValue('color', 'success');
  }

  set color(val: 'success' | 'primary') {
    this.setPropertyValue('color', val);
  }

  public get tooltip(): string {
    return this.getLocalizableStringText('tooltip');
  }

  public set tooltip(val: string) {
    this.setLocalizableStringText('tooltip', val);
  }

  get locTooltip(): LocalizableString {
    return this.getLocalizableString('tooltip');
  }

  public get softValidationError(): string {
    return this.getLocalizableStringText('softValidationError');
  }

  public set softValidationError(val: string) {
    this.setLocalizableStringText('softValidationError', val);
  }

  get locSoftValidationError(): LocalizableString {
    return this.getLocalizableString('softValidationError');
  }

  public get softValidationPromptYes(): string {
    return this.getLocalizableStringText('softValidationPromptYes');
  }

  public set softValidationPromptYes(val: string) {
    this.setLocalizableStringText('softValidationPromptYes', val);
  }

  get locSoftValidationPromptYes(): LocalizableString {
    return this.getLocalizableString('softValidationPromptYes');
  }

  public get softValidationPromptNo(): string {
    return this.getLocalizableStringText('softValidationPromptNo');
  }

  public set softValidationPromptNo(val: string) {
    this.setLocalizableStringText('softValidationPromptNo', val);
  }

  get locSoftValidationPromptNo(): LocalizableString {
    return this.getLocalizableString('softValidationPromptNo');
  }
}

export class SurveyQuestion extends SurveyQuestionRadiogroup {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <RadioGroup
          {...props}
          options={this.question.choices}
          radioStyle={this.question.radioStyle}
          color={this.question.color}
          softValidationPrompt={{
            yesText: this.question.softValidationPromptYes,
            noText: this.question.softValidationPromptNo,
          }}
        />
      </div>
    );
  }
}

export function registerRadioGroupQuestion() {
  const questionType = 'Axo Radiogroup';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'radioStyle',
        default: 'both',
        category: 'general',
        choices: ['radio', 'button', 'both'],
      },
      {
        name: 'color',
        default: 'success',
        category: 'general',
        choices: ['success', 'primary'],
      },
      {
        name: 'tooltip',
        category: 'general',
        type: 'string',
        serializationProperty: 'locTooltip',
      },
      {
        name: 'softValidationError',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationError',
      },
      {
        name: 'softValidationPromptYes',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptYes',
      },
      {
        name: 'softValidationPromptNo',
        category: 'general',
        type: 'string',
        serializationProperty: 'locSoftValidationPromptNo',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'radiogroup'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
