import { SaveAmount } from '@axo/deprecated/util/ui-components';
import { createElement } from 'react';
import { ElementFactory, QuestionHtmlModel, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';

class QuestionModel extends QuestionHtmlModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected renderElement(): JSX.Element | null {
    const roundedUpValue = Math.round(this.questionBase.value / 100) * 100;

    const isVisible = roundedUpValue >= 250 && roundedUpValue <= 10_000;

    if (!isVisible) return null;

    return (
      <SaveAmount
        title={`Du kan spare ${roundedUpValue} kr i måneden!`}
        description={
          'Ved å refinansiere din nåværende gjeld, kan du få lavere rente og dermed spare penger hver måned.'
        }
      />
    );
  }
}

export function registerSaveAmountQuestion() {
  const questionType = 'Axo Save Amount NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
