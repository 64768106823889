import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';
import { send } from '../../lib/send.ts';

export function createToken(api: IAPI) {
  return async function createToken(
    magicToken: string | null
  ): Promise<loan_application.MagicOutput> {
    return send({
      baseURL: api.url.service,
      path: `/loan-application/magic/${magicToken}`,
      token: api.token,
      method: 'GET',
    });
  };
}
