import { useInputState } from '../../../hooks/useInputState';
import { usePersistentTransformInputValue } from '../../../hooks/useTransformValue';
import { IWrappedSoftValidatedInput } from '../../../models';
import { IThousandsSeparator } from '../../../utils/Formatters';
import FormatterUtils from '../../../utils/Formatters';
import FormattedInput from '../../atoms/FormattedInput';
import InputStateBox from '../InputStateBox';
import InputWrapper from '../InputWrapper';

export interface INumberInput
  extends IWrappedSoftValidatedInput<HTMLInputElement, number> {
  thousandsSeparator?: IThousandsSeparator;
  prefix?: string;
  suffix?: string;
  max?: number;
  min?: number;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const NumberInput = ({
  thousandsSeparator = '',
  value,
  max,
  min,
  prefix,
  suffix,
  ...props
}: INumberInput) => {
  const inputState = useInputState<HTMLInputElement>({
    value: value?.toString(),
    /**
     * Since we want to do formatting and modify cursor position we
     * need to have the default type as text since number does not support accessing the cursor safely.
     * */
    type: 'text',
    inputMode: 'numeric',
    ...props,
  });

  const transformedInputState = usePersistentTransformInputValue((v, s) => {
    const value = parseInt(v);

    if (isNaN(value)) return v;
    if (s.axoProps.hasFocus) return v;

    if (max && value > max) return max.toString();
    else if (min && value < min) return min.toString();
    else return v;
  }, inputState);

  return (
    <InputWrapper inputState={transformedInputState}>
      <InputStateBox inputState={transformedInputState}>
        <FormattedInput
          inputState={transformedInputState}
          prefix={prefix}
          suffix={suffix}
          getFormatter={(value) => {
            return FormatterUtils.getNumberFormatter(value, thousandsSeparator);
          }}
        />
      </InputStateBox>
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default NumberInput;
