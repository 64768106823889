import FormatterUtils, { IThousandsSeparator } from './Formatters';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const formatFactory =
  ({ thousandsSeparator }: { thousandsSeparator: IThousandsSeparator }) =>
  (value: number, suffix?: string) =>
    FormatterUtils.formatNumber(value || 0, thousandsSeparator) +
    (suffix ? ` ${suffix}` : '');
