import { PageModel, SurveyModel, Question } from 'survey-core';
import { IChanges } from '../types/data/IChanges';

const injectData = (survey: SurveyModel, data: Partial<IChanges>) => {
  const dataKeys = Object.keys(data);
  survey.pages.forEach((page: PageModel) => {
    page.questions.forEach((question: Question) => {
      if (dataKeys.includes(question.name)) {
        question.value = data[question.name as keyof IChanges];
      }
    });
  });
};

export default injectData;
