import React, { FC, useEffect, useState } from 'react';
import { IWrappedInput } from '../../../models';
import InputWrapper from '../../molecules/InputWrapper';
import styles from './DateDropdown.module.scss';
import Dropdown from './components/Dropdown';

import { useInputState } from '../../../hooks/useInputState';
import classNames from '../../../utils/class-names';
import {
  disableMonthsInFuture,
  disableMonthsInPast,
  generateMonthsOptions,
  generateYearOptions,
  getModifiedEvent,
} from './DateDropdownUtils';

export interface IDateDropdown
  extends IWrappedInput<HTMLSelectElement, string> {
  minYear: number;
  maxYear: number | 'today';
  minYearSuffix: string;
  maxYearSuffix: string;
  latestYearAtTop: boolean;
  monthPlaceholder?: string;
  yearPlaceholder?: string;
  showOnlyYear?: boolean;
  i18n: string;
  disableDatesInFuture?: boolean;
  disableDatesInPast?: boolean;
}

export interface IOption {
  value: number;
  text: string;
  disabled?: boolean;
}

export type IMonthsOptions = Array<IOption>;
export type IYearOptions = Array<IOption>;

export type ISelectedDate = {
  year?: number;
  month?: number;
  date?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const DateDropdown: FC<IDateDropdown> = ({
  i18n,
  minYear,
  maxYear,
  latestYearAtTop,
  minYearSuffix,
  maxYearSuffix,
  disableDatesInFuture,
  disableDatesInPast,
  monthPlaceholder,
  yearPlaceholder,
  showOnlyYear = false,
  ...props
}) => {
  const inputState = useInputState({ ...props, visuallyDetachedState: true });
  const { value, onChange, disabled } = inputState.htmlProps;

  const [monthsOptions, setMonthsOptions] = useState(
    generateMonthsOptions(i18n)
  );
  const [yearOptions] = useState(
    generateYearOptions(
      minYear,
      maxYear,
      latestYearAtTop,
      minYearSuffix,
      maxYearSuffix
    )
  );
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>({
    year: undefined,
    month: undefined,
    date: undefined,
  });

  useEffect(() => {
    if (value) {
      const dateFromValue = new Date(value);
      const year = dateFromValue.getFullYear();
      const month = dateFromValue.getMonth();
      if (
        value !== selectedDate?.date ||
        year !== selectedDate.year ||
        month !== selectedDate.month
      ) {
        let selectedDateCopy: ISelectedDate = { year, month, date: value };
        let monthsOptionsCopy = [...monthsOptions];

        if (disableDatesInFuture) {
          /** We want to disable months that are in the future if the disableMonthsInFuture flag is set */
          const {
            monthsOptions: updatedMonthsOptions,
            selectedDate: updatedSelectedDate,
          } = disableMonthsInFuture(monthsOptionsCopy, selectedDateCopy, year);
          selectedDateCopy = updatedSelectedDate;
          monthsOptionsCopy = updatedMonthsOptions;
        }
        setSelectedDate(selectedDateCopy);
        setMonthsOptions(monthsOptionsCopy);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, value]);

  const handleYearChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selectedYear = parseInt(e.target.value);
    let selectedDateCopy = { ...selectedDate };
    let monthsOptionsCopy = [...monthsOptions];
    selectedDateCopy.year = selectedYear;

    if (showOnlyYear) {
      const dateNow = new Date();
      dateNow.setFullYear(selectedYear);
      if (onChange) {
        // Set date to first month if only year is to be shown
        onChange(getModifiedEvent(e, selectedYear, 0));
      }
    }
    if (disableDatesInPast) {
      /** We want to disable months that are in the Past if the disableMonthsInPast flag is set */
      const {
        monthsOptions: updatedMonthsOptions,
        selectedDate: updatedSelectedDate,
      } = disableMonthsInPast(
        monthsOptionsCopy,
        selectedDateCopy,
        selectedYear
      );
      selectedDateCopy = updatedSelectedDate;
      monthsOptionsCopy = updatedMonthsOptions;
    }
    if (disableDatesInFuture) {
      /** We want to disable months that are in the future if the disableMonthsInFuture flag is set */
      const {
        monthsOptions: updatedMonthsOptions,
        selectedDate: updatedSelectedDate,
      } = disableMonthsInFuture(
        monthsOptionsCopy,
        selectedDateCopy,
        selectedYear
      );
      selectedDateCopy = updatedSelectedDate;
      monthsOptionsCopy = updatedMonthsOptions;
    }

    if (
      onChange &&
      selectedDateCopy.month !== undefined &&
      selectedDateCopy.year !== undefined
    ) {
      onChange(
        getModifiedEvent(e, selectedDateCopy.year, selectedDateCopy.month)
      );
    }
    setSelectedDate({
      ...selectedDateCopy,
      date:
        selectedDateCopy.year && selectedDateCopy.month
          ? new Date(selectedDateCopy.year, selectedDateCopy.month).toString()
          : undefined,
    });
    setMonthsOptions(monthsOptionsCopy);
  };

  const handleMonthChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const currentSelectedMonth = parseInt(e.target.value);

    const selectedDateCopy = { ...selectedDate };
    selectedDateCopy.month = currentSelectedMonth;

    if (
      onChange &&
      selectedDateCopy?.month !== undefined &&
      selectedDateCopy.year !== undefined
    ) {
      onChange(
        getModifiedEvent(e, selectedDateCopy?.year, selectedDateCopy?.month)
      );
    }
    setSelectedDate({
      ...selectedDateCopy,
      date:
        selectedDateCopy.year && selectedDateCopy.month
          ? new Date(selectedDateCopy.year, selectedDateCopy.month).toString()
          : undefined,
    });
  };

  return (
    <InputWrapper inputState={inputState}>
      <div
        className={classNames(styles.container, showOnlyYear && styles.single)}
      >
        <Dropdown
          value={selectedDate?.year?.toString()}
          options={yearOptions}
          name="years"
          id="years"
          onChange={handleYearChange}
          disabled={disabled}
          placeholder={yearPlaceholder}
          aria-label="years"
          errors={inputState.axoProps.errors}
        />

        {!showOnlyYear && (
          <Dropdown
            value={selectedDate?.month?.toString()}
            options={monthsOptions}
            name="months"
            id="months"
            onChange={handleMonthChange}
            disabled={disabled}
            placeholder={monthPlaceholder}
            aria-label="months"
            errors={inputState.axoProps.errors}
          />
        )}
      </div>
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default DateDropdown;
