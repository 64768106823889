import { SurveyModel } from 'survey-core';
import { VALID_KEYS } from '../types/data/IChanges';

/**
 * Shows a warning in the console for invalid field names.
 */
const softVerifyFieldNames = (survey: SurveyModel) => {
  const allFields = survey.getAllQuestions().map((question) => question.name);

  const validPrefixUnion = VALID_KEYS.reduce((sum, next) => sum + '|' + next);
  const keyRegex = new RegExp(`^(${validPrefixUnion})(/.*)?`);

  const invalidFields = allFields.filter((key) => !keyRegex.test(key));

  if (invalidFields && invalidFields.length > 0) {
    console.warn(
      'Invalid field name(s):',
      invalidFields,
      `\n(Each field should be prefixed with one of:${VALID_KEYS.map(
        (prefix) => ` "${prefix}/"`
      )})`
    );
  }
};

export default softVerifyFieldNames;
