import { IChanges } from '../../types/data/IChanges';
import { runWithAutomaticAndManualRetry } from '../../utils/runWithAutomaticAndManualRetry';
import { ChangeHandler } from './ChangeHandler';

// we do not want to include fields that we need for creating customer and (CO)person to the normal changehandler
const changes = ChangeHandler({
  exclude: /^(Co)?Customer|CoPerson\//,
});

// We dont want to create a customer until we have all information ready, therefore we have this delayedChanges that includes the fields prefixed with Customer
const customerChanges = ChangeHandler({
  include: /^Customer\//,
});

const coApplicantcustomerChanges = ChangeHandler({
  include: /^CoCustomer|CoPerson\//,
});

const onChange = async (c: IChanges) => {
  changes.registerChanges(c);
  customerChanges.registerChanges(c);
  coApplicantcustomerChanges.registerChanges(c);
  await runWithAutomaticAndManualRetry(changes.debouncedSend);
};

// we want to send the customer changes on first step
const onSetupComplete = async (c: IChanges) => {
  changes.registerChanges(c);
  customerChanges.registerChanges(c);

  await Promise.all([
    runWithAutomaticAndManualRetry(changes.send),
    runWithAutomaticAndManualRetry(customerChanges.send),
  ]);
};

const onNextPage = async () => {
  await runWithAutomaticAndManualRetry(customerChanges.send);
};

const registerChange = (c: IChanges) => {
  changes.registerChanges(c);
  customerChanges.registerChanges(c);
  coApplicantcustomerChanges.registerChanges(c);
};

const onSubmit = async () => {
  await Promise.all([
    runWithAutomaticAndManualRetry(changes.send),
    runWithAutomaticAndManualRetry(customerChanges.send),
    runWithAutomaticAndManualRetry(coApplicantcustomerChanges.send),
  ]);
};

const shouldRegisterChanges = (value: boolean) => {
  changes.shouldRegisterChanges(value);
  customerChanges.shouldRegisterChanges(value);
  coApplicantcustomerChanges.shouldRegisterChanges(value);
};

export const changeHandler = {
  onChange,
  onSetupComplete,
  onNextPage,
  registerChange,
  onSubmit,
  shouldRegisterChanges,
};
