import { IDebt } from '@axo/form/feature/debt-registry';
import { ISurvey } from 'survey-core';
import { TRefinanceableDebt } from '../ExtendedLoanOverview.question';

export type TDebtRegistryDebt = {
  balance: number;
  type: string;
  refinanceable: boolean;
  interestBearingBalance: number;
  nonInterestBearingBalance: number;
};

type TGetRefinanceableDebtsForExtendedLoanOverview = {
  refinanceableDebts: TRefinanceableDebt[];
  isDebtRegistry: boolean;
  survey: ISurvey;
  debtRegistryDebts?: IDebt[];
  isEmptyDebtsVisible: boolean;
};

const getValueForRefinanceableDebt = ({
  debtRegistryDebts,
  type,
}: {
  type: string;
  debtRegistryDebts: IDebt[];
}) => {
  if (type === 'consumerLoans') {
    return debtRegistryDebts.reduce(
      (acc, { balance, type, refinanceable }) =>
        type === 'repaymentLoan' && refinanceable ? acc + balance : acc,
      0
    );
  }
  if (type === 'bearingCreditCard') {
    return debtRegistryDebts.reduce(
      (acc, { interestBearingBalance, type, refinanceable }) =>
        type === 'creditFacility' && refinanceable
          ? acc + interestBearingBalance
          : acc,
      0
    );
  }
  if (type === 'nonBearingCreditCard') {
    return debtRegistryDebts.reduce(
      (acc, { nonInterestBearingBalance, type, refinanceable }) =>
        type === 'creditFacility' && refinanceable
          ? acc + nonInterestBearingBalance
          : acc,
      0
    );
  }

  return 0;
};

export const getRefinanceableDebts = ({
  refinanceableDebts,
  survey,
  isDebtRegistry,
  debtRegistryDebts,
  isEmptyDebtsVisible,
}: TGetRefinanceableDebtsForExtendedLoanOverview) => {
  const allDebts = refinanceableDebts?.map(
    ({ valuePath, key, label, step }) => {
      const value =
        isDebtRegistry && debtRegistryDebts?.length
          ? getValueForRefinanceableDebt({ debtRegistryDebts, type: key })
          : survey.getQuestionByName(valuePath)?.value ?? 0;

      return {
        value,
        label,
        key,
        step,
        isChecked: value > 0,
      };
    }
  );

  return isEmptyDebtsVisible
    ? allDebts
    : allDebts.filter(({ value }) => value > 0);
};
