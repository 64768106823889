import { z } from 'zod';
import { AuthorizationTokenSchema } from './data/IAuthorizationToken';

export const PersistanceValuesSchema = z.object({
  JWT: AuthorizationTokenSchema,
  loanApplicationId: z.string(),
});

export type IPersistanceValues = z.infer<typeof PersistanceValuesSchema>;

export const isPersistanceValues = (
  input: unknown
): input is IPersistanceValues => {
  return PersistanceValuesSchema.safeParse(input).success;
};
