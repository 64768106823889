/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const validateNorwegianBankAccountNumber = (
  params: unknown[]
): boolean => {
  const accnum = (params[0] || '').toString();
  if (!accnum) return false;

  if (!is11Digits(accnum)) return false;

  const checksum = accnum[accnum.length - 1],
    correctChecksum = calculateBase11Checksum(accnum);

  if (checksum !== correctChecksum) return false;

  return true;
};

type NumberString = `${number}`;

const elevenDigits = /^[0-9]{11}$/;
const is11Digits = (value: string): value is NumberString =>
  elevenDigits.test(value);

const BASE_MOD11_WEIGHTS = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
const calculateBase11Checksum = (from: NumberString) => {
  const weightedSum = BASE_MOD11_WEIGHTS.reduce(
    (sum, weight, i) => sum + weight * parseInt(from[i]),
    0
  );

  const c = weightedSum % 11;

  return (c == 0 ? 0 : 11 - c).toString();
};
