import classNames from '../../../utils/class-names';
import { P } from '../../atoms/StyledElements';
import { TrustPilotLogoSVG } from '../../atoms/svg/TrustPilotLogo';
import { TrustPilotStars } from '../../atoms/TrustPilotStars/TrustPilotStars';
import styles from './trustpilot-widget.module.scss';
import React, { ReactElement } from 'react';
import { i18n } from './i18n';
import { capitalizeFirstLetter } from '@axo/shared/util/string';
import { ITrustpilotJSON } from '@axo/shared/services/trustpilot';

export interface ITrustpilotWidget {
  json: ITrustpilotJSON;
  version?:
    | 'compact'
    | 'extended'
    | 'extended-inline'
    | 'compact-score'
    | 'compact-score-square';
  dark_background?: boolean;
  redirect_link?: string;
  countryCode: 'no' | 'se' | 'fi' | 'dk';
  alignment?: 'left' | 'center' | 'right';
  mobile_alignment?: 'left' | 'center' | 'right';
  withShadow?: boolean;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TrustpilotWidget = ({
  json,
  version = 'compact',
  dark_background,
  redirect_link,
  countryCode,
  alignment = 'center',
  mobile_alignment = 'center',
  withShadow = false,
}: ITrustpilotWidget) => {
  const processRatingText = (): string => {
    if (json) {
      return json.translations
        .main!.replace('[RATING]', String(json.businessEntity.trustScore))
        .replace('[NOREVIEWS]', String(json.businessUnit.numberOfReviews.total))
        .replace('palvelussa', ' palvelussa')
        .replace('[TRUSTPILOT LOGO]', '')
        .replace(
          '[REVIEWS-URL]',
          redirect_link ? redirect_link : String(json.links.profileUrl)
        )
        .replace("'_blank'>", '\'_blank\' rel="noopener">');
      // due to "Links to cross-origin destinations are unsafe".
      // https://web.dev/external-anchors-use-rel-noopener/?utm_source=lighthouse&utm_medium=devtools
    } else {
      return '';
    }
  };

  const TrustPilotLogo = ({ small }: { small?: boolean }): JSX.Element => (
    <header
      className={classNames(
        styles.trustPilotLogoWrapper,
        small ? styles.trustPilotLogoWrapperSmall : ''
      )}
    >
      <div
        className={classNames(
          styles.trustPilotLogo,
          dark_background ? styles['trustPilotLogo--dark'] : ''
        )}
      >
        <TrustPilotLogoSVG />
      </div>
    </header>
  );

  const Stars = ({ small }: { small?: boolean }): JSX.Element => (
    <TrustPilotStars
      size={small ? 'xs' : 'md'}
      rating={json.businessEntity.trustScore}
    />
  );

  const StarsCaption = ({ small }: { small?: boolean }): JSX.Element => (
    <div
      className={classNames(
        styles.starsCaption,
        small ? styles.starsCaptionSmall : ''
      )}
    >
      {json.starsString}
    </div>
  );

  const RatingText = () => (
    <P
      className={styles.ratingText}
      dangerouslySetInnerHTML={{ __html: processRatingText() }}
    />
  );

  const TrustScore = () => (
    <P>
      Trustscore <b>{json.businessEntity.trustScore}</b> |{' '}
      <b> {json.businessEntity.numberOfReviews.total}</b>
      {' ' + i18n[countryCode]['reviewsWord']}
    </P>
  );

  // https://support.trustpilot.com/hc/en-us/articles/201748946-TrustScore-and-star-rating-explained
  const getGrade = (score: number): string => {
    if (score < 1.8) {
      return i18n[countryCode]['grades']['bad'];
    } else if (score < 2.8) {
      return i18n[countryCode]['grades']['poor'];
    } else if (score < 3.7) {
      return i18n[countryCode]['grades']['average'];
    } else if (score < 4.2) {
      return i18n[countryCode]['grades']['great'];
    } else {
      return i18n[countryCode]['grades']['excellent'];
    }
  };

  const AnchorWrapper = ({
    children,
  }: {
    children: ReactElement | ReactElement[];
  }) => {
    if (redirect_link && !!version && version === 'compact') {
      return (
        <a
          href={redirect_link}
          className={version === 'compact' ? styles.noTextDecoration : ''}
        >
          {children}
        </a>
      );
    } else {
      return <>{children}</>;
    }
  };

  /*********************** TrustPilot versions *****************************/

  const ExtendedVersion = () => (
    <div className={classNames(styles.container, styles.flexColumn)}>
      <div className={classNames(styles.container, styles.marginBottom)}>
        <div className={styles.marginRight}>
          <StarsCaption />
        </div>
        <Stars />
      </div>
      <div className={classNames(styles.container, styles.justifyCenter)}>
        <div className={styles.marginRight}>
          <RatingText />
        </div>

        <TrustPilotLogo small />
      </div>
    </div>
  );

  const ExtendedInline = () => (
    <div
      className={classNames(
        styles.container,
        styles.verticalAlignCenter,
        styles.flexWrap,
        styles['alignment-' + alignment],
        styles['mobile_alignment-' + mobile_alignment]
      )}
    >
      <StarsCaption small />

      <div className={styles.marginRight}>
        <Stars small />
      </div>
      <div className={styles.marginRight}>
        <RatingText />
      </div>

      <TrustPilotLogo small />
    </div>
  );

  const CompactVersion = () => (
    <div
      className={classNames(
        styles.container,
        styles.flexColumn,
        styles.flexStart
      )}
    >
      <div className={styles.marginBottom}>
        <TrustPilotLogo />
      </div>
      <div className={styles.marginBottom}>
        <Stars />
      </div>
      <TrustScore />
    </div>
  );

  const CompactScoreVersion = () => (
    <div
      className={classNames(
        styles.container,
        styles['compact-score-container']
      )}
    >
      <span style={{ fontWeight: 'bold' }}>
        {capitalizeFirstLetter(json.starsString)}
      </span>
      <span>
        Trustscore{' '}
        {' ' +
          json.businessEntity.trustScore +
          ' ' +
          i18n[countryCode]['outOfWord']}{' '}
        5
      </span>
      <TrustPilotLogo small />
    </div>
  );

  const CompactScoreSquareVersion = () => (
    <div className={styles['compact-score-square-container']}>
      <div className={styles.score}>
        {capitalizeFirstLetter(json.starsString)}
      </div>
      <Stars />
      <div className={styles.reviewsHolder}>
        {i18n[countryCode].basedOnWord +
          ' ' +
          String(json.businessUnit.numberOfReviews.total) +
          ' ' +
          i18n[countryCode].reviewsWord}{' '}
      </div>
      <TrustPilotLogo />
    </div>
  );
  /**********************************************************************/

  return (
    <article
      className={classNames(
        styles.trustpilot,
        !!redirect_link && version === 'compact' ? styles.withPointer : '',
        withShadow ? styles.withShadow : ''
      )}
    >
      {Object.keys(json).length > 0 && (
        <AnchorWrapper>
          <>
            {version === 'compact' && <CompactVersion />}
            {version === 'extended' && <ExtendedVersion />}
            {version === 'extended-inline' && <ExtendedInline />}
            {version === 'compact-score' && <CompactScoreVersion />}
            {version === 'compact-score-square' && (
              <CompactScoreSquareVersion />
            )}
          </>
        </AnchorWrapper>
      )}

      {Object.keys(json).length === 0 && (
        <div className={styles.fallbackLogoHolder}>
          <TrustPilotLogo />
        </div>
      )}
    </article>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default TrustpilotWidget;
