import { listenAbtestReady } from './abtest/ready';
import { emitFormReady, listenFormReady } from './form/ready';

export const eventConsumer = {
  abtest: {
    ready: listenAbtestReady,
  },
  form: {
    ready: listenFormReady,
  },
};

export const formEventProducer = {
  emit: {
    ready: emitFormReady,
  },
};
