/* eslint-disable max-classes-per-file, @nx/enforce-module-boundaries */
import React, { CSSProperties } from 'react';
import { ElementFactory, QuestionCheckboxModel, Serializer } from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionCheckbox } from 'survey-react-ui';
import getBasicQuestionProps from '../../../../../../../../deprecated/util/ui-components/src/lib/utils/getBasicQuestionProps';
import Checkbox from '../../../../../../../../deprecated/util/ui-components/src/lib/components/atoms/Checkbox/Checkbox';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends QuestionCheckboxModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
}

type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

interface IChoice {
  value: string;
  text: string;
}

export class SurveyQuestion extends SurveyQuestionCheckbox {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    const handleChangeComplete = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>,
      choice: IChoice
    ) => {
      const newValue: string[] = [].concat(this.question.renderedValue || []);
      const idx = newValue.indexOf(choice.value);
      if (event.target.checked) {
        if (idx < 0) {
          newValue.push(choice.value);
        }
      } else {
        if (idx > -1) {
          newValue.splice(idx, 1);
        }
      }
      this.question.renderedValue = newValue;
    };

    return (
      <div style={style}>
        {this.question.visibleChoices?.map((choice: IChoice, index: number) => {
          return (
            <Checkbox
              {...props}
              onChange={(e) => handleChangeComplete(index, e, choice)}
              key={choice.text}
              value={this.question.renderedValue[index] === choice.value}
            >
              {choice.text}
            </Checkbox>
          );
        })}
      </div>
    );
  }
}

export function registerCheckboxListQuestion() {
  const questionType = 'Axo Checkbox List';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'valueLabel',
        category: 'general',
        type: 'text',
        isLocalizable: true,
      },
    ],
    () => new QuestionModel(questionType, ''),
    'checkbox'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
