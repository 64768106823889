import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import { LoanDurationAlternator } from './LoanDurationAlternator';
import { getBasicQuestionProps } from '@axo/deprecated/util/ui-components';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.createLocalizableString('countryCode', this, false);
    this.createLocalizableString('thousandsSeparator', this, false);
    this.createLocalizableString('maxTotalAmount', this, false);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get countryCode() {
    return this.getPropertyValue('countryCode', ' ');
  }

  set countryCode(countryCode: 'no' | 'se' | 'dk' | 'fi') {
    this.setPropertyValue('countryCode', countryCode);
  }

  get maxLoanDuration() {
    return this.getPropertyValue('maxLoanDuration', ' ');
  }

  set maxLoanDuration(maxLoanDuration: number) {
    this.setPropertyValue('maxLoanDuration', maxLoanDuration);
  }

  get minLoanDuration() {
    return this.getPropertyValue('minLoanDuration', ' ');
  }

  set minLoanDuration(maxLoanDuration: number) {
    this.setPropertyValue('minLoanDuration', maxLoanDuration);
  }

  get defaultValue() {
    return this.getPropertyValue('defaultValue', 12);
  }

  set defaultValue(defaultValue: number) {
    this.setPropertyValue('defaultValue', defaultValue);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected mapValue(value: string) {
    return Number(value);
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    const monthly = this.question.survey.runExpression(
      'calculateMonthlyPayment({LoanApplication/AppliedAmount}, {LoanApplication/LoanDuration}, 0.06)'
    );
    const recommended = this.question.value > 12;

    const onChangeValue = (duration: number) => {
      this.question.value = duration;
    };

    return (
      <div style={style}>
        <LoanDurationAlternator
          {...props}
          country={this.question.countryCode}
          currentLoanDuration={this.question.value}
          loanAmountPerMonth={monthly}
          maxDuration={this.question.maxLoanDuration}
          minDuration={this.question.minLoanDuration}
          onDurationChange={onChangeValue}
          recommended={recommended}
        />
      </div>
    );
  }
}

export function registerAxoLoanDurationAlternatorSelector() {
  const questionType = 'Axo Loan Duration Alternator';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'defaultValue',
        category: 'general',
        type: 'number',
      },
      {
        name: 'countryCode',
        category: 'general',
        type: 'string',
      },
      {
        name: 'maxLoanDuration',
        category: 'general',
        type: 'number',
      },
      {
        name: 'minLoanDuration',
        category: 'general',
        type: 'number',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
