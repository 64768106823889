/* eslint-disable max-classes-per-file */
import { ProgressBar } from '@axo/deprecated/util/ui-components';
import React from 'react';
import {
  ElementFactory,
  QuestionTextModel,
  Serializer,
  SurveyModel,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

const getProgressBarText = (
  pageNo: number,
  countryCode: string
): { title: string; subtitle: string } | undefined => {
  // pageNo is 0 indexed in surveyJS
  switch (pageNo) {
    case 1:
      return {
        title: `63% ${countryCode === 'fi' ? 'valmiina' : 'fullført'}!`,
        subtitle:
          countryCode === 'fi'
            ? 'Jatka samaan tahtiin!'
            : 'Fortell oss litt mer om deg selv.',
      };
    // should be visible on step 2 only for NO
    case 2: {
      if (countryCode === 'no') {
        return {
          title: '81% fullført! Vi er snart i mål!',
          subtitle: 'Fortell oss litt mer om husholdningen din.',
        };
      }
      break;
    }
    //should be visible on step 4 only for FI
    case 3: {
      if (countryCode === 'fi') {
        return {
          title: '82% valmiina!',
          subtitle: 'Lähes perillä! Hakemus on hetkessä valmis.',
        };
      }
      break;
    }
    default:
      return undefined;
  }
};
class QuestionModel extends QuestionTextModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get countryCode() {
    return this.getPropertyValue('countryCode');
  }

  set countryCode(countryCode: 'no' | 'fi') {
    this.setPropertyValue('countryCode', countryCode);
  }
}

type QuestionProps = {
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);
    const pageNo = (this.question.survey as SurveyModel).currentPageNo;

    const progressBarProps = getProgressBarText(
      pageNo,
      this.question.countryCode
    );

    /** To prevent css issues, we hide the question via surveyJS if we're not supposed to render this element.
    This cant be done via the config since we have no access to the pageNumber */
    if (!progressBarProps) {
      this.question.visible = false;
    }

    return (
      <>
        {progressBarProps && (
          <ProgressBar
            title={progressBarProps.title}
            subtitle={progressBarProps.subtitle}
          />
        )}
      </>
    );
  }
}

export function registerProgressBarCardQuestion() {
  const questionType = 'Axo Progress Bar';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'countryCode',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'text'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
