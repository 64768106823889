import { Button, IButton } from '@axo/deprecated/util/ui-components';
import { IContinueButtonWrapper } from '../NavigationButtons/ContinueButtonWrapper';
import ContinueButtonWrapper from '../NavigationButtons/ContinueButtonWrapper/ContinueButtonWrapper';

type SavingsAmountButtonProps = {
  buttonContinueProps: IButton;
  continueButtonWrapperProps: Omit<IContinueButtonWrapper, 'children'>;
};

export function SavingsAmountContinueButton({
  buttonContinueProps,
  continueButtonWrapperProps,
}: SavingsAmountButtonProps) {
  return (
    <ContinueButtonWrapper {...continueButtonWrapperProps}>
      <Button {...buttonContinueProps} />
    </ContinueButtonWrapper>
  );
}
