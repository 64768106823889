import clsx from 'clsx';
import FormatterUtils, { IThousandsSeparator } from '../../../utils/Formatters';
import { Span } from '../StyledElements';
import styles from './Stat.module.scss';

export type IStat = {
  label: string;
  value: number;
  name: string;
  valuePrefix?: string;
  thousandsSeparator?: IThousandsSeparator;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Stat = ({
  value,
  label,
  valuePrefix,
  thousandsSeparator = ' ',
}: IStat) => (
  <div className={clsx('Stat', styles.container)}>
    <div className={styles.content}>
      <Span
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: label }}
      ></Span>
      <Span className={styles.stat}>{`${FormatterUtils.formatNumber(
        Math.round(value),
        thousandsSeparator
      )}${valuePrefix ?? ''}`}</Span>
    </div>
  </div>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Stat;
