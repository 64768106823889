import { SyntheticEvent, useEffect, useState } from 'react';
import { Formatter } from '../../../utils/Formatter';
import { FormattedInputCursorController } from './FormattedInputCursorController';
import withFrozenTextCursor from './withFrozenTextCursor';
import styles from './FormattedInput.module.scss';
import { IInputState } from '../../../hooks/useInputState';
import classNames from '../../../utils/class-names';

// subset of all the possibilities for autoComplete. For complete list: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
type AutoCompleteValues = 'on' | 'off' | 'tel-national';

type FormatterFactory = (value: string) => Formatter;

enum ClassNames {
  Root = 'root',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

interface IFormattedInput {
  getFormatter: FormatterFactory;
  prefix?: string;
  suffix?: string;
  autoComplete?: AutoCompleteValues;
  inputState: IInputState<HTMLInputElement>;
  type?: string;
  classes?: ClassNamesProp;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const FormattedInput = ({
  getFormatter,
  prefix = '',
  suffix = '',
  autoComplete = 'off',
  inputState,
  type,
  classes,
}: IFormattedInput) => {
  if (type) inputState.htmlProps.type = type; // For backwards compatibility. Set type using useInputState instead.
  const htmlProps = inputState?.htmlProps;
  const paddedSuffix = suffix ? ' ' + suffix : '';
  const paddedPrefix = prefix ? prefix + ' ' : '';

  const [formattedValue, setFormattedValue] = useState<string>(htmlProps.value);
  const [previousFormatter, setPreviousFormatter] = useState<Formatter | null>(
    null
  );
  const [cursorHandler] = useState<FormattedInputCursorController>(
    new FormattedInputCursorController(inputState.htmlProps.ref)
  );

  useEffect(() => {
    cursorHandler?.setSuffixLength(paddedSuffix.length);
  }, [cursorHandler, paddedSuffix]);

  const unformat = (value: string) => {
    if (typeof value !== 'string') return '';
    value = removeSuffix(value, paddedSuffix);
    if (previousFormatter) {
      value = previousFormatter.unformat(value);
    }
    return value;
  };

  const reformat = (value: string) => {
    if (typeof value !== 'string') return { unformatted: '', formatted: '' };
    value = unformat(value);
    const unformatted = value;

    const formatter = getFormatter(value);

    if (!formatter.fitsInFormat(value)) {
      value = formattedValue;
    } else {
      value = formatter.format(value) + paddedSuffix;
    }

    cursorHandler.setFormatter(formatter);
    setFormattedValue(value);
    setPreviousFormatter(formatter);

    return { unformatted, formatted: value };
  };

  // Apply formatting when the value is changed from a parent component.
  useEffect(() => {
    const { formatted } = reformat(htmlProps.value);
    setFormattedValue(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlProps.value]);

  const placeholderCheck = formattedValue || !htmlProps.placeholder;

  return (
    <>
      {paddedPrefix && placeholderCheck && (
        <span className={styles.prefix}>{paddedPrefix}</span>
      )}
      <input
        {...htmlProps}
        autoComplete={autoComplete}
        className={classNames(styles.input, classes?.root)}
        onChange={(e) => {
          const { unformatted, formatted } = reformat(e.target.value);
          withFrozenTextCursor(e.target, () => {
            /* Changing the input's value in code will reset the text cursor
                        position to the end of the input. However, by using the
                        withFrozenTextCursor method, we can make sure the cursor position
                        does not change. Since updating the value passed as a prop to the
                        <input> cannot be wrapped in a method, we change the value manually
                        here. Then when the component re-renders, the formattedValue state
                        variable will be the same as the <input>'s value, so it won't be
                        replaced. */
            e.target.value = formatted;
          });

          cursorHandler?.afterInputValueChange();

          htmlProps.onChange?.(replaceEventValue(e, unformatted));
        }}
        onKeyDown={(e) => {
          // Run afterCursorMove after the input has updated
          setTimeout(() => {
            cursorHandler?.afterCursorMove();
          }, 0);
          htmlProps.onKeyDown?.(e);
        }}
        onClick={(e) => {
          cursorHandler?.afterCursorMove();
          htmlProps.onClick?.(e);
        }}
        onBlur={(e) => {
          htmlProps.onBlur?.(replaceEventValue(e, unformat(e.target.value)));
        }}
        onFocus={(e) =>
          htmlProps.onFocus?.(replaceEventValue(e, unformat(e.target.value)))
        }
        value={formattedValue}
      />
    </>
  );
};

const removeSuffix = (value: string, suffix: string) =>
  value.replace(new RegExp(`${suffix}$`), '');

/**
 * Returns a new event object with a cloned target and the event.target.value replaced.
 * Note that this detaches the target from the DOM, so updates in the object
 * will not affect the DOM and vice versa.
 */
const replaceEventValue = <T extends SyntheticEvent<HTMLInputElement>>(
  e: T,
  newValue: string
): T => {
  if (e.target instanceof Element) {
    const n = e.target.cloneNode() as HTMLInputElement;
    n.value = newValue;

    return {
      ...e,
      target: n,
    };
  } else {
    return e;
  }
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default FormattedInput;
