import { Subject } from '@axo/shared/util/reactivity';
import { Dependencies } from '../types/Dependencies';
import { IStep } from '../types/Step';
import { State } from './state';

export const initFactory =
  ({ loan_application_api, state }: Dependencies & { state: Subject<State> }) =>
  async ({
    loanApplicationId,
    token,
    baseUrl,
  }: {
    loanApplicationId: string;
    token: string;
    baseUrl: string;
  }) => {
    const result = await loan_application_api.getChannelSnapshots(
      baseUrl,
      token,
      loanApplicationId
    );

    const currentSteps = new Set<IStep>();

    for (const snapshot of result) {
      if (!snapshot.Action) continue;

      /**
       * TODO: Temporary fix for when duplicate snapshots are created for the same step, one is created by API
       * and one by the frontend for Step1. Swap out the action name from API with the one from frontend.
       */
      if (snapshot.Action === 'Step Integration API') {
        currentSteps.add({ name: 'Step 1: About the loan' });
        continue;
      }
      currentSteps.add({ name: snapshot.Action });
    }

    state.update({
      ...state.value,
      completedSteps: [...currentSteps],
      initialized: true,
    });
  };
