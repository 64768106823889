import { Formatters } from '@axo/shared/util/string';
import { Button } from '@axo/ui-core/components/Button';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Message } from '@axo/ui-core/components/Message';
import { Text } from '@axo/ui-core/components/typography';
import { ReactElement } from 'react';

import styles from './MessageBoxDebtInfo.module.scss';

interface IMessageBoxDebtInfo {
  onClick: () => void;
  text: string;
  totalDebtText: string;
  totalDebt: number;
  showDetailsText: string;
}

export function MessageBoxDebtInfo({
  text,
  totalDebtText,
  totalDebt,
  showDetailsText,
  onClick,
}: IMessageBoxDebtInfo): ReactElement {
  const getFormattedAmount = (value: number): string => {
    return `${Formatters.formatNumber(value, ' ')} kr`;
  };

  return (
    <Message
      hasIcon={true}
      iconName={'table-solid'}
      className={styles.messageBoxDebtInfo}
      variant={'neutral'}
    >
      <Stack gap="3xs" className={styles.content}>
        <Text size={'l'}>
          <b>
            {totalDebtText} {getFormattedAmount(totalDebt)}
          </b>
        </Text>
        <Text size={'s'}>{text}</Text>
      </Stack>
      <Message.Action>
        <Button
          variant={'tertiary'}
          size={'small'}
          onClick={onClick}
          className={styles.action}
        >
          {showDetailsText}
        </Button>
      </Message.Action>
    </Message>
  );
}
