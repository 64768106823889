import { Button, Icons, MessageBox } from '@axo/deprecated/util/ui-components';
import { ReactElement, RefObject } from 'react';

import styles from './MessageBoxBankID.module.scss';

interface IMessageBoxBankID {
  onClick: () => void;
  loginText: string;
  loginButtonText: string;
  manualInputText: string;
  loadingStateButton: boolean;
  forwardRef?: RefObject<HTMLButtonElement>;
}

export function MessageBoxBankID({
  onClick,
  loginText,
  loginButtonText,
  loadingStateButton,
  forwardRef,
}: IMessageBoxBankID): ReactElement {
  return (
    <MessageBox classes={{ root: styles.messageBoxRoot }} iconVisible={false}>
      <div className={styles.row}>
        <div className={styles.column}>
          <span>{loginText}</span>
        </div>

        <div className={styles.buttonHolder}>
          <Button
            forwardRef={forwardRef}
            startIcon={
              <Icons.BankID size="sm" color={{ primary: '--white-100' }} />
            }
            onClick={onClick}
            loading={loadingStateButton}
            classes={{ root: styles.loginButton }}
          >
            {loginButtonText}
          </Button>
        </div>
      </div>
    </MessageBox>
  );
}
