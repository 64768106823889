export enum MarketCountryCode {
  Finland = 'FI',
  Denmark = 'DK',
  Norway = 'NO',
  Sweden = 'SE',
}

export interface MarketCountry {
  Code: MarketCountryCode;
}
