import { isValidIBAN } from 'ibantools';

const regex = /^FI\d{16}$/;
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const validateFinnishIBAN = (params: unknown[]): boolean => {
  const iban = params[0];

  if (!iban) return false;

  return typeof iban === 'string' && regex.test(iban) && isValidIBAN(iban);
};
