import { SavingsExample } from '@axo/deprecated/util/ui-components';
import { Formatters, currencySymbol } from '@axo/shared/util/string';
import { createElement } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../../utils/getBasicQuestionProps';
import { calculateMonthlySavedAmount } from '../SavedAmount/calculateMonthlySavedAmount';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  get placement() {
    return this.getPropertyValue('placement');
  }

  set placement(value) {
    this.setPropertyValue('placement', value);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element | null {
    const positionLD = this.question.survey.getQuestionByName(
      'Ignore/SavingsExample'
    ).value;
    if (this.question.placement !== positionLD) return <div data-is-hidden />;

    const { style } = getBasicQuestionProps(this, this.questionBase);

    const appliedAmount = this.question.survey.getQuestionByName(
      'LoanApplication/AppliedAmount'
    ).value;

    const loanDuration = this.question.survey.getQuestionByName(
      'LoanApplication/LoanDuration'
    ).value;

    const monthlySavings = calculateMonthlySavedAmount([
      appliedAmount,
      loanDuration,
    ]);

    if (!monthlySavings) return <div data-is-hidden />;

    const getFormattedSavedAmount = (value: number): string => {
      return `${Formatters.formatNumber(value, ' ')} ${currencySymbol('EUR')} `;
    };
    const isRefinanceLoan =
      this.question.survey.getQuestionByName('LoanApplication/InitialPurpose')
        ?.value === 'Refinancing loan';

    return (
      <div style={style} data-is-hidden={!isRefinanceLoan}>
        {isRefinanceLoan && (
          <SavingsExample
            title="Uudelleenrahoittamalla asiakkaamme säästävät keskimäärin:"
            savingCards={[
              {
                key: 'saveAmount',
                title: 'per kuukausi',
                value: getFormattedSavedAmount(monthlySavings),
              },
            ]}
            disclaimer="Säästöt perustuvat keskimääräiseen luottokorttikorkoon Suomessa ja vertailumme kautta tarjottuun keskimääräiseen korkoon."
          />
        )}
      </div>
    );
  }
}

export function registerSavingsExampleFIQuestion() {
  const questionType = 'Savings Example FI';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'placement',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
