import { registerDebtRegistryQuestion } from './questions/no/DebtRegistry.question';
import { registerNOTermsQuestion } from './questions/no/Terms.question';
import { registerAggregateValueQuestion } from './questions/shared/AggregateValue.question';
import { registerCoApplicantSectionHeadingQuestion } from './questions/shared/CoApplicantSectionHeader/CoApplicantSectionHeading.question';
import { registerStatQuestion } from './questions/shared/Stat/Stat.question';

import { registerAxoLoanDurationAlternatorSelector } from '../../components';
import { registerMarketingConsentFinlandQuestion } from './questions/fi/MarketingConsentFinland.question';
import { registerSaveAmountFIQuestion } from './questions/fi/SaveAmountFI.question';
import { registerFITermsQuestion } from './questions/fi/Terms.question';
import { registerLoanAmountDropdownNOQuestion } from './questions/no/LoanAmountDropdownNO.question';
import { registerLoanDurationDropdownNOQuestion } from './questions/no/LoanDurationDropdownNO.question';
import { registerMarketingConsentNorwayQuestion } from './questions/no/MarketingConsentNorway.question';
import { registerStorebrandQuestion } from './questions/no/Storebrand/Storebrand.question';
import { registerCardDetailsQuestion } from './questions/shared/CardDetails.question';
import { registerCheckboxQuestion } from './questions/shared/Checkbox.question';
import { registerCheckboxButtonQuestion } from './questions/shared/CheckboxButton/CheckboxButton.question';
import { registerCheckboxListQuestion } from './questions/shared/CheckboxList.question';
import { registerCountryDropdownQuestion } from './questions/shared/CountryDropdown/CountryDropdown.question';
import { registerDateDropdownQuestion } from './questions/shared/DateDropdown.question';
import { registerDropdownQuestion } from './questions/shared/Dropdown.question';
import { registerEmailQuestion } from './questions/shared/EmailInput/EmailInput.question';
import { registerEmptyQuestion } from './questions/shared/Empty.question';
import { registerExtendedLoanOverviewWidgetQuestion } from './questions/shared/ExtendedLoanOverview/ExtendedLoanOverview.question';
import { registerTrustpilotWidgetQuestion } from './questions/shared/FormV1Trustpilot.question';
import { registerKeyBenefitsQuestion } from './questions/shared/KeyBenefits.question';
import { registerLoanOverviewQuestion } from './questions/shared/LoanOverview/LoanOverview.question';
import { registerMessageBoxQuestion } from './questions/shared/MessageBox.question';
import { registerNavigationButtonsQuestion } from './questions/shared/NavigationButtons/NavigationButtons.question';
import { registerNumberInputQuestion } from './questions/shared/NumberInput.question';
import { registerOrganisationNumberInputQuestion } from './questions/shared/OrganisationNumberInput/OrganisationNumberInput.question';
import { registerPOPInsuranceQuestion } from './questions/shared/POPInsurance/POPInsurance.question';
import { registerPhoneQuestion } from './questions/shared/PhoneInput/PhoneInput.question';
import { registerProgressBarCardQuestion } from './questions/shared/ProgressBarCard.question';
import { registerRadioGroupQuestion } from './questions/shared/RadioGroup.question';
import { registerSSNInputQuestion } from './questions/shared/SSNInput/SSNInput.question';
import { registerSaveAmountQuestion } from './questions/shared/SaveAmount.question';
import { registerSavedAmountQuestion } from './questions/shared/SavedAmount/SavedAmount.question';
import { registerSavingsExampleFIQuestion } from './questions/shared/SavingsExample/SavingsExampleFI.question';
import { registerSavingsExampleNOQuestion } from './questions/shared/SavingsExample/SavingsExampleNO.question';
import { registerSkeletonQuestion } from './questions/shared/Skeleton/Skeleton.question';
import { registerSliderQuestion } from './questions/shared/Slider.question';
import { registerStaticValueQuestion } from './questions/shared/StaticValue.question';
import { registerStepperQuestion } from './questions/shared/Stepper.question';
import { registerTextQuestion } from './questions/shared/Text.question';
import { registerTextInputQuestion } from './questions/shared/TextInput/TextInput.question';

export const register = () => {
  registerFITermsQuestion();
  registerNOTermsQuestion();
  registerAggregateValueQuestion();
  registerStatQuestion();
  registerCoApplicantSectionHeadingQuestion();
  registerDebtRegistryQuestion();
  registerDropdownQuestion();
  registerStaticValueQuestion();
  registerTextQuestion();
  registerProgressBarCardQuestion();
  registerLoanAmountDropdownNOQuestion();
  registerLoanDurationDropdownNOQuestion();
  registerMarketingConsentFinlandQuestion();
  registerMarketingConsentNorwayQuestion();
  registerSavedAmountQuestion();
  registerCountryDropdownQuestion();
  registerSliderQuestion();
  registerNumberInputQuestion();
  registerTextInputQuestion();
  registerPhoneQuestion();
  registerSSNInputQuestion();
  registerCheckboxListQuestion();
  registerCheckboxQuestion();
  registerRadioGroupQuestion();
  registerNavigationButtonsQuestion();
  registerCheckboxButtonQuestion();
  registerDateDropdownQuestion();
  registerEmailQuestion();
  registerLoanOverviewQuestion();
  registerStepperQuestion();
  registerTrustpilotWidgetQuestion();
  registerKeyBenefitsQuestion();
  registerSavedAmountQuestion();
  registerMessageBoxQuestion();
  registerSaveAmountQuestion();
  registerSavedAmountQuestion();
  registerOrganisationNumberInputQuestion();
  registerPOPInsuranceQuestion();
  registerStorebrandQuestion();
  registerSavingsExampleNOQuestion();
  registerSavingsExampleFIQuestion();
  registerEmptyQuestion();
  registerSaveAmountFIQuestion();
  registerSkeletonQuestion();
  registerAxoLoanDurationAlternatorSelector();
  registerCardDetailsQuestion();
  registerExtendedLoanOverviewWidgetQuestion();
};
