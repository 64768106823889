import { classNames } from '@axo/shared/util/dom';
import { Fragment, ReactNode, useCallback, useMemo } from 'react';
import styles from './AuthenticationProviders.module.scss';
import { useAuthenticationProvidersContext } from './context/Authentication';
import useTrackAuthentication from './hooks/useTrackAuthentication';
import { IAuthProviderResponse } from './IAuthProviderResponse.types';
import { TProviders, TProvidersProps } from './IAuthProviders.types';
import { providersComponentMapping } from './utils/providersComponentMapping';

export type TAuthenticationProvidersProps = {
  providers: TProvidersProps;
  axoApiUrl?: string;
  separator?: ReactNode;
};

export const AuthenticationProviders = ({
  providers,
  axoApiUrl,
  separator,
}: TAuthenticationProvidersProps) => {
  const { onError, onSuccess } = useAuthenticationProvidersContext();

  const {
    onCanceled: onAuthenticationCanceledTrack,
    onError: onAuthenticationErrorTrack,
    onSuccess: onAuthenticationSuccessTrack,
    onStarted: onAuthenticationStartedTrack,
  } = useTrackAuthentication();

  const _onSuccess = useCallback(
    (data: IAuthProviderResponse) => {
      onSuccess(data);
      onAuthenticationSuccessTrack(data.provider);
    },
    [onSuccess, onAuthenticationSuccessTrack]
  );

  const _onError = useCallback(
    (data: IAuthProviderResponse) => {
      onError(data);
      onAuthenticationErrorTrack(data.provider);
    },
    [onAuthenticationErrorTrack, onError]
  );

  const _onCanceled = useCallback(
    (data: IAuthProviderResponse) => {
      onError(data);
      onAuthenticationCanceledTrack(data.provider);
    },
    [onAuthenticationCanceledTrack, onError]
  );

  const _onStart = useCallback(
    (provider: TProviders) => {
      onAuthenticationStartedTrack(provider);
    },
    [onAuthenticationStartedTrack]
  );

  const _providers = useMemo(
    () => Object.keys(providers) as TProviders[],
    [providers]
  );

  const amountOfProviders = _providers?.length;

  if (!amountOfProviders) return null;

  return _providers.map((provider, idx) => {
    const ProviderComponent = providersComponentMapping[provider];
    const providerProps = providers?.[provider] || {};

    const isSeparatorNeeded = separator && idx < amountOfProviders - 1;

    if (!ProviderComponent) return null;

    return (
      <Fragment key={provider + idx}>
        <ProviderComponent
          className={classNames(styles.provider, providerProps?.className)}
          key={provider + idx}
          onStart={_onStart}
          onSuccess={_onSuccess}
          onError={_onError}
          onCanceled={_onCanceled}
          axoApiUrl={axoApiUrl}
          {...providerProps}
        />
        {isSeparatorNeeded && separator}
      </Fragment>
    );
  });
};
