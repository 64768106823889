import { PageModel } from 'survey-core';
import { IPageStructure } from '../types/survey/IPageStructure';
import { ISurveyConfig } from '../types/survey/ISurveyConfig';

/**
 * Searches recursively for a panel with a specific name and replaces the panel's elements with a new set of elements.
 * Adds a suffix to each element in structure to make sure they have unique names.
 */
function findAndReplacePanelElements({
  panel,
  panelName,
  newElements,
}: {
  panel: IPageStructure;
  panelName: string;
  newElements: IPageStructure[];
}) {
  return panel.elements?.map((element) => {
    if (element.name.split(' ').includes(panelName)) {
      element.elements = newElements;
    } else {
      findAndReplacePanelElements({
        panel: element,
        panelName,
        newElements,
      });
    }

    return element;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addIds(panel?: any, counter = 0) {
  if (!panel) return;
  if (panel?.type === 'panel') {
    panel.name += ` id-${counter}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  panel.elements?.forEach((element: any) => addIds(element, ++counter));
}

export function addPageStructure(
  config: ISurveyConfig,
  pagesStructure: IPageStructure[]
) {
  const pagesWithStructure = config.pages.map((page, index) => {
    const pageNumber = index + 1;
    const structureForCurrentPage = pagesStructure.find((pageStructure) =>
      pageStructure?.includeOnPages?.includes(pageNumber)
    );

    if (!structureForCurrentPage) return page;

    // Creates a clone of the pageStructure object for each page, so each page get's it's own structure object.
    const clonedPageStructure = JSON.parse(
      JSON.stringify(structureForCurrentPage)
    );

    const elementsWithStructure = findAndReplacePanelElements({
      panel: clonedPageStructure,
      panelName: clonedPageStructure.injectAtPanelName,
      newElements: page.elements,
    });

    const pageWithStructure = {
      ...page,
      elements: elementsWithStructure,
    } as PageModel;

    addIds(pageWithStructure);

    return pageWithStructure;
  });

  config.pages = pagesWithStructure;

  return config;
}
