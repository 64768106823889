import { FC, useEffect, useState } from 'react';
import InputWrapper from '../InputWrapper';
import { ErrorModel, IWrappedSoftValidatedInput } from '../../../models';
import EmailSuggestionsList from './EmailSuggestionsList/EmailSuggestionsList';
import * as CONSTANTS from '../../../variables/norway';
import { useInputState } from '../../../hooks/useInputState';
import styles from './EmailInput.module.scss';
import {
  IBackendValidationArgs,
  useBackendEmailValidation,
} from './useBackendEmailValidation';
import EmailInputStateBox from './EmailInputStateBox/EmailInputStateBox';

export type IEmailInput = IWrappedSoftValidatedInput<
  HTMLInputElement,
  string
> & {
  showSuggestions?: boolean;
  id?: string;
  autoComplete?: 'off' | 'email';
  onSuggestionClick?: (suggestion: string) => void;
  onShowingSuggestions?: (showingSuggestions: boolean) => void;
  emailDomains?: string[];
  backendValidation?: IBackendValidationArgs & { suggestionPrefix: string };
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const EmailInput: FC<IEmailInput> = ({
  showSuggestions = false,
  onSuggestionClick,
  onShowingSuggestions = (showingSuggestions) => showingSuggestions,
  emailDomains,
  autoComplete = 'email',
  backendValidation: backendValidationArgs,
  ...props
}) => {
  const [shouldShowSuggestions, setShouldShowSuggestions] =
    useState<boolean>(showSuggestions);

  const backendValidation = useBackendEmailValidation(backendValidationArgs);

  const inputState = useInputState({
    ...props,
    errors: [props.errors, backendValidation.error]
      .flat()
      .filter((v) => v) as ErrorModel[],
    softValidationError:
      props.softValidationError || backendValidation.softError,
    async onBlur(e) {
      props.onBlur?.(e);
      await backendValidation.validate(e.target.value);
    },
    onFocus(e) {
      setShouldShowSuggestions(true);
      props.onFocus?.(e);
    },
  });

  const onApplySuggestion = (suggestion: string) => {
    inputState.setValue(suggestion);
    onSuggestionClick?.(suggestion);
    setShouldShowSuggestions(false);
  };

  useEffect(() => {
    const emailValueDomain = '@' + inputState.htmlProps.value.split('@')[1];

    if (
      emailDomains &&
      emailDomains?.filter((domain) => domain.includes(emailValueDomain))
        .length > 0 &&
      !emailDomains?.includes(emailValueDomain)
    ) {
      setShouldShowSuggestions(true);
    }
  }, [emailDomains, inputState.htmlProps.value]);

  return (
    <InputWrapper inputState={inputState} classes={{ label: styles.label }}>
      <EmailInputStateBox
        inputState={inputState}
        isLoading={backendValidation.isLoading}
        suggestion={backendValidation.validation?.Suggestion.Host}
        suggestionPrefix={
          backendValidationArgs?.suggestionPrefix ?? 'Did you mean'
        }
        onClickSuggestion={() => {
          const suggestion = `${backendValidation.validation?.Input.Local}@${backendValidation.validation?.Suggestion.Host}`;
          onApplySuggestion(suggestion);
          void backendValidation.validate(suggestion);
        }}
      >
        <input
          {...inputState.htmlProps}
          type="email"
          autoComplete={autoComplete}
          defaultValue={props.defaultValue}
        />
      </EmailInputStateBox>
      {showSuggestions &&
        emailDomains &&
        shouldShowSuggestions &&
        inputState.htmlProps.value?.includes('@') &&
        !new RegExp(CONSTANTS.EMAIL_REGEX).test(
          inputState.htmlProps.value.split('@')[0]
        ) && (
          <EmailSuggestionsList
            suggestionsList={emailDomains}
            value={inputState.htmlProps.value}
            onSuggestionClick={onApplySuggestion}
            onShowingSuggestions={(_showSuggestions) => {
              onShowingSuggestions?.(_showSuggestions);
              setShouldShowSuggestions(_showSuggestions);
            }}
          />
        )}
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default EmailInput;
