import { ReactNode } from 'react';
import { IInputState } from '../../../../hooks/useInputState';
import classNames from '../../../../utils/class-names';
import { Icons, LoadingSpinner } from '../../..';
import InputInfoBox from '../../InputInfoBox';
import styles from './EmailInputStateBox.module.scss';

export interface IInputStateBox {
  children: ReactNode;
  icon?: ReactNode;
  inputState: IInputState;
  isLoading: boolean;
  suggestion?: string;
  suggestionPrefix: string;
  onClickSuggestion: React.MouseEventHandler<HTMLElement>;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputStateBox = ({
  inputState,
  children,
  isLoading,
  suggestion,
  suggestionPrefix,
  onClickSuggestion,
}: IInputStateBox) => {
  const { hasFocus, primaryState } = inputState.axoProps;
  const { id } = inputState.htmlProps;

  const Icon = () => {
    if (isLoading) return <LoadingSpinner colorVariable={'--dark-200'} />;
    if (suggestion) return null;
    if (primaryState === 'valid') return <Icons.CircleCheckMarkValid />;
    return <></>;
  };

  const inputInfoBoxProps = (() => {
    if (suggestion || isLoading) return;
    if (inputState.axoProps.primaryState === 'soft-invalid') {
      return {
        message: inputState.axoProps.softValidationError,
        prompt: inputState.axoProps.softValidationPrompt,
        icon: <Icons.CirclePromptBlack size="sm" />,
        backgroundColor: '--primary-200' as const,
        onClickYes: (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault(); // I'm not 100% sure why, but without this, the field seems to get focus when ´yes´ is clicked
          inputState.dismissSoftValidation();
        },
        onClickNo: () => {
          const el = document.getElementById(inputState.htmlProps.id);
          el?.focus();
        },
      };
    }
    if (inputState.axoProps.primaryState === 'undetermined-with-tooltip') {
      return {
        message: inputState.axoProps.tooltip,

        icon: <Icons.CircleInfoBlack size="sm" />,
        backgroundColor: '--primary-200' as const,
      };
    }
    if (inputState.axoProps.primaryState === 'invalid') {
      return {
        message: inputState.axoProps.errors.map((e) => e.message).join(', '),
        icon: <Icons.CircleWarningWhite size="sm" />,
        backgroundColor: '--secondary-700' as const,
        color: '--white-100' as const,
      };
    }
  })();

  return (
    <>
      <InputInfoBox {...inputInfoBoxProps}>
        <div
          key={id}
          className={classNames(
            styles.root,
            suggestion ? styles.softSuggestion : styles[primaryState],
            hasFocus ? styles.focused : styles.unfocused
          )}
        >
          {children}
          <div className={styles.iconWrapper}>
            <Icon />
          </div>
        </div>
      </InputInfoBox>
      {suggestion && (
        <div
          onClick={(e) => {
            e.preventDefault();
            onClickSuggestion(e);
          }}
          className={styles.suggestionWrapper}
        >
          {suggestionPrefix}{' '}
          <span className={styles.suggestion}>{suggestion}?</span>
        </div>
      )}
    </>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default InputStateBox;
