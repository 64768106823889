import { loan_application_api } from '@axo/shared/data-access/api';
import { trackingFactory } from './lib/tracking';

export const tracking = trackingFactory({
  loan_application_api: loan_application_api,
});

export * as ga from './lib/tracking/ga';

export { createChannelSnapshot } from './lib/tracking/createChannelSnapshot';

export { setStartedAtUrlIfNotSet } from './lib/tracking/startedAtUrl';
