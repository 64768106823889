import { Checkbox, Icons } from '@axo/deprecated/util/ui-components';
import { FormFlagSet } from '@axo/form/configs';
import { useFlags } from '@axo/shared/services/feature-flags';
import { ReactElement } from 'react';
import sanitizeHtml from 'sanitize-html';

import styles from './POPInsurance.module.scss';

export type IPOPInsurance = {
  isChecked: boolean;
  title: string;
  description: string;
  checkboxText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function POPInsurance({
  isChecked,
  title,
  description,
  checkboxText,
  onChange,
}: IPOPInsurance): ReactElement | null {
  // TODO: Remove when FF is deleted
  const { POPInsuranceEnabled: popInsuranceEnabled } = useFlags(FormFlagSet);

  if (!popInsuranceEnabled) return null;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <Icons.PopVakuutus size="full" />
      </div>

      <div>
        <h2>{title}</h2>
        {/* Wanted to pass a String | Array and iterate through it, but SurveyJS doesn't like that solution */}
        <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />
        <Checkbox
          onChange={onChange}
          value={isChecked}
          classes={{ root: styles.checkbox }}
        >
          <span>{checkboxText}</span>
        </Checkbox>
      </div>
    </div>
  );
}
