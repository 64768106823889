export const getStartedAtUrl = () => {
  if (typeof window === 'undefined') return;

  let StartedAtUrl = sessionStorage.getItem('StartedAtUrl');
  if (!StartedAtUrl) {
    StartedAtUrl = window.location.pathname;
    sessionStorage.setItem('StartedAtUrl', StartedAtUrl);
  }

  return StartedAtUrl;
};

export const setStartedAtUrlIfNotSet = () => {
  getStartedAtUrl();
};
