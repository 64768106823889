import { FC, ReactNode } from 'react';
import styles from './InformationContainer.module.scss';
import { classNames } from '@axo/shared/util/dom';

enum ClassNames {
  Root = 'root',
}
type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IInformationContainer {
  classes?: ClassNamesProp;
  children?: ReactNode;
}

export const InformationContainer: FC<IInformationContainer> = ({
  classes,
  children,
}) => {
  return (
    <div className={classNames(styles.informationContainer, classes?.root)}>
      {children && <div>{children}</div>}
    </div>
  );
};
