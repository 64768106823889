import styles from './separator.module.scss';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Separator = () => {
  return <hr className={styles.stylizedSeparator} />;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Separator;
