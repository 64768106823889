import { useEffect, useMemo, useRef } from 'react';
import { IAuthProviderConfig } from '../../../../IAuthProviderConfig.types';
import { TProviders } from '../../../../IAuthProviders.types';
import { getSearchParameter } from '../../../../utils/getSearchParameter';

export const useUniifyProvider = ({
  axoApiUrl,
  applicationID,
  token,
  onError,
  onSuccess,
}: Omit<IAuthProviderConfig, 'serviceSourceUrl'> & {
  applicationID: string | null | undefined;
  token: string | null | undefined;
}) => {
  const hasOnSuccessBeenCalled = useRef(false);

  useEffect(() => {
    let ignore = false;
    if (hasOnSuccessBeenCalled.current || ignore) return;

    if (hasOnSuccessBeenCalled.current) return;
    const isESkatLogin =
      getSearchParameter('provider') === TProviders.ESkat.toLowerCase();

    if (!isESkatLogin) return;

    const state = getSearchParameter('state');

    if (state === 'failed') return;

    onSuccess({ provider: TProviders.ESkat });

    hasOnSuccessBeenCalled.current = true;

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectUri = useMemo(() => {
    const url = new URL(window.location.href);

    if (!url.searchParams.has('provider'))
      url.searchParams.set('provider', 'eskat');

    return url.href;
  }, []);

  const authenticate = async () => {
    if (!applicationID || !token)
      throw new Error(
        'Uniify authentication failed: missing form applicationID or JWT'
      );

    try {
      const uniifyResponse = await fetch(`${axoApiUrl}/uniify/create-case`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          LoanApplicationID: applicationID,
          ReturnURL: redirectUri,
          FailedReturnURL: redirectUri,
        }),
      });

      const { url: uniifyUri } = await uniifyResponse.json();

      // Redirect user to Login flow
      window.location.href = uniifyUri;
    } catch (error) {
      if (error instanceof Error) {
        onError({ provider: TProviders.ESkat, errorMessage: error.message });
      }
    }
  };

  return { authenticate };
};
