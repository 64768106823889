const labels = {
  loginText: 'Del din usikrede gjeld fra Gjeldsregisteret',
  loginDescription: 'Du må logge inn med enten BankId eller Vipps',
  loginSeparatorText: 'eller',
  loginButtonText: 'Logg inn med BankID',
  sellingPointsTitle:
    'Det er mange fordeler ved å hente oversikten over din eksisterende gjeld direkte fra gjeldsregistret.',
  sellingPoints: [
    'Du sparer deg selv og oss for tid og arbeid og slipper å hente underlag manuelt.',
    'Søknaden blir som regel ofte behandlet raskere og bedre da all gjeldsinfo fremkommer automatisk.',
  ],
  consentHeading: 'Godkjenn deling av usikret gjeld',
  consentText:
    'Delingsperioden er 12 måneder. Du kan når som helst trekke tilbake ditt samtykke på: <a href="https://gjeldregistreteret.com">Gjeldregistreteret.com</a>.<br><br>Gjeldsopplysningene er levert av Gjeldsregisteret AS. Oversikt over finansinstitusjonene som leverer gjeldsopplysninger til Gjeldsregisteret AS finner du på <a href="https://gjeldregistreteret.com">Gjeldregistreteret.com</a>.',
  consentCheckboxText: 'Godkjenn deling av usikret gjeld',
  consentButtonText: 'Gå videre',
  cancelButtonText: 'Avbryt',
  debtInformationText:
    'Dine gjeldsopplysninger har blitt delt med Axo Finans og du kan se detaljene under.',
  manualInputText: 'Fyll ut manuelt',
  debtInformationShowDetailsText: 'Vis detaljer',
  loansModalLabels: {
    loansModalTexts: {
      title: 'Dine gjeldsopplysninger',
      text: 'Gjeldinformationen er delt / Usikret gjeld registeret i Gjeldsregisteret 14.06.2022, klokken 16:02.',
      confirmText: 'Lukk',
    },
    loansModalDebtEntriesTexts: {
      totalDebt: 'Din totale gjeld er:',
      interestBearingDebt: 'Rentebærende saldo',
      nonInterestBearingDebt: 'Ikke rentebærende saldo',
      nominalInterest: 'Pålydende rente',
      creditLimit: 'Kredittgrense',
      coBorrower: 'Medlåntaker',
      installmentChargePeriod: 'Belastes',
      installmentCharges: 'Gebyr (kr)',
      processedTime: 'Oppdatert hos kreditor',
      receivedTime: 'Oppdatert i Gjeldsregisteret',
      creditType: 'Kredittype',
      originalBalance: 'Opprinnelig balanse',
      remainingBalance: 'Gjenstående balanse',
    },
  },
  errorModal: {
    title: 'Vi har støtt på en ukjent feil under autentiseringen',
    paragraphs: ['Vennligst prøv igjen senere.'],
    buttonText: 'Prøv igjen',
  },
};

export default labels;
