import { IMarketingConsentMarkets } from './IMarketingConsentMarkets';
import { MarketingConsentServicesDK } from './services/MarketingConsentServicesDK';
import { marketingConsentServicesFI } from './services/MarketingConsentServicesFI';
import { marketingConsentServicesNO } from './services/MarketingConsentServicesNO';
import { marketingConsentServicesSE } from './services/MarketingConsentServicesSE';

export const getMarketingConsentServices = (
  country: IMarketingConsentMarkets
) => {
  switch (country) {
    case 'no':
      return [...marketingConsentServicesNO];
    case 'se':
      return [...marketingConsentServicesSE];
    case 'fi':
      return [...marketingConsentServicesFI];
    case 'dk':
      return [...MarketingConsentServicesDK];
    default:
      return [];
  }
};
