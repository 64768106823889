const getDebtRegistryLabels = (informationModalTextWithTimestamp: string) => ({
  consentButtonText: 'Gå videre',
  consentCheckboxText: 'Godkjenn deling av usikret gjeld',
  consentHeading: 'Godkjenn deling av usikret gjeld',
  cancelButtonText: 'Avbryt',
  consentServiceTermsText:
    'Gjeldsopplysningene er levert av Gjeldsregisteret AS. Oversikt over finansinstitusjonene som leverer gjeldsopplysninger til Gjeldsregisteret AS finner du på <a href="https://www.gjeldsregisteret.com" target="_blank">gjeldsregisteret.com</a>.',
  consentText:
    'Delingsperioden er 12 måneder. Du kan når som helst trekke tilbake ditt samtykke på: <a href="https://gjeldregistreteret.com">Gjeldregistreteret.com</a>.<br><br>Gjeldsopplysningene er levert av Gjeldsregisteret AS. Oversikt over finansinstitusjonene som leverer gjeldsopplysninger til Gjeldsregisteret AS finner du på <a href="https://gjeldregistreteret.com">Gjeldregistreteret.com</a>.',
  debtInformationShowDetailsText: 'Vis detaljer',
  debtInformationText:
    'Dine gjeldsopplysninger har blitt delt med Axo Finans og du kan se detaljene under.',
  loginButtonText: 'Logg inn med BankID',
  loginText: 'Logg inn med BankID for å dele din usikrede gjeld.',
  manualInputText: 'Fyll ut manuelt',
  sellingPoints: [
    'Du øker sannsynligheten for å få et lån',
    'Du slipper å fylle ut informasjonen selv',
    'Du får full oversikt over eksisterende gjeld',
    'Du kan enklere se hvor mye penger du sparer',
  ],
  sellingPointsTitle:
    'Det er mange fordeler ved å dele din eksisterende gjeld direkte fra Gjeldsregisteret.',
  loansModalProps: {
    loansModalTexts: {
      title: 'Din gjeldsregisterinformasjon',
      text: informationModalTextWithTimestamp,
      confirmText: 'Lukk',
    },
    loansModalDebtEntriesTexts: {
      totalDebt: 'Din gjeld er: ',
      originalBalance: 'Opprinnelig balanse',
      remainingBalance: 'Gjenstående balanse',
      interestBearingDebt: 'Rentebærende saldo',
      nonInterestBearingDebt: 'Ikke rentebærende saldo',
      nominalInterest: 'Pålydende rente',
      creditType: 'Kredittype',
      creditLimit: 'Kredittgrense',
      coBorrower: 'Medlåntaker',
      installmentChargePeriod: 'Belastes',
      installmentCharges: 'Gebyr (kr)',
      processedTime: 'Oppdatert hos kreditor',
      receivedTime: 'Oppdatert i Gjeldsregisteret',
    },
  },
});

export default getDebtRegistryLabels;
