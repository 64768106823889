import { configs } from '.';

export const MARKETS = ['no', 'fi'] as const,
  VARIANTS = ['pre', 'main'] as const;

export type Market = (typeof MARKETS)[number];
export type Variant = (typeof VARIANTS)[number];

export const isValidMarketCountry = (v: unknown): v is Market =>
  MARKETS.includes(v as Market);

export const isValidFormVariant = (v: unknown): v is Variant =>
  VARIANTS.includes(v as Variant);

interface IgetConfig {
  market: Market;
  variant: Variant;
}
export const getConfig = ({ market, variant }: IgetConfig) => {
  // Clone the data in order to avoid global mutations on the json object
  return structuredClone({
    json: configs[market][variant],
    locale: market,
    market,
    variant,
  });
};
