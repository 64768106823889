const withFrozenTextCursor = (element: HTMLInputElement, fn: () => void) => {
  const { selectionStart, selectionEnd } = element;
  fn();
  element.selectionStart = selectionStart;
  element.selectionEnd = selectionEnd;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default withFrozenTextCursor;
