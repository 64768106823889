import { MarketCountryCode } from '@axo/shared/types';
import { ReactNode } from 'react';

export type TProvidersProps = {
  Criipto?: {
    country: MarketCountryCode;
    label?: string;
    className?: string;
  };
  Vipps?: {
    label?: string;
    className?: string;
  };
  BankID?: {
    label?: string;
    serviceSourceUrl?: string;
    className?: string;
  };
  ESkat?: {
    children: ReactNode;
    applicationID?: string;
    token?: string;
    hasIcon?: boolean;
    className?: string;
  };
};

export enum TProviders {
  Criipto = 'Criipto',
  BankID = 'BankID',
  ESkat = 'ESkat',
  Vipps = 'Vipps',
}
