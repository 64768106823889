/* eslint-disable max-classes-per-file */
import { Slider } from '@axo/deprecated/util/ui-components';
import React, { CSSProperties } from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from 'survey-react-ui';

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('valueLabel', this, true);
    this.createLocalizableString('valueSuffix', this, true);
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instanciated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  // Add getters and setters for any value to be set by the user in Survey or Creator.
  get min() {
    return this.getPropertyValue('min', 1);
  }

  set min(newMin) {
    this.setPropertyValue('min', newMin);
  }

  get minSuffix() {
    return this.getPropertyValue('minSuffix');
  }

  set minSuffix(v: string) {
    this.setPropertyValue('minSuffix', v);
  }

  get max() {
    return this.getPropertyValue('max', 20);
  }

  set max(newMax) {
    this.setPropertyValue('max', newMax);
  }

  get maxSuffix() {
    return this.getPropertyValue('maxSuffix');
  }

  set maxSuffix(v: string) {
    this.setPropertyValue('maxSuffix', v);
  }

  get step() {
    return this.getPropertyValue('step', 5);
  }

  set step(newStep) {
    this.setPropertyValue('step', newStep);
  }

  get valueSuffixIsAnExpression() {
    return this.getPropertyValue('valueSuffixIsAnExpression');
  }

  set valueSuffixIsAnExpression(v: boolean) {
    this.setPropertyValue('valueSuffixIsAnExpression', v);
  }

  public get valueLabel(): string {
    return this.getLocalizableStringText('valueLabel');
  }

  public set valueLabel(val: string) {
    this.setLocalizableStringText('valueLabel', val);
  }

  get locValueLabel(): LocalizableString {
    return this.getLocalizableString('valueLabel');
  }

  public get valueSuffix(): string {
    return this.getLocalizableStringText('valueSuffix');
  }

  public set valueSuffix(val: string) {
    this.setLocalizableStringText('valueSuffix', val);
  }

  get locValueSuffix(): LocalizableString {
    return this.getLocalizableString('valueSuffix');
  }
}

type QuestionProps = {
  creator?: unknown;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  handleChangeComplete = (data: unknown) => {
    this.question.value = Number(data);
    const errors = this.props.question.errors.map(
      (e: { text: string }) => e.text
    );
    this.setState({ errors });
  };

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderElement(): JSX.Element {
    const suffix = this.props.question.valueSuffixIsAnExpression
      ? this.question.survey.runExpression(this.props.question.valueSuffix)
      : this.props.question.valueSuffix;

    return (
      <div style={this.style}>
        <Slider
          min={Number(this.props.question.min)}
          minSuffix={this.props.question.minSuffix}
          max={Number(this.props.question.max)}
          maxSuffix={this.props.question.maxSuffix}
          step={Number(this.props.question.step)}
          value={Number(this.props.question.value)}
          label={this.props.question.valueLabel}
          valueSuffix={suffix}
          onChange={this.handleChangeComplete}
        />
      </div>
    );
  }
}

export function registerSliderQuestion() {
  const questionType = 'Axo Slider';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'value',
        default: 15,
        category: 'general',
        type: 'number',
      },
      {
        name: 'min',
        default: 1,
        category: 'general',
        type: 'number',
      },
      {
        name: 'minSuffix',
        type: 'text',
      },
      {
        name: 'max',
        default: 20,
        category: 'general',
        type: 'number',
        nextToProperty: '*min',
      },
      {
        name: 'maxSuffix',
        type: 'text',
      },
      {
        name: 'step',
        default: 5,
        category: 'general',
        type: 'number',
      },
      {
        name: 'valueLabel',
        default: 'Payback time',
        category: 'general',
        type: 'text',
        serializationProperty: 'locValueLabel',
      },
      {
        name: 'valueSuffix',
        default: 'Years',
        category: 'general',
        type: 'text',
        serializationProperty: 'locValueSuffix',
      },
      {
        name: 'valueSuffixIsAnExpression',
        default: false,
        type: 'boolean',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
