import { FC } from 'react';
import InputWrapper from '../InputWrapper';
import { IWrappedSoftValidatedInput } from '../../../models';
import InputStateBox from '../InputStateBox';
import { useInputState } from '../../../hooks/useInputState';
import { useTransformInputValue } from '../../../hooks/useTransformValue';
import FormattedInput from '../../atoms/FormattedInput';
import { Formatter } from '../../../utils/Formatter';
import FormatterUtils from '../../../utils/Formatters';

export type ITextInput = IWrappedSoftValidatedInput<
  HTMLInputElement,
  string
> & {
  upperCaseInput?: boolean;
  format?: string;
  type?: string;
  inputMode?: 'text' | 'numeric';
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const TextInput: FC<ITextInput> = ({
  upperCaseInput,
  format,
  type = 'text',
  ...props
}) => {
  const inputState = useInputState({
    type,
    autoComplete: 'off',
    ...props,
  });

  const transformedInputState = useTransformInputValue(
    (v) => (upperCaseInput ? v?.toUpperCase() : v),
    inputState
  );

  return (
    <InputWrapper inputState={transformedInputState}>
      <InputStateBox inputState={transformedInputState}>
        <FormattedInput
          getFormatter={() =>
            format !== undefined
              ? new Formatter(format)
              : FormatterUtils.unformatted()
          }
          inputState={transformedInputState}
        ></FormattedInput>
      </InputStateBox>
    </InputWrapper>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default TextInput;
