import { dispatch } from '../store/store';
import { IPersistanceValues } from '../types/IPersistanceValues';
import { IChanges, IDebtChanges } from '../types/data/IChanges';
import { IDebt } from '../types/data/IDebt';
import { IPerson } from '../types/data/IPerson';
import client from './client';

const reloadState = async ({
  JWT,
  loanApplicationId,
}: IPersistanceValues): Promise<IChanges> => {
  const loanApplication = await client.getLoanApplication(loanApplicationId, {
    Authorization: JWT,
  });

  // we should redirect the user to the front page if status is above or 160
  if (loanApplication?.Status && parseInt(loanApplication.Status) >= 160) {
    window.location.href = window.location.origin;
  }

  const customer = await client.getCustomer(loanApplication.CustomerID, {
    Authorization: JWT,
  });
  dispatch({
    type: 'customer.created',
    customer: {
      JWT,
      ...customer,
    },
  });

  const debt = await client.getAllDebtObjects(loanApplication.ID, {
    Authorization: JWT,
  });

  let person: IPerson | undefined = undefined;

  if (loanApplication.PersonID) {
    person = await client.getPerson(loanApplication.PersonID, {
      Authorization: JWT,
    });

    dispatch({
      type: 'field-name-to-backend-id-map.add',
      backendID: person.ID,
      fieldName: 'Person',
    });
  }

  const children = await client.getChildren({
    Authorization: JWT,
    LoanApplicationID: loanApplication.ID,
  });

  children.forEach((child) => {
    dispatch({
      type: 'field-name-to-backend-id-map.add',
      backendID: child.ID,
      fieldName: `Child/${child.ID}`,
    });
  });

  dispatch({
    type: 'loan-application.created',
    loanApplication: loanApplication,
  });
  return {
    ...prefixAttributes(
      customer as unknown as Record<string, unknown>,
      'Customer/'
    ),
    ...prefixAttributes(
      loanApplication as unknown as Record<string, unknown>,
      'LoanApplication/'
    ),
    ...(person
      ? prefixAttributes(
          person as unknown as Record<string, unknown>,
          'Person/'
        )
      : {}),
    AmountOfChildren: children.length,

    ...convertToDebtChanges(debt),
  } as IChanges;
};

const convertToDebtChanges = (debts: IDebt[]): IDebtChanges => {
  const reducedDebts = debts.reduce((debts, nextDebt) => {
    if (!nextDebt.Type) return debts;

    let fieldName = `Debt/${nextDebt.Type}`;
    if (nextDebt.Debtor === 'CoApplicant') {
      fieldName = 'Co' + fieldName;
    }

    dispatch({
      type: 'field-name-to-backend-id-map.add',
      backendID: nextDebt.ID,
      fieldName: fieldName,
    });
    return {
      ...debts,
      ...prefixAttributes(
        nextDebt as unknown as Record<string, unknown>,
        `${fieldName}/`
      ),
    };
  }, {});

  return reducedDebts;
};

const prefixAttributes = <T extends Record<string, unknown>>(
  obj: T,
  prefix: string
) => {
  return Object.keys(obj).reduce((sum, key) => {
    sum[prefix + key] = obj[key];
    return sum;
  }, {} as Record<string, unknown>);
};

export default reloadState;
