import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  setStoredCashAmount,
  setStoredRefinanceableDebts,
} from './setStoredValues';

type TExtendedLoanOverviewState = {
  _refinanceableDebts: TRefinanceableDebt[];
  _cashAmount: number;
  _loanDuration: number;
};

export enum TExtendedLoanOverviewActions {
  setIsCreditCardDebtChecked = 'setIsCreditCardDebtChecked',
  setIsUnsecuredDebtChecked = 'setIsUnsecuredDebtChecked',
  setCashAmount = 'setCashAmount',
  setLoanDuration = 'setLoanDuration',
  setDebtValues = 'setDebtValues',
  setDebtValuesAndCashAmount = 'setDebtValuesAndCashAmount',
  setRefinanceableDebts = 'setRefinanceableDebts',
}

type TExtendedLoanOverviewAction =
  | {
      type:
        | TExtendedLoanOverviewActions.setCashAmount
        | TExtendedLoanOverviewActions.setLoanDuration;
      payload: number;
    }
  | {
      type: TExtendedLoanOverviewActions.setDebtValues;
      payload: {
        creditCardDebt: number;
        unsecuredDebt: number;
      };
    }
  | {
      type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount;
      payload: {
        refinanceableDebts: TRefinanceableDebt[];
        cashAmount: number;
      };
    }
  | {
      type: TExtendedLoanOverviewActions.setIsCreditCardDebtChecked;
      payload: boolean;
    }
  | {
      type: TExtendedLoanOverviewActions.setIsUnsecuredDebtChecked;
      payload: boolean;
    }
  | {
      type: TExtendedLoanOverviewActions.setRefinanceableDebts;
      payload: TRefinanceableDebt[];
    };

export const extendedLoanOverviewContextReducer = (
  state: TExtendedLoanOverviewState,
  action: TExtendedLoanOverviewAction
) => {
  const { type, payload } = action;

  switch (type) {
    case TExtendedLoanOverviewActions.setRefinanceableDebts:
      setStoredRefinanceableDebts(JSON.stringify(payload));
      return {
        ...state,
        _refinanceableDebts: payload,
      };
    case TExtendedLoanOverviewActions.setCashAmount:
      setStoredCashAmount(Math.max(0, payload));
      return {
        ...state,
        _cashAmount: Math.max(0, payload),
      };
    case TExtendedLoanOverviewActions.setLoanDuration:
      return {
        ...state,
        _loanDuration: payload,
      };
    case TExtendedLoanOverviewActions.setDebtValuesAndCashAmount:
      setStoredCashAmount(Math.max(0, payload.cashAmount));
      setStoredRefinanceableDebts(JSON.stringify(payload.refinanceableDebts));
      return {
        ...state,
        _refinanceableDebts: payload.refinanceableDebts,
        _cashAmount: Math.max(0, payload.cashAmount),
      };

    default:
      return state;
  }
};
