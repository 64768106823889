import {
  IMarketingConsentMarkets,
  getMarketingConsentPartners,
  getMarketingConsentServices,
  getMarketingConsentText,
} from '@axo/form/configs';
import { IPartialCustomer } from '../../types/data/ICustomer';
import { isArraysEqual } from '../general/isArraysEqual';

export const hasConsentedToMarketing = ({
  MarketingConsent,
}: IPartialCustomer): boolean =>
  !!MarketingConsent &&
  !MarketingConsent.WithdrawnAt &&
  !!MarketingConsent.ConsentText;

/**
 * checks if marketing consent is the newest one we ask for.
 * @param marketingConsent1 original consent.
 * @returns true if consents are equal, false otherwise.
 */
export const hasNewestConsent = (customer: IPartialCustomer): boolean => {
  if (!hasConsentedToMarketing(customer)) return false;
  const market =
    customer.MarketCountry?.toLowerCase() as IMarketingConsentMarkets;
  const m1 = customer.MarketingConsent;
  const m2 = {
    ConsentText: getMarketingConsentText(market),
    Partners: getMarketingConsentPartners(market),
    Services: getMarketingConsentServices(market),
  };

  if (!m1 || !m2) return false;

  if (m1.ConsentText !== m2.ConsentText) {
    return false;
  }

  if (!isArraysEqual(m1.Partners, m2.Partners)) {
    return false;
  }

  if (!isArraysEqual(m1.Services, m2.Services)) {
    return false;
  }

  return true;
};
