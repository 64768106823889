import { IFormValueValidator } from './IFormValueValidator';

const maxLoanAmount = 600000;
const minLoanAmount = 10000;
const loanAmountIncrement = 5000;

const minLoanDuration = 1;
const maxLoanDuration = 20;

export const formValueValidatorsNO: IFormValueValidator = {
  appliedAmount: (value: number) => {
    if (value > maxLoanAmount) return maxLoanAmount;
    if (value < minLoanAmount) return minLoanAmount;
    const moduloRes = value % loanAmountIncrement;
    if (moduloRes !== 0) return value + loanAmountIncrement - moduloRes;
    return value;
  },
  loanDuration: (value: number) => {
    if (value > maxLoanDuration) return maxLoanDuration;
    if (value < minLoanDuration) return minLoanDuration;
    return value;
  },
};
