import { FC } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';

type IMessageIconVariety = 'warning' | 'info' | 'error' | 'success' | 'light';
export interface IMessageIcon {
  variety?: IMessageIconVariety;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const MessageIcon: FC<IMessageIcon> = ({ variety = 'info' }) => {
  return (
    <svg width="16" height="16" fill="none">
      <path
        d="M13.7 6.15a5.5 5.5 0 1 1-9.5 5.52L1.62 7.24a1 1 0 0 1 1.74-1l1.16 2.01a.5.5 0 1 0 .86-.5L2.94 3.5a1 1 0 0 1 1.73-1l2.46 4.25a.5.5 0 1 0 .86-.5L6.12 3a1 1 0 0 1 1.73-1l2.53 4.38a3.02 3.02 0 0 0-.28 3.92.5.5 0 1 0 .8-.6 2 2 0 0 1 .41-2.8.5.5 0 0 0 .14-.66L10.73 5a1 1 0 0 1 1.73-1l1.25 2.15ZM11.88 2.6a2.73 2.73 0 0 1 1.68 1.29l.02.03a.5.5 0 1 0 .86-.5l-.02-.04a3.73 3.73 0 0 0-2.28-1.75.5.5 0 0 0-.26.97Zm-6.9 11.52a6.47 6.47 0 0 1-1.6-1.86.5.5 0 0 0-.87.5c.48.83 1.1 1.55 1.85 2.14a.5.5 0 0 0 .62-.78Z"
        fill={`var(${getVarietyColor(variety)})`}
      />
    </svg>
  );
};

const getVarietyColor = (variety: IMessageIconVariety): ICSSColorVariable => {
  switch (variety) {
    case 'info':
      return '--info-500';
    case 'warning':
      return '--color-yellow-700';
    case 'error':
      return '--danger-700';
    case 'success':
      return '--success-700';
    case 'light':
      return '--danger-300';
  }
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default MessageIcon;
