import { BankIdLogin } from '../components/bankId';
import { CriiptoLogin } from '../components/criipto';
import { ESkatLogin } from '../components/eskat';
import { VippsLogin } from '../components/vipps';
import { TProviders } from '../IAuthProviders.types';

export const providersComponentMapping: Record<TProviders, any> = {
  BankID: BankIdLogin,
  ESkat: ESkatLogin,
  Vipps: VippsLogin,
  Criipto: CriiptoLogin,
};
