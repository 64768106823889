import fiPre from './fi/embedded.json';
import fiMain from './fi/fullwidth.json';
import noMain from './no/fullwidth.json';
import noPre from './no/stepOne.json';

export const configs = {
  no: {
    pre: noPre,
    main: noMain,
  },
  fi: {
    pre: fiPre,
    main: fiMain,
  },
};

export * from './loadConfig';
