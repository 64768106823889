import { IDebt, LoansModal } from '@axo/form/feature/debt-registry';
import { LoansModalUI } from '../../../../../../debt-registry/src/lib/components/DebtRegistry/components/DebtRegistryModals/LoansModal/LoansModalUI';

export type TDebtsInfoProps = {
  loansModalDebtEntriesTexts: {
    totalDebt: string;
    originalBalance: string;
    remainingBalance: string;
    interestBearingDebt: string;
    nonInterestBearingDebt: string;
    nominalInterest: string;
    creditType: string;
    creditLimit: string;
    coBorrower: string;
    installmentChargePeriod: string;
    installmentCharges: string;
    processedTime: string;
    receivedTime: string;
  };
  loansModalTexts: {
    title: string;
    text: string;
    confirmText: string;
  };
  debts: IDebt[];
  totalDebt: number;
  onClose: () => void;
};

export const DebtsInfo = ({
  loansModalDebtEntriesTexts,
  loansModalTexts,
  debts,
  totalDebt,
  onClose,
}: TDebtsInfoProps) => {
  return (
    <LoansModalUI
      loansModalDebtEntriesTexts={loansModalDebtEntriesTexts}
      loansModalTexts={loansModalTexts}
      debts={debts}
      totalDebt={totalDebt}
      onButtonClick={onClose}
      onCloseButtonClick={onClose}
    />
  );
};
