import { MarketCountryCode } from '@axo/shared/types';
import { ReactElement, useMemo } from 'react';
import { IAuthProviderUiProps } from '../../../IAuthProviderUiProps.types';
import { useCriiptoProvider } from '../services/useCriiptoProvider';
import { criiptoProviderMapping } from './providers';

export type CriiptoLoginProps = IAuthProviderUiProps & {
  country: MarketCountryCode;
};

export const CriiptoLogin = (props: CriiptoLoginProps): ReactElement => {
  const { country, label, axoApiUrl, onStart } = props;

  useCriiptoProvider(props);

  const uiMapping = criiptoProviderMapping.get(country);

  if (!uiMapping)
    throw new Error(
      `Criipto provider is not mapped to use country: ${country}`
    );

  const { provider, Component } = uiMapping;

  const redirectUri = useMemo(() => {
    const url = new URL(window.location.href);

    if (!url.searchParams.has('provider'))
      url.searchParams.set('provider', 'criipto');

    return url.href;
  }, []);

  const authUrl = useMemo(
    () =>
      `${axoApiUrl}/auth/provider/criipto/auth?country=${country}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`,
    [axoApiUrl, redirectUri, country]
  );

  const handleLoginStart = () => {
    onStart?.(provider);
  };

  return (
    <Component
      label={label ?? provider}
      href={authUrl}
      onClick={handleLoginStart}
    />
  );
};
