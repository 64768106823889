import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { useEffect } from 'react';

type TLDWrapperProps = {
  children: React.ReactNode;
  featureFlagKey: string;
  onFeatureFlag?: (value: boolean) => void;
};

const LDWrapper = ({
  children,
  featureFlagKey,
  onFeatureFlag,
}: TLDWrapperProps) => {
  const { [featureFlagKey]: isEnabled } = useFlags({
    [featureFlagKey]: featureFlagKey,
  });
  useFlagImpression(
    { [featureFlagKey]: featureFlagKey },
    { [featureFlagKey]: isEnabled }
  );

  useEffect(() => {
    onFeatureFlag?.(isEnabled);
  }, [isEnabled, onFeatureFlag]);

  if (!isEnabled) return null;

  return children;
};

export default LDWrapper;
