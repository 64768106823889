import { captureException } from '@sentry/browser';
import { useEffect } from 'react';
import { utmParametersSchema } from './utmParameters';

/**
 * could be added to the script, or to the div element. Check the script first,
 * if there exists a script, then we can assume that they are using the new
 * setup and have added their data tags to the script element
 *  */
const whiteLabelElement =
  document.getElementById('axo-script') || document.getElementById('axo-root');

const hasOffersAffiliateUrl = whiteLabelElement?.getAttribute(
  'data-has-offers-affiliate-url'
);

interface IOptions {
  integrationUrl: string;
}
export const useSetUtmParametersFromAffiliateTrackingLinkOnMount = ({
  integrationUrl,
}: IOptions) => {
  useEffect(() => {
    if (hasOffersAffiliateUrl) {
      fetch(
        `${integrationUrl}/v1/loan-application/affiliate-tracking?${new URLSearchParams(
          {
            hasOffersAffiliateUrl,
          }
        )}`,
        {}
      )
        .then((res) => res.json())
        .then(utmParametersSchema.parse)
        .then(({ Source, Medium, Term, Content, Name }) => {
          const url = new URL(window.location.href);

          url.searchParams.set('utm_source', Source ?? '');
          url.searchParams.set('utm_medium', Medium ?? '');
          url.searchParams.set('utm_term', Term ?? '');
          url.searchParams.set('utm_content', Content ?? '');
          url.searchParams.set('utm_name', Name ?? '');

          window.history.replaceState(window.history.state, '', url);
        })
        .catch((e) => {
          captureException(e);
          console.error(e);
        });
    }
  }, []);
};
