import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export const getAllExpenses =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (loanApplicationId: string): Promise<loan_application.Expense[]> => {
    const url = new URL(`/loan-application/${loanApplicationId}/expense`, baseUrl);

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const postExpense =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationId: string,
    Expense: loan_application.CreateExpense
  ): Promise<loan_application.Expense> => {
    const url = new URL(`/loan-application/${loanApplicationId}/expense`, baseUrl);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(Expense),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error posting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const patchExpense =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationId: string,
    ExpenseId: string,
    Expense: loan_application.CreateExpense
  ): Promise<loan_application.Expense> => {
    const url = new URL(`/loan-application/${loanApplicationId}/expense`, baseUrl);

    const response = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ...Expense, ID: ExpenseId }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error patching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const deleteExpense =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (loanApplicationID: string, ExpenseID: string): Promise<void> => {
    const url = new URL(`/loan-application/${loanApplicationID}/expense`, baseUrl);

    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ID: ExpenseID }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error deleting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
