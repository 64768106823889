import { IconName } from '../components/Icon';
import { FeedbackState } from './feedback-state.const';

export const feedbackStateIconsMap: Record<FeedbackState, IconName> = {
  neutral: 'chat-circle-text-solid',
  info: 'info-solid',
  warn: 'hand-waving-solid',
  error: 'warning-solid',
  success: 'clover-solid',
};
