import { FC, ChangeEvent } from 'react';
import { useInputState } from '../../../../hooks/useInputState';
import { IWrappedSoftValidatedInput } from '../../../../models';
import Caret, { ICaret } from '../../../atoms/Caret';
import InputStateBox, {
  IInputStateBox,
} from '../../../molecules/InputStateBox';
import styles from './Dropdown.module.scss';

export interface IItemValue {
  value: string | number;
  text: string;
  key?: string; // If not set, the text will be used as the key
  disabled?: boolean;
}

type IOption = IItemValue | string;

export interface IDropdown
  extends IWrappedSoftValidatedInput<HTMLSelectElement, string | undefined> {
  options: IOption[];
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  caretProps?: Partial<ICaret>;
  inputStateBoxProps?: Partial<IInputStateBox>;
}

export interface IOptionsDict {
  [index: string]: IOption;
}

export interface IDropDownEvent
  extends React.SyntheticEvent<HTMLSelectElement> {
  target: EventTarget & HTMLSelectElement;
}

export type IDropDownEventHandler = React.EventHandler<IDropDownEvent>;

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Dropdown: FC<IDropdown> = ({
  options,
  placeholder = '',
  caretProps,
  inputStateBoxProps,
  elementId,
  ...props
}) => {
  const inputState = useInputState({
    id: elementId,
    ...props,
  });

  // Don't show validations for individual dropdown components in the DateDropdown
  inputState.axoProps.errors = [];
  inputState.axoProps.softValidationError = undefined;

  return (
    <InputStateBox
      {...inputStateBoxProps}
      inputState={inputState}
      icon={
        <Caret
          {...caretProps}
          direction={inputState.axoProps.hasFocus ? 'up' : 'down'}
        />
      }
    >
      <select
        className={styles.select}
        {...inputState.htmlProps}
        onFocus={(e) => handleEvent(e, inputState.htmlProps.onFocus, options)}
        onBlur={(e) => handleEvent(e, inputState.htmlProps.onBlur, options)}
        onChange={(e) => handleEvent(e, inputState.htmlProps.onChange, options)}
        value={inputState.htmlProps.value || placeholder}
      >
        <option
          disabled
          hidden
          key={`${inputState.htmlProps.name}_${-1}`}
          value={placeholder}
        >
          {placeholder}
        </option>
        {options.map((option) => {
          const { value, key, text, disabled } = parseOption(option);
          return (
            <option value={value} key={key} disabled={disabled}>
              {text}
            </option>
          );
        })}
      </select>
    </InputStateBox>
  );
};

const parseOption = (option: IOption) => ({
  text: typeof option === 'object' ? option.text : option,
  value: typeof option === 'object' ? option.value : option,
  key: typeof option !== 'object' ? option : option.key || option.text,
  disabled: typeof option === 'object' ? option.disabled : false,
});

function handleEvent(
  e: IDropDownEvent,
  eventHandler: IDropDownEventHandler | undefined,
  options: IOption[]
) {
  const value = options
    ?.map(parseOption)
    ?.find((option) => `${option.value}` === e.target.value)?.value;

  if (value) {
    const modifiedEvent = {
      ...e,
      target: {
        ...e.target,
        value: value.toString(),
      },
    };
    return eventHandler && eventHandler(modifiedEvent);
  }
  return eventHandler && eventHandler(e);
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Dropdown;
