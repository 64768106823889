import { Text } from './Text';
import { Separator, TextAndHeading } from '@axo/deprecated/util/ui-components';
import { ICMSContentTermsAndConditions } from './CMSContentTermsAndConditionsTypes';

export const CMSContentTermsAndConditions = ({
  content,
}: {
  content:
    | ICMSContentTermsAndConditions
    | ICMSContentTermsAndConditions[]
    | null;
}) => {
  if (!content) return null;
  if (Array.isArray(content))
    return (
      <>
        {content.map((c) => (
          <CMSContentTermsAndConditions key={c._id} content={c} />
        ))}
      </>
    );
  if (!('__typename' in content)) {
    throw new Error('missing typename in ' + JSON.stringify(content));
  }

  switch (content.__typename) {
    case 'Text':
      return <Text key={content._id} {...content} />;
    case 'TextAndHeading':
      return <TextAndHeading {...content} />;
    case 'Separator':
      return <Separator />;
  }
};
