export const scrubObjectProperties = (obj: object) => {
  const out: Record<string, string> = {};

  for (const key in obj) {
    out[key] = `type: ${typeof obj[key as keyof typeof obj]}, length: ${
      ('' + obj[key as keyof typeof obj]).length
    }`;
  }

  return out;
};
