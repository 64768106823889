import { ReactNode } from 'react';
import styles from './InputStateBox.module.scss';
import classNames from '../../../utils/class-names';
import { IInputState } from '../../../hooks/useInputState';
import {
  InputErrorBox,
  InputSoftValidationBox,
  InputTooltipBox,
} from '../InputInfoBox';
import { Icons } from '../../atoms/Icons';

enum ClassNames {
  Root = 'root',
  IconWrapper = 'iconWrapper',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IInputStateBox {
  children: ReactNode;
  icon?: ReactNode;
  inputState: IInputState;
  classes?: ClassNamesProp;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputStateBox = ({
  inputState,
  icon,
  children,
  classes,
}: IInputStateBox) => {
  const { hasFocus, primaryState } = inputState.axoProps;
  const { id } = inputState.htmlProps;

  return (
    <InputErrorBox inputState={inputState}>
      <InputSoftValidationBox inputState={inputState}>
        <InputTooltipBox inputState={inputState}>
          <div
            key={id}
            className={classNames(
              styles.root,
              styles[primaryState],
              hasFocus ? styles.focused : styles.unfocused,
              classes?.root
            )}
          >
            {children}
            <div
              className={classNames(
                'InputStateBox__iconWrapper',
                styles.iconWrapper,
                classes?.iconWrapper
              )}
            >
              {primaryState === 'valid' ? <Icons.CircleCheckMarkValid /> : icon}
            </div>
          </div>
        </InputTooltipBox>
      </InputSoftValidationBox>
    </InputErrorBox>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default InputStateBox;
