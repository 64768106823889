import { captureException } from '@sentry/react';

export const withMonitoring = async <R>(fn: () => Promise<R>): Promise<R> => {
  try {
    return await fn();
  } catch (error) {
    captureException(error);
    throw error;
  }
};
