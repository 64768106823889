import clsx from 'clsx';
import { HTMLAttributes, ReactElement, ReactNode } from 'react';
import styles from './text.module.scss';

export const sizes = ['xs', 's', 'm', 'l', 'xl', 'xxl'] as const;
export const asHTMLElements = ['span', 'div', 'section', 'p'] as const;

type TextProps = {
  children?: ReactNode;
  size?: (typeof sizes)[number];
  as?: (typeof asHTMLElements)[number];
  className?: string;
} & HTMLDataAttributes &
  HTMLAttributes<HTMLParagraphElement> &
  HTMLAttributes<HTMLSpanElement> &
  HTMLAttributes<HTMLDivElement>;

/**
 * Text component for generic text rendering
 *
 * @warning Do not nest `<p>` elements inside `<p>` elements as it is invalid HTML (especially for cms content)
 */
export const Text = ({
  children,
  size = 'm',
  as,
  className,
  ...props
}: TextProps): ReactElement => {
  const Element = as || 'span';

  return (
    <Element
      className={clsx(styles.text, className)}
      data-size={size}
      {...props}
    >
      {children}
    </Element>
  );
};
