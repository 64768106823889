type ITrustpilotI18n = {
  [key in 'se' | 'fi' | 'dk' | 'no']: {
    grades: grades;
    reviewsWord: string;
    outOfWord: string;
    basedOnWord: string;
  };
};

type grades = {
  [key in 'bad' | 'poor' | 'average' | 'great' | 'excellent']: string;
};

export const i18n: ITrustpilotI18n = {
  fi: {
    grades: {
      bad: 'Huono',
      poor: 'Välttävä',
      average: 'Keskitasoinen',
      great: 'Hyvä',
      excellent: 'Erinomainen',
    },
    reviewsWord: 'arvosteluun',
    outOfWord: '/',
    basedOnWord: 'Perustuu',
  },
  se: {
    grades: {
      bad: 'Mycket dålig',
      poor: 'Dålig',
      average: 'Medel',
      great: 'Bra',
      excellent: 'Utmärkt',
    },
    reviewsWord: 'omdömen',
    outOfWord: 'av',
    basedOnWord: 'Baserad på',
  },
  no: {
    grades: {
      bad: 'Dårlig',
      poor: 'Under middels',
      average: 'Middels',
      great: 'Bra',
      excellent: 'enestående',
    },
    reviewsWord: 'anmeldelser',
    outOfWord: 'av',
    basedOnWord: 'Basert på',
  },
  dk: {
    grades: {
      bad: 'Dårlig',
      poor: 'Under middel',
      average: 'Middel',
      great: 'God',
      excellent: 'Fremragende',
    },
    reviewsWord: 'anmeldelser',
    outOfWord: 'ud af',
    basedOnWord: 'Baseret på',
  },
};
