import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import Button from '../../atoms/Button';
import styles from './InputInfoBox.module.scss';

export interface ISoftValidationPrompt {
  yesText: string;
  noText: string;
}

export type IInputInfoBox = {
  message?: string | undefined;
  prompt?: ISoftValidationPrompt;
  children?: ReactNode;
  onClickYes?: MouseEventHandler;
  onClickNo?: MouseEventHandler;
  backgroundColor?: ICSSColorVariable;
  color?: ICSSColorVariable;
  icon?: ReactNode;
};

/**
 * Shows soft validations, errors and tooltips for input elements.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const InputInfoBox = ({
  backgroundColor = '--white-100',
  color = '--dark-900',
  icon,
  message,
  children,
  prompt,
  onClickYes,
  onClickNo,
}: IInputInfoBox) => {
  return (
    <div
      className={clsx('InputInfoBox', styles.container)}
      style={{
        backgroundColor: `var(${backgroundColor})`,
      }}
    >
      {children}
      {message && (
        <div
          className={styles.row}
          style={{
            color: `var(${color})`,
          }}
        >
          {icon}
          <div className={styles.column}>
            {message}
            {prompt && (
              <div className={styles.prompt}>
                {prompt.yesText && (
                  <Button
                    fontVariant="secondary"
                    width="full-width"
                    variant="white"
                    onClick={onClickYes}
                  >
                    {prompt.yesText}
                  </Button>
                )}
                {prompt.noText && (
                  <Button
                    fontVariant="secondary"
                    width="full-width"
                    variant="white"
                    onClick={onClickNo}
                  >
                    {prompt.noText}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default InputInfoBox;
