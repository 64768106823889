import RCSlider from 'rc-slider';
import styles from './Slider.module.scss';

import { getFormattedAmount } from '@axo/shared/util/formatters';
import 'rc-slider/assets/index.css';
import { cloneElement } from 'react';

const customRender = (handle: React.ReactElement) => {
  return cloneElement(
    handle,
    {
      style: {
        ...handle.props.style,
        visibility: 'hidden',
      },
    },
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{
        visibility: 'visible',
        position: 'absolute',
        transform: 'translate(-50% , -50%)',
      }}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="white"
        stroke="#828D98"
        strokeWidth="4"
      />
    </svg>
  );
};

type TSliderProps = {
  value: number | number[];
  onChange: (newValue: number) => void;
  min: number;
  max: number;
  onChangeComplete?: (newValue: number) => void;
  step: number;
  trackHeight?: number;
  currency: string;
};

const Slider = ({
  value,
  onChange,
  min,
  max,
  onChangeComplete,
  step,
  trackHeight = 4,
  currency,
}: TSliderProps) => {
  const _onChange = (newValue: number | number[]) =>
    onChange(newValue as number);

  const _onChangeComplete = (newValue: number | number[]) =>
    onChangeComplete?.(newValue as number);

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <RCSlider
          value={value}
          onChange={_onChange}
          onChangeComplete={_onChangeComplete}
          min={min}
          max={max}
          className={styles.slider}
          step={step}
          handleRender={customRender}
          styles={{
            track: {
              height: trackHeight,
            },
          }}
        />
      </div>
      <div className={styles.legend}>
        <span className={styles.value}>{`${getFormattedAmount(
          min
        )} ${currency}`}</span>
        <span className={styles.value}>{`${getFormattedAmount(
          max
        )} ${currency}`}</span>
      </div>
    </div>
  );
};

export default Slider;
