import { reloadData } from '@axo/form/data-access/backend-sync';
import { tracking } from '@axo/form/data-access/tracking';

import { hasNewestConsent } from '../utils/marketingConsent/marketingConsentUtils';
import { IFormStateAction } from './actions';
import { IFormState } from './state';

const reducer: React.Reducer<IFormState, IFormStateAction> = (state, event) => {
  // console.log('event dispatched: ', event.type);

  switch (event.type) {
    case 'customer.created':
    case 'customer.replaced':
      tracking.setTag('axoform.customer.id', event.customer.ID);
      reloadData.set({
        shouldRetrieveMarketingConsent: (!hasNewestConsent(
          event.customer
        )).toString(),
      });
      return {
        ...state,
        customer: event.customer,
      };

    case 'loan-application.created':
      tracking.setTag('axoform.application.id', event.loanApplication.ID);
      return {
        ...state,
        loanApplication: event.loanApplication,
      };
    case 'promise.pending':
      return { ...state, loadingState: 'pending' };
    case 'promise.complete':
      return { ...state, loadingState: 'complete' };
    case 'promise.failed':
      return {
        ...state,
        loadingState: 'failed',
      };
    case 'error.thrown':
      console.error(event.error);

      return {
        ...state,
        errors: [...state.errors, event.error],
      };
    case 'error.resolved':
      return {
        ...state,
        errors: state.errors.filter((error) => error !== event.error),
      };
    case 'changes.queued':
      return { ...state, hasUnsyncedChanges: true };
    case 'changes.resolved':
      return { ...state, hasUnsyncedChanges: false };
    case 'form.initializing':
      return { ...state, setupState: 'restoring' };
    case 'form.restored':
      return { ...state, setupState: 'restored' };
    case 'form.ready':
      return { ...state, setupState: 'ready' };

    case 'field-name-to-backend-id-map.add':
      return {
        ...state,
        fieldNameToBackendIDMap: {
          ...state.fieldNameToBackendIDMap,
          [event.fieldName]: event.backendID,
        },
      };

    case 'field-name-to-backend-id-map.remove': {
      const newFieldNameToBackendIdMap = { ...state.fieldNameToBackendIDMap };
      delete newFieldNameToBackendIdMap[event.fieldName];
      return {
        ...state,
        fieldNameToBackendIDMap: newFieldNameToBackendIdMap,
      };
    }

    default:
      return state;
  }
};

export default reducer;
