import { classNames } from '@axo/shared/util/dom';
import React from 'react';
import { Change, Done } from '../icons';
import styles from './Button.module.scss';

type IButtonMode = 'edit' | 'change';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Button = ({
  mode,
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  mode: IButtonMode;
}) => (
  <button
    type="button"
    {...rest}
    className={classNames(styles.button, styles[mode])}
  >
    <span>{mode === 'edit' ? <Done /> : <Change />}</span>
    <span>{children}</span>
  </button>
);
