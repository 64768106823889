import React from 'react';
import {
  ElementFactory,
  FunctionFactory,
  QuestionHtmlModel,
  Serializer,
} from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionHtml } from 'survey-react-ui';
import { calculateMonthlySavedAmount } from './calculateMonthlySavedAmount';
import { numberFormatter } from './numberFormatter';
import SavedAmount from './SavedAmount';

class QuestionModel extends QuestionHtmlModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  get dependsOn(): string[] {
    return this.getPropertyValue('dependsOn', null);
  }

  set dependsOn(dependencies: string[]) {
    this.setPropertyValue('dependsOn', dependencies);
  }
}

export class SurveyQuestion extends SurveyQuestionHtml {
  constructor(props: any) {
    super(props);
    this.question.dependsOn.forEach((dep) => {
      const question = this.question.survey.getQuestionByName(dep);

      question.registerFunctionOnPropertyValueChanged(
        'value',
        (value: any) =>
          setTimeout(() => (this.question.value = question.value), 50),
        `${question.name}-savedAmount`
      );
    });
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    return <SavedAmount html={this.question.processedHtml} />;
  }
}

export function registerSavedAmountQuestion() {
  const questionType = 'Axo Saved Amount';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'dependsOn',
        type: 'string',
        category: 'general',
      },
    ],
    () => new QuestionModel(questionType, ''),
    'html'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });

  FunctionFactory.Instance.register(
    'calculateMonthlySavedAmount',
    calculateMonthlySavedAmount
  );

  FunctionFactory.Instance.register('numberFormatter', numberFormatter);
}
