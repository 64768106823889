import { IAPI } from '../../lib/models/api';
import { send } from '../../lib/send';
import { loan_application } from '@axo/shared/data-access/types';

export function cloneLoanApplication(api: IAPI) {
  return async function cloneLoanApplication(
    loanApplicationID: string,
    data: Partial<loan_application.LoanApplication>
  ): Promise<loan_application.LoanApplication> {
    return send({
      baseURL: api.url.service,
      token: api.token,
      path: `/loan-application/${loanApplicationID}/clone`,
      method: 'POST',
      body: data,
    });
  };
}
