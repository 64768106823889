import { ChangeEvent } from 'react';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const createFakeSyntheticChangeEvent = <E extends HTMLElement>(
  target: E
) => {
  // This is not nice, and might cause errors, but it seems necessary. The
  // reason is that updating the value from JS does not trigger an on change
  // event (which is needed for e.g. SurveyJS to be alerted about the value changing).
  const e: ChangeEvent<E> = {
    target: target,
    nativeEvent: new Event('input'),
    currentTarget: target,
    bubbles: false,
    cancelable: false,
    defaultPrevented: false,
    eventPhase: 0,
    isTrusted: false,
    timeStamp: 0,
    type: '',

    preventDefault: function (): void {
      throw new Error('Function not implemented.');
    },
    isDefaultPrevented: function (): boolean {
      throw new Error('Function not implemented.');
    },
    stopPropagation: function (): void {
      throw new Error('Function not implemented.');
    },
    isPropagationStopped: function (): boolean {
      throw new Error('Function not implemented.');
    },
    persist: function (): void {
      throw new Error('Function not implemented.');
    },
  };
  return e;
};
