const Chat = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Chat" clipPath="url(#clip0_540_11709)">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 7.9998C16.5 11.9763 12.9183 15.1998 8.5 15.1998C7.00836 15.1998 5.61208 14.8324 4.41677 14.1926L0.5 15.1998L1.8383 11.9879C0.992801 10.8462 0.5 9.47478 0.5 7.9998C0.5 4.02335 4.08172 0.799805 8.5 0.799805C12.9183 0.799805 16.5 4.02335 16.5 7.9998ZM5.5 6.97123H3.5V9.02838H5.5V6.97123ZM13.5 6.97123H11.5V9.02838H13.5V6.97123ZM7.5 6.97123H9.5V9.02838H7.5V6.97123Z"
        fill="#886700"
      />
    </g>
    <defs>
      <clipPath id="clip0_540_11709">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Chat;
