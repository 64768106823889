import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import { getStoredAppliedAmount } from '../store/getStoredValues';

type TGetDebtValuesOnPropChange = {
  maxLoanAmount?: number;
  appliedAmount: number;
  isUpSell: boolean;
  refinanceableDebts: TRefinanceableDebt[];
  currentRefinanceableDebts: TRefinanceableDebt[];
};

export const getDebtValuesOnPropChange = ({
  maxLoanAmount,
  appliedAmount,
  isUpSell,
  refinanceableDebts,
  currentRefinanceableDebts,
}: TGetDebtValuesOnPropChange) => {
  const newRefinanceableDebts = refinanceableDebts.map((debt, idx) => ({
    ...debt,
    value: maxLoanAmount ? Math.min(debt.value, maxLoanAmount) : debt.value,
    isChecked: currentRefinanceableDebts?.[idx]
      ? currentRefinanceableDebts[idx].isChecked
      : true,
  }));

  const storedAppliedAmount = getStoredAppliedAmount();

  const refinanceAmount = newRefinanceableDebts.reduce(
    (acc, { value }) => acc + value,
    0
  );
  const newCashValue = isUpSell
    ? storedAppliedAmount ?? appliedAmount
    : storedAppliedAmount
    ? storedAppliedAmount - refinanceAmount
    : appliedAmount - refinanceAmount;
  const maxCashValue = maxLoanAmount
    ? maxLoanAmount - refinanceAmount
    : undefined;

  return {
    newRefinanceableDebts,
    newCashValue: Math.min(newCashValue, maxCashValue ?? newCashValue),
  };
};
