import { useState, useEffect } from 'react';
import { ErrorModel } from '../models';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const useErrorsWithoutAddingNewWhenFocused = (
  errors: ErrorModel[],
  hasFocus: boolean
): ErrorModel[] => {
  const [filteredErrors, setFilteredErrors] = useState<ErrorModel[]>(errors);

  useEffect(() => {
    const intersection = intersectionOf(errors, filteredErrors);

    // Only update when needed in order to avoid a loop of infinite renders
    if (!isSubset(intersection, filteredErrors)) {
      setFilteredErrors(intersection);
    }
  }, [errors, filteredErrors]);

  useEffect(() => {
    if (!hasFocus && !isSubset(errors, filteredErrors))
      setFilteredErrors(errors);
  }, [errors, filteredErrors, hasFocus]);

  return filteredErrors;
};

const isSubset = (A: ErrorModel[], B: ErrorModel[]) => {
  if (A.length === 0 && B.length !== 0) return false;
  return A.every((a) => B.some((b) => a.message === b.message));
};

const intersectionOf = (A: ErrorModel[], B: ErrorModel[]) => {
  return A.filter((a) => B.some((b) => a.message === b.message));
};
