import labels from '../../constants/labels';
import { IDebtsResponse } from '../../hooks/useAssentlyClient';
import { ConsentModalUI } from './ConsentModal/ConsentModalUI';
import { ErrorModalUI } from './ErrorModal/ErrorModalUI';
import { LoansModalUI } from './LoansModal/LoansModalUI';

export type DebtRegistryModalsTypes = 'information' | 'consent' | 'error';

export const modals: Record<DebtRegistryModalsTypes, DebtRegistryModalsTypes> =
  {
    information: 'information',
    consent: 'consent',
    error: 'error',
  };

type DebtRegistryModalsProps = {
  activeModal: DebtRegistryModalsTypes | undefined;
  handleOnConsent: (value: boolean) => void;
  handleModalClose: () => void;
  debtsData?: IDebtsResponse;
};
export const DebtRegistryModalsUI = ({
  activeModal,
  handleOnConsent,
  handleModalClose,
  debtsData,
}: DebtRegistryModalsProps) => {
  return (
    <>
      <ConsentModalUI
        isOpen={activeModal === modals.consent}
        consentHeading={labels.consentHeading}
        consentText={labels.consentText}
        consentButtonText={labels.consentButtonText}
        cancelButtonText={labels.cancelButtonText}
        onConfirm={handleOnConsent}
        onCancel={handleModalClose}
      />
      <LoansModalUI
        isOpen={activeModal === modals.information}
        loansModalDebtEntriesTexts={
          labels.loansModalLabels.loansModalDebtEntriesTexts
        }
        loansModalTexts={labels.loansModalLabels.loansModalTexts}
        debts={debtsData?.debts || []}
        totalDebt={debtsData?.debtTotal ?? 0}
        onButtonClick={handleModalClose}
        onCloseButtonClick={handleModalClose}
      />
      <ErrorModalUI
        isOpen={activeModal === modals.error}
        title={labels.errorModal.title}
        message={labels.errorModal.paragraphs.join(' ')}
        actionLabel={labels.errorModal.buttonText}
        onAction={handleModalClose}
      />
    </>
  );
};
