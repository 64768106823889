type TGetAmountsForExceedingValue = {
  maxLoanAmount: number;
  allocation: Record<string, number>;
  priority: string[];
};

export const getLoanAllocation = ({
  allocation,
  maxLoanAmount,
  priority,
}: TGetAmountsForExceedingValue) => {
  const totalLoanValue = priority.reduce(
    (acc, key) => (allocation[key] ? acc + allocation[key] : acc),
    0
  );

  if (totalLoanValue <= maxLoanAmount) {
    return allocation;
  }

  const newAllocation = priority.reduce((acc, key) => {
    const currentDebtAllocated = Object.values(acc).reduce(
      (acc, value) => acc + value,
      0
    );

    if (currentDebtAllocated >= maxLoanAmount) {
      return { ...acc, [key]: 0 };
    }

    if (currentDebtAllocated + (allocation[key] ?? 0) <= maxLoanAmount) {
      return { ...acc, [key]: allocation[key] ?? 0 };
    }

    return { ...acc, [key]: maxLoanAmount - currentDebtAllocated };
  }, {} as Record<string, number>);

  return newAllocation;
};
