import { IFormValueValidator } from './IFormValueValidator';

/**
 * Temporary validation until we have our own validation/state framework
 * TODO: remove when other solution is in place (edit: 1.12.2022)
 */

const maxLoanAmount = 60000;
const minLoanAmount = 2000;

const minLoanDuration = 1;
const maxLoanDuration = 20;

export const formValueValidatorsFI: IFormValueValidator = {
  appliedAmount: (value: number) => {
    if (value > maxLoanAmount) return maxLoanAmount;
    if (value < minLoanAmount) return minLoanAmount;
    return value;
  },
  loanDuration: (value: number) => {
    if (value > maxLoanDuration) return maxLoanDuration;
    if (value < minLoanDuration) return minLoanDuration;
    return value;
  },
};
