import { formatDate } from '@axo/deprecated/util/ui-components';
import { Formatters } from '@axo/shared/util/string';
import { Loan } from './Loan';

export function formatCurrency(value: number) {
  return `${Formatters.formatNumber(value, ' ')} kr`;
}

export function formatInterest(value: number) {
  return `${value} %`;
}

export function formatDateString(dateString: string) {
  return formatDate(new Date(dateString), '[dd].[MM].[yyyy]');
}

export function getInstallmentPeriodText(installmentChargePeriod: string) {
  switch (installmentChargePeriod) {
    case 'MONTHLY':
      return 'Månedlig';
    case 'QUARTERLY':
      return 'Kvartalsvis';

    default:
      return installmentChargePeriod;
  }
}

export function getCoBorrowerTexts(coBorrowerCode: string) {
  switch (coBorrowerCode) {
    case '0':
      return 'Nei';
    case '1':
      return 'Ja';
    case '2':
      return 'Ja';

    default:
      return coBorrowerCode;
  }
}

export function formatDebtType(type: string) {
  switch (type) {
    case 'repaymentLoan':
      return 'Repayment Loan';

    case 'creditFacility':
      return 'Credit Facility';

    case 'chargeCard':
      return 'Charge Card';

    default:
      return type;
  }
}

type DebtDetails = {
  headerDetails: { [key: string]: string };
  expandedDetails: { [key: string]: string };
};

export function getDebtDetails({ debt, labels }: Loan): DebtDetails {
  let headerDetails = {
    [labels.nonInterestBearingDebt]: formatCurrency(
      debt.nonInterestBearingBalance
    ),
    [labels.interestBearingDebt]: formatCurrency(debt.interestBearingBalance),
  };

  const expandedDetails = {
    [labels.creditType]: formatDebtType(debt.type),
    [labels.creditLimit]: formatCurrency(debt.creditLimit),
    [labels.nominalInterest]: formatInterest(debt.nominalInterestRate),
    [labels.installmentCharges]: formatCurrency(debt.installmentCharges),
    [labels.installmentChargePeriod]: getInstallmentPeriodText(
      debt.installmentChargePeriod
    ),
    [labels.processedTime]: formatDateString(debt.processedTime),
    [labels.receivedTime]: formatDateString(debt.receivedTime),
    [labels.coBorrower]: getCoBorrowerTexts(debt.coBorrower),
  };

  if (debt.type === 'repaymentLoan') {
    headerDetails = {
      [labels.originalBalance]: formatCurrency(debt.originalBalance),
      [labels.remainingBalance]: formatCurrency(debt.balance),
    };
  }

  return {
    headerDetails,
    expandedDetails,
  };
}
