import { IDebtsResponse } from '../hooks/useAssentlyClient';

// The data that comes from debtRegistry is stored as integers to avoid decimals. Convert to decimals before use.
export const convertIntToDecimals = (debt: IDebtsResponse) => {
  debt.debtTotal = Math.round(debt.debtTotal / 100);

  debt.debts.forEach((debtObject) => {
    debtObject.interestBearingBalance = Math.round(
      debtObject.interestBearingBalance / 100
    );
    debtObject.creditLimit = Math.round(debtObject.creditLimit / 100);
    debtObject.nonInterestBearingBalance = Math.round(
      debtObject.nonInterestBearingBalance / 100
    );
    debtObject.originalBalance = Math.round(debtObject.originalBalance / 100);
    debtObject.balance = Math.round(debtObject.balance / 100);
    debtObject.nominalInterestRate /= 100;
  });

  return debt;
};
