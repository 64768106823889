import { changeHandler } from '../../../backend-sync/handle-changes';
import { isChanges } from '../../../types/data/IChanges';

interface ISyncField {
  name: string;
  value: unknown;
}
export const syncField = async ({ name, value }: ISyncField) => {
  const changes = {
    [name]: value,
  };

  if (!isChanges(changes)) {
    return console.warn(`Ignoring invalid change ${JSON.stringify(changes)}`);
  }
  await changeHandler.onChange(changes);
};
