export function isObject(u: unknown): u is Record<string, unknown> {
  return !!u && typeof u === 'object';
}

const propertyIsType =
  <T>(expectedType: string) =>
  <P extends string>(
    obj: unknown,
    attribute: P
  ): obj is { [Property in P]: T } => {
    if (!isObject(obj)) return false;
    if (!(attribute in obj)) {
      console.warn(
        `Attribute ${attribute} is missing in ${JSON.stringify(obj)}`
      );
      return false;
    }

    const actualType = typeof obj[attribute as keyof typeof obj];
    const isType = actualType === expectedType;

    if (!isType) {
      console.warn(`Attribute ${attribute} is not type ${expectedType}`);
    }

    return isType;
  };

export const propertyIsNumber = propertyIsType<number>('number');
export const propertyIsString = propertyIsType<string>('string');
export const propertyIsBoolean = propertyIsType<boolean>('boolean');

const propertyIsOptionalType =
  <T>(expectedType: string) =>
  <P extends string>(
    obj: unknown,
    attribute: P
  ): obj is { [Property in P]: T | undefined } => {
    if (!isObject(obj)) return false;
    if (!(attribute in obj)) return true;

    const value = obj[attribute as keyof typeof obj];
    const actualType = typeof value;
    const isType =
      actualType === expectedType ||
      actualType === 'undefined' ||
      value === null;

    if (!isType) {
      console.warn(
        `Attribute ${attribute} is not type ${expectedType} or undefined`
      );
    }

    return isType;
  };

export type PropertyValidator<T, P extends string> = (
  obj: unknown,
  attribute: P
) => obj is { [Property in P]: T };

export const propertyIsOptionalNumber =
  propertyIsOptionalType<number>('number');
export const propertyIsOptionalString =
  propertyIsOptionalType<string>('string');
export const propertyIsOptionalBoolean =
  propertyIsOptionalType<boolean>('boolean');
export const propertyIsOptionalObject =
  propertyIsOptionalType<object>('object');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const compileTimeTypeCheck = <T>(input: T) => undefined;
