/* eslint-disable max-classes-per-file */
import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';
import tnc from '../../../../config/fi/terms';
import {
  DataRetentionTerms,
  GeneralTerms,
} from '../shared/TermsAndConditions/TermsAndConditions';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, label, ...props } = getBasicQuestionProps(
      this,
      this.questionBase
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.question.value = event.target.checked;
    };

    return (
      <div style={style}>
        Lähettämällä hakemuksen hyväksyt{' '}
        <GeneralTerms terms={tnc}>käyttöehtomme</GeneralTerms> ja{' '}
        <DataRetentionTerms terms={tnc}>
          tietosuojakäytäntömme
        </DataRetentionTerms>
      </div>
    );
  }
}

export function registerFITermsQuestion() {
  const questionType = 'Axo Terms FI';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'checkbox'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
