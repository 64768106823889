type TButtonBGProps = {
  isDisabled?: boolean;
};

const ButtonBG = ({ isDisabled }: TButtonBGProps) =>
  isDisabled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="20" cy="20" r="20" fill="#F9F9FA" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="20" cy="20" r="19.5" fill="white" stroke="#65707B" />
    </svg>
  );

export default ButtonBG;
