import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  getStoredCashAmount,
  getStoredRefinanceableDebts,
} from '../store/getStoredValues';

type TGetInitialValues = {
  isUpSell: boolean;
  appliedAmount: number;
  refinanceableDebts: TRefinanceableDebt[];
  loanApplicationID?: string;
};

export const getInitialValues = ({
  isUpSell,
  appliedAmount,
  refinanceableDebts,
  loanApplicationID,
}: TGetInitialValues) => {
  const storedRefinanceableDebts = getStoredRefinanceableDebts({
    refinanceableDebts,
  });

  const _refinanceableDebts = storedRefinanceableDebts ?? refinanceableDebts;

  const storedCashAmountValue = getStoredCashAmount({ loanApplicationID });

  const totalDebt = _refinanceableDebts.reduce(
    (acc, { value }) => acc + value,
    0
  );

  const cashAmount =
    storedCashAmountValue ??
    (isUpSell ? appliedAmount : appliedAmount - totalDebt);

  return {
    refinanceableDebts: _refinanceableDebts,
    cashAmount,
  };
};
