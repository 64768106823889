import {
  Arrow,
  Button,
  Icons,
  Modal,
  Span,
} from '@axo/deprecated/util/ui-components';
import { ReactElement } from 'react';
import { InformationContainer } from '../../../../InformationContainer';
import styles from './ConsentModal.module.scss';

export interface IConsentModal {
  consentHeading?: string;
  consentText: string;
  consentButtonText: string;
  cancelButtonText: string;
  onConfirm: (consent: boolean) => void;
  onCancel: () => void;
}

export function ConsentModal({
  consentText,
  consentHeading,
  consentButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}: IConsentModal): ReactElement {
  return (
    <Modal
      topBorderColor="--info-500"
      classes={{ root: styles.ConsentModal, box: styles.box }}
      icon={<Icons.AxoFinans size="lg" />}
    >
      {consentHeading && (
        <Span className={styles.heading}>{consentHeading}</Span>
      )}

      <InformationContainer classes={{ root: styles.informationContainer }}>
        <div
          className={styles.consentText}
          dangerouslySetInnerHTML={{ __html: consentText }}
        />
      </InformationContainer>
      <div className={styles.buttonHolder}>
        <Button
          variant="primary"
          onClick={() => onConfirm(true)}
          endIcon={<Arrow color="dark" direction="right" />}
        >
          {consentButtonText}
        </Button>
        <Button variant="tertiary" onClick={() => onCancel()}>
          {cancelButtonText}
        </Button>
      </div>
    </Modal>
  );
}
