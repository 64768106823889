/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const calculateMonthlyPayment = (args: number[]) => {
  const loanAmount = args[0];
  const tenure = args[1] * 12;
  const interestRate = args[2] / 12;

  const monthlyPayment =
    loanAmount /
    ((Math.pow(1 + interestRate, tenure) - 1) /
      (interestRate * Math.pow(1 + interestRate, tenure)));
  return Math.ceil(monthlyPayment);
};

const MONTHLY_FEE_NO = 30;
const RATES_NO = {
  LOW: 4.9,
  MID: 11.9,
  HIGH: 19.9,
} as const;

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const calculateMonthlyPaymentNO = (args: number[]) => {
  const loanAmount = args[0];
  const tenure = args[1];
  const interestRate = args[2] || RATES_NO.MID;

  const monthlyTerms = tenure * 12;
  const termInterest = interestRate / 100 / 12;
  const tenor = Math.round(monthlyTerms);
  const value =
    (loanAmount * termInterest) / (1 - 1 / Math.pow(1 + termInterest, tenor)) +
    Math.round(MONTHLY_FEE_NO);
  return Math.ceil(value);
};
