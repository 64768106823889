import { H1, H2, H3, H4, H5, H6 } from '@axo/deprecated/util/ui-components';
import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';

import { z } from 'zod';

export const textSchema = z.object({
  _id: z.string(),
  __typename: z.literal('Text'),
  html: z.string(),
  body: z.string(),
  text: z.string(),
  format: z
    .union([
      z.literal('H1'),
      z.literal('H2'),
      z.literal('H3'),
      z.literal('H4'),
      z.literal('H5'),
      z.literal('H6'),
      z.literal('HTML'),
      z.literal('Code'),
    ])
    .nullable(),
});

export type IText = z.infer<typeof textSchema>;

export interface ITextProps extends IText {
  classes?: {
    root?: string;
  };
}

const map: Record<
  string,
  | undefined
  | FC<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
      >
    >
> = {
  H1: H1,
  H2: H2,
  H3: H3,
  H4: H4,
  H5: H5,
  H6: H6,
};

export const Text: FC<ITextProps> = ({ body, text, format, classes }) => {
  const Tag = map[format as keyof typeof map];

  return Tag && text ? (
    <Tag className={classes?.root}>{text}</Tag>
  ) : (
    <div
      className={classes?.root}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }}
    />
  );
};

export default Text;
