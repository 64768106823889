import { getFormState } from '../../store';
import { IAuthorizationToken } from '../../types/data/IAuthorizationToken';
import { ICustomer } from '../../types/data/ICustomer';
import { ILoanApplication } from '../../types/data/ILoanApplication';

type IChangeDependencies =
  | {
      hasDependencies: false;
      dependencies: {
        customer?: ICustomer;
        loanApplication?: ILoanApplication;
        JWT?: IAuthorizationToken;
      };
    }
  | {
      hasDependencies: true;
      dependencies: {
        customer: ICustomer;
        loanApplication: ILoanApplication;
        JWT: IAuthorizationToken;
      };
    };

export const getChangeDependencies = (): IChangeDependencies => {
  const { customer, loanApplication } = getFormState();

  if (customer && loanApplication && customer.JWT) {
    return {
      hasDependencies: true,
      dependencies: {
        customer,
        loanApplication,
        JWT: customer.JWT,
      },
    };
  } else {
    return {
      hasDependencies: false,
      dependencies: {
        customer,
        loanApplication,
        JWT: customer?.JWT,
      },
    };
  }
};
