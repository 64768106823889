import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';
import { FormV1Trustpilot } from '@axo/deprecated/util/ui-components';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    return this.questionType ?? '';
  }

  get testing() {
    return this.survey;
  }

  get version() {
    return this.getPropertyValue('version');
  }

  set version(version: 'compact' | 'extended' | 'extended-inline') {
    this.setPropertyValue('version', version);
  }

  get trustpilotLink() {
    return this.getPropertyValue('trustpilot_link');
  }

  set trustpilotLink(trustpilotLink: string) {
    this.setPropertyValue('trustpilot_link', trustpilotLink);
  }

  get countryCode() {
    return this.getPropertyValue('countryCode');
  }

  set countryCode(countryCode: 'se' | 'no' | 'fi') {
    this.setPropertyValue('countryCode', countryCode);
  }

  get withShadow() {
    return this.getPropertyValue('withShadow');
  }

  set withShadow(withShadow: boolean) {
    this.setPropertyValue('withShadow', withShadow);
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style } = getBasicQuestionProps(this, this.questionBase);

    return (
      <div style={style}>
        <FormV1Trustpilot
          trustpilot_link={this.question.trustpilot_link}
          countryCode={this.question.countryCode}
          version={this.question.version}
          withShadow={this.question.withShadow}
        />
      </div>
    );
  }
}

export function registerTrustpilotWidgetQuestion() {
  const questionType = 'Axo Trustpilot Widget';
  Serializer.addClass(
    questionType,
    [
      {
        name: 'trustpilot_link',
        category: 'general',
        type: 'string',
      },
      {
        name: 'version',
        category: 'general',
        type: 'string',
        default: 'extended-inline',
      },
      {
        name: 'countryCode',
        category: 'general',
        type: 'string',
        default: 'no',
      },
      {
        name: 'withShadow',
        category: 'general',
        type: 'boolean',
        default: false,
      },
    ],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
