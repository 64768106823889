import { loan_application } from '@axo/shared/data-access/types';

export async function getChannelSnapshots(
  baseUrl: string,
  token: string,
  loanApplicationId: string
): Promise<loan_application.ChannelSnapshot[]> {
  const url = new URL(
    `/loan-application/${loanApplicationId}/channel-snapshot`,
    baseUrl
  );

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const responseJson = await response.json();

  if (response.ok) return responseJson;

  throw new Error(
    `Error fetching ${response.url}: ${response.status} ${
      response.statusText
    } ${responseJson.error || responseJson.message}`
  );
}

export async function postChannelSnapshot(
  baseUrl: string,
  token: string,
  loanApplicationId: string,
  data: loan_application.ChannelSnapshot
): Promise<loan_application.ChannelSnapshot> {
  const url = new URL(
    `/loan-application/${loanApplicationId}/channel-snapshot`,
    baseUrl
  );

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const responseJson = await response.json();

  if (response.ok) return responseJson;

  throw new Error(
    `Error posting ${response.url}: ${response.status} ${response.statusText} ${
      responseJson.error || responseJson.message
    }`
  );
}
