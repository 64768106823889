/* eslint-disable @nx/enforce-module-boundaries */
import styles from './Input.module.scss';
import { useInputState } from '@axo/deprecated/util/ui-components/hooks/useInputState';
import { IWrappedSoftValidatedInput } from '@axo/deprecated/util/ui-components/models';
import { usePersistentTransformInputValue } from '@axo/deprecated/util/ui-components/hooks/useTransformValue';
import FormatterUtils, {
  IThousandsSeparator,
} from '@axo/deprecated/util/ui-components/utils/Formatters';
import classNames from '@axo/deprecated/util/ui-components/utils/class-names';
import {
  InputWrapper,
  InputStateBox,
  FormattedInput,
} from '@axo/deprecated/util/ui-components/components';

export interface IInput
  extends IWrappedSoftValidatedInput<HTMLInputElement, number | undefined> {
  separator: IThousandsSeparator;
  config: {
    min: number;
    max: number;
  };
  microcopy: {
    suffix: string;
  };
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Input = ({
  disabled,
  separator,
  value,
  config,
  microcopy,
  ...rest
}: IInput) => {
  const inputState = useInputState<HTMLInputElement>({
    disabled,
    value: value?.toString(),
    ...rest,
  });

  const transformedInputState = usePersistentTransformInputValue((v, s) => {
    const value = parseInt(v);
    if (isNaN(value)) return v;
    if (s.axoProps.hasFocus) return v;
    if (config.max && value > config.max) return config.max.toString();
    else if (config.min && value < config.min) return config.min.toString();
    else return v;
  }, inputState);

  return (
    <InputWrapper
      inputState={transformedInputState}
      classes={{ label: styles.label, root: styles.inputWrapper }}
    >
      <InputStateBox
        inputState={transformedInputState}
        classes={{
          root: classNames(
            styles.inputContainer,
            disabled && styles.inputContainerWithoutBorder,
            !disabled && styles.inputContainerBordered
          ),
        }}
      >
        <FormattedInput
          classes={{
            root: classNames(styles.input, disabled && styles.disabledInput),
          }}
          inputState={transformedInputState}
          suffix={microcopy.suffix}
          getFormatter={(value) => {
            return FormatterUtils.getNumberFormatter(value, separator);
          }}
        />
      </InputStateBox>
    </InputWrapper>
  );
};
