import { IAmountOfChildren } from '../../../types/data/IAmountOfChildren';
import { IChanges } from '../../../types/data/IChanges';
import { IPartialCustomer } from '../../../types/data/ICustomer';
import { IPartialDebt } from '../../../types/data/IDebt';
import { IPartialLoanApplication } from '../../../types/data/ILoanApplication';
import { IPartialPerson } from '../../../types/data/IPerson';
import { IChangePropertyNamePrefix } from '../../../types/data/prefixes';
import { reduceChangesToObjects } from '../change-reducer';

type IObjectTypes = Exclude<IChangePropertyNamePrefix, 'Ignore'>;
type ICallback<T> = (key: string, changes: T) => Promise<void>;
interface ICallbacks {
  LoanApplication: ICallback<IPartialLoanApplication>;
  Customer: ICallback<IPartialCustomer>;
  CoCustomer: ICallback<IPartialCustomer>;
  Debt: ICallback<IPartialDebt>;
  CoDebt: ICallback<IPartialDebt>;
  AmountOfChildren: ICallback<IAmountOfChildren>;
  Person: ICallback<IPartialPerson>;
  CoPerson: ICallback<IPartialPerson>;
}

export const handleChangesPerTypeFactory =
  (callbacks: ICallbacks) => async (changes: IChanges) => {
    const changeObjects = reduceChangesToObjects(changes);

    await Promise.all(
      Object.keys(changeObjects).map((key) => {
        if (!key) return undefined;
        const prefix = key.split('/')[0] || key;
        return callbacks[prefix as IObjectTypes]?.(key, changeObjects[key]);
      })
    );
  };
