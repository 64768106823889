import React from 'react';
import { P, H2 } from '../../atoms/StyledElements';
import styles from './ProgressBarCard.module.scss';

export type IProgressBar = {
  title: string;
  subtitle: string;
};
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ProgressBar = ({ title, subtitle }: IProgressBar) => (
  <div className={styles.progressbarContainer}>
    <H2 className={styles.progressbarHeader}>{title}</H2>
    <P className={styles.progressbarSubtitle}>{subtitle}</P>
  </div>
);
