import { MarketCountryCode } from '@axo/shared/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IAuthProviderConfig } from '../../../IAuthProviderConfig.types';
import { TProviders } from '../../../IAuthProviders.types';
import { IAssentlyResponse, assentlyStart } from '../client/assentlyClient';
import { addAssentlyScriptToDom } from '../utils/addAssentlyScriptToDom';
import { getAssentlyToken } from './utils/getAssentlyToken';
import { handleAssentlyEvent } from './utils/handleAssentlyEvent';

export const useAssentlyProvider = ({
  axoApiUrl = '',
  serviceSourceUrl,
  onError,
  onCanceled,
  onSuccess,
  onStart,
}: IAuthProviderConfig) => {
  const isAddingScriptToDOM = useRef(false);
  const [assentlyScriptOnDOM, setAssentlyScriptOnDOM] = useState<
    HTMLScriptElement | undefined
  >(undefined);

  const handleOnCallback = useCallback(
    async (response: IAssentlyResponse) =>
      handleAssentlyEvent({
        response,
        url: axoApiUrl,
        onSuccess,
        onError,
        onCanceled,
      }),
    [onSuccess, onError, onCanceled, axoApiUrl]
  );

  const getAssentlyTokenAndAddScriptToDOM = useCallback(async () => {
    try {
      const _assentlyToken = await getAssentlyToken(axoApiUrl);

      if (!_assentlyToken) throw new Error('Failed to get Assently token');

      const assentlyScript = addAssentlyScriptToDom(
        serviceSourceUrl as string,
        {
          country: MarketCountryCode.Norway,
          token: _assentlyToken,
          onCallback: handleOnCallback,
        }
      );

      setAssentlyScriptOnDOM(assentlyScript);
    } catch (error) {
      onError({
        errorMessage: 'Failed to get Assently token',
        provider: TProviders.BankID,
      });
    } finally {
      isAddingScriptToDOM.current = false;
    }
  }, [axoApiUrl, serviceSourceUrl, onError, handleOnCallback]);

  useEffect(() => {
    if (isAddingScriptToDOM.current) return;

    if (!assentlyScriptOnDOM) {
      isAddingScriptToDOM.current = true;
      getAssentlyTokenAndAddScriptToDOM();
    }

    return () => {
      if (assentlyScriptOnDOM) {
        assentlyScriptOnDOM.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onStart = useCallback(() => {
    onStart?.(TProviders.BankID);
    assentlyStart();
  }, [onStart]);

  return useMemo(() => ({ authenticate: _onStart }), [_onStart]);
};
