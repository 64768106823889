import { ILocalizationConfig } from '../type';

export const no: ILocalizationConfig = {
  genericErrorAlert: {
    title: 'Sjekk at du er koblet til nett og prøv igjen.',
    paragraphs: [
      'Ring oss på <a href="tel:+4722867400">+47 22 86 74 00</a> hvis du trenger videre assistanse.',
    ],
    retry: 'Prøv igjen',
  },
};
