import { FlagSetMap } from './Flag.types';

export function getActiveExperiments<T extends FlagSetMap<T>>(flagSet: T) {
  const experiments = [];

  for (const flag of Object.keys(flagSet)) {
    const experiment = sessionStorage.getItem(`ff_${flag}`);
    if (experiment) experiments.push(`${flag}:${experiment}`);
  }

  return experiments;
}
