import { createElement } from 'react';
import { ElementFactory, Question, Serializer, SurveyModel } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { Storebrand } from './Storebrand';
import { shouldDisplayStorebrand } from './util';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): React.ReactElement | null {
    const { display, reason } = shouldDisplayStorebrand({
      survey: this.question.survey as SurveyModel,
    });

    if (!display) return <div hidden data-reason={reason} />;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.question.value = event.target.value === 'yes';
    };

    return <Storebrand onChange={onChange} />;
  }
}

export function registerStorebrandQuestion() {
  const questionType = 'Storebrand';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
