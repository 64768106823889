import { FC, ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';
import classNames from '../../../utils/class-names';
import MessageIcon from '../../atoms/MessageIcon';
import { Span } from '../../atoms/StyledElements';
import styles from './MessageBox.module.scss';

enum ClassNames {
  Root = 'root',
}
type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IMessageBox {
  variety?: 'warning' | 'error' | 'info' | 'success' | 'light';
  text?: string;
  classes?: ClassNamesProp;
  iconVisible?: boolean;
  marginTop?: boolean;
  children?: ReactNode;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const MessageBox: FC<IMessageBox> = ({
  variety = 'warning',
  iconVisible = true,
  text,
  children,
  classes,
  marginTop = false,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        styles[variety],
        classes?.root,
        marginTop ? styles.marginTop : ''
      )}
      {...props}
    >
      {iconVisible && <div className={styles.svgHolder}>
        <MessageIcon variety={variety} />
      </div>}

      {text && (
        <Span
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}
        />
      )}

      {children && <div>{children}</div>}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default MessageBox;
