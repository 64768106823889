const creditCardDebtPriority: string[] = [
  'creditCardDebt',
  'unsecuredDebt',
  'cash',
];

const unsecuredDebtPriority: string[] = [
  'unsecuredDebt',
  'creditCardDebt',
  'cash',
];

const debtRegistryPriority: string[] = [
  'consumerLoans',
  'bearingCreditCard',
  'nonBearingCreditCard',
  'cash',
];

type TGetAllocationPriority = {
  isDebtRegistry?: boolean;
  creditCardDebt?: number;
  unsecuredDebt?: number;
};

export const getAllocationPriority = ({
  isDebtRegistry = false,
  creditCardDebt = 0,
  unsecuredDebt = 0,
}: TGetAllocationPriority) => {
  if (isDebtRegistry) {
    return debtRegistryPriority;
  }

  return creditCardDebt > unsecuredDebt
    ? creditCardDebtPriority
    : unsecuredDebtPriority;
};
