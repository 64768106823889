/* eslint-disable max-classes-per-file */
import { IMessageBox } from '@axo/deprecated/util/ui-components';
import { Message, MessageProps } from '@axo/ui-core/components/Message';
import React from 'react';
import {
  ElementFactory,
  LocalizableString,
  Question,
  Serializer,
} from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps'; // Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
    this.createLocalizableString('text', this, false);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get visibleIf(): string {
    return this.getPropertyValue('visibleIf', 'true');
  }

  public set visibleIf(val: string) {
    this.setPropertyValue('visibleIf', val);
  }

  public get iconVisible(): boolean {
    return this.getPropertyValue('iconVisible');
  }

  public set iconVisible(val: boolean) {
    this.setPropertyValue('iconVisible', val);
  }

  public get text(): string {
    return this.getLocalizableStringText('text');
  }

  public set text(val: string) {
    this.setLocalizableStringText('text', val);
  }

  public get variety(): IMessageBox['variety'] {
    return this.getPropertyValue('variety');
  }

  public set variety(val: IMessageBox['variety']) {
    this.setPropertyValue('variety', val);
  }

  get locText(): LocalizableString {
    return this.getLocalizableString('text');
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    const { style, ...props } = getBasicQuestionProps(this, this.questionBase);

    const show = this.question.survey.runExpression(this.question.visibleIf);

    type Variety = Required<IMessageBox>['variety'];
    type Variant = Required<MessageProps>['variant'];
    const varietyToVariantMap: Record<Variety, Variant> = {
      error: 'error',
      warning: 'warn',
      info: 'info',
      success: 'success',
      light: 'neutral',
    };

    if (!show) return <></>;
    return (
      <div style={style}>
        {/** keeping this for emergency */}
        {/*<MessageBox*/}
        {/*  iconVisible={this.question.iconVisible}*/}
        {/*  text={this.question.text}*/}
        {/*  variety={this.question.variety || 'warning'}*/}
        {/*  {...props}*/}
        {/*/>*/}
        <Message
          hasIcon={this.question.iconVisible}
          variant={
            this.question.variety
              ? varietyToVariantMap[this.question.variety]
              : 'warn'
          }
        >
          {this.question.text}
        </Message>
      </div>
    );
  }
}

export function registerMessageBoxQuestion() {
  const questionType = 'Axo Message Box';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'text',
        category: 'general',
        type: 'string',
        serializationProperty: 'locText',
      },
      {
        name: 'visibleIf',
        category: 'general',
        type: 'string',
      },
      {
        name: 'iconVisible',
        category: 'general',
        type: 'boolean',
      },
      {
        name: 'variety',
        category: 'general',
        type: 'string',
      },
    ],
    () => new QuestionModel(questionType, '')
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
