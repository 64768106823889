// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../shared/data-access/prepr/src/@types/preprio.d.ts" />
import { createPreprClient } from '@preprio/nodejs-sdk';
import { z } from 'zod';

import { SiteProperties } from '@axo/shared/data-access/prepr/@types/prepr.shared.schema.graphql.types';

// TODO to be moved in correct place
export const textSchema = z.object({
  _id: z.string(),
  __typename: z.literal('Text'),
  html: z.string(),
  body: z.string(),
  text: z.string(),
  format: z
    .union([
      z.literal('H1'),
      z.literal('H2'),
      z.literal('H3'),
      z.literal('H4'),
      z.literal('H5'),
      z.literal('H6'),
      z.literal('HTML'),
      z.literal('Code'),
    ])
    .nullable(),
});

// TODO refactor usage: load json, parse and cast to type... does it require the defaults so it doesnt break ?
export const termsSchema = z.object({
  general_terms_modal_title: z.string().nullable().default(''),
  general_terms_version: z.string().nullable().default(''),
  general_terms_modal_button_text: z.string().nullable().default(''),
  general_terms: z.array(textSchema).nullable().default([]),
  general_terms_intro_paragraph: z.array(textSchema).nullable().default([]),

  data_retention_modal_title: z.string().nullable().default(''),
  data_retention_version: z.string().nullable().default(''),
  data_retention_modal_button_text: z.string().nullable().default(''),
  data_retention_terms: z.array(textSchema).nullable().default([]),
  data_retention_intro_paragraph: z.array(textSchema).nullable().default([]),

  customer_club_modal_title: z.string().nullable().default(''),
  customer_club_version: z.string().nullable().default(''),
  customer_club_modal_button_text: z.string().nullable().default(''),
  customer_club_terms: z.array(textSchema).nullable().default([]),
  customer_club_intro_paragraph: z.array(textSchema).nullable().default([]),
});

export type ITermsData = z.infer<typeof termsSchema>;

export type TermsData = Pick<
  SiteProperties,
  | 'general_terms_modal_title'
  | 'general_terms_version'
  | 'general_terms_modal_button_text'
  | 'general_terms_intro_paragraph'
  | 'general_terms'
  | 'data_retention_modal_title'
  | 'data_retention_version'
  | 'data_retention_modal_button_text'
  | 'data_retention_intro_paragraph'
  | 'data_retention_terms'
  | 'customer_club_modal_title'
  | 'customer_club_version'
  | 'customer_club_modal_button_text'
  | 'customer_club_intro_paragraph'
  | 'customer_club_terms'
>;

export type termsResponse = {
  SiteProperties: TermsData;
};

export const fetchTerms = async ({
  market,
  token,
}: {
  market: 'no' | 'se' | 'fi';
  token: string;
}) => {
  const prepr = createPreprClient({
    token,
    baseUrl: 'https://graphql.prepr.io/graphql',
    userId: undefined,
  });
  const id = {
    no: '1ea2bcc4-1482-4ceb-9400-850deb70333e',
    se: '0d7be5d0-ffd5-47d9-8908-c155c28737cf',
    fi: '99719561-bd0e-433e-98d7-d2a6eeb66f3a',
  }[market];

  const result = await prepr
    .graphqlQuery(
      `#graphql
          query {
            SiteProperties(id: "${id}") {
              _id
              general_terms_modal_title
              general_terms_version
              general_terms_modal_button_text
              general_terms_intro_paragraph {
                ...text
              }
              general_terms {
                ...text
              }

              data_retention_modal_title
              data_retention_version
              data_retention_modal_button_text
              data_retention_intro_paragraph {
                ...text
              }
              data_retention_terms {
                ...text
              }

              customer_club_modal_title
              customer_club_version
              customer_club_modal_button_text
              customer_club_intro_paragraph {
                ...text
              }
              customer_club_terms {
                ...text
              }
            }
          }

          fragment text on Text {
            _id
            __typename
            html
            body
            text
            format
          }
    `
    )
    .fetch<termsResponse>();

  const terms = result.data?.SiteProperties as TermsData;

  if ('errors' in terms)
    throw new Error(
      'graphql query failed: ' + JSON.stringify(result, undefined, 2)
    );

  return terms;
};
