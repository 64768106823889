import styles from './DebtRegistryUnavailable.module.scss';

const defaultDisabledMessage =
  'We couldn’t fetch your debt right now, please add your data manually.';

type TDebtRegistryNOManualProps = {
  message?: string;
};

const DebtRegistryUnavailable = ({ message }: TDebtRegistryNOManualProps) => {
  const disabledMessage = message || defaultDisabledMessage;

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="New02Icon / ChatCircleText">
            <path
              id="Vector"
              d="M8.19917 2.375C7.07696 2.37476 5.97381 2.66506 4.99708 3.21764C4.02035 3.77023 3.20333 4.56628 2.62553 5.52831C2.04773 6.49034 1.72885 7.58558 1.69992 8.70741C1.67098 9.82925 1.93298 10.9395 2.46042 11.93L1.75105 14.0581C1.69229 14.2343 1.68376 14.4234 1.72642 14.6042C1.76908 14.7849 1.86124 14.9503 1.99258 15.0816C2.12391 15.2129 2.28923 15.3051 2.47 15.3478C2.65077 15.3904 2.83985 15.3819 3.01605 15.3231L5.14417 14.6138C6.0159 15.0774 6.98181 15.3364 7.96856 15.371C8.95532 15.4056 9.937 15.215 10.8391 14.8136C11.7412 14.4122 12.54 13.8105 13.1748 13.0543C13.8096 12.2981 14.2639 11.4072 14.503 10.4492C14.7421 9.49124 14.7599 8.49138 14.5549 7.52552C14.3499 6.55967 13.9276 5.65321 13.3199 4.87495C12.7123 4.09669 11.9354 3.46708 11.0481 3.03391C10.1608 2.60074 9.18654 2.37541 8.19917 2.375ZM10.1992 10.375H6.19917C6.06656 10.375 5.93939 10.3223 5.84562 10.2286C5.75185 10.1348 5.69917 10.0076 5.69917 9.875C5.69917 9.74239 5.75185 9.61521 5.84562 9.52145C5.93939 9.42768 6.06656 9.375 6.19917 9.375H10.1992C10.3318 9.375 10.459 9.42768 10.5527 9.52145C10.6465 9.61521 10.6992 9.74239 10.6992 9.875C10.6992 10.0076 10.6465 10.1348 10.5527 10.2286C10.459 10.3223 10.3318 10.375 10.1992 10.375ZM10.1992 8.375H6.19917C6.06656 8.375 5.93939 8.32232 5.84562 8.22855C5.75185 8.13479 5.69917 8.00761 5.69917 7.875C5.69917 7.74239 5.75185 7.61521 5.84562 7.52145C5.93939 7.42768 6.06656 7.375 6.19917 7.375H10.1992C10.3318 7.375 10.459 7.42768 10.5527 7.52145C10.6465 7.61521 10.6992 7.74239 10.6992 7.875C10.6992 8.00761 10.6465 8.13479 10.5527 8.22855C10.459 8.32232 10.3318 8.375 10.1992 8.375Z"
              fill="#33383D"
            />
          </g>
        </svg>
      </div>

      <span className={styles.text}>{disabledMessage}</span>
    </div>
  );
};

export default DebtRegistryUnavailable;
