import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
// Notes: State MUST be handled by the question property. This is how values are passed to the survey instance and other questions

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
  }

  // TODO: Figure out how this can possibly be undefined when it is set in the constructor. Where is this
  // class instantiated without a questionType argument?
  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected mapValue(value: string) {
    return Number(value);
  }

  get question() {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element {
    return <div></div>;
  }
}

export function registerEmptyQuestion() {
  const questionType = 'Axo Empty';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
