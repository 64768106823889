import { ComponentProps, ReactNode, useCallback } from 'react';

import { classNames } from '@axo/shared/util/dom';
import { getFormattedAmount } from '@axo/shared/util/formatters';
import { useExtendedLoanOverviewContext } from '../../context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import { TBlockType } from '../../ExtendedLoanOverview.types';
import Detail from '../Detail/Detail';
import Slider from '../Slider/Slider';
import SteppedNumberInput from '../SteppedNumberInput/SteppedNumberInput';
import styles from './LoanOverviewBlock.module.scss';

export type TLoanOverviewBlockProps = {
  type?: TBlockType;
  title?: string;
  isEditable?: boolean;
  value: number;
  labelSuffix?: string;
  editType?: 'slider' | 'steppedNumberInput';
  onChange?: (newValue: number) => void;
  details?: Omit<ComponentProps<typeof Detail>, 'onChangeComplete'>[];
  minValue?: number;
  maxValue?: number;
  step?: number;
  onEnableEditModeClick?: (mode: TBlockType | undefined) => void;
  isEditMode?: boolean;
  warningMessage?: ReactNode;
  className?: string;
  isWarning?: boolean;
  sliderConfig?: Partial<ComponentProps<typeof Slider>>;
  isInfoButtonVisible?: boolean;
  description?: ReactNode;
  onInfoButtonClick?: () => void;
};

const LoanOverviewBlock = ({
  type,
  title = '',
  value,
  isEditable = true,
  labelSuffix = '',
  editType = 'slider',
  minValue = 0,
  maxValue = 10000,
  step = 500,
  isEditMode = false,
  warningMessage,
  details,
  className,
  isWarning,
  sliderConfig,
  isInfoButtonVisible,
  description,
  onInfoButtonClick,
  onEnableEditModeClick,
  onChange,
}: TLoanOverviewBlockProps) => {
  const { currency } = useExtendedLoanOverviewContext();

  const handleEditModeClick = useCallback(() => {
    onEnableEditModeClick?.(type);
  }, [onEnableEditModeClick, type]);

  const handleOnChange = useCallback(
    (newValue: number) => {
      onChange?.(newValue);
    },
    [onChange]
  );

  const valueFormatter = useCallback(
    (value: number) => (
      <div className={styles['stepped-number-input-value']}>
        <span className={styles.title}>{title}</span>
        <span
          className={classNames(
            styles.value,
            isWarning && styles['value--warning']
          )}
        >{`${value} ${labelSuffix}`}</span>
      </div>
    ),
    [labelSuffix, title, isWarning]
  );

  const hasDetails = !!details?.length;

  const isEditButtonVisible = isEditable && !isEditMode;

  const showSlider = !hasDetails && editType === 'slider' && isEditMode;
  const showSteppedNumberInput =
    !hasDetails && editType === 'steppedNumberInput' && isEditMode;

  if (showSteppedNumberInput)
    return (
      <SteppedNumberInput
        value={value}
        onChange={handleOnChange}
        minValue={minValue}
        maxValue={maxValue}
        isWarning={isWarning}
        valueFormatter={valueFormatter}
      />
    );

  return (
    <div className={classNames(styles['editable-data'], className)}>
      <div className={styles['title-container']}>
        <div className={styles.left}>
          {!showSteppedNumberInput && <h4 className={styles.title}>{title}</h4>}
          {isInfoButtonVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={onInfoButtonClick}
              className={styles.info}
            >
              <path
                d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM7.75 4.5C7.89834 4.5 8.04334 4.54399 8.16668 4.6264C8.29002 4.70881 8.38615 4.82594 8.44291 4.96299C8.49968 5.10003 8.51453 5.25083 8.48559 5.39632C8.45665 5.5418 8.38522 5.67544 8.28033 5.78033C8.17544 5.88522 8.04181 5.95665 7.89632 5.98559C7.75083 6.01453 7.60003 5.99968 7.46299 5.94291C7.32595 5.88614 7.20881 5.79001 7.1264 5.66668C7.04399 5.54334 7 5.39834 7 5.25C7 5.05109 7.07902 4.86032 7.21967 4.71967C7.36032 4.57902 7.55109 4.5 7.75 4.5ZM8.5 11.5C8.23479 11.5 7.98043 11.3946 7.7929 11.2071C7.60536 11.0196 7.5 10.7652 7.5 10.5V8C7.36739 8 7.24022 7.94732 7.14645 7.85355C7.05268 7.75979 7 7.63261 7 7.5C7 7.36739 7.05268 7.24021 7.14645 7.14645C7.24022 7.05268 7.36739 7 7.5 7C7.76522 7 8.01957 7.10536 8.20711 7.29289C8.39465 7.48043 8.5 7.73478 8.5 8V10.5C8.63261 10.5 8.75979 10.5527 8.85356 10.6464C8.94732 10.7402 9 10.8674 9 11C9 11.1326 8.94732 11.2598 8.85356 11.3536C8.75979 11.4473 8.63261 11.5 8.5 11.5Z"
                fill="#4281C8"
              />
            </svg>
          )}
        </div>

        <div className={styles.right}>
          {showSteppedNumberInput && (
            <span className={styles.title}>{title}</span>
          )}

          <span className={styles.value}>{`${getFormattedAmount(
            value
          )} ${labelSuffix}`}</span>

          {isEditButtonVisible && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={handleEditModeClick}
              className={styles['edit-icon']}
            >
              <path
                d="M14 7.99991V12.9999C14 13.2651 13.8946 13.5195 13.7071 13.707C13.5196 13.8945 13.2652 13.9999 13 13.9999H3C2.73478 13.9999 2.48043 13.8945 2.29289 13.707C2.10536 13.5195 2 13.2651 2 12.9999V2.9999C2 2.73469 2.10536 2.48033 2.29289 2.2928C2.48043 2.10526 2.73478 1.9999 3 1.9999H8C8.13261 1.9999 8.25979 2.05258 8.35355 2.14635C8.44732 2.24012 8.5 2.3673 8.5 2.4999C8.5 2.63251 8.44732 2.75969 8.35355 2.85346C8.25979 2.94723 8.13261 2.9999 8 2.9999H3V12.9999H13V7.99991C13 7.8673 13.0527 7.74012 13.1464 7.64635C13.2402 7.55258 13.3674 7.49991 13.5 7.49991C13.6326 7.49991 13.7598 7.55258 13.8536 7.64635C13.9473 7.74012 14 7.8673 14 7.99991ZM14.3538 4.35366L8.35375 10.3537C8.30728 10.4001 8.25212 10.4369 8.19143 10.462C8.13073 10.4871 8.06568 10.5 8 10.4999H6C5.86739 10.4999 5.74021 10.4472 5.64645 10.3535C5.55268 10.2597 5.5 10.1325 5.5 9.9999V7.99991C5.49995 7.93422 5.51284 7.86918 5.53793 7.80848C5.56303 7.74778 5.59983 7.69262 5.64625 7.64615L11.6462 1.64615C11.6927 1.59967 11.7478 1.56279 11.8085 1.53763C11.8692 1.51246 11.9343 1.49951 12 1.49951C12.0657 1.49951 12.1308 1.51246 12.1915 1.53763C12.2522 1.56279 12.3073 1.59967 12.3538 1.64615L14.3538 3.64615C14.4002 3.69259 14.4371 3.74774 14.4623 3.80843C14.4874 3.86913 14.5004 3.9342 14.5004 3.99991C14.5004 4.06561 14.4874 4.13068 14.4623 4.19138C14.4371 4.25207 14.4002 4.30722 14.3538 4.35366ZM13.2913 3.99991L12 2.70678L11.2069 3.49991L12.5 4.79303L13.2913 3.99991Z"
                fill="#4281C8"
              />
            </svg>
          )}
        </div>
      </div>
      {description && (
        <>
          <div className={styles.divider} />
          {description}
        </>
      )}
      {warningMessage}
      {showSlider && (
        <Slider
          value={value}
          max={maxValue}
          min={minValue}
          onChange={handleOnChange}
          step={step}
          currency={currency}
          {...sliderConfig}
        />
      )}
      {hasDetails && (
        <div className={styles.details}>
          <div className={styles.divider} />
          {details?.map((detail, index) => (
            <Detail
              key={detail.label.concat(index.toString())}
              {...detail}
              isEditMode={isEditMode}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LoanOverviewBlock;
