import { tracking } from '@axo/form/data-access/tracking';

const customFetch: typeof window.fetch = (input, init): Promise<Response> => {
  return tracking
    .withMonitoring(() => withThrowError(() => fetch(input, init)))
    .then((response) => response);
};

const withThrowError = async (
  fn: () => Promise<Response>
): Promise<Response> => {
  const response = await fn();

  if (response.ok) {
    return response;
  }

  const responseJson = await response.json();

  const completeErrorMessage = `Error fetching ${response.url}: ${
    response.status
  } ${response.statusText} ${responseJson.error || responseJson.message}`;

  throw new HttpError(completeErrorMessage, response);
};

export class HttpError extends Error {
  public override readonly name = 'HttpError';
  public readonly statusCode: number;
  public readonly url: string;

  constructor(message: string, response: Response) {
    super(message);
    this.statusCode = response.status;
    this.url = response.url;
  }
}

export default customFetch;
